import React from 'react'
import { selectBreadcrumbs } from '@Core/Redux/Ui'
import Breadcrumbs from '@Common/Components/Breadcrumbs'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { IconButton } from '@mui/material';
import { Logout, Person, Settings } from '@mui/icons-material';
import Box from './Box';
import SettingsDialog from './SettingsDialog';
import UserSettingsDialog from '@Auth/Components/UserSettingsDialog'
import NotificationToggle from '@Notifications/Components/NotificationToggle'
import { logout } from '@Auth/Services/Msal'
import AdvandecSettingsModal from './AdvancedSettingsModal'

const Wrapper = styled.header`
  align-items: center;
  background: ${({ theme }) => theme.palette.navbar.main};
  color: ${({ theme }) => theme.palette.navbar.contrastText};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: .8rem 1rem;
`

const Navbar = () => {
  const dispatch = useDispatch()
  const breadcrumbsItems = useSelector(selectBreadcrumbs)
  const [settingsDialogIsOpen, setSettingsDialogIsOpen] = React.useState(false)
  const [advSettingsDialogIsOpen, setAdvSettingsDialogIsOpen] = React.useState(false)
  const [userSettingsDialogIsOpen, setUserSettingsDialogIsOpen] = React.useState()

  const handleSettingsClick = (evt) => {
    if (evt.ctrlKey) setAdvSettingsDialogIsOpen(true)
    else setSettingsDialogIsOpen(true)
  }

  return (
    <Wrapper>
      <Breadcrumbs items={breadcrumbsItems} />
      <Box direction='row' align='center'>
        <IconButton onClick={() => setUserSettingsDialogIsOpen(true)}>
          <Person />
        </IconButton>
        <NotificationToggle />
        <IconButton onClick={handleSettingsClick}>
          <Settings />
        </IconButton>
        <IconButton onClick={() => logout(dispatch)}>
          <Logout />
        </IconButton>
      </Box>
      {settingsDialogIsOpen && (
        <SettingsDialog onClose={() => setSettingsDialogIsOpen(false)} />
      )}
      {advSettingsDialogIsOpen && (
        <AdvandecSettingsModal onClose={() => setAdvSettingsDialogIsOpen(false)} />
      )}
      {userSettingsDialogIsOpen && (
        <UserSettingsDialog onClose={() => setUserSettingsDialogIsOpen(false)} />
      )}
    </Wrapper>
  )
}

export default Navbar
