import { useMsal, useAccount } from '@azure/msal-react'
import i18next from 'i18next'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { authTokenRequest } from '@Auth/Config'
import { setSaml, setCredentials, selectAccessToken, setUser } from '@Auth/Redux'
import { logout } from '@Auth/Services/Msal'
import Logger from '@Common/Utils/Logger'
import config from '@Config'
// import { history } from '@Core/Redux/Store'

// at first load, the account prop is null -> an init completed would be dispatched
// but if then the account is evaluated, it is a problem, the init completed
// shouldn't have been dispatched in that case -> clear timeout
let initCompleteTimeout

export const useAuthentication = (condition, deps = []) => {
  const dispatch = useDispatch()
  const [isComplete, setIsComplete] = React.useState(false)
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const accessToken = useSelector(selectAccessToken)

  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0] || {})

  React.useEffect(() => {
    if (condition && account) {
      clearTimeout(initCompleteTimeout)
      Logger.debug('Acquiring token silent', account)
      dispatch(setSaml(account))
      instance
        .acquireTokenSilent({
          ...authTokenRequest,
          account: account,
        })
        .then(async (response) => {
          if (response) {
            Logger.debug('Token acquired')
            dispatch(setCredentials({ accessToken: response.accessToken, username: response.account.username }))
          } else {
            Logger.debug('Init completed, with account but no token')
            setIsComplete(true)
          }
        })
        .catch((err) => {
          Logger.warning('Acquire token silent error: ', err)
          // Logger.info('Performing logout')
          // logout(dispatch)
          setIsComplete(true)
        })
    } else { // @TODO check SSO from other app
      Logger.debug('Msal, performing silent sso') // eslint-disable-line
      instance
        .ssoSilent({
          ...authTokenRequest,
        })
        .then(async (response) => {
          Logger.debug('Msal sso silent response', response) // eslint-disable-line
          if (response) {
            Logger.debug('Token acquired')
            dispatch(setCredentials({ accessToken: response.accessToken, username: response.account.username }))
          } else {
            Logger.debug('Init completed, with account but no token')
            setIsComplete(true)
          }
        })
        .catch((err) => {
          Logger.warning('Token ssoSilent error: ', err)
          initCompleteTimeout = setTimeout(() => {
            Logger.debug('Init completed, no account')
            setIsComplete(true)
          }, 2000)
        })
    }
  }, [condition, ...deps])

  React.useEffect(() => {
    const fetchUser = async (token) => {
      try {
        const response = await fetch(
          config.apiBasePath + '/current-user',
          {
            headers: {'Authorization': `Bearer ${token}`}
          }
        );
        if (response.status === 200) {
          const responseJson = await response.json();
          Logger.debug('Whoami completed', responseJson)
          dispatch(setUser(responseJson))
          setTimeout(() => { // wait for user to be set
            setIsComplete(true)
          }, 1000)
        } else {
          Logger.debug('Whoami error', response)
          Logger.debug('Token is ok but whoami is ko, now we perform a lgout')
          toast.error(i18next.t('auth:errors.YouAreNotAuthorizedToAccessThisApplication'))
          logout(dispatch)
        }
      } catch (err) {
        Logger.debug('Whoami error', err)
        Logger.debug('Token is ok but whoami is ko, now we perform a lgout')
        toast.error(i18next.t('auth:errors.YouAreNotAuthorizedToAccessThisApplication'))
        logout(dispatch)
      }
    }
    if (accessToken) {
      fetchUser(accessToken)
      setIsAuthenticated(true)
    }
  }, [accessToken])

  return {
    isComplete,
    isAuthenticated,
  }
}
