import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'

const options = [
  { value: 'DEV', label: 'DEV' },
  { value: 'RELEASE', label: 'RELEASE' },
  { value: 'STAGING', label: 'STAGING' },
  { value: 'TEST', label: 'TEST' },
]

const RingDropdown = ({ value, onChange, required, error, helperText, ...other }) => {
  const { t } = useTranslation()

  return (
    <Dropdown
      label={t('common:fields.ring')}
      required={required}
      value={value}
      onChange={onChange}
      options={options}
      error={error}
      helperText={helperText}
      {...other}
    />
  )
}

RingDropdown.defaultProps = {
  required: false,
}

RingDropdown.propTypes = FieldType

export default RingDropdown
