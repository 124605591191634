import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import FiltersForm from '@Common/Forms/FiltersForm'
import { setStateFromEvent } from '@Common/Utils/Events'
import SwDeltaStatusDropdown from '@SoftwareCatalog/Components/SwDeltaStatusDropdown'

const SwDeltaFilterForm = ({ onClose, fields, setField, onReset, onSave }) => {
  const { t } = useTranslation()
  return (
    <FiltersForm title={t('sc:ui.FilterSoftwareComponentDeltas')} onClose={onClose} onReset={onReset} onSave={onSave}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <SwDeltaStatusDropdown value={fields.status} onChange={setStateFromEvent(setField('status'))} fullWidth />
        </Grid>
      </Grid>
    </FiltersForm>
  )
}

SwDeltaFilterForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  setField: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default SwDeltaFilterForm
