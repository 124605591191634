import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import TextField from '@Common/Components/TextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import FiltersForm from '@Common/Forms/FiltersForm'

const SwComponentFilterForm = ({ onClose, fields, setField, onReset, onSave }) => {
  const { t } = useTranslation()
  return (
    <FiltersForm title={t('sc:ui.FilterSoftwareComponents')} onClose={onClose} onReset={onReset} onSave={onSave}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextField
            label={t('sc:fields.name')}
            value={fields.name}
            onChange={setStateFromEvent(setField('name'))}
            fullWidth
          />
        </Grid>
      </Grid>
    </FiltersForm>
  )
}

SwComponentFilterForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  setField: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default SwComponentFilterForm
