import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import { prop } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectThemeMode } from '@Core/Redux/Ui'
import { chartBackground, chartCommonOptions, chartXAxisCommonOptions, chartYAxisCommonOptions } from '@Dashboard/Utils/Charts'

const VehiclesRingColumnChart = ({ stats }) => {
  const { t } = useTranslation()
  const themeMode = useSelector(selectThemeMode)

  const options = {
    ...chartCommonOptions(themeMode),
    chart: {
      backgroundColor: chartBackground(themeMode),
      type: 'column',
      height: '350px',
    },
    title: {
      text: t('dashboard:ui.Vehicles'),
      // style: chartTitleStyle(themeMode),
      style: { display: 'none' }
    },
    xAxis: {
      ...chartXAxisCommonOptions(themeMode),
      categories: stats.PCM3.map(prop('ring')),
    },
    yAxis: chartYAxisCommonOptions(themeMode, t('dashboard:ui.Number')),
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      useHTML: true,
    },
    series: [
      {
        name: 'PCM3',
        color: '#00A2FF',
        data: stats.PCM3.map(prop('cardinality')),
        // data: stats.PCM3.map(() => Math.floor(Math.random() * 1000))
      },
      {
        name: 'IHP',
        color: '#61D836',
        data: stats.IHP.map(prop('cardinality')),
        // data: stats.IHP.map(() => Math.floor(Math.random() * 1000))
      },
    ],
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

VehiclesRingColumnChart.propTypes = {
  stats: PropTypes.shape({
    PCM3: PropTypes.arrayOf(
      PropTypes.shape({
        cardinality: PropTypes.number,
        connectedCardinality: PropTypes.number,
        deviceType: PropTypes.string,
        domain: PropTypes.string,
        ring: PropTypes.string,
      }),
    ),
    IHP: PropTypes.arrayOf(
      PropTypes.shape({
        cardinality: PropTypes.number,
        connectedCardinality: PropTypes.number,
        deviceType: PropTypes.string,
        domain: PropTypes.string,
        ring: PropTypes.string,
      }),
    ),
  }),
}

export default React.memo(VehiclesRingColumnChart)
