import React from 'react'
import { Route, Routes } from 'react-router-dom'

import config from '@Config'

import BundlesListView from '../Views/BundlesListView'
import SwComponentDetailView from '../Views/BundleSwComponentDetailView'
import BundleDetailView from '../Views/BundleDetailView'
import PageNotFoundView from '@Core/Views/PageNotFoundView'

export const BundlesRouter = () => {
  return (
    <Routes>
      <Route path={config.urls.bundles.detail} element={<BundleDetailView />} />
      <Route path={config.urls.bundles.main} element={<BundlesListView />} />
      <Route path={config.urls.bundles.swDetail} element={<SwComponentDetailView />} />
      <Route path={'*'} element={<PageNotFoundView />} />
    </Routes>
  )
}

export default BundlesRouter
