import { Cached } from '@mui/icons-material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { selectUtcEnabled } from '@Core/Redux/Ui'

import getStorage from '../../../Common/Services/Storage'
import config from '../../../Config'
import { setStateFromEvent } from '../../Utils/Events'
import { useCurrentUser } from '../../Utils/Hooks'
import ActionsButton from '../ActionsButton'
import Box from '../Box'
import Cursor from '../Cursor'
import Dropdown from '../Dropdown'
import Loader from '../Loader'
import ExportAction from './ExportAction'
import SettingsDialog from './SettingsDialog'
import { Toolbar, BulkAndChildren, SettingsIcon, Tr } from './Styled'
import TablePagination from './TablePagination'
import { createColumnsProps, getDisplayValue } from './Utils'
import { useResizableColumns } from './Hooks'

const storageService = getStorage()

const DataTable = ({
  name,
  autoWidth,
  refreshData,
  data,
  dataCount,
  loading,
  qsAdditions,
  selectable,
  selected,
  onSelect,
  model,
  dftListPerPage,
  dftSortDirection,
  dftSortField,
  listDisplay,
  fieldsMapping,
  qs,
  actions,
  bulkActions,
  onAction,
  noSettings,
  noExport,
  noBulkSelection,
  onExpandRow,
  sortMapping,
  disableSorting,
  disableCheckbox,
  children,
  filterForm,
  filterFormActive,
  exportApi,
  refetch,
  disableSticky,
  disableResizing,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  useSelector(selectUtcEnabled) // subscribe just to update

  const storageKey = `${name}_DATA_TABLE_SETTINGS`

  // columns resizing
  const resizingData = storageService.get(
    `${name}_DATA_TABLE_RESIZING`,
    {},
  )

  // first fetch
  const [firstFetch, setFirstFetch] = React.useState(false)

  // sticky
  const sticky = {
    position: 'sticky',
    background: theme.palette.content.main,
    zIndex: 2,
  }

  const stickyLeft = {
    ...sticky,
    left: 0,
  }

  const stickyRight = {
    ...sticky,
    right: 0,
  }

  // check storage for invalidation
  if (config.localStorageInvalidation[storageKey] && storageService.get(storageKey)) {
    const storageTimestamp = storageService.getNested(storageKey, 'timestamp', 0)
    if (storageTimestamp < config.localStorageInvalidation[storageKey]) {
      storageService.remove(`${name}_DATA_TABLE_SETTINGS`)
      toast.warn(t('common:ui.ForceResetChangelistTableSettingsWarning'))
    }
  }

  // logged user
  const user = useCurrentUser()
  // primary key
  const primaryKeyValue = (item) =>
    model.primaryKey
      .split(',')
      .map((f) => item[f])
      .join('-')

  // list per page
  const [listPerPage, setListPerPage] = React.useState(
    parseInt(storageService.getNested(storageKey, 'listPerPage', dftListPerPage)),
  )

  // sort field
  const [defaultSortField, setDefaultSortField] = React.useState(
    storageService.getNested(storageKey, 'defaultSortField', dftSortField),
  )

  const [defaultSortDirection, setDefaultSortDirection] = React.useState(
    storageService.getNested(storageKey, 'defaultSortDirection', dftSortDirection),
  )

  // pagination
  const [page, setPage] = React.useState(0)

  // sorting
  const sortQs = (field) => (sortMapping[field] ? sortMapping[field] : field)

  const [sort, setSort] = React.useState({
    field: sortQs(qs.base.orderBy),
    direction: qs.base.orderType,
  })
  const handleSortChange =
    (field, direction = null) =>
    () =>
      setSort({
        field: sortQs(field),
        direction:
          direction !== null ? direction : sort.field === sortQs(field) && sort.direction === 'asc' ? 'desc' : 'asc',
      })

  const handleChangeDefaultSortField = (field) => {
    setDefaultSortField(field)
    handleSortChange(field, defaultSortDirection)()
  }

  const handleChangeDefaultSortDirection = (direction) => {
    setDefaultSortDirection(direction)
    handleSortChange(defaultSortField, direction)()
  }

  // reset page when changing filtering
  React.useEffect(() => {
    setPage(0)
  }, [JSON.stringify(qsAdditions)])

  // data refreshing
  React.useEffect(() => {
    if (firstFetch) {
      refreshData({
        base: {
          pageSize: Math.max(1, listPerPage),
          pageNumber: page,
          orderBy: sort.field,
          orderType: sort.direction,
        },
        qsAdditions: { ...qsAdditions },
      })
    } else {
      setFirstFetch(true)
    }

    if (onExpandRow) {
      setOpenRow(null)
    }
  }, [JSON.stringify(qsAdditions), page, sort, listPerPage, refreshData])

  // bulk
  const [selectedBulk, setSelectedBulk] = React.useState(null)
  const executeBulkAction = () => {
    const action = R.find(R.propEq('id', selectedBulk))(bulkActions)
    return action && onAction(action.id)
  }
  // collapsable
  const [openRow, setOpenRow] = React.useState(null)

  // columns
  const [columnsProps, setColumnProps] = React.useState([])
  React.useEffect(() => {
    setColumnProps(
      R.ifElse(
        R.isNil,
        () => createColumnsProps(model.columns, listDisplay),
        R.identity,
      )(storageService.getNested(storageKey, 'columns')),
    )
  }, [storageKey, listDisplay])

  // columns selection
  const handleSelectColumn = (id) => (event) => {
    setColumnProps(
      columnsProps.map((column) =>
        R.ifElse(
          R.equals(id),
          R.always({ ...column, visible: R.prop('checked')(event.target) }),
          R.always(column),
        )(column.id),
      ),
    )
  }

  // filter form
  const [filterFormIsOpen, setFilterFormIsOpen] = React.useState(false)
  const handleOpenFilterForm = () => setFilterFormIsOpen(true)
  const handleCloseFilterForm = () => setFilterFormIsOpen(false)

  // settings
  const saveSettings = () => {
    storageService.save(storageKey, {
      timestamp: new Date().getTime(),
      columns: columnsProps,
      listPerPage,
      defaultSortField: sortQs(defaultSortField),
      defaultSortDirection,
    })
    setSettingsDialogIsOpen(false)
  }

  const resetSettings = () => {
    storageService.remove(storageKey)
    setColumnProps(createColumnsProps(model.columns, listDisplay))
    setListPerPage(dftListPerPage)
    setDefaultSortField(dftSortField)
    setDefaultSortDirection(dftSortDirection)
    setSettingsDialogIsOpen(false)
    handleSortChange(dftSortField, dftSortDirection)()
  }

  // columns to be displayed
  const displayColumns = columnsProps.filter((c) => c.visible)

  // settings
  const [settingsDialogIsOpen, setSettingsDialogIsOpen] = React.useState(false)
  const handleOpenSettings = React.useCallback(() => setSettingsDialogIsOpen(true), [setSettingsDialogIsOpen])

  // selection
  const selectedIds = selected?.map((item) => primaryKeyValue(item))
  // page selection
  const handleSelectPage = (event) => {
    const displayDataIds = displayData.map(R.prop(model.primaryKey))
    const selectedIds = selected.map(R.prop(model.primaryKey))
    onSelect(
      R.ifElse(
        R.prop('checked'),
        R.always([
          ...selected,
          ...displayData.filter(R.pipe(R.prop(model.primaryKey), R.flip(R.indexOf)(selectedIds), R.equals(-1))),
        ]),
        R.always([
          ...selected.filter(R.pipe(R.prop(model.primaryKey), R.flip(R.indexOf)(displayDataIds), R.equals(-1))),
        ]),
      )(event.target),
    )
  }
  // record selection
  const handleSelectRecord = (record) => (event) =>
    onSelect(
      R.ifElse(
        R.prop('checked'),
        R.always([...selected, record]),
        R.always(selected.filter(R.compose(R.not, R.equals(primaryKeyValue(record)), primaryKeyValue))),
      )(event.target),
      record,
      event.target.checked,
    )

  // display
  const displayData = data

  // col span calculation
  const colSpan = displayColumns.length + (selectable ? 1 : 0) + (onExpandRow ? 1 : 0) + (actions?.length ? 1 : 0)

  let totalItems = dataCount
  // end of table my friend
  if (dataCount === -1 && displayData.length < listPerPage) {
    totalItems = page * listPerPage + displayData.length
  }

  useResizableColumns(name, data, displayColumns, loading, { disabled: disableResizing })

  return (
    <>
      {(!!bulkActions.length || children) && (
        <BulkAndChildren>
          {!!bulkActions.length && (
            <>
              <Dropdown
                label={t('common:ui.Commands')}
                width="300px"
                value={selectedBulk}
                onChange={setStateFromEvent(setSelectedBulk)}
                options={bulkActions
                  .filter((a) => !a.perm || a.perm(user))
                  .map((a) => ({
                    value: a.id,
                    label: a.label,
                    icon: a.icon,
                  }))}
              />
              <Button size="small" disabled={!selectedBulk || !selected.length} onClick={executeBulkAction}>
                {t('common:ui.Go')}
              </Button>
            </>
          )}
          {children}
        </BulkAndChildren>
      )}
      {(selectable || filterForm || !noSettings || !noExport || refetch) && (
        <Toolbar bg={theme.palette.changelistToolbar.main} color={theme.palette.changelistToolbar.contrastText}>
          <Box align="center" direction="row">
            {selectable && !noBulkSelection && (
              <Typography component="div">
                {dataCount && dataCount !== -1
                  ? t('common:ui:numSelectedTot', { num: selected.length, tot: dataCount })
                  : t('common:ui:numSelected', { num: selected.length })}
              </Typography>
            )}
            {(!selectable || noBulkSelection) && dataCount !== -1 && (
              <Typography component="div">{t('ui.Total')}: {loading ? (<Loader size={12} style={{ display: 'inline-block' }} />) : dataCount}</Typography>
            )}
          </Box>
          <div>
            {!!refetch && (
              <Cursor onClick={refetch}>
                <Tooltip title={t('common:buttons.Refresh')}>
                  <Cached />
                </Tooltip>
              </Cursor>
            )}
            {filterForm && (
              <Cursor>
                <Tooltip title={t('common:actions.Filter')}>
                  <FilterAltIcon
                    style={{ marginLeft: '.5rem' }}
                    onClick={handleOpenFilterForm}
                    color={filterFormActive ? 'primary' : undefined}
                  />
                </Tooltip>
              </Cursor>
            )}
            {!noExport && (
              <ExportAction
                name={name}
                displayColumns={displayColumns}
                sortedData={displayData}
                fieldsMapping={fieldsMapping}
                marginLeft=".5rem"
                exportApi={exportApi}
                qs={qs}
              />
            )}
            {!noSettings && (
              <Cursor>
                <Tooltip title={t('common:ui.Settings')}>
                  <SettingsIcon onClick={handleOpenSettings} style={{ marginLeft: '.5rem' }} />
                </Tooltip>
              </Cursor>
            )}
          </div>
        </Toolbar>
      )}
      <TableContainer style={{ maxWidth: '100%', overflow: 'auto' }}>
        <Table style={autoWidth ? { width: 'auto' } : {}} size="small" id={`id-table-${name}`} className={`datatable${config.ui.enableDataTableColumnResizing ? ' resizable-active' : ''}`}>
          <TableHead key="thead">
            <TableRow key="thead-row">
              {selectable && (
                <TableCell padding="checkbox" style={{ width: '20px' }} key="col-selection" className='fix'>
                  {!noBulkSelection && (
                    <Checkbox
                      indeterminate={selected.length > 0 && selected.length < displayData.count}
                      checked={
                        displayData.length !== 0 &&
                        displayData.filter((item) => selectedIds.indexOf(primaryKeyValue(item)) === -1).length === 0
                      }
                      onChange={handleSelectPage}
                    />
                  )}
                </TableCell>
              )}
              {displayColumns.map((column, idx) => {
                const widthStyles = {width: column.width ? column.width : 'auto'}
                const resizableIdx = selectable ? idx + 1 : idx
                if (config.ui.enableDataTableColumnResizing && resizingData[resizableIdx]) {
                  widthStyles.width = `${resizingData[resizableIdx]}px`
                  widthStyles.minWidth = `${resizingData[resizableIdx]}px`
                  widthStyles.maxWidth = `${resizingData[resizableIdx]}px`
                }
                return (
                  <TableCell
                    key={`col-${column.id}`}
                    align={column.numeric ? 'right' : column.align || 'left'}
                    sortDirection={sort.field === column.id ? sort.direction : false}
                    style={{
                      ...widthStyles,
                      ...(idx === 0 && !disableSticky ? stickyLeft : {}),
                    }}
                    className={(idx === displayColumns.length - 1 && !actions?.length) ? 'fix th-col-name' : 'th-col-name'}
                  >
                    {disableSorting || column.disableSorting ? (
                      column.label
                    ) : (
                      <TableSortLabel
                        active={sort.field === sortQs(column.id)}
                        direction={sort.field === sortQs(column.id) ? sort.direction : 'asc'}
                        onClick={handleSortChange(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                )
              })}
              {actions && actions.length && <TableCell className='fix' key="col-actions" style={disableSticky ? {} : stickyRight} />}
              {onExpandRow && <TableCell className='fix' key="col-collapse" style={{ width: '30px' }} />}
            </TableRow>
          </TableHead>
          {!loading && (
            <TableBody key="tbody">
              {displayData.map((record, index) => {
                const allowedActions =
                  actions && actions.length
                    ? actions.filter((a) => {
                        return (!a.perm || a.perm(user, record)) && (!a.cond || a.cond(record))
                      })
                    : []

                return (
                  <React.Fragment key={`row-${index}`}>
                    <Tr data-no-border={!!onExpandRow} hover>
                      {selectable && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={
                              selected.filter(R.compose(R.equals(primaryKeyValue(record)), primaryKeyValue)).length ===
                              1
                            }
                            onChange={handleSelectRecord(record)}
                            disabled={disableCheckbox(record)}
                          />
                        </TableCell>
                      )}
                      {displayColumns.map((column, idx) => {
                        const widthStyles = {width: column.width ? column.width : 'auto'}
                        const resizableIdx = selectable ? idx + 1 : idx
                        if (config.ui.enableDataTableColumnResizing && resizingData[resizableIdx]) {
                          widthStyles.width = `${resizingData[resizableIdx]}px`
                          widthStyles.minWidth = `${resizingData[resizableIdx]}px`
                          widthStyles.maxWidth = `${resizingData[resizableIdx]}px`
                        }
                        return (
                          <TableCell
                            key={`col-${column.id}`}
                            align={column.numeric ? 'right' : column.align || 'left'}
                            style={{
                              ...widthStyles,
                              ...(idx === 0 && !disableSticky ? stickyLeft : {}),
                            }}
                          >
                            {getDisplayValue(fieldsMapping, record, column.id, column)}
                          </TableCell>
                        )
                      })}
                      {actions && actions.length && (
                        <TableCell align="right" key={`col-actions-${index}`} style={disableSticky ? {} : stickyRight}>
                          {!!allowedActions.length && (
                            <ActionsButton
                              actions={allowedActions}
                              onClick={(action, evt) => onAction(action.id, record, evt)}
                            />
                          )}
                        </TableCell>
                      )}
                      {onExpandRow && (
                        <TableCell align="right" key={`col-expand-${index}`}>
                          <IconButton size="small" onClick={() => setOpenRow(openRow === index ? null : index)}>
                            {openRow === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                        </TableCell>
                      )}
                    </Tr>
                    {onExpandRow && (
                      <TableRow key={`expand-${index}`}>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
                          <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                            {onExpandRow(record)}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {loading && <Loader minHeight="100px" style={{ width: '100%' }} />}
      <TablePagination
        rowsPerPageOptions={[listPerPage]}
        component="div"
        count={totalItems}
        rowsPerPage={listPerPage}
        page={page}
        onPageChange={(_, page) => setPage(page)}
        showFirstButton
        showLastButton
      />
      {settingsDialogIsOpen && (
        <SettingsDialog
          columns={columnsProps}
          open={settingsDialogIsOpen}
          handleClose={() => setSettingsDialogIsOpen(false)}
          handleReset={resetSettings}
          handleSaveAndClose={saveSettings}
          onSelect={handleSelectColumn}
          onSort={setColumnProps}
          listPerPage={listPerPage}
          onListPerPageChange={(value) => setListPerPage(value ? parseInt(value) : '')}
          disableSorting={disableSorting}
          defaultSortField={defaultSortField}
          defaultSortDirection={defaultSortDirection}
          onDefaultSortFieldChange={handleChangeDefaultSortField}
          onDefaultSortDirectionChange={handleChangeDefaultSortDirection}
        />
      )}
      {filterFormIsOpen && filterForm(handleCloseFilterForm)}
    </>
  )
}

DataTable.defaultProps = {
  qsAdditions: {},
  autoWidth: false,
  sortDirection: 'asc',
  fieldsMapping: {},
  bulkActions: [],
  dftListPerPage: 10,
  dftSortField: 'id',
  dftSortDirection: 'asc',
  disableSorting: false,
  disableCheckbox: R.always(false),
  sortMapping: {},
  noExport: true,
}

DataTable.propTypes = {
  name: PropTypes.string.isRequired,
  refreshData: PropTypes.func,
  autoWidth: PropTypes.bool,
  data: PropTypes.array,
  dataCount: PropTypes.number,
  loading: PropTypes.bool,
  qsAdditions: PropTypes.object,
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  selectable: PropTypes.bool,
  model: PropTypes.shape({
    primaryKey: PropTypes.string.isRequired,
    columns: PropTypes.array,
  }),
  listDisplay: PropTypes.array.isRequired,
  dftListPerPage: PropTypes.number,
  dftSortField: PropTypes.string,
  dftSortDirection: PropTypes.string,
  qs: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
    }),
  ),
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
    }),
  ),
  onAction: PropTypes.func,
  noExport: PropTypes.bool,
  noSettings: PropTypes.bool,
  noBulkSelection: PropTypes.bool,
  fieldsMapping: PropTypes.object,
  onExpandRow: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  disableSorting: PropTypes.bool,
  disableCheckbox: PropTypes.func,
  filterForm: PropTypes.func,
  filterFormActive: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  exportApi: PropTypes.func,
  sortMapping: PropTypes.object,
  refetch: PropTypes.func,
  disableSticky: PropTypes.bool,
  disableResizing: PropTypes.bool,
}

DataTable.whyDidYouRender = true

export default React.memo(DataTable)

export const getDefaultListPerPage = (name, defaultListPerPage = 10) =>
  parseInt(storageService.getNested(`${name}_DATA_TABLE_SETTINGS`, 'listPerPage', defaultListPerPage))

export const getDefaultSortField = (name, defaultSortField = 'id') =>
  storageService.getNested(`${name}_DATA_TABLE_SETTINGS`, 'defaultSortField', defaultSortField)

export const getDefaultSortDirection = (name, defaultSortDirection = 'asc') =>
  storageService.getNested(`${name}_DATA_TABLE_SETTINGS`, 'defaultSortDirection', defaultSortDirection)
