import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import CampaignDashboard from '@Campaigns/Components/Dashboard'
import CampaignsPermissions from '@Campaigns/Permissions'
import { useLastCampaignQuery } from '@Campaigns/Services/Api'
import Fab from '@Common/Components/Fab'
import Padding from '@Common/Components/Padding'
import BaseLayout from '@Common/Layouts/BaseLayout'
import { withLoader } from '@Common/Utils/HOF'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import { apiDictFromList } from '@Core/Services/Api'

const CampaignsDashboardView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const { data: campaign, isFetching } = apiDictFromList(useLastCampaignQuery())
  useBreadcrumbs([{ label: t('navigation.Campaigns') }])
  const navigate = useNavigate()

  const handleChange = (_, tab) => {
    if (tab === 0) navigate(makePath('campaigns.list'))
  }
  return (
    <BaseLayout>
      <Padding bottom="4rem">
        <Tabs value={1} onChange={handleChange}>
          <Tab label={t('campaigns:ui.CampaignsList')} />
          <Tab label={t('campaigns:ui.LastCampaign')} />
        </Tabs>
        {withLoader(
          () => (
            <CampaignDashboard campaign={campaign} />
          ),
          isFetching,
        )}
        {CampaignsPermissions.create(user) && (
          <Fab right="2rem" onClick={() => navigate(makePath('campaigns.create'))} />
        )}
      </Padding>
    </BaseLayout>
  )
}

export default CampaignsDashboardView
