import { FileDownload } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'

import { CampaignType } from '@Campaigns/Models/Campaign'
import CampaignExecutionDailyReport from '@Campaigns/Models/CampaignExecutionDailyReport'
import { useCampaignExecutionDailyReportsQuery } from '@Campaigns/Services/Api'
import DataTable from '@Common/Components/DataTable/Server'
import { apiList } from '@Core/Services/Api'

const LIST_DISPLAY = ['campaignExecutionDailyReportTimestamp', 'campaignExecutionDailyReportUrl']
const FIELDS_MAPPING = {
  campaignExecutionDailyReportUrl: (record) => (
    <IconButton onClick={() => window.open(record.campaignExecutionDailyReportUrl)}>
      <FileDownload />
    </IconButton>
  ),
}

const CampaignReports = ({ campaign }) => {
  const [qs, setQs] = React.useState({
    base: {
      pageSize: 5,
      pageNumber: 0,
      orderBy: 'campaignExecutionDailyReportTimestamp',
      orderType: 'desc',
    },
  })

  const refreshData = setQs
  const {
    data: reports,
    isFetching,
    orData,
  } = apiList(useCampaignExecutionDailyReportsQuery({ campaignId: campaign._id, qs: { ...qs.base } }))

  return (
    <DataTable
      name="campaign-dashboard-reports"
      data={reports}
      refreshData={refreshData}
      qs={qs}
      dataCount={orData?.totalSize || -1}
      model={CampaignExecutionDailyReport}
      listDisplay={LIST_DISPLAY}
      fieldsMapping={FIELDS_MAPPING}
      dftListPerPage={5}
      loading={isFetching}
      noSettings
    />
  )
}

CampaignReports.propTypes = {
  campaign: CampaignType,
}

export default CampaignReports
