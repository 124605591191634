import PropTypes from 'prop-types'

import i18n from '../../i18n'
import { SoftwareComponentType } from './SoftwareComponent'

export const BundleType = PropTypes.shape({
  _id: PropTypes.string,
  bundleInfo: PropTypes.shape({
    domain: PropTypes.string,
    ring: PropTypes.string,
    assetType: PropTypes.string,
    deviceType: PropTypes.string,
    bundleVersion: PropTypes.string,
    bundleIncrementalVersion: PropTypes.string,
  }),
  swComponents: PropTypes.arrayOf(SoftwareComponentType),
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: '_id',
      label: i18n.t('common:fields.id'),
    },
    {
      id: 'bundleInfo.domain',
      label: i18n.t('common:fields.domain'),
    },
    {
      id: 'bundleInfo.ring',
      label: i18n.t('common:fields.ring'),
    },
    {
      id: 'bundleInfo.assetType',
      label: i18n.t('common:fields.assetType'),
    },
    {
      id: 'bundleInfo.deviceType',
      label: i18n.t('common:fields.deviceType'),
    },
    {
      id: 'bundleInfo.bundleVersion',
      label: i18n.t('common:fields.bundleVersion'),
    },
    {
      id: 'bundleStatus.status',
      label: i18n.t('common:fields.status'),
    },
    {
      id: 'bundleStatus.deltaStatus',
      label: i18n.t('common:fields.deltaStatus'),
    },
    {
      id: 'bundleSize',
      label: i18n.t('common:fields.bundleSize'),
    },
  ],
}
