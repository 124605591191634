import PropTypes from 'prop-types'

import Box from '@Common/Components/Box'
import DomainDropdown from '@Common/Components/DomainDropdown'
import { setStateFromEvent } from '@Common/Utils/Events'
import AssetTypeDropdown from '@Common/Components/AssetTypeDropdown'
import { useTranslation } from 'react-i18next'

/**
 * Dashboard filters bar
 * Contains the domain and assetType filters
 *
 * @param {String} props.domain - the initial domain
 * @param {Function} props.onDomainChange - the handler for domain change
 * @param {String} props.assetType - the initial asset type
 * @param {Function} props.onAssetTypeChange - the handler for asset type change
 */
const FiltersBar = ({ domain, onDomainChange, assetType, onAssetTypeChange }) => {
  const { t } = useTranslation()

  return (
    <Box direction="row" align="center" gap='1rem'>
      <DomainDropdown value={domain} onChange={setStateFromEvent(onDomainChange)} width='150px' />
      <AssetTypeDropdown value={assetType} onChange={setStateFromEvent(onAssetTypeChange)} width='300px' emptyLabel={t('common:ui.All').toUpperCase()} emptyValue='ALL' />
    </Box>
  )
}

FiltersBar.propTypes = {
  domain: PropTypes.string,
  onDomainChange: PropTypes.func,
  assetType: PropTypes.string,
  onAssetTypeChange: PropTypes.func,
}

export default FiltersBar
