export const chartBackground = (themeMode) => (themeMode === 'light' ? '#fff' : '#1F212C')
export const chartTitleStyle = (themeMode) => ({
  color: themeMode === 'light' ? '#000' : '#eee',
})
export const chartCommonOptions = (themeMode) => ({
  exporting: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  legend: {
    itemStyle: {
      color: themeMode === 'light' ? '#000' : '#eee',
    },
  },
})
export const chartXAxisCommonOptions = (themeMode) => ({
  crosshair: true,
  title: {
    style: {
      color: themeMode === 'light' ? '#000' : '#eee',
    },
  },
  labels: {
    style: {
      color: themeMode === 'light' ? '#000' : '#eee',
    },
  },
})
export const chartYAxisCommonOptions = (themeMode, title, opposite = false) => ({
  gridLineColor: themeMode === 'light' ? '#eee' : '#444',
  title: {
    text: title,
    style: {
      color: themeMode === 'light' ? '#000' : '#eee',
    },
  },
  labels: {
    style: {
      color: themeMode === 'light' ? '#000' : '#eee',
    },
  },
  opposite,
})
