import PropTypes from 'prop-types'
import { groupBy, prop } from 'ramda'
import styled from 'styled-components'

import Box from '@Common/Components/Box'
import Logger from '@Common/Utils/Logger'

import VehiclesActivePieChart from './VehiclesActivePieChart'
import VehiclesRingColumnChart from './VehiclesRingColumnChart'

const ColLeft = styled.div`
  width: 70%;
`

const ColRight = styled.div`
  width: 30%;
`

/**
 * Vehicles charts section in the dashboard
 *
 * @param {Array} props.deviceStatistics - device statistics
 */
const VehiclesCharts = ({ deviceStatistics }) => {
  const groupedStats = groupBy(prop('deviceType'), deviceStatistics)
  Logger.debug('Dashboard grouped stats', groupedStats) // eslint-disable-line
  return (
    <Box direction="row" align="center">
      <ColLeft>
        <VehiclesRingColumnChart stats={groupedStats} />
      </ColLeft>
      <ColRight>
        <VehiclesActivePieChart stats={groupedStats} />
      </ColRight>
    </Box>
  )
}

VehiclesCharts.propTypes = {
  deviceStatistics: PropTypes.arrayOf(
    PropTypes.shape({
      cardinality: PropTypes.number,
      connectedCardinality: PropTypes.number,
      deviceType: PropTypes.string,
      domain: PropTypes.string,
      ring: PropTypes.string,
    }),
  ),
}

export default VehiclesCharts
