import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { makePrivate } from '@Auth/Utils/Router'
import CampaignsPermissions from '@Campaigns/Permissions'
import config from '@Config'
import PageNotFoundView from '@Core/Views/PageNotFoundView'

import CampaignCreateView from './Views/CampaignCreateView'
import CampaignDetailExecutionsView from './Views/CampaignDetailExecutionsView'
import CampaignDetailView from './Views/CampaignDetailView'
import CampaignsDashboardView from './Views/CampaignsDashboardView'
import CampaignsListView from './Views/CampaignsListView'

const CampaignsRouter = () => {
  return (
    <Routes>
      <Route path={config.urls.campaigns.dashboard} element={<CampaignsDashboardView />} />
      <Route path={config.urls.campaigns.list} element={<CampaignsListView />} />
      <Route path={config.urls.campaigns.detail} element={<CampaignDetailView />} />
      <Route path={config.urls.campaigns.detailExecutions} element={<CampaignDetailExecutionsView />} />
      <Route
        path={config.urls.campaigns.create}
        element={makePrivate(<CampaignCreateView />, { permFunction: CampaignsPermissions.create })}
      />
      <Route path={'*'} element={<PageNotFoundView />} />
    </Routes>
  )
}

export default CampaignsRouter
