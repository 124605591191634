import { InsertLink } from '@mui/icons-material'
import { Chip, Divider, List, ListItem, ListItemText, TablePagination, Typography } from '@mui/material'
import { defaultTo, multiply, pipe, prop } from 'ramda'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useUpdateCurrentUserMutation } from '@Auth/Services/Api'
import Box from '@Common/Components/Box'
import Dropdown from '@Common/Components/Dropdown'
import Link from '@Common/Components/Link'
import { datetime } from '@Common/Utils/Datetime'
import { setStateFromEvent } from '@Common/Utils/Events'
import { withLoader } from '@Common/Utils/HOF'
import { useCurrentUser } from '@Common/Utils/Hooks'
import Logger from '@Common/Utils/Logger'
import { operatorToMongoQuery } from '@Common/Utils/Operators'
import { gt } from '@Common/Utils/Ramda'
import { readFromStorage, storeAndRun } from '@Common/Utils/Storage'
import { apiList } from '@Core/Services/Api'
import { NOTIFICATION_TYPES } from '@Notifications/Config'
import { useNotificationsQuery } from '@Notifications/Services/Api'
import { getNotificationLink, getNotificationTagColor } from '@Notifications/Utils'

const PER_PAGE = 10
const FILTERS_STORAGE_KEY = 'filters-notifications'

const NotificationsList = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const [updateUser] = useUpdateCurrentUserMutation()
  const [filter, setFilter] = React.useState(readFromStorage(FILTERS_STORAGE_KEY, []))

  // update last notification timestamp
  useEffect(() => {
    const updateLastNotificationTimestamp = async () => {
      try {
        await updateUser({ userStatus: { lastNotificationTimestamp: Math.floor(new Date().getTime()) } }).unwrap()
      } catch (err) {
        Logger.error('Cannot update current user last notification timestamp')
      }
    }
    updateLastNotificationTimestamp()
  }, [])

  const qsAdditions = React.useMemo(() => {
    return filter.length > 0 ? { filterBy: operatorToMongoQuery('type', 'in', filter) } : {}
  }, [filter])
  const [qs, setQs] = React.useState({
    base: {
      pageSize: PER_PAGE,
      pageNumber: 0,
      orderBy: 'timestamp',
      orderType: 'desc',
    },
    qsAdditions,
  })
  const { data, isLoading, orData } = apiList(useNotificationsQuery({ ...qs.base, ...qsAdditions }))

  const isUnreadNotification = pipe(
    prop('timestamp'),
    multiply(1e3),
    gt(defaultTo(0, user.userStatus.lastNotificationTimestamp)),
  )

  return (
    <div>
      <Box direction="row" margin="0 1rem 2rem">
        <Dropdown
          label={t('common:actions.Filter')}
          value={filter}
          onChange={setStateFromEvent(storeAndRun(FILTERS_STORAGE_KEY, setFilter))}
          multiple
          options={NOTIFICATION_TYPES.map((type) => ({ value: type, label: t(`notifications:types.${type}`) }))}
        />
      </Box>
      {withLoader(
        () => (
          <List>
            {data.notifications.map((notification) => {
              // const tag = getNotificationTag(notification.type)
              const linkUrl = getNotificationLink(notification)
              return (
                <React.Fragment key={notification._id}>
                  <ListItem
                    secondaryAction={
                      linkUrl ? (
                        <Link to={getNotificationLink(notification)}>
                          <InsertLink />
                        </Link>
                      ) : undefined
                    }
                  >
                    <ListItemText
                      primary={
                        <>
                          <Chip
                            size="small"
                            color={getNotificationTagColor(notification.type)}
                            label={t(`notifications:types.${notification.type}`)}
                            style={{ marginBottom: '.4rem' }}
                          />
                          <Typography style={{ fontWeight: isUnreadNotification(notification) ? 'bold' : 'normal' }}>
                            {notification.message}
                          </Typography>
                        </>
                      }
                      secondary={
                        <Typography variant="body2" style={{ opacity: '.7', marginTop: '.4rem' }}>
                          {datetime(notification.timestamp).format('dddd, MMMM D, YYYY HH:mm')}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider component="li" style={{ margin: '.5rem .9rem' }} />
                </React.Fragment>
              )
            })}
            <TablePagination
              rowsPerPageOptions={[PER_PAGE]}
              component="div"
              count={defaultTo(-1, orData.totalSize)}
              rowsPerPage={PER_PAGE}
              page={qs.base.pageNumber}
              onPageChange={(_, page) => setQs({ ...qs, base: { ...qs.base, pageNumber: page } })}
              showFirstButton
              showLastButton
            />
          </List>
        ),
        isLoading,
      )}
    </div>
  )
}

export default NotificationsList
