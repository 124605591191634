import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import PropTypes from 'prop-types'
import React from 'react'

import TextField from '@Common/Components/TextField'

import { FieldType } from '../Types/Field'

/**
 * A wrpper to make it easy to add functionality, i.e. clearable
 */
const DateTimePicker = ({ value, onChange, inputFormat, ...props }) => {
  return (
    <MuiDateTimePicker
      value={value}
      inputFormat={inputFormat}
      onChange={onChange}
      {...props}
      renderInput={(params) => <TextField {...params} />}
    />
  )
}

DateTimePicker.defaultProps = {
  inputFormat: 'YYYY-MM-DD HH:mm',
}

DateTimePicker.propTypes = {
  ...FieldType,
  inputFormat: PropTypes.string,
}

export default DateTimePicker
