import PropTypes from 'prop-types'
import { compose, either, not, propEq } from 'ramda'

import i18n from '../../i18n'
import { SwDeltaType } from './SwDelta'

export const SoftwareComponentType = PropTypes.shape({
  _id: PropTypes.string,
  swComponentInfo: PropTypes.shape({
    domain: PropTypes.string,
    ring: PropTypes.string,
    deviceType: PropTypes.string,
    name: PropTypes.string,
    version: PropTypes.string,
    incrementalVersion: PropTypes.string,
    type: PropTypes.string,
  }),
  swComponentStatus: PropTypes.shape({
    status: PropTypes.string,
    deltaStatus: PropTypes.string,
  }),
  swComponentDeltas: PropTypes.arrayOf(SwDeltaType),
})

const columns = [
  {
    id: '_id',
    label: i18n.t('common:fields.id'),
  },
  {
    id: 'swComponentInfo.domain',
    label: i18n.t('common:fields.domain'),
  },
  {
    id: 'swComponentInfo.ring',
    label: i18n.t('common:fields.ring'),
  },
  {
    id: 'swComponentInfo.deviceType',
    label: i18n.t('common:fields.deviceType'),
  },
  {
    id: 'swComponentInfo.name',
    label: i18n.t('sc:fields.name'),
  },
  {
    id: 'swComponentInfo.version',
    label: i18n.t('sc:fields.version'),
  },
  {
    id: 'swComponentInfo.type',
    label: i18n.t('sc:fields.type'),
  },
  {
    id: 'swComponentStatus.status',
    label: i18n.t('sc:fields.status'),
  },
  {
    id: 'swComponentStatus.deltaStatus',
    label: i18n.t('sc:fields.deltaStatus'),
  },
  {
    id: 'swComponentStatus.sizeInBytes',
    label: i18n.t('sc:fields.size'),
    fileSize: true,
  },
]

export const BundleSwComponent = {
  primaryKey: '_id',
  columns: columns.filter(compose(not, either(propEq('id', '_id'), propEq('id', 'swComponentInfo.type')))),
}

export default {
  primaryKey: '_id',
  columns: columns.filter(compose(not, propEq('id', 'swComponentInfo.type'))),
}
