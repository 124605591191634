import React from 'react'
import { DeviceType } from '@Vehicles/Models/Device'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { apiList } from '@Core/Services/Api'
import { useExecutionsQuery } from '@Campaigns/Services/Api'
import CampaignExecution from '@Campaigns/Models/CampaignExecution'
import Link from '@Common/Components/Link'
import { makePath } from '@Config'
import ExecutionStatus from '@Campaigns/Components/ExecutionStatus'

const LIST_DISPLAY = [
  'campaignInfo.name',
  'jobExecutionStatus.status',
  'jobExecutionStatus.timestamp',
  'campaignInfo.bundleVersion',

]
const FIELDS_MAPPING = {
  'campaignInfo.name': (record) => (
    <Link to={makePath('campaigns.detail', { campaignId: record.campaignId})}>{record.campaignInfo.name}</Link>
  ),
  'device.vin': (record) => (
    <Link to={makePath('vehicles.detail', { deviceId: record.device.deviceId })}>
      {record.device.vin}
    </Link>
  ),
  'campaignInfo.bundleVersion': (record) => (
    <Link to={makePath('bundles.detail', { bundleId: record.campaignInfo.bundleId })}>
      {record.campaignInfo.bundleVersion}
    </Link>
  ),
  'jobExecutionStatus.status': (record) => <ExecutionStatus status={record.jobExecutionStatus?.status} />,
}

const ExecutionsList = ({ device }) => {
  const qsAdditions = {
    filterBy: `"device.deviceId":"${device.thingName}"`
  }
  const [qs, setQs] = React.useState({
    base: {
      pageSize: getDefaultListPerPage('vehicle-executions'),
      pageNumber: 0,
      orderBy: 'jobExecutionStatus.timestamp',
      orderType: 'desc',
    },
    qsAdditions,
  })


  const refreshData = setQs
  const { data: vehicleExecutions, isFetching, orData } = apiList(useExecutionsQuery({ qs: { ...qs.base, ...qsAdditions }}))

  return (
    <DataTable
      name="campaign-executions"
      data={vehicleExecutions}
      refreshData={refreshData}
      qs={qs}
      dataCount={orData?.totalSize || -1}
      dftSortField="jobExecutionStatus.timestamp"
      dftSortDirection="desc"
      model={CampaignExecution}
      listDisplay={LIST_DISPLAY}
      fieldsMapping={FIELDS_MAPPING}
      loading={isFetching}
    />
  )
}

ExecutionsList.propTypes = {
  device: DeviceType,
}

export default ExecutionsList
