import storageFactory from '@Common/Services/Storage'

const Storage = storageFactory()

/**
 * Function decorator which stores the received value in the storage
 * before calling the decorated function
 *
 * @param {String} key - storage key
 * @param {Function} fn - decorated function
 */
export const storeAndRun = (key, fn) => value => {
  Storage.save(key, value)
  return fn(value)
}

/**
 * Utility function the get a key value from the storage
 *
 * @param {String} key - storage key
 * @param {Any} dft - a default value if key is not in storage
 * @returns {Any} the value found in storage ot dft
 */
export const readFromStorage = (key, dft) => {
  return Storage.get(key, dft)
}

/**
 * Utility function to store a value in the storage
 *
 * @param {String} key - storage key
 * @param {Any} value - value to store
 */
export const storeInStorage = (key, value) => {
  return Storage.save(key, value)
}

/**
 * Utility function to remove a key from storage
 *
 * @param {String} key - storage key
 */
export const deleteFromStorage = (key) => {
  return Storage.remove(key)
}
