import PropTypes from 'prop-types'

import i18n from '../../i18n'

export const SwDeltaType = PropTypes.shape({
  versionFrom: PropTypes.string,
  startedAt: PropTypes.number,
  lastUpdatedAt: PropTypes.number,
  incrementalVersionFrom: PropTypes.string,
  status: PropTypes.string,
  progress: PropTypes.number,
})

export default {
  primaryKey: 'id',
  columns: [
    {
      id: 'versionFrom',
      label: i18n.t('sc:fields.versionFrom'),
    },
    {
      id: 'startedAt',
      label: i18n.t('sc:fields.startedAt'),
      datetime: true,
    },
    {
      id: 'lastUpdatedAt',
      label: i18n.t('sc:fields.lastUpdatedAt'),
      datetime: true,
    },
    {
      id: 'incrementalVersionFrom',
      label: i18n.t('sc:fields.incrementalVersionFrom'),
    },
    {
      id: 'status',
      label: i18n.t('sc:fields.status'),
    },
    {
      id: 'progress',
      label: i18n.t('sc:fields.progress'),
    },
    {
      id: 'sizeInBytes',
      label: i18n.t('sc:fields.size'),
      fileSize: true,
    },
  ]
}
