import styled from "styled-components"

const Box = styled.div`
  ${({ theme, background }) => background && theme
          ? `background: ${theme.palette[background]?.main}; color: ${theme.palette[background]?.contrastText};`
          : ''
  }
  align-items: ${(props) => props.align || 'flex-start'};
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  flex-grow: ${(props) => props.grow || 0};
  flex-wrap: ${(props) => props.$wrap ? 'wrap' : 'nowrap'};
  gap: ${(props) => props.gap ? props.gap : 'auto'};
  height: ${(props) => props.height || 'auto'};
  justify-content: ${(props) => props.justify || 'flex-start'};
  margin: ${(props) => props.margin || '0'};
  padding: ${(props) => props.pad || '0'};
  position: ${(props) => props.position || 'static'};
  width: ${(props) => props.width || 'auto'};
`

export default Box
