import { api } from '@Core/Services/Api'

const prefix = 'asset'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    assetTypes: builder.query({
      query: () => `${prefix}/types`,
      providesTags: [{ type: 'AssetType' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useAssetTypesQuery,
} = extendedApi
