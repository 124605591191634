import { checkRequired } from '@Common/Utils/Validation'
import { bundleStatusError } from '@SoftwareCatalog/Utils/StatusChecks'
import i18next from 'i18next'
import * as R from 'ramda'

export const validate = (fields, setErrors, targetBundle) => {
  const err = {}

  const required = ['name', 'domain', 'ring', 'assetType', 'deviceType', 'bundleId']
  checkRequired(err, required, fields)

  if (bundleStatusError(targetBundle)) {
    err['bundleId'] = i18next.t('sc:validation:BundleStatusFieldError')
  }

  setErrors(err)

  return R.isEmpty(err)
}
