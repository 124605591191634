import { createTheme } from '@mui/material/styles'

const getPalette = (mode) => ({
  mode,
  content: {
    main: mode === 'light' ? '#fff' : '#1F212C',
    contrastText: mode === 'light' ? '#000' : '#fff',
  },
  primary: {
    main: mode === 'light' ? '#2196F3' : '#2196F3',
    contrastText: '#fff',
  },
  accent: {
    main: mode === 'light' ? '#1E2433' : '#4D5464',
    contrastText: '#fff',
  },
  contrastExtraLight: {
    main: mode === 'light' ? '#f3f3f3' : '#303030',
    contrastText: mode === 'light' ? '#000' : '#fff',
  },
  contrastLight: {
    main: mode === 'light' ? '#e7e7e7' : '#404040',
    contrastText: mode === 'light' ? '#000' : '#fff',
    type: 'rgb',
  },
  contrastMedium: {
    main: mode === 'light' ? '#e0e0e0' : '#525252',
    contrastText: mode === 'light' ? '#000' : '#fff',
    type: 'rgb',
  },
  white: {
    main: '#fff',
    contrastText: '#fff',
    type: 'rgb',
  },
  infoLight: {
    main: '#36a3f7',
    contrastText: '#fff',
    type: 'rgb',
  },
  warningLight: {
    main: '#ffb822',
    contrastText: '#fff',
    type: 'rgb',
  },
  errorLight: {
    main: '#f4516c',
    contrastText: '#fff',
    type: 'rgb',
  },
  successLight: {
    main: '#34bfa3',
    contrastText: '#fff',
    type: 'rgb',
  },
  navbar: {
    main: mode === 'light' ? '#F2F3F8' : '#42475E',
    contrastText: mode === 'light' ? '#000' : '#fff',
  },
  sidebar: {
    main: mode === 'light' ? '#2C2E3E' : '#2C2E3E',
    contrastText: mode === 'light' ? '#fff' : '#fff',
  },
  dataTableRowHover: {
    main: mode === 'light' ? '#fafafa' : '#202020',
    contrastText: mode === 'light' ? '#000' : '#fff',
  },
  changelistToolbar: {
    main: mode === 'light' ? '#f7f7f7' : '#101010',
    contrastText: mode === 'light' ? '#000' : '#fff',
  },
  fileDropZone: {
    main: mode === 'light' ? '#f8f8f8' : '#242424',
    onDrag: mode === 'light' ? '#f0f0f0' : '#181818',
    border: '#859ba6'
  }
})

const getTheme = (mode) => {
  const theme = createTheme({
    palette: getPalette(mode),
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    overrides: {
      MuiTableCell: {
        sizeSmall: {
          padding: '4px 8px',
        },
      },
    },
  })
  return theme
}

export default getTheme
