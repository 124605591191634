import styled from "styled-components"
import PropTypes from 'prop-types'
import Navbar from "@Common/Components/Navbar"
import Sidebar from "@Common/Components/Sidebar"
import NotificationsPanel from "Notifications/Components/NotificationsPanel"

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const Content = styled.div`
  flex: 1;
  overflow: auto;
`
const Main = styled.main`
  padding: 1rem;
  padding-bottom: ${({ withFab }) => withFab ? '4.5rem' : '1rem'};
`

/**
 * The base layout (almost) all views extend
 *
 * @param {Component} props.children - the main content
 * @param {Boolean} props.withFab - if the view contains a fab button, anm additional bottom padding is added
 */
const BaseLayout = ({ children, withFab }) => {
  return (
    <Container>
      <Sidebar />
      <Content>
        <Navbar />
        <Main withFab={withFab}>
          {children}
        </Main>
      </Content>
      <NotificationsPanel />
    </Container>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  withFab: PropTypes.bool,
}

export default BaseLayout
