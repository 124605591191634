import * as R from 'ramda'

export const deltaStatusCheck = (record) => {
  let errors = R.pipe(R.filter((x) => x.swComponentStatus.deltaStatus === 'FAILED'))(record.response)
  if (errors.length > 0) {
    return 'FAILED'
  }
  let warnings = R.pipe(R.filter((x) => x.swComponentStatus.deltaStatus === 'IN_PROGRESS'))(record.response)
  if (warnings.length > 0) {
    return 'IN_PROGRESS'
  }

  return 'COMPLETED'
}
export const statusCheck = (record) => {
  let errors = R.pipe(R.filter((x) => x.swComponentStatus.status !== 'PRESENT'))(record.response).length
  if (errors > 0) {
    return 'ERROR'
  }
  return 'OK'
}

export const bundleStatusError = R.pathEq(['bundleStatus', 'status'], 'ERROR')
export const bundleDeltaStatusWarning = R.either(
  R.pathEq(['bundleStatus', 'deltaStatus'], 'IN_PROGRESS'),
  R.pathEq(['bundleStatus', 'deltaStatus'], 'FAILED'),
)
