import { api, apiQueryString } from '@Core/Services/Api'

const prefix = 'user'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    whoami: builder.query({
      query: () => `current-user`,
      providesTags: [{ type: 'CurrentUser' }],
      keepUnusedDataFor: 5 * 60,
    }),
    updateCurrentUser: builder.mutation({
      query: (data) => ({
        url: `current-user`,
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: ['User', 'CurrentUser', 'Notification'],
    }),
    users: builder.query({
      query: (qs) => `${prefix}?${apiQueryString(qs)}`,
      providesTags: [{ type: 'User', id: 'LIST' }],
    }),
    createUser: builder.mutation({
      query: ({ user }) => ({
        url: `${prefix}`,
        body: user,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    updateUser: builder.mutation({
      query: ({ userId, user }) => ({
        url: `${prefix}/${userId}`,
        body: user,
        method: 'PATCH',
      }),
      invalidatesTags: ['User'],
    }),
    deleteUser: builder.mutation({
      query: ({ userId }) => ({
        url: `${prefix}/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useWhoamiQuery,
  useLazyWhoamiQuery,
  useUpdateCurrentUserMutation,
  useUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = extendedApi
