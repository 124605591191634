import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { pipe } from 'ramda'

const FiltersForm = ({ title, onClose, onReset, onSave, children }) => {
  const { t } = useTranslation()
  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onReset} color="primary" variant='contained'>
          {t('common:actions.Reset')}
        </Button>
        <Button onClick={onClose} color="primary" variant='contained'>
          {t('common:actions.Ok')}
        </Button>
        <Button onClick={pipe(onSave, onClose)} color="primary" variant='contained'>
          {t('common:actions.OkAndSave')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

FiltersForm.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default FiltersForm
