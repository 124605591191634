import * as R from 'ramda'

import { checkRequired } from '@Common/Utils/Validation'

import i18n from '../../../i18n'

export const validate = (fields, setErrors, errors) => {
  const err = {}

  // require at least one checked criteria
  if (
    !fields.abortAllEnabled &&
    !fields.abortFailedEnabled &&
    !fields.abortRejectedEnabled &&
    !fields.abortTimedOutEnabled
  ) {
    err['abortRequired'] = i18n.t('campaigns:errors.SelectAtLeastOneCriterion')
  }

  const allRequired = ['abortAllAction', 'abortAllMinNumberOfExecutedThingsPercentage', 'abortAllThresholdPercentage']
  const failedRequired = ['abortFailedAction', 'abortFailedMinNumberOfExecutedThingsPercentage', 'abortFailedThresholdPercentage']
  const rejectedRequired = [
    'abortRejectedAction',
    'abortRejectedMinNumberOfExecutedThingsPercentage',
    'abortRejectedThresholdPercentage',
  ]
  const timedOutRequired = [
    'abortTimedOutAction',
    'abortTimedOutMinNumberOfExecutedThingsPercentage',
    'abortTimedOutThresholdPercentage',
  ]

  if (fields.abortAllEnabled) checkRequired(err, allRequired, fields)
  if (fields.abortFailedEnabled) checkRequired(err, failedRequired, fields)
  if (fields.abortRejectedEnabled) checkRequired(err, rejectedRequired, fields)
  if (fields.abortTimedOutEnabled) checkRequired(err, timedOutRequired, fields)

  setErrors({ ...errors, ...err })

  return R.isEmpty(err)
}
