import BaseLayout from "@Common/Layouts/BaseLayout"
import PageNotFound from "@Common/Components/PageNotFound"

const PageNotFoundView = () => {
  return (
    <BaseLayout>
      <PageNotFound />
    </BaseLayout>
  )
}

export default PageNotFoundView
