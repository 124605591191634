import { Navigate, Route, Routes } from 'react-router-dom'
import { HistoryRouter as Router } from 'redux-first-history/rr6'

import AdministrationPermissions from '@Administration/Permissions'
// views
import { makePrivate } from '@Auth/Utils/Router'
import LoginMsalView from '@Auth/Views/LoginMsalView'
import LoginView from '@Auth/Views/LoginView'
// routers
import CampaignsRouter from '@Campaigns/Router'
import config, { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import DashboardView from '@Dashboard/Views/DashboardView'

import AdministrationRouter from '../Administration/Router'
import BundlesRouter from '../SoftwareCatalog/Routers/BundlesRouter'
import SoftwareComponentsRouter from '../SoftwareCatalog/Routers/SoftwareComponentsRouter'
import VehiclesRouter from '../Vehicles/Router'
import PageNotFoundView from './Views/PageNotFoundView'

/**
 * Main application router
 * Each route element can be one of the following options:
 * - a view component
 * - a (sub)router defining other routes
 */
const AppRouter = () => {
  return (
    <Router history={history}>
      <Routes>
        <Route exact path={config.urls.loginMsal} element={<LoginMsalView />} />
        <Route exact path={config.urls.login} element={<LoginView />} />
        <Route exact path={config.urls.homeToRedirect} element={<Navigate replace to={config.urls.home} />} />
        <Route exact path={config.urls.home} element={<Navigate replace to={makePath('campaigns.list')} />} />
        <Route exact path={config.urls.campaigns.base} element={makePrivate(<CampaignsRouter />)} />
        <Route exact path={config.urls.dashboard} element={makePrivate(<DashboardView />)} />
        <Route exact path={config.urls.vehicles.base} element={makePrivate(<VehiclesRouter />)} />
        <Route exact path={config.urls.bundles.base} element={makePrivate(<BundlesRouter />)} />
        <Route exact path={config.urls.softwareComponents.base} element={makePrivate(<SoftwareComponentsRouter />)} />
        <Route
          exact
          path={config.urls.users.base}
          element={makePrivate(<AdministrationRouter />, { permFunction: AdministrationPermissions.read })}
        />
        <Route exact path={config.urls.pageNotFound} element={<PageNotFoundView />} />
        <Route path={'*'} element={<PageNotFoundView />} />
      </Routes>
    </Router>
  )
}

export default AppRouter
