export const operatorToAwsQueryMap = {
  'lt': '<',
  'lte': '<=',
  'eq': '=',
  'gte': '<=',
  'gt': '>',
  'neq': '!='
}

/**
 * Convert an operator condition to the corresponding AWS querystring part
 *
 * @param {String} property - prop name
 * @param {String} operator - operator
 * @param {Any} value - prop value
 * @param {Any} valueTo - optional "to" prop value
 */
export const operatorToAwsQuery = (property, operator, value, valueTo) => {
  if (operator === 'neq') {
    return `NOT ${property} = ${value}`
  } else if (operator === 'between') {
    return `${property} >= ${value} AND ${property} <= ${valueTo}`
  }

  return `${property} ${operatorToAwsQueryMap[operator]} ${value}`
}

/**
 * Convert an operator condition to the corresponding Mongo querystring part
 *
 * @param {String} property - prop name
 * @param {String} operator - operator
 * @param {Any} value - prop value
 */
export const operatorToMongoQuery = (property, operator, value) => {
  if (operator === 'in') {
    // eslint-disable-next-line no-useless-escape
    return `"$or": [${value.map(v => `{"${property}": "${v}"}`).join(',')}]`
  } else {
    return `"${property}":"${value}"`
  }
}
