import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useTheme } from '@mui/system'
import PropTypes from 'prop-types'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import ClearIcon from '@mui/icons-material/Clear'
import Loader from './Loader'
import Select from '@mui/material/Select'
import logger from '../Utils/Logger'
import { FieldType } from '../Types/Field'
import * as R from 'ramda'
import styled from 'styled-components'
import { ListSubheader, InputAdornment, TextField } from '@mui/material'
import { Cancel, Search } from '@mui/icons-material'
import { setStateFromEvent } from '../Utils/Events'
import { IconButton } from '@mui/material'

const ClearButton = styled(IconButton)`
  color: ${(props) => props.color};
  position: absolute;
  right: 20px;
  top: 14px;

  svg {
    width: 20px;
  }
`

const Dropdown = ({
  label,
  value,
  onChange,
  required,
  error,
  options,
  helperText,
  requestAction,
  width,
  readonly,
  multiple,
  disabled,
  emptyLabel,
  emptyValue,
  clearable,
  noEmpty,
  loading,
  searchable,
  formControlStyle,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [searchText, setSearchText] = React.useState('')
  const [isHover, setIsHover] = React.useState(false)

  const handleMouseEnter = React.useCallback(
    () => setIsHover(true),
    [setIsHover],
  )
  const handleMouseLeave = React.useCallback(
    () => setIsHover(false),
    [setIsHover],
  )

  React.useEffect(() => {
    if (options === undefined && requestAction) {
      logger.debug(
        `Dropdown ${label}, undefined options, dispatching action`,
        requestAction(),
      )
      dispatch(requestAction())
    }
  }, [])

  const val = R.ifElse(R.isNil, () => '', R.identity)(value)

  const more = multiple
    ? {
        renderValue: (selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected
              .filter(
                (value) => options.filter((o) => o.value === value).length,
              )
              .map((value) => (
                <Chip
                  key={value}
                  label={R.find(R.propEq('value', value))(options).label}
                  onDelete={() => {
                    onChange({ target: { value: val.filter(R.compose(R.not, R.equals(value))) }})
                  }}
                  deleteIcon={
                    <Cancel onMouseDown={(event) => event.stopPropagation()} />
                  }
                />
              ))}
          </Box>
        ),
      }
    : {}

  return (
    <FormControl
      style={{ width, ...formControlStyle }}
      variant="standard"
      onMouseEnter={handleMouseEnter}
      onFocus={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onBlur={handleMouseEnter}
    >
      {label && (
        <InputLabel>
          {label}
          {required && ' *'}
        </InputLabel>
      )}
      <Select
        MenuProps={{ autoFocus: false }}
        value={val}
        onChange={onChange}
        style={{ width }}
        required
        readOnly={readonly}
        multiple={multiple}
        disabled={disabled}
        error={error}
        variant="standard"
        onClose={() => setSearchText('')}
        {...more}
      >
        {searchable && (
          <ListSubheader>
            <TextField
              autoFocus
              value={searchText}
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={setStateFromEvent(setSearchText)}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation()
                }
              }}
            />
          </ListSubheader>
        )}

        {options && !required && !noEmpty && !multiple && (
          <MenuItem value={emptyValue}>{emptyLabel}</MenuItem>
        )}
        {options &&
          options
            .filter(
              R.either(
                R.always(R.isEmpty('searchText')),
                R.pipe(
                  R.prop('label'),
                  R.toLower,
                  R.includes(searchText.toLowerCase()),
                ),
              ),
            )
            .map((opt) => (
              <MenuItem key={`opt-${opt.value}`} value={opt.value}>
                {opt.icon ? opt.icon : null} {opt.label}
              </MenuItem>
            ))}
        {(!options || loading) && (
          <MenuItem value="">
            <Loader size={18} />{' '}
            <span style={{ marginLeft: '.5rem' }}>
              {t('common:ui.loadingDots')}
            </span>
          </MenuItem>
        )}
      </Select>
      {clearable && isHover && R.not(R.equals(multiple ? [] : '', val)) && (
        <ClearButton
          onClick={() => onChange({ target: { value: multiple ? [] : '' } })}
        >
          <ClearIcon color={theme.palette.contrastHigh.main} />
        </ClearButton>
      )}
      {helperText && (
        <FormHelperText variant="standard">{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}

Dropdown.defaultProps = {
  width: '100%',
  disabled: false,
  readonly: false,
  multiple: false,
  emptyLabel: '--',
  emptyValue: '',
  clearable: false,
  noEmpty: false,
  loading: false,
  searchable: false,
  formControlStyle: {},
}

Dropdown.propTypes = {
  ...FieldType,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
  ),
  clearable: PropTypes.bool,
  requestAction: PropTypes.func,
  width: PropTypes.string,
  emptyLabel: PropTypes.string,
  emptyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noEmpty: PropTypes.bool,
  loading: PropTypes.bool,
  searchable: PropTypes.bool,
  formControlStyle: PropTypes.object,
}

export default Dropdown
