import React from 'react'
import PropTypes from 'prop-types'
import MUITablePagination from '@mui/material/TablePagination'
import Box from '../Box'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useDebounce } from '../../Utils/Hooks'
import { setStateFromEvent } from '../../Utils/Events'

const Input = styled.input`
  border: 0px solid;
  background: ${({ theme, warning }) =>
    warning
      ? theme.palette.warningLight.main
      : theme.palette.contrastLight.main};
  color: ${({ theme }) => theme.palette.content.contrastText};
  margin: 0 2rem 0 0.5rem;
  padding: 0.5rem;
  max-width: 40px;

  &:focus {
    border: 1px solid
      ${({ theme, warning }) =>
        warning
          ? theme.palette.errorLight.main
          : theme.palette.contrastMedium.main};
    outline: 0;
  }
`
const Label = styled.label`
  color: ${({ theme }) => theme.palette.content.contrastText};
  font-size: 0.9rem;
  line-height: 1.43;
`

const TablePagination = ({ noPageField, ...props }) => {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(props.page + 1)
  const debouncedValue = useDebounce(value)
  const [warning, setWarning] = React.useState(false)

  React.useEffect(() => {
    setValue(props.page + 1)
  }, [props.page])

  React.useEffect(() => {
    const val = debouncedValue
    if (
      val > 0 &&
      (props.count === -1 || (val - 1) * props.rowsPerPage <= props.count)
    ) {
      setWarning(false)
      props.onPageChange(null, debouncedValue - 1)
    } else {
      setWarning(true)
    }
  }, [debouncedValue, props.count])

  return noPageField ? (
    <MUITablePagination {...props} />
  ) : (
    <Box direction="row" align="center" justify='flex-end' width='100%'>
      <Box direction="row" margin="1rem -.8rem 1rem auto" align="center">
        <Label>{t('common:ui.Page')}</Label>
        <Input
          value={value}
          onChange={setStateFromEvent(setValue)}
          type="number"
          warning={warning}
        />
        <Label>{t('common:ui.Rows')}</Label>
      </Box>
      <MUITablePagination {...props} style={{ width: 'auto' }} />
    </Box>
  )
}

TablePagination.propTypes = {
  rowsPerPageOptions: PropTypes.array,
  component: PropTypes.string,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  showFirstButton: PropTypes.bool,
  showLastButton: PropTypes.bool,
  noPageField: PropTypes.bool,
}

export default TablePagination
