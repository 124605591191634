import PropTypes from 'prop-types'
import styled from 'styled-components'

const Table = styled.table`
  border-spacing: 0 1rem;
`

const Tr = styled.tr`
  display: table-row;
`

const Th = styled.th`
  color: ${({ theme }) => theme.palette.content.contrastText};
  padding-right: 1rem;
  font-weight: normal;
  ${({ align }) => `text-align: ${align};`}
`

const Td = styled.td`
  color: ${({ theme }) => theme.palette.content.contrastText};
  font-weight: bold;
  white-space: ${({ nowrap }) => nowrap ? 'nowrap' : 'wrap'};
`

const NameValueList = ({ data, nowrap, nameAlign }) => {
  return (
    <Table>
      <tbody>
      {data.map(({ name, value }, index) =>
        <Tr key={name + index}>
          <Th align={nameAlign}>{name}</Th>
          <Td nowrap={nowrap}>{value}</Td>
        </Tr>,
      )}
      </tbody>
    </Table>
  )
}

NameValueList.defaultProps = {
  width: '100%',
  nameAlign: 'right',
  nowrap: false,
}

NameValueList.propTypes = {
  data: PropTypes.array,
  nameAlign: PropTypes.oneOf(['right', 'left']),
  width: PropTypes.string,
  nowrap: PropTypes.bool,
}

export default NameValueList
