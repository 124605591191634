import PropTypes from 'prop-types'
import { defaultTo, isNil } from 'ramda'
import TrueChip from './TrueChip'

const ValueOrDashes = ({ value, isBoolean }) => {
  if (isBoolean) {
    return isNil(value) ? '--' : (value ? <TrueChip /> : '')
  }

  return defaultTo('--', value)
}

ValueOrDashes.propTypes = {
  value: PropTypes.any,
  isBoolean: PropTypes.bool,
}

export default ValueOrDashes
