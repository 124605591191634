import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'
import i18n from '../../i18n'

const options = [
  { value: 'CANCEL', label: i18n.t('campaigns:ui.Cancel') },
]

const JobAbortActionDropdown = ({ value, onChange, required, error, helperText, ...other }) => {
  const { t } = useTranslation()

  return (
    <Dropdown
      label={t('campaigns:fields.action')}
      required={required}
      value={value}
      onChange={onChange}
      options={options}
      error={error}
      helperText={helperText}
      {...other}
    />
  )
}

JobAbortActionDropdown.defaultProps = {
  required: false,
}

JobAbortActionDropdown.propTypes = FieldType

export default JobAbortActionDropdown
