import { Menu, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import Box from '@Common/Components/Box'
import TextField from '@Common/Components/TextField'
import { FieldType } from '@Common/Types/Field'
import { wrapValueInEvent } from '@Common/Utils/Events'
import { minutesToTimeRange, timeRangeToMinutes } from '@Common/Utils/Datetime'

const Button = styled.div`
  background: ${({ theme }) => theme.palette.contrastLight.main};
  border-radius: .8rem;
  cursor: pointer;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
`

export const unitsMap = {
  m: 'min',
  h: 'hours',
  d: 'days',
  w: 'weeks',
}

const TimeRangeField = ({ label, defaultUnit, value, onChange, required, error, helperText, ...other }) => {
  const [unit, setUnit] = React.useState(defaultUnit)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickUnit = (unit) => () => {
    setUnit(unit)
    onChange(wrapValueInEvent(null, unit))
    handleClose()
  }

  const handleChange = (evt) => {
    onChange(wrapValueInEvent(timeRangeToMinutes(evt.target.value, unit)), unit)
  }

  return (
    <Box direction="row" align="flex-start">
      <Button
        variant="outlined"
        color="contrastLight"
        onClick={handleOpen}
        style={{ position: 'relative', top: '14px', width: '36px', textAlign: 'center' }}
      >
        {unitsMap[unit]}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClickUnit('m')}>{'minutes'}</MenuItem>
        <MenuItem onClick={handleClickUnit('h')}>{'hours'}</MenuItem>
        <MenuItem onClick={handleClickUnit('d')}>{'days'}</MenuItem>
        <MenuItem onClick={handleClickUnit('w')}>{'weeks'}</MenuItem>
      </Menu>
      <TextField
        label={label}
        required={required}
        value={minutesToTimeRange(value, unit)}
        onChange={handleChange}
        helperText={helperText}
        error={error}
        {...other}
      />
    </Box>
  )
}

TimeRangeField.defaultProps = {
  defaultUnit: 'd',
  required: false,
}

TimeRangeField.propTypes = {
  ...FieldType,
  defaultUnit: PropTypes.string,
  valueTo: PropTypes.number,
  domain: PropTypes.string,
  ring: PropTypes.string,
  assetType: PropTypes.string,
  deviceType: PropTypes.string,
  noOperator: PropTypes.bool,
}

export default TimeRangeField
