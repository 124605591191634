import styled from 'styled-components'

const Padding = styled.div`
  padding-bottom: ${(props) => props.bottom || 0};
  padding-left: ${(props) => props.left || 0};
  padding-right: ${(props) => props.right || 0};
  padding-top: ${(props) => props.top || 0};
`

export default Padding
