import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { FormControl } from '@Common/Components/Forms'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useCurrentUser, useForm } from '@Common/Utils/Hooks'

import NotificationDropdown from './NotificationDropdown'
import { toast } from 'react-toastify'
import { Person } from '@mui/icons-material'
import Box from '@Common/Components/Box'
import { useUpdateCurrentUserMutation } from '@Auth/Services/Api'
import Logger from '@Common/Utils/Logger'
import { responseTextError } from '@Common/Utils/Api'

const UserSettingsDialog = ({ onClose }) => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const [updateUser] = useUpdateCurrentUserMutation()

  const { fields, setField } = useForm({
    swComponentNotification: user?.userSettings?.swComponentNotification || 'NONE',
    bundleNotification: user?.userSettings?.bundleNotification || 'NONE',
    campaignNotification: user?.userSettings?.campaignNotification || 'NONE',
    campaignDailyReportNotification: user?.userSettings?.campaignDailyReportNotification || 'NONE',
  })

  const handleSave = async () => {
    try {
      await updateUser({ userSettings: fields }).unwrap()
      toast.success(t('auth:success.CurrentUserUpdated'))
    } catch (err) {
      Logger.error('Update current user error', err)
      toast.error(t('auth:errors.UpdateCurrentUserError', { error: responseTextError(err) }))
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('auth:ui.UserSettings')}</DialogTitle>
      <DialogContent>
        <Box direction='row' gap='1rem' align='center' background='contrastExtraLight' pad='.5rem' margin='0 0 1rem'>
          <Person /> { user.userInfo.eMail}
        </Box>
        <FormControl>
          <NotificationDropdown
            required
            label={t('auth:fields.swComponentNotification')}
            value={fields.swComponentNotification}
            onChange={setStateFromEvent(setField('swComponentNotification'))}
            fullWidth
          />
        </FormControl>
        <FormControl>
          <NotificationDropdown
            required
            label={t('auth:fields.bundleNotification')}
            value={fields.bundleNotification}
            onChange={setStateFromEvent(setField('bundleNotification'))}
            fullWidth
          />
        </FormControl>
        <FormControl>
          <NotificationDropdown
            required
            label={t('auth:fields.campaignNotification')}
            value={fields.campaignNotification}
            onChange={setStateFromEvent(setField('campaignNotification'))}
            fullWidth
          />
        </FormControl>
        <FormControl compact>
          <NotificationDropdown
            required
            label={t('auth:fields.campaignDailyReportNotification')}
            value={fields.campaignDailyReportNotification}
            onChange={setStateFromEvent(setField('campaignDailyReportNotification'))}
            fullWidth
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          {t('common:actions.Close')}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {t('common:actions.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UserSettingsDialog.propTypes = {
  onClose: PropTypes.func,
}

export default UserSettingsDialog
