import ExportIcon from '@mui/icons-material/Save'
import { Alert } from '@mui/material'
import { Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { datetime } from '../../Utils/Datetime'
import { setStateFromEvent } from '../../Utils/Events'
import Loader from '../Loader'
import TextField from '../TextField'
import { ExportLink } from './Styled'
import { getCsvValue } from './Utils'

const Content = styled.div`
  align-items: center;
  box-sizing: border-box;
  margin: 1rem 0.5rem;
  min-width: 300px;

  > div {
    width: 100%;
  }
`

const Icon = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-left: ${(props) => props.marginLeft || 0};
  margin-right: ${(props) => props.marginRight || 0};
`

const ExportAction = ({ exportApi, qs, name, displayColumns, sortedData, fieldsMapping, marginRight, marginLeft }) => {
  const { t } = useTranslation()
  const [fileName, setFileName] = React.useState(`${datetime().format('YYYYMMDDHHmm')}-${name}.csv`)
  const [isOpen, setIsOpen] = React.useState(false)
  const [csvData, setCsvData] = React.useState(null)
  const handleOpen = React.useCallback(() => setIsOpen(true), [setIsOpen])
  const handleClose = () => setIsOpen(false)

  React.useEffect(() => {
    const fetchData = async () => {
      if (isOpen) {
        setCsvData(null)
        const newData = []
        newData.push(displayColumns.map((c) => c.label))

        let data
        if (exportApi) {
          const res = await exportApi({
            ...qs.base,
            ...qs.qsAdditions,
            top: 10000,
          })
          data = R.defaultTo([], res.data?.value)
        } else {
          data = sortedData
        }

        data.forEach((record) => {
          newData.push(displayColumns.map((c) => getCsvValue(fieldsMapping, record, c.id, c)))
        })
        setCsvData(newData)
      }
    }

    fetchData()

  }, [JSON.stringify(displayColumns), JSON.stringify(sortedData), isOpen])

  return (
    <>
      <Icon marginLeft={marginLeft} marginRight={marginRight}>
        <Tooltip title={t('common:actions.Export')}>
          <ExportIcon onClick={handleOpen} />
        </Tooltip>
      </Icon>
      <Dialog onClose={handleClose} open={isOpen} maxWidth="sm">
        <DialogContent>
          <Content>
            <TextField
              required
              label={t('common:ui.FileName')}
              value={fileName}
              onChange={setStateFromEvent(setFileName)}
            />
            {R.isNil(csvData) && <Loader size={20} minHeight="60px" />}
          </Content>
          {R.not(R.isNil(csvData)) && exportApi && csvData.length >= 1e4 && (
            <Alert severity="info" sx={{ marginTop: '1rem' }}>
              {t('common:ui.Max10000Rows')}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('common:actions.Cancel')}
          </Button>
          {R.not(R.isNil(csvData)) ? (
            <ExportLink data={csvData} filename={fileName} onClick={handleClose}>
              <Button disabled={R.isNil(csvData)}>{t('common:actions.Export')}</Button>
            </ExportLink>
          ) : (
            <Button disabled={R.isNil(csvData)}>{t('common:actions.Export')}</Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

ExportAction.propTypes = {
  exportApi: PropTypes.func,
  name: PropTypes.string.isRequired,
  displayColumns: PropTypes.array,
  sortedData: PropTypes.array,
  fieldsMapping: PropTypes.object,
  qs: PropTypes.object,
  marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
export default ExportAction
