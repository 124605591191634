import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'
import i18n from '../../i18n'

const options = [
  { value: 'LINEAR', label: i18n.t('campaigns:ui.Linear') },
  { value: 'EXP', label: i18n.t('campaigns:ui.Exponential') },
]

const RolloutTypeDropdown = ({ value, onChange, required, error, helperText, ...other }) => {
  const { t } = useTranslation()

  return (
    <Dropdown
      label={t('campaigns:fields.rolloutType')}
      required={required}
      value={value}
      onChange={onChange}
      options={options}
      error={error}
      helperText={helperText}
      {...other}
    />
  )
}

RolloutTypeDropdown.defaultProps = {
  required: false,
}

RolloutTypeDropdown.propTypes = FieldType

export default RolloutTypeDropdown
