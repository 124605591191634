import PropTypes from 'prop-types'
import styled from 'styled-components'
import Logo from '@Common/Components/Logo'
import Box from './Box'
import Menu from './Menu'
import { Divider } from '@mui/material'
import packageInfo from '../../../package.json'
import Bg from '@Assets/Images/login-bg.jpg'
import config from '@Config'

const Separator = styled(Divider)`
  border-color: ${({ theme }) => theme.palette.contrastExtraLight.main};
`
const Wrapper = styled.div`
  background: ${({ theme }) => theme.palette.mode === 'dark' ? theme.palette.sidebar.main : `url(${Bg}) repeat top center`};
  background-size: auto;
  color: ${({ theme }) => theme.palette.sidebar.contrastText};
  width: ${({ width }) => `${width}px`};
  min-height: 100vh;
`

const Version = styled.span`
  font-size: 12px;
  left: 120px;
  position: absolute;
  top: 35px;
`

const Sidebar = ({ width }) => {

  return (
    <Wrapper width={width}>
      <Box align='flex-start' margin='10px 1rem' position='relative'>
        <Logo style={{ width: '104px' }} /> 
        <Version>v{packageInfo.version} / {config.env}</Version>
      </Box>
      <Separator />
      <Menu />
    </Wrapper>
  )
}

Sidebar.defaultProps = {
  width: 250,
}

Sidebar.propTypes = {
  width: PropTypes.number,
}

export default Sidebar
