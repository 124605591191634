import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import { identity, ifElse } from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { useAuthentication } from '@Auth/Hooks'
import { useGuessThemeMode } from '@Common/Utils/Hooks'
import Logger from '@Common/Utils/Logger'
import getTheme from '@Theme'
import GlobalStyles from '@Theme/GlobalStyles'

import { selectThemeMode, selectUtcEnabled } from './Redux/Ui'
import AppRouter from './Router'
import StartupView from './Views/StartupView'
import NotificationsWebSocket from '@Notifications/Components/NotificationsWebSocket'

const AppContent = () => {
  const { isComplete } = useAuthentication(true)

  return ifElse(
    identity,
    () => <AppRouter />,
    () => <StartupView />,
  )(isComplete)
}

function App() {
  useGuessThemeMode()
  const mode = useSelector(selectThemeMode)
  const theme = React.useMemo(() => getTheme(mode), [mode])
  const utcEnabled = useSelector(selectUtcEnabled)

  Logger.info('Rendering App component')
  Logger.info('Theme', mode)
  Logger.info('UTC enabled: ', utcEnabled)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale('en')}>
        <ToastContainer />
        <AppContent />
        <NotificationsWebSocket />
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
