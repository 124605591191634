import { Chip } from '@mui/material'
import PropTypes from 'prop-types'

import { getExecutionStatusColor, getExecutionStatusLabel } from '@Campaigns/Utils'

const ExecutionStatus = ({ status }) => {
  return status ? (
    <Chip size="small" color={getExecutionStatusColor(status)} label={getExecutionStatusLabel(status)} />
  ) : null
}
ExecutionStatus.propTypes = {
  status: PropTypes.oneOf([
    'SUCCEEDED',
    'FAILED',
    'REJECTED',
    'CANCELED',
    'REMOVED',
    'TIMED_OUT',
    'QUEUED',
    'IN_PROGRESS',
  ]),
}
export default ExecutionStatus
