import PropTypes from 'prop-types'

import { CampaignInfoType } from '@Campaigns/Types/CampaignInfo'
import { CampaignStatusType } from '@Campaigns/Types/CampaignStatus'

import i18n from '../../i18n'

export const CampaignType = PropTypes.shape({
  _id: PropTypes.string,
  campaignInfo: CampaignInfoType,
  campaignStatus: CampaignStatusType,
  group: PropTypes.shape({
    thingGroupArn: PropTypes.string,
    thingGroupId: PropTypes.string,
    thingGroupName: PropTypes.string,
    thingsCardinality: PropTypes.number,
    thingsNotAddedCardinality: PropTypes.number,
  }),
  job: PropTypes.shape({
    jobId: PropTypes.string,
    jobArn: PropTypes.string,
    timeoutInMinutes: PropTypes.number,
    jobDocument: PropTypes.shape({
      opcode: PropTypes.string,
      payload: PropTypes.shape({
        assetType: PropTypes.string,
        bundleIncrementalVersion: PropTypes.string,
        bundleVersion: PropTypes.string,
        deviceType: PropTypes.string,
        domain: PropTypes.string,
        options: PropTypes.shape({
          forceUpdate: PropTypes.bool,
          wipeApplicationData: PropTypes.bool,
          wipeSettings: PropTypes.bool,
        }),
        ring: PropTypes.string,
      }),
      storage: PropTypes.string,
      target: PropTypes.string,
    }),
    jobDefinition: PropTypes.shape({
      abortConfig: PropTypes.shape({
        criteriaList: PropTypes.arrayOf(
          PropTypes.shape({
            action: PropTypes.string,
            failureType: PropTypes.string,
            minNumberOfExecutedThings: PropTypes.number,
            thresholdPercentage: PropTypes.number,
          }),
        ),
      }),
      description: PropTypes.string,
      jobExecutionsRetryConfig: PropTypes.shape({
        criteriaList: PropTypes.arrayOf(
          PropTypes.shape({
            failureType: PropTypes.string,
            NumberOfRetries: PropTypes.number,
          }),
        ),
      }),
      jobExecutionsRolloutConfig: PropTypes.shape({
        maximumPerMinute: PropTypes.number,
      }),
      namespaceId: PropTypes.string,
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          Key: PropTypes.string,
          Value: PropTypes.string,
        }),
      ),
      targets: PropTypes.arrayOf(PropTypes.string),
      targetSelection: PropTypes.string,
      timeoutConfig: PropTypes.shape({
        inProgressTimeoutInMinutes: PropTypes.number,
      }),
      schedulingConfig: PropTypes.shape({
        startTime: PropTypes.string,
      }),
    }),
    jobEvent: PropTypes.shape({
      eventType: PropTypes.string,
      eventId: PropTypes.string,
      timestamp: PropTypes.number,
      operation: PropTypes.string,
      jobId: PropTypes.string,
      status: PropTypes.string,
      targetSelection: PropTypes.string,
      targets: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.string,
      completedAt: PropTypes.number,
      createdAt: PropTypes.number,
      lastUpdatedAt: PropTypes.number,
      jobProcessDetails: PropTypes.shape({
        numberOfCanceledThings: PropTypes.number,
        numberOfRejectedThings: PropTypes.number,
        numberOfFailedThings: PropTypes.number,
        numberOfRemovedThings: PropTypes.number,
        numberOfSucceededThings: PropTypes.number,
      }),
    }),
  }),
})

export default {
  primaryKey: '_id',
  columns: [
    {
      id: '_id',
      label: i18n.t('campaigns:fields.id'),
    },
    {
      id: 'campaignInfo.name',
      label: i18n.t('campaigns:fields.name'),
    },
    {
      id: 'campaignInfo.queryString',
      label: i18n.t('campaigns:fields.queryString'),
    },
    {
      id: 'campaignInfo.deviceIds',
      label: i18n.t('campaigns:fields.deviceIds'),
    },
    {
      id: 'campaignInfo.failedDeviceIds',
      label: i18n.t('campaigns:fields.failedDeviceIds'),
    },
    {
      id: 'campaignInfo.domain',
      label: i18n.t('common:fields.domain'),
    },
    {
      id: 'campaignInfo.ring',
      label: i18n.t('common:fields.ring'),
    },
    {
      id: 'campaignInfo.assetType',
      label: i18n.t('common:fields.assetType'),
    },
    {
      id: 'campaignInfo.deviceType',
      label: i18n.t('common:fields.deviceType'),
    },
    {
      id: 'campaignInfo.bundleId',
      label: i18n.t('sc:fields.bundleId'),
    },
    {
      id: 'campaignInfo.bundleVersion',
      label: i18n.t('common:fields.bundleVersion'),
    },
    {
      id: 'campaignInfo.options.forceUpdate',
      label: i18n.t('campaigns:fields.forceUpdate'),
      boolean: true,
    },
    {
      id: 'campaignInfo.options.wipeSettings',
      label: i18n.t('campaigns:fields.wipeSettings'),
      boolean: true,
    },
    {
      id: 'campaignInfo.options.wipeApplicationData',
      label: i18n.t('campaigns:fields.wipeApplicationData'),
      boolean: true,
    },
    {
      id: 'campaignStatus.createdBy',
      label: i18n.t('campaigns:fields.createdBy'),
    },
    {
      id: 'campaignStatus.canceledBy',
      label: i18n.t('campaigns:fields.canceledBy'),
    },
    {
      id: 'campaignStatus.creationTimestamp',
      label: i18n.t('campaigns:fields.creationTimestamp'),
      datetime: true,
    },
    {
      id: 'job.jobDefinition.schedulingConfig.startTime',
      label: i18n.t('campaigns:ui.ScheduledAt'),
      datetime: true,
      inputFormat: 'datestring',
    },
    {
      id: 'campaignStatus.status',
      label: i18n.t('campaigns:fields.status'),
    },
    {
      id: 'campaignStatus.statusTimestamp',
      label: i18n.t('campaigns:fields.statusTimestamp'),
      datetime: true,
    },
    {
      id: 'campaignStatus.numberOfCanceledThings',
      label: i18n.t('campaigns:fields.numberOfCanceledThings'),
    },
    {
      id: 'campaignStatus.numberOfInProgressThings',
      label: i18n.t('campaigns:fields.numberOfInProgressThings'),
    },
    {
      id: 'campaignStatus.numberOfQueuedThings',
      label: i18n.t('campaigns:fields.numberOfQueuedThings'),
    },
    {
      id: 'campaignStatus.numberOfRejectedThings',
      label: i18n.t('campaigns:fields.numberOfRejectedThings'),
    },
    {
      id: 'campaignStatus.numberOfFailedThings',
      label: i18n.t('campaigns:fields.numberOfFailedThings'),
    },
    {
      id: 'campaignStatus.numberOfRemovedThings',
      label: i18n.t('campaigns:fields.numberOfRemovedThings'),
    },
    {
      id: 'campaignStatus.numberOfSucceededThings',
      label: i18n.t('campaigns:fields.numberOfSucceededThings'),
    },
    {
      id: 'campaignStatus.numberOfTimedOutThings',
      label: i18n.t('campaigns:fields.numberOfTimedOutThings'),
    },
    {
      id: 'campaignStatusProgress',
      label: i18n.t('campaigns:ui.Progress'),
      disableSorting: true,
    },
    {
      id: 'group.thingsCardinality',
      label: i18n.t('campaigns:fields.cardinality'),
    },
    {
      id: 'job.jobDefinition.targetSelection',
      label: i18n.t('campaigns:fields.targetSelection'),
    },
  ],
}
