import PropTypes from 'prop-types'
import { Link } from '@mui/material'
import { history } from '@Core/Redux/Store'

const OtaLink = ({to, children, ...props}) => {
  return (
    <Link {...props} style={{ cursor: 'pointer' }} onClick={() => history.push(to)}>{children}</Link>
  )
}

OtaLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default OtaLink
