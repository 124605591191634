import React from 'react'
import PropTypes from 'prop-types'
import MUITablePagination from '@mui/material/TablePagination'

const AwsTablePagination = ({ ...props }) => {
  return (
    <MUITablePagination {...props} />
  )
}

AwsTablePagination.propTypes = {
  rowsPerPageOptions: PropTypes.array,
  component: PropTypes.string,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  showFirstButton: PropTypes.bool,
}

export default AwsTablePagination
