import PropTypes from 'prop-types'

const Logo = ({ color, style }) => {
  return (
    <svg id="Livello_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 62" style={style}>
      <g id="Livello_1-2">
        <g>
          <g>
            <path
              fill={color}
              d="M1.4,28.6H9.8c.73,0,1.4-.6,1.4-1.41V1.94c0-.74-.6-1.41-1.4-1.41H1.4C.6,.54,0,1.14,0,1.94V27.26c0,.94,.87,1.34,1.4,1.34"
            />
            <path
              fill={color}
              d="M16.34,2.55l5.94,22.03c.4,1.47,1.47,2.54,2.27,3.15,1,.67,2,.94,3.6,.94h6.54c2.8,0,4.87-1.74,5.67-4.69L45.69,2.68c.07-.2,.07-.4,.07-.6,0-.94-.67-1.47-1.4-1.47h-4.87c-.27,0-1.07,.07-1.4,1.07l-4.2,18.48c-.13,.6-.47,.8-.8,.8-.27,0-.6-.2-.67-.6L27.95,2.01c-.13-.94-.73-1.41-1.4-1.41h-8.87c-1.13,0-1.47,1-1.4,1.61,0,0,.07,.13,.07,.33"
            />
            <path
              fill={color}
              d="M61.7,11.32v-2.61c0-.87,.67-1.47,1.4-1.47h10c.6,0,1.4-.47,1.4-1.41V1.94c0-.8-.67-1.41-1.4-1.41h-17.01c-2.73,0-5.54,2.14-5.54,6.03V22.97c0,3.55,3,5.63,5.54,5.63h17.21c.73,0,1.47-.54,1.4-1.54v-3.88c0-.8-.6-1.41-1.4-1.41h-10c-.93,0-1.53-.54-1.53-1.41v-2.75h8.74c.67,0,1.4-.54,1.4-1.47v-3.42c0-.74-.6-1.41-1.4-1.41h-8.8Z"
            />
            <path
              fill={color}
              d="M96.25,7.17c-4,0-5.47,1.67-5.47,7.37s1.47,7.37,5.47,7.37h4.94c1.6,0,2.13,.54,2.13,1.81v3.42c0,1.27-.53,1.81-2.13,1.81h-5.14c-4.47,0-7.67,.07-11.01-2.01-3.74-2.14-5.34-6.76-5.34-12.46s1.6-10.31,5.34-12.46C88.31-.13,91.58,0,96.05,0h5.14c1.6,0,2.13,.54,2.13,1.81v3.42c0,1.27-.53,1.81-2.13,1.81s-4.94,.13-4.94,.13"
            />
            <path
              fill={color}
              d="M118.46,14.53c0,5.69,1.47,7.57,5.47,7.57s5.34-1.88,5.34-7.57-1.4-7.57-5.34-7.57-5.47,1.88-5.47,7.57m21.54,0c0,5.69-1.6,10.25-5.34,12.39-3.27,2.14-6.4,2.08-10.87,2.08s-7.67,.07-11.01-2.08c-3.74-2.14-5.34-6.7-5.34-12.39s1.6-10.25,5.34-12.39C116.06,0,119.26,.07,123.79,.07s7.6-.07,10.87,2.08c3.74,2.14,5.34,6.7,5.34,12.39"
            />
          </g>
          <g>
            <path
              fill={color}
              d="M.61,61.73c-.2,0-.35-.06-.45-.17-.11-.12-.16-.26-.16-.44v-17.42c0-.2,.05-.35,.16-.47,.11-.12,.26-.17,.45-.17H11.5c.2,0,.35,.06,.45,.17,.11,.12,.16,.27,.16,.47v1.07c0,.2-.05,.35-.16,.45-.11,.11-.26,.16-.45,.16H2.59v6.22H10.96c.2,0,.35,.06,.45,.17,.11,.12,.16,.27,.16,.47v1.07c0,.18-.05,.32-.16,.44-.11,.12-.26,.17-.45,.17H2.59v7.2c0,.18-.06,.32-.17,.44-.12,.12-.27,.17-.47,.17H.61Z"
            />
            <path
              fill={color}
              d="M22.14,62c-1.51,0-2.81-.26-3.89-.79-1.09-.52-1.92-1.33-2.51-2.43-.59-1.09-.92-2.47-.99-4.12-.02-.78-.03-1.53-.03-2.25s0-1.47,.03-2.25c.07-1.64,.41-3,1.03-4.1s1.47-1.91,2.56-2.45,2.36-.81,3.8-.81,2.73,.27,3.83,.81c1.09,.54,1.95,1.36,2.58,2.45,.62,1.09,.96,2.46,1.01,4.1,.03,.78,.05,1.53,.05,2.25s-.02,1.47-.05,2.25c-.05,1.65-.38,3.03-.97,4.12s-1.44,1.9-2.52,2.43c-1.09,.52-2.39,.79-3.92,.79Zm0-2.27c1.37,0,2.48-.41,3.35-1.23,.86-.82,1.33-2.14,1.4-3.97,.03-.8,.05-1.52,.05-2.15s-.02-1.34-.05-2.12c-.04-1.23-.26-2.22-.68-2.99-.42-.76-.97-1.32-1.67-1.68-.69-.36-1.49-.53-2.4-.53s-1.65,.18-2.35,.53-1.25,.92-1.67,1.68c-.42,.76-.65,1.76-.71,2.99-.02,.78-.03,1.49-.03,2.12s0,1.35,.03,2.15c.07,1.83,.54,3.16,1.41,3.97,.87,.82,1.97,1.23,3.31,1.23Z"
            />
            <path
              fill={color}
              d="M37.8,61.73c-.18,0-.33-.06-.44-.17s-.17-.26-.17-.44v-15.69h-5.12c-.18,0-.32-.06-.44-.17s-.17-.26-.17-.44v-1.12c0-.2,.06-.35,.17-.47s.26-.17,.44-.17h12.86c.2,0,.35,.06,.47,.17s.17,.27,.17,.47v1.12c0,.18-.06,.32-.17,.44s-.27,.17-.47,.17h-5.1v15.69c0,.18-.06,.32-.17,.44s-.27,.17-.47,.17h-1.39Z"
            />
            <path
              fill={color}
              d="M45.32,61.73c-.14,0-.27-.05-.37-.16s-.16-.23-.16-.37c0-.09,0-.19,.03-.29l6.62-17.26c.07-.18,.17-.32,.29-.43,.12-.11,.31-.16,.56-.16h1.81c.23,0,.41,.05,.55,.16,.13,.11,.24,.25,.31,.43l6.59,17.26c.03,.11,.05,.2,.05,.29,0,.14-.05,.27-.16,.37-.11,.11-.23,.16-.37,.16h-1.36c-.21,0-.37-.05-.47-.16-.1-.11-.17-.2-.2-.29l-1.47-3.79h-8.75l-1.47,3.79c-.02,.09-.08,.19-.19,.29s-.27,.16-.48,.16h-1.36Zm4.27-6.54h7.2l-3.6-9.47-3.6,9.47Z"
            />
            <path
              fill={color}
              d="M70.91,61.73c-.2,0-.35-.06-.45-.17-.11-.12-.16-.26-.16-.44v-17.42c0-.2,.05-.35,.16-.47,.11-.12,.26-.17,.45-.17h6.78c1.32,0,2.46,.21,3.43,.64s1.72,1.06,2.25,1.91c.53,.85,.8,1.9,.8,3.16s-.27,2.31-.8,3.15c-.53,.84-1.29,1.47-2.25,1.89s-2.11,.64-3.43,.64h-4.72v6.67c0,.18-.06,.32-.17,.44s-.27,.17-.47,.17h-1.41Zm2.03-9.52h4.62c1.32,0,2.3-.29,2.96-.88s.99-1.44,.99-2.56-.32-1.96-.96-2.56c-.64-.61-1.64-.91-2.99-.91h-4.62v6.91Z"
            />
            <path
              fill={color}
              d="M92.57,62c-1.35,0-2.48-.26-3.39-.77-.91-.52-1.6-1.23-2.08-2.15-.48-.92-.75-1.96-.8-3.13-.02-.3-.03-.69-.03-1.16s0-.85,.03-1.13c.05-1.19,.32-2.24,.81-3.15,.49-.91,1.19-1.62,2.09-2.13,.91-.52,2.03-.77,3.36-.77s2.45,.26,3.36,.77c.91,.52,1.6,1.23,2.09,2.13,.49,.91,.76,1.96,.81,3.15,.02,.28,.03,.66,.03,1.13s0,.86-.03,1.16c-.05,1.17-.32,2.22-.8,3.13-.48,.92-1.17,1.63-2.08,2.15-.91,.51-2.04,.77-3.39,.77Zm0-2.05c1.1,0,1.98-.35,2.64-1.05,.66-.7,1.01-1.73,1.07-3.08,.02-.27,.03-.61,.03-1.01s0-.75-.03-1.01c-.05-1.35-.41-2.38-1.07-3.08-.66-.7-1.54-1.05-2.64-1.05s-1.99,.35-2.65,1.05c-.67,.7-1.02,1.73-1.05,3.08-.02,.27-.03,.61-.03,1.01s0,.75,.03,1.01c.03,1.35,.39,2.38,1.05,3.08,.67,.7,1.55,1.05,2.65,1.05Z"
            />
            <path
              fill={color}
              d="M102.81,61.73c-.18,0-.32-.06-.44-.17s-.17-.26-.17-.44v-12.62c0-.18,.06-.33,.17-.45s.26-.19,.44-.19h1.23c.18,0,.33,.06,.45,.19s.19,.27,.19,.45v1.17c.36-.6,.85-1.06,1.47-1.36,.62-.3,1.37-.45,2.24-.45h1.07c.18,0,.32,.06,.43,.17,.11,.12,.16,.26,.16,.44v1.09c0,.18-.05,.32-.16,.43s-.25,.16-.43,.16h-1.6c-.96,0-1.72,.28-2.27,.84-.55,.56-.83,1.32-.83,2.28v7.84c0,.18-.06,.32-.19,.44-.13,.12-.28,.17-.45,.17h-1.31Z"
            />
            <path
              fill={color}
              d="M117.75,61.73c-1,0-1.8-.19-2.43-.57-.62-.38-1.08-.92-1.36-1.63-.28-.7-.43-1.53-.43-2.49v-7.04h-2.08c-.18,0-.32-.06-.44-.17s-.17-.26-.17-.44v-.91c0-.18,.06-.32,.17-.44,.12-.11,.26-.17,.44-.17h2.08v-4.46c0-.18,.06-.32,.17-.44s.26-.17,.44-.17h1.25c.18,0,.32,.06,.44,.17s.17,.26,.17,.44v4.46h3.31c.18,0,.32,.06,.44,.17,.12,.12,.17,.26,.17,.44v.91c0,.18-.06,.32-.17,.44s-.26,.17-.44,.17h-3.31v6.86c0,.84,.14,1.49,.43,1.97s.79,.72,1.52,.72h1.63c.18,0,.32,.06,.44,.17,.12,.12,.17,.26,.17,.44v.96c0,.18-.06,.32-.17,.44s-.26,.17-.44,.17h-1.84Z"
            />
            <path
              fill={color}
              d="M126.64,62c-.89,0-1.7-.18-2.43-.53s-1.32-.84-1.76-1.44c-.44-.6-.67-1.29-.67-2.05,0-1.23,.5-2.21,1.49-2.93s2.29-1.21,3.89-1.44l3.98-.56v-.77c0-.85-.24-1.52-.73-2s-1.29-.72-2.39-.72c-.8,0-1.45,.16-1.93,.48s-.83,.73-1.03,1.23c-.11,.27-.29,.4-.56,.4h-1.2c-.2,0-.34-.06-.44-.17s-.15-.25-.15-.41c0-.27,.1-.6,.31-.99,.2-.39,.52-.77,.95-1.15s.97-.69,1.64-.95c.67-.26,1.48-.39,2.44-.39,1.07,0,1.97,.14,2.69,.41,.73,.28,1.3,.64,1.72,1.11,.42,.46,.72,.99,.91,1.57s.28,1.18,.28,1.79v8.64c0,.18-.06,.32-.17,.44-.12,.12-.26,.17-.44,.17h-1.23c-.2,0-.35-.06-.45-.17-.11-.12-.16-.26-.16-.44v-1.15c-.23,.32-.54,.64-.93,.95-.39,.31-.88,.57-1.47,.77-.59,.2-1.31,.31-2.16,.31Zm.56-2c.73,0,1.4-.16,2-.47,.61-.31,1.08-.8,1.43-1.45,.35-.66,.52-1.49,.52-2.48v-.75l-3.09,.45c-1.26,.18-2.21,.48-2.85,.89-.64,.42-.96,.95-.96,1.59,0,.5,.15,.91,.44,1.24,.29,.33,.67,.57,1.12,.73,.45,.16,.92,.24,1.4,.24Z"
            />
            <path
              fill={color}
              d="M138.13,61.73c-.18,0-.33-.06-.44-.17s-.17-.26-.17-.44v-17.71c0-.18,.06-.32,.17-.44s.26-.17,.44-.17h1.25c.2,0,.35,.06,.45,.17,.11,.12,.16,.26,.16,.44v17.71c0,.18-.05,.32-.16,.44-.11,.12-.26,.17-.45,.17h-1.25Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

Logo.defaultProps = {
  color: '#fff',
  style: {},
}

Logo.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
}

export default Logo
