import { Button, Stack } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import PropTypes from 'prop-types'
import { F, nthArg, pipe } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import styled from 'styled-components'

import CampaignJobAbortForm from '@Campaigns/Forms/CampaignJobAbortForm'
import { validate as validateAbort } from '@Campaigns/Forms/CampaignJobAbortForm/Validation'
import CampaignJobGeneralForm from '@Campaigns/Forms/CampaignJobGeneralForm'
import { validate as validateGeneral } from '@Campaigns/Forms/CampaignJobGeneralForm/Validation'
import CampaignJobRetryForm from '@Campaigns/Forms/CampaignJobRetryForm'
import { validate as validateRetry } from '@Campaigns/Forms/CampaignJobRetryForm/Validation'
import CampaignJobRolloutForm from '@Campaigns/Forms/CampaignJobRolloutForm'
import { validate as validateRollout } from '@Campaigns/Forms/CampaignJobRolloutForm/Validation'
import CampaignJobTimeoutForm from '@Campaigns/Forms/CampaignJobTimeoutForm'
import { validate as validateTimeout } from '@Campaigns/Forms/CampaignJobTimeoutForm/Validation'
import { isContinuousCampaign } from '@Campaigns/Utils'
import Box from '@Common/Components/Box'
import Logger from '@Common/Utils/Logger'

const TabContent = styled.div`
  margin: 0 2rem 0;
  flex-grow: 1;
`

const StepJobConfiguration = ({
  fields,
  setField,
  setFields,
  errors,
  setErrors,
  onCancel,
  onNext,
  targetSelection,
  totDevices,
  useAbortAwsDefaults,
  useRetryAwsDefaults,
  useRolloutAwsDefaults,
  useTimeoutAwsDefaults,
  setUseAbortAwsDefaults,
  setUseRetryAwsDefaults,
  setUseRolloutAwsDefaults,
  setUseTimeoutAwsDefaults,
  expRolloutNumberOf,
  setExpRolloutNumberOf,
}) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = React.useState(0)

  const handleChange = compose(setActiveTab, nthArg(1))

  const setTabAndReturnError = pipe(setActiveTab, F)

  const validate = (fields, setErrors, errors) => {
    if (!validateGeneral(fields, setErrors, errors)) return setTabAndReturnError(0)
    if (!useAbortAwsDefaults && !validateAbort(fields, setErrors, errors)) return setTabAndReturnError(1)
    if (!useRetryAwsDefaults && !validateRetry(fields, setErrors, errors)) return setTabAndReturnError(2)
    if (!useRolloutAwsDefaults && !validateRollout(fields, setErrors, errors, expRolloutNumberOf))
      return setTabAndReturnError(3)
    if (!useTimeoutAwsDefaults && !validateTimeout(fields, setErrors, errors)) return setTabAndReturnError(4)

    return true
  }

  const handleSubmit = () => {
    Logger.debug('Campaign job form submission, fields:', fields)
    if (validate(fields, setErrors, errors)) {
      Logger.debug('Campaign job form submission, validation passed')
      onNext()
    } else {
      Logger.debug('Campaign job form submission, validation failed')
    }
  }

  return (
    <>
      <Box direction="row">
        <Tabs
          value={activeTab}
          onChange={handleChange}
          orientation="vertical"
          sx={{ borderRight: 1, borderColor: 'divider', minWidth: '150px' }}
        >
          <Tab label={t('campaigns:ui.General')} />
          <Tab label={t('campaigns:ui.Abort')} disabled={isContinuousCampaign(targetSelection)} />
          <Tab label={t('campaigns:ui.Retry')} />
          <Tab label={t('campaigns:ui.Rollout')} disabled={isContinuousCampaign(targetSelection)} />
          <Tab label={t('campaigns:ui.Timeout')} />
        </Tabs>
        <TabContent>
          {activeTab === 0 && (
            <CampaignJobGeneralForm
              fields={fields}
              setField={setField}
              setFields={setFields}
              errors={errors}
              setErrors={setErrors}
            />
          )}
          {activeTab === 1 && (
            <CampaignJobAbortForm
              fields={fields}
              setField={setField}
              setFields={setFields}
              errors={errors}
              setErrors={setErrors}
              useAwsDefaults={useAbortAwsDefaults}
              setUseAwsDefaults={setUseAbortAwsDefaults}
            />
          )}
          {activeTab === 2 && (
            <CampaignJobRetryForm
              fields={fields}
              setField={setField}
              setFields={setFields}
              errors={errors}
              setErrors={setErrors}
              useAwsDefaults={useRetryAwsDefaults}
              setUseAwsDefaults={setUseRetryAwsDefaults}
            />
          )}
          {activeTab === 3 && (
            <CampaignJobRolloutForm
              fields={fields}
              setField={setField}
              setFields={setFields}
              errors={errors}
              setErrors={setErrors}
              useAwsDefaults={useRolloutAwsDefaults}
              setUseAwsDefaults={setUseRolloutAwsDefaults}
              expRolloutNumberOf={expRolloutNumberOf}
              setExpRolloutNumberOf={setExpRolloutNumberOf}
              totDevices={totDevices}
            />
          )}
          {activeTab === 4 && (
            <CampaignJobTimeoutForm
              fields={fields}
              setField={setField}
              setFields={setFields}
              errors={errors}
              setErrors={setErrors}
              useAwsDefaults={useTimeoutAwsDefaults}
              setUseAwsDefaults={setUseTimeoutAwsDefaults}
            />
          )}
        </TabContent>
      </Box>
      <Stack direction="row" justifyContent="end" spacing={2} marginTop="2rem">
        <Button variant="contained" color="primary" onClick={onCancel}>
          {t('common:actions.Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {t('common:actions.Next')}
        </Button>
      </Stack>
    </>
  )
}

StepJobConfiguration.propTypes = {
  fields: PropTypes.object,
  setField: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  targetSelection: PropTypes.string,
  totDevices: PropTypes.number,
  useAbortAwsDefaults: PropTypes.bool,
  useRetryAwsDefaults: PropTypes.bool,
  useRolloutAwsDefaults: PropTypes.bool,
  useTimeoutAwsDefaults: PropTypes.bool,
  setUseAbortAwsDefaults: PropTypes.func,
  setUseRetryAwsDefaults: PropTypes.func,
  setUseRolloutAwsDefaults: PropTypes.func,
  setUseTimeoutAwsDefaults: PropTypes.func,
  expRolloutNumberOf: PropTypes.oneOf(['NOTIFIED', 'SUCCEEDED']),
  setExpRolloutNumberOf: PropTypes.func,
}

export default StepJobConfiguration
