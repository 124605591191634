import PropTypes from 'prop-types'

export const CampaignStatusType = PropTypes.shape({
    canceledBy: PropTypes.string,
    createdBy: PropTypes.string,
    creationTimestamp: PropTypes.number,
    numberOfCanceledThings: PropTypes.number,
    numberOfFailedThings: PropTypes.number,
    numberOfRejectedThings: PropTypes.number,
    numberOfRemovedThings: PropTypes.number,
    numberOfSucceededThings: PropTypes.number,
    status: PropTypes.string,
    statusTimestamp: PropTypes.number,
})
