import getStorage from '@Common/Services/Storage'
const storageService = getStorage()

const config = {
  logger: {
    // eslint-disable-next-line no-undef
    level: storageService.get('configLoggerLevel', process.env.REACT_APP_LOGGER_LEVEL), // DEBUG, INFO, WARNING, ERROR
  },
  ui: {
    enableDataTableColumnResizing: storageService.get('configEnableDataTableColumnResizing', false),
    inputsDebounceTime: storageService.get('configInputsDebounceTime', 500),
  },
  env: process.env.REACT_APP_ENV,
  // eslint-disable-next-line no-undef
  apiBasePath: process.env.REACT_APP_API_BASE_PATH,
  // eslint-disable-next-line no-undef
  wsBasePath: process.env.REACT_APP_WS_BASE_PATH,
  wsUrls: {},
  urls: {
    home: '/',
    homeToRedirect: '/index.html',
    dashboard: '/dashboard',
    login: '/login',
    loginMsal: '/login/:username/msal',
    pageNotFound: '/404',
    vehicles: {
      base: '/vehicles/*',
      main: '',
      detail: ':deviceId',
    },
    campaigns: {
      base: '/campaigns/*',
      list: '',
      dashboard: 'last',
      create: 'create',
      detail: ':campaignId',
      detailExecutions: ':campaignId/executions',
    },
    bundles: {
      base: '/bundles/*',
      main: '',
      detail: ':bundleId',
      swDetail:':bundleId/sw/:swComponentName/:swComponentVersion'
    },
    softwareComponents: {
      base: '/software-components/*',
      main: '',
      detail: ':softwareComponentId',
    },
    users: {
      base: '/users/*',
      main: '',
    },
    administration: {
      base: '/administration/*',
      users: 'users',
    }
  },
  datetime: {
    msTimestamp: false,
  },
  localStorageInvalidation: {
    // [key]: Date getTime
  },
}

export default config

export const makePath = (selector, context) => {
  const parts = selector.split('.')
  parts.pop() // remove leaf
  const parents = []
  parts.reduce((acc, curr) => {
    if (acc[curr]?.base) {
      parents.push(acc[curr]?.base)
    }
    return acc ? acc[curr] : null
  }, config.urls)

  let path = selector.split('.').reduce((acc, curr) => (acc ? acc[curr] : null), config.urls)

  // compose path
  path = parents.join('').replace(/\*/g, '') + (path ? `${path}` : '')

  // apply context
  if (context) {
    Object.keys(context).forEach((key) => {
      path = path.replace(`:${key}`, context[key])
    })
  }

  return path
}
