import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'auth',
  initialState: { account: null, saml: null, token: '', user: null },
  reducers: {
    setSaml: (state, { payload }) => {
      state.saml = payload
    },
    setCredentials: (state, { payload }) => {
      state.token = payload.accessToken
      state.account = payload.username
    },
    refreshToken: (state, { payload }) => {
      state.token = payload
    },
    resetCredentials: (state) => {
      state.token = null
      state.account = null
      state.user = null
    },
    logout: (state) => {
      state.token = null
      state.account = null
      state.user = null
    },
    setUser: (state, { payload }) => {
      state.user = payload
    },
  },
})

export const { setSaml, setCredentials, refreshToken, setUser, resetCredentials, logout } = slice.actions

export default slice.reducer

export const selectAuth = (state) => state.auth
export const selectAccount = (state) => state.auth.saml
export const selectCurrentAccount = (state) => state.auth.account
export const selectCurrentUser = (state) => state.auth.user
export const selectAccessToken = (state) => state.auth.token
