import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import { groupBy, prop, uniq } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectThemeMode } from '@Core/Redux/Ui'
import { chartBackground, chartCommonOptions, chartTitleStyle, chartXAxisCommonOptions, chartYAxisCommonOptions } from '@Dashboard/Utils/Charts'

const BundlesRingColumnChart = ({ stats, title }) => {
  const { t } = useTranslation()
  const themeMode = useSelector(selectThemeMode)
  const groupedStats = groupBy(prop('status'), stats)

  const options = {
    ...chartCommonOptions(themeMode),
    chart: {
      backgroundColor: chartBackground(themeMode),
      type: 'column',
      height: '350px',
    },
    colors: ['#2DA2FF', '#61D837', '#F6644E', '#F8BA00'],
    title: {
      text: title,
      style: chartTitleStyle(themeMode),
    },
    xAxis: {
      ...chartXAxisCommonOptions(themeMode),
      categories: uniq(stats.map(prop('ring'))),
    },
    yAxis: chartYAxisCommonOptions(themeMode, t('dashboard:ui.Number')),
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      shared: true,
      useHTML: true,
    },
    series: Object.keys(groupedStats).map((status) => {
      return {
        name: status,
        data: groupedStats[status].map(prop('cardinality')),
      }
    }),
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

BundlesRingColumnChart.propTypes = {
  title: PropTypes.string,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      cardinality: PropTypes.number,
      status: PropTypes.oneOf(['OK', 'ERROR', 'PENDING', 'FAILED', 'NONE']),
      deviceType: PropTypes.string,
      domain: PropTypes.string,
      ring: PropTypes.string,
    }),
  ),
}

export default React.memo(BundlesRingColumnChart)
