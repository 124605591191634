import { replace } from "ramda"

export const isContinuousCampaign = targetSelection => targetSelection === 'CONTINUOUS'
export const getCampaignStatusColor = status => {
  switch (status) {
    case 'JOB_COMPLETED':
      return 'successLight'
    case 'JOB_CANCELED':
      return 'warningLight'
    case 'JOB_DELETED':
    case 'GROUP_CREATION_FAILED':
    case 'JOB_CREATION_FAILED':
      return 'errorLight'
    default:
      return 'infoLight'
  }
}
export const getCampaignStatusLabel = replace(/_/g, ' ')

export const getExecutionStatusColor = status => {
  switch (status) {
    case 'SUCCEEDED':
      return 'successLight'
    case 'JOB_CACANCELEDNCELED':
      return 'warningLight'
    case 'FAILED':
    case 'REJECTED':
    case 'REMOVED':
    case 'TIMED_OUT':
      return 'errorLight'
    default:
      return 'infoLight'
  }
}
export const getExecutionStatusLabel = replace(/_/g, ' ')
