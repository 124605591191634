import React from 'react'
import styled from 'styled-components'
import { Loop } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'

export const Chip = styled.div`
  align-items: center;
  background: ${props => props.bg};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 26px;
  width: 26px;
`

const ProgressChip = () => {
  const theme = useTheme()
  return (
    <Chip bg={theme.palette.infoLight.main}>
      <Loop sx={{ color: theme.palette.infoLight.contrastText }} />
    </Chip>
  )
}

export default ProgressChip
