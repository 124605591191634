import * as R from 'ramda'

export const ROLES = {
  ADMIN: 'OTA_ADMIN',
  MANAGER: 'OTA_MANAGER',
  USER: 'OTA_USER',
}

// receives a role name as argument and returns a function that requires user as argument
export const hasRole = (role) => R.compose(R.equals(role), R.path(['userInfo', 'role']))

// returns a function that requires user as argument and checks if user is admin
export const isAdmin = hasRole(ROLES.ADMIN)

// returns a function that requires user as argument and checks if user is manager
export const isManager = hasRole(ROLES.MANAGER)

// returns a function that requires user as argument and checks if user is simple user
export const isUser = hasRole(ROLES.USER)
