import { api, apiQueryString } from '@Core/Services/Api'

const prefix = 'campaign'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    campaigns: builder.query({
      query: (qs) => `${prefix}?${apiQueryString(qs)}`,
      providesTags: [{ type: 'Campaign', id: 'LIST' }],
    }),
    campaign: builder.query({
      query: (campaignId) => `${prefix}/${campaignId}`,
      providesTags: (_, __, campaignId) => [{ type: 'Campaign', id: campaignId }],
    }),
    campaignUploadFile: builder.query({
      query: () => `${prefix}/uploadFile`,
    }),
    campaignDownloadFile: builder.query({
      query: (campaignId) => `${prefix}/${campaignId}/downloadFile`,
    }),
    createCampaign: builder.mutation({
      query: (campaign) => ({
        url: `${prefix}`,
        body: campaign,
        method: 'POST',
      }),
      invalidatesTags: ['Campaign'],
    }),
    lastCampaign: builder.query({
      query: () =>
        `${prefix}?${apiQueryString({
          pageSize: 1,
          pageNumber: 0,
          orderBy: 'campaignStatus.statusTimestamp',
          orderType: 'desc',
        })}`,
      providesTags: [{ type: 'Campaign', id: 'LAST' }],
    }),
    campaignExecutionDailyReports: builder.query({
      query: ({ campaignId, qs }) => `${prefix}/${campaignId}/execution/daily-report?${apiQueryString(qs)}`,
      providesTags: (_, __, { campaignId }) => [{ type: 'CampaignExecutionDailyReport', id: campaignId }],
    }),
    campaignDailyReports: builder.query({
      query: (campaignId) => `${prefix}/${campaignId}/daily-report`,
      providesTags: (_, __, campaignId) => [{ type: 'CampaignDailyReport', id: campaignId }],
    }),
    campaignExecutions: builder.query({
      query: ({ campaignId, qs }) => `${prefix}/execution?campaignId=${campaignId}&${apiQueryString(qs)}`,
      providesTags: (_, __, { campaignId }) => [{ type: 'CampaignExecution', id: campaignId }],
    }),
    executions: builder.query({
      query: ({ qs }) => `${prefix}/execution?${apiQueryString(qs)}`,
      providesTags: (_, __, { campaignId }) => [{ type: 'CampaignExecution', id: campaignId }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useCampaignsQuery,
  useCampaignQuery,
  useLazyCampaignUploadFileQuery,
  useLazyCampaignDownloadFileQuery,
  useCreateCampaignMutation,
  useLastCampaignQuery,
  useCampaignExecutionDailyReportsQuery,
  useCampaignDailyReportsQuery,
  useCampaignExecutionsQuery,
  useExecutionsQuery,
} = extendedApi
