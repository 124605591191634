import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import { groupBy, prop, uniq } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectThemeMode } from '@Core/Redux/Ui'
import { chartBackground, chartCommonOptions, chartTitleStyle, chartXAxisCommonOptions, chartYAxisCommonOptions } from '@Dashboard/Utils/Charts'
import Logger from '@Common/Utils/Logger'

const CampaignsRingColumnChart = ({ stats, title }) => {
  const { t } = useTranslation()
  const themeMode = useSelector(selectThemeMode)
  const groupedStats = groupBy(prop('fromSucceededPercentage'), stats)

  Logger.debug('Campaign percentage grouped stats', title, groupedStats)

  const options = {
    ...chartCommonOptions(themeMode),
    chart: {
      backgroundColor: chartBackground(themeMode),
      type: 'column',
      height: '350px',
    },
    colors: ['#CB297B', '#834190', '#EF5FA7', '#9D70CB'],
    title: {
      text: title,
      style: chartTitleStyle(themeMode),
    },
    xAxis: {
      ...chartXAxisCommonOptions(themeMode),
      categories: uniq(stats.map(prop('ring'))),
    },
    yAxis: {
      ...chartYAxisCommonOptions(themeMode, t('dashboard:ui.Number')),
      allowDecimals: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      shared: true,
      useHTML: true,
    },
    series: Object.keys(groupedStats).map((slot) => {
      return {
        name: `${groupedStats[slot][0].fromSucceededPercentage}% - ${groupedStats[slot][0].toSucceededPercentage}%`,
        data: groupedStats[slot].map(prop('cardinality')),
      }
    }),
  }
  console.log('OPTIONS', options) // eslint-disable-line

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

CampaignsRingColumnChart.propTypes = {
  title: PropTypes.string,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      cardinality: PropTypes.number,
      fromSucceededPercentage: PropTypes.number,
      toSucceededPercentage: PropTypes.number,
      deviceType: PropTypes.string,
      domain: PropTypes.string,
      ring: PropTypes.string,
    }),
  ),
}

export default React.memo(CampaignsRingColumnChart)
