import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'

import Bg from '@Assets/Images/login-bg.jpg'
import { selectCurrentUser } from '@Auth/Redux'
import Box from '@Common/Components/Box'
import Logo from '@Common/Components/Logo'
import { setStateFromEvent } from '@Common/Utils/Events'
import logger from '@Common/Utils/Logger'
import config from '@Config'
import { history } from '@Core/Redux/Store'

const Wrapper = styled(Box)`
  background: #011327 url(${Bg}) no-repeat center center;
  background-size: cover;
  min-height: 100vh;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

const WhiteBorderTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  .MuiFormLabel-root {
    color: white;
  }
  .MuiInputBase-input {
    color: white;
  }

  &:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: white;
    }
    .MuiFormLabel-root {
      color: white;
    }
    .MuiInputBase-input {
      color: white;
    }
  }
`

const LoginView = () => {
  const { t } = useTranslation()
  const [username, setUsername] = React.useState('')
  const user = useSelector(selectCurrentUser)
  const nav = useLocation()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (user?._id) {
      logger.debug('Already logged in, redirecting to ', nav.state?.next || config.urls.home)
      const redirect = nav.state?.next && nav.state.next !== '/login' ? nav.state.next : config.urls.home
      history.push(redirect)
    }
  }, [user?._id])

  return (
    <Wrapper align="center" direction="column" justify="center">
      <Box align="center" direction="column" justify="center" margin="2rem" pad="2rem" width="350px">
        <Logo style={{ width: '170px', marginBottom: '2rem' }} />
        <WhiteBorderTextField
          color="white"
          label={t('auth:fields.eMail')}
          value={username}
          onChange={setStateFromEvent(setUsername)}
          style={{ width: '100%' }}
          variant="outlined"
          sx={{
            input: {
              color: '#fff',
              borderColor: '#fff',
            },
          }}
        />
        <ButtonRow>
          <Button
            disabled={!username}
            variant="contained"
            color="primary"
            onClick={() => navigate(config.urls.loginMsal.replace(':username', username), { state: nav.state })}
          >
            {t('actions.Login')}
          </Button>
        </ButtonRow>
      </Box>
    </Wrapper>
  )
}

export default LoginView
