import React from 'react'
import { Route, Routes } from 'react-router-dom'

import config from '@Config'

import AdminUsersView from './Views/AdminUsersView'

export const AdministrationRouter = () => {
  return (
    <Routes>
      <Route path={config.urls.users.main} element={<AdminUsersView />} />
    </Routes>
  )
}

export default AdministrationRouter
