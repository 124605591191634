import { Alert, Button, DialogTitle } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useLazyCampaignUploadFileQuery } from '@Campaigns/Services/Api'
import FileField from '@Common/Components/FileField'
import Loader from '@Common/Components/Loader'
import logger from '@Common/Utils/Logger'
import Logger from '@Common/Utils/Logger'
import { uploadToS3 } from '@Common/Utils/Upload'

const DropzoneContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: block;
  padding: 1rem;
`

const CampaignCsvForm = ({ onCancel, onSubmit }) => {
  const { t } = useTranslation()
  const [fetchUploadInfo] = useLazyCampaignUploadFileQuery()
  const [file, setFile] = useState([])
  const [requiredError, setRequiredError] = useState(false)
  const [uploadError, setUploadError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleFileDrop = (acceptedFiled) => {
    setFile(acceptedFiled)
    setUploadError(null)
    setRequiredError(false)
  }

  const validateRequired = useCallback(() => {
    if (file.length !== 1) {
      setRequiredError(true)
      return false
    }

    return true
  }, [file])

  const handleSubmit = async () => {
    setUploadError(null)
    if (!validateRequired()) {
      return
    }

    setIsLoading(true)
    try {
      // Get signed url for upload
      const { uploadUrl, fileName } = await fetchUploadInfo().unwrap()
      // Perform upload to S3
      const response = await uploadToS3(uploadUrl, file[0])
      Logger.debug('upload response:', response)

      const fileContent = await file[0].text()
      let lines = fileContent.match(/\r?\n/g).length
      if (!/\r?\n$/g.test(fileContent)) lines++

      onSubmit({ fileName, lines })
    } catch (err) {
      logger.error(err)
      setUploadError(err.toString())
    }
    setIsLoading(false)
  }

  return (
    <Dialog open={true} onClose={onCancel} maxWidth="sm" fullWidth>
      {isLoading && <Loader overlay />}
      <DialogTitle>{t('campaigns:ui.UploadCsvFile')}</DialogTitle>
      <DialogContent>
        {!!uploadError && (
          <Alert severity="warning" sx={{ mb: '1rem' }}>
            {uploadError}
          </Alert>
        )}
        <DropzoneContainer>
          <FileField onDrop={handleFileDrop} files={file} accept={{ 'text/csv': ['csv'] }} error={requiredError} />
        </DropzoneContainer>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={onCancel}>
          {t('common:actions.Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {t('common:actions.Upload')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CampaignCsvForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default CampaignCsvForm
