import PropTypes from 'prop-types'

const CampaignsIcon = ({ width, height, style, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      style={style}
    >
      <path fill={color} d="M21,16V4H3V16H21M21,2A2,2 0 0,1 23,4V16A2,2 0 0,1 21,18H14V20H16V22H8V20H10V18H3C1.89,18 1,17.1 1,16V4C1,2.89 1.89,2 3,2H21M5,6H14V11H5V6M15,6H19V8H15V6M19,9V14H15V9H19M5,12H9V14H5V12M10,12H14V14H10V12Z" />
    </svg>
  )
}

CampaignsIcon.defaultProps = {
  width: '24px',
  height: '24px',
  style: {},
  color: '#fff',
}

CampaignsIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
}

export default CampaignsIcon
