import { Alert, Grid, Switch } from '@mui/material'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { compose, isEmpty, isNil, path } from 'ramda'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import RolloutTypeDropdown from '@Campaigns/Components/RolloutTypeDropdown'
import Box from '@Common/Components/Box'
import { FormControlLabel } from '@Common/Components/Forms'
import NumberTextField from '@Common/Components/NumberTextField'
import { setStateFromEvent } from '@Common/Utils/Events'

const CampaignJobRolloutForm = ({
                                  fields,
                                  setField,
                                  setFields,
                                  errors,
                                  useAwsDefaults,
                                  setUseAwsDefaults,
                                  expRolloutNumberOf,
                                  setExpRolloutNumberOf,
                                  totDevices,
                                }) => {
  const { t } = useTranslation()

  const customDefaults = {
    rolloutType: 'LINEAR',
    rolloutMaximumPerMinute: fields.timeoutInMinutes
      ? Math.min(parseInt(Math.max((totDevices * 10) / fields.timeoutInMinutes, 1)), 1000)
      : null,
    rolloutBaseRatePerMinute: null,
    rolloutIncrementFactor: null,
    rolloutNumberOfNotifiedThings: null,
    rolloutNumberOfSucceededThings: null,
  }

  const handleSetUseAwsDefaults = (value) => {
    if (value && (isEmpty(fields.timeoutInMinutes) || isNil(fields.timeoutInMinutes))) {
      return toast.warning(t('campaigns:ui.MustConfigureJobTimeoutFirst'))
    }
    if (!value) {
      setFields({
        ...fields,
        rolloutType: null,
        rolloutMaximumPerMinute: null,
        rolloutBaseRatePerMinute: null,
        rolloutNumberOfNotifiedThings: null,
        rolloutNumberOfSucceededThings: null,
      })
    } else {
      setFields({ ...fields, ...customDefaults })
    }
    setUseAwsDefaults(!value)
  }

  const handleExpRolloutNumberOf = (type) => (enable) => {
    if (type === 'NOTIFIED') {
      if (enable) {
        setExpRolloutNumberOf('NOTIFIED')
      } else {
        setExpRolloutNumberOf('SUCCEEDED')
      }
    } else if (type === 'SUCCEEDED') {
      if (enable) {
        setExpRolloutNumberOf('SUCCEEDED')
      } else {
        setExpRolloutNumberOf('NOTIFIED')
      }
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12} alignItems="center">
        <Box direction="row" justify="center" width="100%">
          <FormControlLabel
            control={
              <Switch
                checked={!useAwsDefaults}
                onChange={compose(handleSetUseAwsDefaults, path(['target', 'checked']))}
              />
            }
            label={t('campaigns:ui.EnableManualConfiguration')}
          />
        </Box>
      </Grid>
      <Grid item md={6}>
        <RolloutTypeDropdown
          required
          value={fields.rolloutType}
          onChange={setStateFromEvent(setField('rolloutType'))}
          fullWidth
          error={!!errors.rolloutType}
          helperText={errors.rolloutType}
          disabled={useAwsDefaults}
        />
      </Grid>
      <Grid item md={6}>
        <div>
          <NumberTextField
            required
            label={t('campaigns:fields.maximumPerMinute')}
            type="number"
            value={fields.rolloutMaximumPerMinute}
            onChange={setStateFromEvent(setField('rolloutMaximumPerMinute'))}
            fullWidth
            error={!!errors.rolloutMaximumPerMinute}
            helperText={errors.rolloutMaximumPerMinute || t('campaigns:helperTexts.rolloutMaximumPerMinute')}
            min={1}
            max={1000}
            onlyInt
          />
          {fields.rolloutMaximumPerMinute && (
            <Alert severity="info">
              {t('campaigns:ui.AllDevicesNotifiedIn', {
                time: dayjs.duration(totDevices / fields.rolloutMaximumPerMinute, 'minutes').humanize(),
              })}
            </Alert>
          )}
        </div>
      </Grid>
      <Grid item md={6}>
        {fields.rolloutType === 'EXP' && (
          <div>
            <NumberTextField
              required
              label={t('campaigns:fields.baseRatePerMinute')}
              type="number"
              value={fields.rolloutBaseRatePerMinute}
              onChange={setStateFromEvent(setField('rolloutBaseRatePerMinute'))}
              fullWidth
              error={!!errors.rolloutBaseRatePerMinute}
              helperText={errors.rolloutBaseRatePerMinute || t('campaigns:helperTexts.rolloutBaseRatePerMinute', { max: Math.min(+fields.rolloutMaximumPerMinute, 1000) })}
              min={1}
              max={Math.min(+fields.rolloutMaximumPerMinute, 1000)}
            />
          </div>
        )}
      </Grid>
      {fields.rolloutType === 'EXP' && (
        <>
          <Grid item md={6}>
            <NumberTextField
              required
              label={t('campaigns:fields.incrementFactor')}
              type="number"
              value={fields.rolloutIncrementFactor}
              onChange={setStateFromEvent(setField('rolloutIncrementFactor'))}
              fullWidth
              error={!!errors.rolloutIncrementFactor}
              helperText={errors.rolloutIncrementFactor || t('campaigns:helperTexts.rolloutIncrementFactor')}
              min={1.1}
              max={5}
              warnOnly
            />
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item md={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={expRolloutNumberOf === 'NOTIFIED'}
                    onChange={compose(handleExpRolloutNumberOf('NOTIFIED'), path(['target', 'checked']))}
                  />
                }
                label={t('campaigns:fields.numberOfNotifiedThings')}
              />
              <NumberTextField
                required={expRolloutNumberOf === 'NOTIFIED'}
                disabled={expRolloutNumberOf !== 'NOTIFIED'}
                label={t('campaigns:fields.numberOfNotifiedThings')}
                type="number"
                value={fields.rolloutNumberOfNotifiedThings}
                onChange={setStateFromEvent(setField('rolloutNumberOfNotifiedThings'))}
                fullWidth
                error={!!errors.rolloutNumberOfNotifiedThings}
                helperText={
                  errors.rolloutNumberOfNotifiedThings || t('campaigns:helperTexts.rolloutNumberOfNotifiedThings')
                }
                min={1}
              />
            </Grid>
            <Grid item md={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={expRolloutNumberOf === 'SUCCEEDED'}
                    onChange={compose(handleExpRolloutNumberOf('SUCCEEDED'), path(['target', 'checked']))}
                  />
                }
                label={t('campaigns:fields.numberOfSucceededThings')}
              />
              <NumberTextField
                required={expRolloutNumberOf === 'SUCCEEDED'}
                disabled={expRolloutNumberOf !== 'SUCCEEDED'}
                label={t('campaigns:fields.numberOfSucceededThings')}
                type="number"
                value={fields.rolloutNumberOfSucceededThings}
                onChange={setStateFromEvent(setField('rolloutNumberOfSucceededThings'))}
                fullWidth
                error={!!errors.rolloutNumberOfSucceededThings}
                helperText={
                  errors.rolloutNumberOfSucceededThings || t('campaigns:helperTexts.rolloutNumberOfSucceededThings')
                }
                min={1}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}

CampaignJobRolloutForm.propTypes = {
  fields: PropTypes.object,
  setField: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
  useAwsDefaults: PropTypes.bool,
  setUseAwsDefaults: PropTypes.func.isRequired,
  expRolloutNumberOf: PropTypes.oneOf(['NOTIFIED', 'SUCCEEDED']),
  setExpRolloutNumberOf: PropTypes.func,
  totDevices: PropTypes.number,
}

export default CampaignJobRolloutForm
