import Box from '@Common/Components/Box'
import PropTypes from 'prop-types'
import { defaultTo, groupBy, prop, sortBy } from 'ramda'
import BundlesRingColumnChart from './BundlesRingColumnChart'
import styled from 'styled-components'

const ColLeft = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.contrastLight.main};
  width: 50%;
`

const ColRight = styled.div`
  width: 50%;
`
/**
 * Bundles charts section in the dashboard
 *
 * @param {Array} props.bundleStatistics - device statistics
 */
const BundlesChart = ({ bundleStatistics }) => {
  const groupedStats = groupBy(prop('deviceType'), sortBy(prop('ring'), bundleStatistics))
  return (
    <Box direction="row" align="center">
      <ColLeft>
        <BundlesRingColumnChart title='PCM3' stats={defaultTo([], groupedStats.PCM3)} />
      </ColLeft>
      <ColRight>
        <BundlesRingColumnChart title='IHP' stats={defaultTo([], groupedStats.IHP)} />
      </ColRight>
    </Box>

  )
}

BundlesChart.propTypes = {
  bundleStatistics: PropTypes.arrayOf(
    PropTypes.shape({
      cardinality: PropTypes.number,
      sttatus: PropTypes.oneOf(['OK', 'ERROR', 'PENDING', 'FAILED']),
      deviceType: PropTypes.string,
      domain: PropTypes.string,
      ring: PropTypes.string,
    }),
  ),
}

export default BundlesChart
