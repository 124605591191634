import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'
import { useAssetTypesQuery } from '@Vehicles/Services/Api/AssetTypes'
import { apiList } from '@Core/Services/Api'

const AssetTypeDropdown = ({ value, onChange, required, error, helperText, ...other }) => {
  const { t } = useTranslation()
  const { orData: data, isFetching } = apiList(useAssetTypesQuery())

  return (
    <Dropdown
      label={t('common:fields.assetType')}
      required={required}
      value={value}
      onChange={onChange}
      options={Array.isArray(data) ? data.map(type => ({ value: type, label: type })) : []}
      error={error}
      helperText={helperText}
      loading={isFetching}
      {...other}
    />
  )
}

AssetTypeDropdown.defaultProps = {
  required: false,
}

AssetTypeDropdown.propTypes = FieldType

export default AssetTypeDropdown
