import { defaultTo } from 'ramda'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import Link from '@Common/Components/Link'
import BaseLayout from '@Common/Layouts/BaseLayout'
import { useBreadcrumbs, useDebounce, useForm } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import BundlesFilterForm from '@SoftwareCatalog/Forms/BundleFilterForm'
import Bundle from '@SoftwareCatalog/Models/Bundle'
import { useBundlesQuery } from '@SoftwareCatalog/Services/Api'
import DeltaStatus from '@SoftwareCatalog/Components/DeltaStatus'
import BundleStatus from '@SoftwareCatalog/Components/BundleStatus'
import { readFromStorage, storeInStorage } from '@Common/Utils/Storage'
import { calculateBundleSize } from '@SoftwareCatalog/Utils/Bundles'

const LIST_DISPLAY = [
  'bundleInfo.bundleVersion',
  'bundleInfo.domain',
  'bundleInfo.ring',
  'bundleInfo.assetType',
  'bundleInfo.deviceType',
  'bundleStatus.status',
  'bundleStatus.deltaStatus',
  'bundleSize',
]

const FIELDS_MAPPING = {
  'bundleInfo.bundleVersion': (record) => (
    <Link to={makePath('bundles.detail', { bundleId: record._id })}>{record.bundleInfo.bundleVersion}</Link>
  ),
  'bundleStatus.status': (record) => <BundleStatus status={record.bundleStatus.status} />,
  'bundleStatus.deltaStatus': (record) => <DeltaStatus status={record.bundleStatus.deltaStatus} />,
  'bundleSize': calculateBundleSize,
}

const SORT_MAPPING = {
  'bundleInfo.bundleVersion': 'bundleInfo.bundleIncrementalVersion',
}

const FILTERS_STORAGE_KEY = 'filters-bundles'

const BundlesListView = () => {
  const { t } = useTranslation()
  useBreadcrumbs([{ label: t('navigation.Home'), path: makePath('home') }, { label: t('navigation.Bundles') }])

  const { fields: filterFields, setField: setFilterField, setFields: setFilterFields } = useForm(readFromStorage(FILTERS_STORAGE_KEY, {}))
  const debouncedFilterFields = useDebounce(filterFields)
  const handleFilterForm = React.useCallback(
    (handleClose) => {
      return (
        <BundlesFilterForm
          onClose={handleClose}
          fields={filterFields}
          setField={setFilterField}
          onReset={() => setFilterFields({})}
          onSave={() => storeInStorage(FILTERS_STORAGE_KEY, filterFields)}
        />
      )
    },
    [BundlesFilterForm, filterFields, setFilterField],
  )

  const qsAdditions = React.useMemo(() => {
    const filters = []
    if (debouncedFilterFields.domain) filters.push(`"bundleInfo.domain":"${debouncedFilterFields.domain}"`)
    if (debouncedFilterFields.ring) filters.push(`"bundleInfo.ring":"${debouncedFilterFields.ring}"`)
    if (debouncedFilterFields.assetType) filters.push(`"bundleInfo.assetType":"${debouncedFilterFields.assetType}"`)
    if (debouncedFilterFields.type) filters.push(`"bundleInfo.deviceType":"${debouncedFilterFields.type}"`)
    if (debouncedFilterFields.version) filters.push(`"bundleInfo.bundleVersion":{"$regex":"${debouncedFilterFields.version}", "$options": "i"}`)
    return filters.length ? { filterBy: filters.join(',') } : {}
  }, [
    debouncedFilterFields.domain,
    debouncedFilterFields.ring,
    debouncedFilterFields.assetType,
    debouncedFilterFields.type,
    debouncedFilterFields.version,
  ])
  const [qs, setQs] = React.useState({
    base: {
      pageSize: getDefaultListPerPage('bundles'),
      pageNumber: 0,
      orderBy: 'bundleInfo.bundleIncrementalVersion',
      orderType: 'desc',
    },
    qsAdditions,
  })
  const { data, isFetching } = useBundlesQuery({ ...qs.base, ...qsAdditions })
  const refreshData = setQs

  return (
    <BaseLayout>
      <DataTable
        name="bundles"
        label={t('ui.Bundles')}
        data={defaultTo([], data?.response)}
        refreshData={refreshData}
        qs={qs}
        dataCount={data?.totalSize || -1}
        dftSortField="bundleInfo.bundleVersion"
        dftSortDirection="desc"
        model={Bundle}
        listDisplay={LIST_DISPLAY}
        fieldsMapping={FIELDS_MAPPING}
        sortMapping={SORT_MAPPING}
        loading={isFetching}
        filterForm={handleFilterForm}
        filterFormActive={R.values(R.filter(R.compose(R.not, R.either(R.isEmpty, R.isNil)), filterFields)).length}
      />
    </BaseLayout>
  )
}

export default BundlesListView
