import { Chip } from '@mui/material'
import PropTypes from 'prop-types'

import { getCampaignStatusColor, getCampaignStatusLabel } from '@Campaigns/Utils'

const CampaignStatus = ({ status }) => {
  return status
    ? <Chip size="small" color={getCampaignStatusColor(status)} label={getCampaignStatusLabel(status)} />
    : null
}
CampaignStatus.propTypes = {
  status: PropTypes.oneOf([
    'CREATING_GROUP',
    'GROUP_CREATION_FAILED',
    'ADDING_DEVICE_TO_GROUP',
    'CREATING_JOB',
    'JOB_CREATION_FAILED',
    'JOB_STARTED',
    'JOB_COMPLETED',
    'JOB_CANCELLATION_IN_PROGRESS',
    'JOB_CANCELED',
    'JOB_DELETION_IN_PROGRESS',
    'JOB_DELETED',
  ]),
}
export default CampaignStatus
