import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'

const options = [
  { value: 'CV', label: 'CV' },
]

const DomainDropdown = ({ value, onChange, required, error, helperText, ...other }) => {
  const { t } = useTranslation()

  return (
    <Dropdown
      label={t('common:fields.domain')}
      required={required}
      value={value}
      onChange={onChange}
      options={options}
      error={error}
      helperText={helperText}
      {...other}
    />
  )
}

DomainDropdown.defaultProps = {
  required: false,
}

DomainDropdown.propTypes = FieldType

export default DomainDropdown
