import { api, apiQueryString } from '@Core/Services/Api'

const prefix = 'bundle'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    bundles: builder.query({
      query: (qs) => `${prefix}?${apiQueryString(qs)}`,
      providesTags: [{ type: 'Bundle', id: 'LIST' }],
    }),
    bundle: builder.query({
      query: (bundleId) => `${prefix}/${bundleId}`,
      providesTags: (_, __, bundleId) => [{ type: `Bundle`, id: bundleId }],
    }),
  }),
  overrideExisting: false,
})

export const { useBundlesQuery, useBundleQuery, useLazyBundlesQuery, useLazyBundleQuery } = extendedApi
