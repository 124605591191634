import PropTypes from 'prop-types'
import { Link as MuiLink } from '@mui/material'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import DataTable from '@Common/Components/DataTable'
import { useForm } from '@Common/Utils/Hooks'
import Logger from '@Common/Utils/Logger'
import { readFromStorage, storeInStorage } from '@Common/Utils/Storage'
import { makePath } from '@Config'
import { history } from '@Core/Redux/Store'
import SwDeltaFilterForm from '@SoftwareCatalog/Forms/SwDeltaFilterForm'
import { SoftwareComponentType } from '@SoftwareCatalog/Models/SoftwareComponent'
import SwDelta from '@SoftwareCatalog/Models/SwDelta'

import { useLazySwComponentsQuery } from '../Services/SwApi'
import DeltaProgress from './DeltaProgress'
import DeltaStatus from './DeltaStatus'

const LIST_DISPLAY = ['versionFrom', 'startedAt', 'lastUpdatedAt', 'sizeInBytes', 'status', 'progress']
const SORT_MAPPING = {
  'versionFrom': R.prop('incrementalVersionFrom')
}

const FILTERS_STORAGE_KEY = 'filters-software-component-deltas'

const SwDeltaListView = ({ data, onRefetch }) => {
  const { t } = useTranslation()
  const deltas = R.defaultTo([], data?.swComponentDeltas)
  const [fetchSoftwareComponents] = useLazySwComponentsQuery()

  const goToSoftwareComponent = (record) => async () => {
    let res
    try {
      const qs = {
        pageNumber: 0,
        pageSize: 1,
        orderBy: '_id',
        orderType: 'asc',
        domain: data.swComponentInfo.domain,
        ring: data.swComponentInfo.ring,
        deviceType: data.swComponentInfo.deviceType,
        name: data.swComponentInfo.name,
        version: record.versionFrom,
      }
      res = await fetchSoftwareComponents(qs)
    } catch (err) {
      Logger.error('Fetch software components error', err)
      toast.error(t('sc:errors.FetchSoftwareComponentsError'))
      return
    }

    try {
      const softwareComponentId = res.data.response[0]._id
      history.push(makePath('softwareComponents.detail', { softwareComponentId }))
    } catch (err) {
      Logger.warning('The software component is not present in the software catalog', err)
      toast.warn(t('sc:warnings.SoftwareComponentNotPresentInSoftwareCatalog'))
    }
  }

  const FIELDS_MAPPING = {
    versionFrom: (record) => {
      return (
        <MuiLink style={{ cursor: 'pointer' }} onClick={goToSoftwareComponent(record)}>
          {record.versionFrom}
        </MuiLink>
      )
    },
    status: (record) => <DeltaStatus status={record.status} />,
    progress: (record) => <DeltaProgress progress={record.progress} />,
  }
  const {
    fields: filterFields,
    setField: setFilterField,
    setFields: setFilterFields,
  } = useForm(readFromStorage(FILTERS_STORAGE_KEY, {}))

  const filteredDeltas = deltas.filter(
    R.either(R.always(R.isEmpty(filterFields)), R.pipe(R.prop('status'), R.equals(filterFields.status))),
  )

  const handleFilterForm = React.useCallback(
    (handleClose) => {
      return (
        <SwDeltaFilterForm
          onClose={handleClose}
          fields={filterFields}
          setField={setFilterField}
          onReset={() => setFilterFields({})}
          onSave={() => storeInStorage(FILTERS_STORAGE_KEY, filterFields)}
        />
      )
    },
    [SwDeltaFilterForm, filterFields, setFilterField],
  )
  return (
    <DataTable
      name="sw-delta-components"
      sortField="versionFrom"
      data={filteredDeltas}
      model={SwDelta}
      listDisplay={LIST_DISPLAY}
      fieldsMapping={FIELDS_MAPPING}
      sortMapping={SORT_MAPPING}
      filterForm={handleFilterForm}
      filterFormActive={R.values(R.filter(R.compose(R.not, R.either(R.isEmpty, R.isNil)), filterFields)).length}
      refreshData={onRefetch}
    />
  )
}

SwDeltaListView.propTypes = {
  data: SoftwareComponentType,
  onRefetch: PropTypes.func,
}

export default SwDeltaListView
