import { LogLevel } from '@azure/msal-browser'
import Logger from '@Common/Utils/Logger'
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
// const ua = window.navigator.userAgent
// const msie = ua.indexOf('MSIE ')
// const msie11 = ua.indexOf('Trident/')
// const msedge = ua.indexOf('Edge/')
// const firefox = ua.indexOf('Firefox')
// const isIE = msie > 0 || msie11 > 0
// const isEdge = msedge > 0
// const isFirefox = firefox > 0 // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    authority: process.env.REACT_APP_AUTH_AUTORITY,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            Logger.none(message)
            return
          case LogLevel.Info:
            Logger.none(message)
            return
          case LogLevel.Verbose:
            Logger.none(message)
            return
          case LogLevel.Warning:
            Logger.none(message)
            break
          default:
        }
      },
    },
  },
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [process.env.REACT_APP_LOGIN_REQUEST_SCOPES],
}

export const authTokenRequest = {
  scopes: [process.env.REACT_APP_API_REQUEST_SCOPES],
}
