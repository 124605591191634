import { api, awsApiQueryString } from '@Core/Services/Api'

const prefix = 'device'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    devices: builder.query({
      query: (qs) => `${prefix}?${awsApiQueryString(qs)}`,
      providesTags: [{ type: 'Device', id: 'LIST' }],
    }),
    device: builder.query({
      query: (deviceId) => `${prefix}/${deviceId}`, // deviceId is the thingName
      providesTags: (_, __, deviceId) => [{ type: 'Device', id: deviceId }],
    }),
    deviceCardinality: builder.query({
      query: ({ queryString }) => `${prefix}/cardinality?queryString=${queryString}`,
      providesTags: [{ type: 'Device', id: 'CARDINALITY' }],
    }),
  }),
  overrideExisting: false,
})

export const { useDevicesQuery, useDeviceQuery, useDeviceCardinalityQuery } = extendedApi
