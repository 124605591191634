import PropTypes from 'prop-types'

import i18n from '../../i18n'

export const UserType = PropTypes.shape({
  _id: PropTypes.string,
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    eMail: PropTypes.string,
    role: PropTypes.string,
  }),
  userSettings: PropTypes.shape({
    swComponentNotification: PropTypes.string,
    bundleNotification: PropTypes.string,
    campaignNotification: PropTypes.string,
    campaignDailyReportNotification: PropTypes.string,
  }),
  userStatus: PropTypes.shape({
    active: PropTypes.bool,
    lastNotificationTimestamp: PropTypes.number,
  })
})

export default {
  primaryKey: '_id',
  columns: [
    {
      id: '_id',
      label: i18n.t('auth:fields.id'),
    },
    {
      id: 'userInfo.name',
      label: i18n.t('auth:fields.userName'),
    },
    {
      id: 'userInfo.eMail',
      label: i18n.t('auth:fields.eMail'),
    },
    {
      id: 'userInfo.role',
      label: i18n.t('auth:fields.role'),
    },
    {
      id: 'userSettings.swComponentNotification',
      label: i18n.t('auth:fields.swComponentNotification'),
    },
    {
      id: 'userSettings.bundleNotification',
      label: i18n.t('auth:fields.bundleNotification'),
    },
    {
      id: 'userSettings.campaignNotification',
      label: i18n.t('auth:fields.campaignNotification'),
    },
    {
      id: 'userSettings.campaignDailyReportNotification',
      label: i18n.t('auth:fields.campaignDailyReportNotification'),
    },
    {
      id: 'userStatus.active',
      label: i18n.t('auth:fields.active'),
      boolean: true,
    },
    {
      id: 'userStatus.lastNotificationTimestamp',
      label: i18n.t('auth:fields.lastNotificationTimestamp'),
      datetime: true,
    },
  ],
}
