import { Grid, Switch } from '@mui/material'
import PropTypes from 'prop-types'
import { compose, path } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import TargetDropdown from '@Campaigns/Components/TargetDropdown'
import { isContinuousCampaign } from '@Campaigns/Utils'
import DateTimePicker from '@Common/Components/DateTimePicker'
import { FormControlLabel } from '@Common/Components/Forms'
import TimeRangeField from '@Common/Components/TimeRangeField'
import { setStateFromEvent } from '@Common/Utils/Events'
import { datetime } from '@Common/Utils/Datetime'

const CampaignJobGeneralForm = ({ fields, setField, errors }) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={2} rowSpacing={4}>
      <Grid item md={6}>
        <TargetDropdown
          required
          label={t('campaigns:fields.targetSelection')}
          value={fields.targetSelection}
          onChange={setStateFromEvent(setField('targetSelection'))}
          fullWidth
          error={!!errors.targetSelection}
          helperText={errors.targetSelection}
        />
      </Grid>
      {!isContinuousCampaign(fields.targetSelection) && (
        <Grid item md={6}>
          <TimeRangeField
            required
            label={t('campaigns:fields.timeout')}
            value={fields.timeoutInMinutes}
            onChange={setStateFromEvent(setField('timeoutInMinutes'))}
            fullWidth
            error={!!errors.timeoutInMinutes}
            helperText={errors.timeoutInMinutes}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={fields.enableScheduling}
              onChange={compose(setField('enableScheduling'), path(['target', 'checked']))}
            />
          }
          label={t('campaigns:ui.EnableCampaignScheduling')}
        />
      </Grid>
      <Grid item xs={12}>
        <DateTimePicker
          label={t('campaigns:fields.startTime')}
          onChange={setField('startTime')}
          minDateTime={datetime().add(1, 'hour')}
          maxDateTime={datetime().add(1, 'year')}
          value={fields.startTime}
          disabled={!fields.enableScheduling}
        />
      </Grid>
    </Grid>
  )
}

CampaignJobGeneralForm.propTypes = {
  fields: PropTypes.object,
  setField: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
}

export default CampaignJobGeneralForm
