import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useCampaignQuery } from '@Campaigns/Services/Api'
import BaseLayout from '@Common/Layouts/BaseLayout'
import { useBreadcrumbs, usePageNotFoundRedirect } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import { withLoader } from '@Common/Utils/HOF'
import { Grid } from '@mui/material'
import { Header, Section } from '@Campaigns/Components/Dashboard/Styles'
import CampaignInfo from '@Campaigns/Components/Dashboard/CampaignInfo'
import StatusHistoryTimeChart from '@Campaigns/Components/Dashboard/StatusHistoryTimeChart'
import StatusPieChart from '@Campaigns/Components/Dashboard/StatusPieChart'
import CampaignReports from '@Campaigns/Components/Dashboard/CampaignReports'

const CampaignDetailView = () => {
  const { t } = useTranslation()
  const { campaignId } = useParams()
  const { data: campaign, isFetching: isFetchingCampaign, error } = useCampaignQuery(campaignId)

  useBreadcrumbs([
    { label: t('navigation.Campaigns'), path: makePath('campaigns.list') },
    { label: campaign?.campaignInfo?.name },
  ], [campaign?.campaignInfo?.name])
  const navigate = useNavigate()

  const handleChange = (_, tab) => {
    if (tab === 1) navigate(makePath('campaigns.detailExecutions', { campaignId }))
  }

  usePageNotFoundRedirect(!isFetchingCampaign && (!campaign?._id || error?.status === 404), [isFetchingCampaign, campaign, error])

  return (
    <BaseLayout>
      <Tabs value={0} onChange={handleChange}>
        <Tab label={t('campaigns:ui.CampaignDetails')} />
        <Tab label={t('campaigns:ui.ExecutionsList')} />
      </Tabs>
      {withLoader(() => (
        <Grid container columnSpacing={4}>
          <Grid item md={4}>
            <Section>
              <Header>{t('campaigns:ui.CampaignInfo')}</Header>
              <CampaignInfo campaign={campaign} />
            </Section>
          </Grid>
          <Grid item md={8}>
            <Section>
              <Header>{t('campaigns:ui.CampaignHistory')}</Header>
              <StatusHistoryTimeChart campaign={campaign} />
            </Section>
          </Grid>
          <Grid item md={4}>
            <Section>
              <StatusPieChart campaign={campaign} legendLayout='horizontal' />
            </Section>
          </Grid>
          <Grid item md={8}>
            <Section>
              <Header>{t('campaigns:ui.CampaignExecutionDailyReports')}</Header>
              <CampaignReports campaign={campaign} />
            </Section>
          </Grid>
        </Grid>
      ), isFetchingCampaign || !campaign?._id)}
    </BaseLayout>
  )
}

export default CampaignDetailView
