import PropTypes from 'prop-types'
import { always, cond, equals, pipe, prop, T } from 'ramda'
import TrueChip from '@Common/Components/TrueChip'
import FailChip from '@Common/Components/FailChip'
import { withTooltip } from '@Common/Utils/HOF'
import i18next from 'i18next'

const SwStatus = pipe(
  prop('status'),
  cond([
    [equals('NOT_PRESENT'), always(withTooltip(<FailChip />, i18next.t('sc:ui.NotPresent'), true))],
    [equals('PRESENT'), always(withTooltip(<TrueChip />, i18next.t('sc:ui.Present'), true))],
    [T, always(null)],
  ])
)

SwStatus.propTypes = {
  status: PropTypes.string,
}

export default SwStatus
