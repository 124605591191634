import React from 'react'
import { useTranslation } from 'react-i18next'
import { CircularProgress, Typography } from '@mui/material'
import styled from 'styled-components'
import Box from '@Common/Components/Box'
import Logo from '@Common/Components/Logo'
import Bg from '@Assets/Images/login-bg.jpg'

const Wrapper = styled(Box)`
  background: url(${Bg}) no-repeat center center;
  background-size: cover;
  min-height: 100vh;
`

const StartupView = () => {
  const { t } = useTranslation()

  return (
    <Wrapper align='center' direction='column' justify='center'>
      <Box align='center' direction='column' justify='center' margin='2rem' pad='2rem'>
        <Logo style={{ width: '170px', marginBottom: '2rem' }} /> 
        <CircularProgress color="white" />
        <Typography color="white.main" mt="1.5rem" paragraph>
          {t('ui.Loading')}
        </Typography>
      </Box>
    </Wrapper>
  )
}

export default StartupView
