// import { Grid, Tab, Tabs } from '@mui/material'
import { Grid } from '@mui/material'
// import { compose, nthArg } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'

import BaseLayout from '@Common/Layouts/BaseLayout'
import { withLoader } from '@Common/Utils/HOF'
import { useBreadcrumbs, usePageNotFoundRedirect } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import SwComponentInfoBox from '@SoftwareCatalog/Components/SwComponentInfoBox'
import SwDeltaList from '@SoftwareCatalog/Components/SwDeltaList'
import { useSwComponentQuery } from '@SoftwareCatalog/Services/SwApi'
import OngoingDeltas from '@SoftwareCatalog/Components/OngoingDeltas'

const Section = styled.section``
const SectionHeader = styled.h3`
  color: ${({ theme }) => theme.palette.content.contrastText};
`

const SwComponentDetailView = () => {
  const { t } = useTranslation()
  const { softwareComponentId } = useParams()
  const { data: swComponent, isFetching, refetch } = useSwComponentQuery(softwareComponentId)
  // const [activeTab, setActiveTab] = React.useState(0)
  const activeTab = 0

  useBreadcrumbs(
    [
      { label: t('navigation.Home'), path: makePath('home') },
      { label: t('navigation.SoftwareCatalog'), path: makePath('softwareComponents.main') },
      { label: `${swComponent?.swComponentInfo.name} (${swComponent?.swComponentInfo.version})` },
    ],
    !!swComponent?._id,
    [swComponent?._id],
  )

  usePageNotFoundRedirect(!isFetching && !swComponent?._id, [isFetching, swComponent])

  return (
    <BaseLayout>
      {withLoader(
        () => (
          <div>
            {/*
            <Tabs value={activeTab} onChange={compose(setActiveTab, nthArg(1))}>
              <Tab label={t('sc:ui.ComponentDetails')} />
              <Tab label={t('sc:ui.OngoingDeltaCalculations')} />
            </Tabs>
            */}
            {activeTab === 0 && (
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <Section>
                    <SectionHeader>{t('sc:ui.SwInformation')}</SectionHeader>
                    {withLoader(
                      () => (
                        <div>
                          <SwComponentInfoBox SwComponent={swComponent} />
                        </div>
                      ),
                      isFetching,
                    )}
                  </Section>
                </Grid>
                <Grid item md={9}>
                  <Section>
                    <SectionHeader>{t('sc:ui.SwDelta')}</SectionHeader>
                    <SwDeltaList data={swComponent} onRefetch={refetch}></SwDeltaList>
                  </Section>
                </Grid>
              </Grid>
            )}
            {activeTab === 1 && (
              <OngoingDeltas />
            )}
          </div>
        ),
        isFetching || !swComponent?._id,
      )}{' '}
    </BaseLayout>
  )
}

export default SwComponentDetailView
