import { checkRequired } from '@Common/Utils/Validation'
import * as R from 'ramda'

export const validate = (fields, setErrors, errors) => {
  const err = {}

  const required = ['timeoutInProgressTimeoutInMinutes']
  checkRequired(err, required, fields)

  setErrors({...errors, ...err })

  return R.isEmpty(err)
}
