import { api, apiQueryString } from '@Core/Services/Api'

const prefix = 'sw-component'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    swComponents: builder.query({
      query: (qs) => `${prefix}?${apiQueryString(qs)}`,
      providesTags: [{ type: 'SWComponent', id: 'LIST' }],
    }),
    swComponent: builder.query({
      query: (componentId) => `${prefix}/${componentId}`,
      providesTags: (_, __, componentId) => [{ type: `SWComponent`, id: componentId }],
    }),
  }),
  overrideExisting: false,
})

export const { useSwComponentsQuery, useSwComponentQuery, useLazySwComponentsQuery } = extendedApi
