import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import AssetTypeDropdown from '@Common/Components/AssetTypeDropdown'
import DeviceTypeDropdown from '@Common/Components/DeviceTypeDropdown'
import DomainDropdown from '@Common/Components/DomainDropdown'
import RingDropdown from '@Common/Components/RingDropdown'
import TextField from '@Common/Components/TextField'
import FiltersForm from '@Common/Forms/FiltersForm'
import { setStateFromEvent } from '@Common/Utils/Events'
import BundleVersionField from '@SoftwareCatalog/Components/BundleVersionField'

const CampaignsFilterForm = ({ onClose, fields, setField, onReset, onSave }) => {
  const { t } = useTranslation()
  return (
    <FiltersForm title={t('campaigns:ui.FilterCampaigns')} onClose={onClose} onReset={onReset} onSave={onSave}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <TextField
            label={t('vehicles:fields.vin')}
            value={fields.vin}
            onChange={setStateFromEvent(setField('vin'))}
            fullWidth
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            label={t('campaigns:fields.name')}
            value={fields.name}
            onChange={setStateFromEvent(setField('name'))}
            fullWidth
          />
        </Grid>
        <Grid item md={6}>
          <DomainDropdown value={fields.domain} onChange={setStateFromEvent(setField('domain'))} fullWidth />
        </Grid>
        <Grid item md={6}>
          <RingDropdown value={fields.ring} onChange={setStateFromEvent(setField('ring'))} fullWidth />
        </Grid>
        <Grid item md={6}>
          <AssetTypeDropdown value={fields.assetType} onChange={setStateFromEvent(setField('assetType'))} fullWidth />
        </Grid>
        <Grid item md={6}>
          <DeviceTypeDropdown value={fields.type} onChange={setStateFromEvent(setField('type'))} fullWidth />
        </Grid>
        <Grid item md={6}>
          <BundleVersionField
            value={fields.bundleVersion}
            onChange={setStateFromEvent(setField('bundleVersion'))}
            domain={fields.domain}
            ring={fields.ring}
            assetType={fields.assetType}
            deviceType={fields.type}
            fullWidth
            noOperator
          />
        </Grid>
      </Grid>
    </FiltersForm>
  )
}

CampaignsFilterForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  setField: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default CampaignsFilterForm
