import DragIcon from '@mui/icons-material/DragIndicator'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Draggable } from 'react-smooth-dnd'
import styled from 'styled-components'

import Box from '@Common/Components/Box'
import Cursor from '@Common/Components/Cursor'
import Margin from '@Common/Components/Margin'

import Dropdown from '../../../Common/Components/Dropdown'
import NumberTextField from '../../../Common/Components/NumberTextField'
import { setStateFromEvent } from '../../Utils/Events'
import { FormControl } from '../Forms'
import { useDebounce } from '@Common/Utils/Hooks'

const Row = styled.div`
  align-items: center;
  border: 1px solid ${(props) => props.bg};
  border-top: ${({ first, bg }) => (first ? '1px solid ' + bg : '0px solid')};
  display: flex;
  justify-content: space-between;
  min-width: 300px;
  padding: 0;
`

const SettingsDialog = ({
  open,
  handleClose,
  handleReset,
  handleSaveAndClose,
  columns,
  onSelect,
  onSort,
  listPerPage,
  onListPerPageChange,
  defaultSortField,
  defaultSortDirection,
  onDefaultSortFieldChange,
  onDefaultSortDirectionChange,
  disableSorting,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [itemsPerPage, setItemsPerPage] = React.useState(listPerPage)
  const debouncedItemsPerPage = useDebounce(itemsPerPage)
  const selected = columns.filter(R.prop('visible')).map(R.prop('id'))

  const handleDrop = ({ removedIndex, addedIndex }) => {
    const copy = [...columns]
    onSort(
      R.ifElse(
        R.isNil,
        R.always(columns),
        R.compose(R.always(copy), R.always(copy.splice(addedIndex, 0, copy.splice(removedIndex, 1)[0]))),
        R.always(columns),
      )(removedIndex),
    )
  }

  const sortedColumns = columns.sort((a, b) => {
    if (a.visible && !b.visible) {
      return -1
    } else if (b.visible && !a.visible) {
      return 1
    } else {
      return 0
    }
  })

  const handleChangeItemsPerPage = (event) => {
    const value = event.target.value
    setItemsPerPage(value)
  }

  React.useEffect(() => {
    if (debouncedItemsPerPage) {
      onListPerPageChange(Math.max(1, debouncedItemsPerPage))
    } 
  }, [debouncedItemsPerPage])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('ui.Settings')}</DialogTitle>
      <DialogContent>
        <FormControl compact>
          <NumberTextField
            required
            min={1}
            max={100}
            label={t('ui.ItemsPerPage')}
            helperText={t('ui.DataTablePageItemsLimit')}
            value={itemsPerPage}
            onChange={handleChangeItemsPerPage}
          />
        </FormControl>
        {!disableSorting && (
          <FormControl compact>
            <Box direction="row" margin="0 0 1rem">
              <Dropdown
                label={t('ui.DefaultSortField')}
                required
                value={defaultSortField.replace(/\//g, '.')}
                onChange={setStateFromEvent(onDefaultSortFieldChange)}
                options={columns
                  .filter(R.compose(R.not, R.prop('disableSorting')))
                  .map((c) => ({ value: c.id, label: c.label }))}
                width="100%"
              />
              <Margin left=".5rem">
                <Dropdown
                  label={t('ui.DefaultSortDirection')}
                  required
                  value={defaultSortDirection}
                  onChange={setStateFromEvent(onDefaultSortDirectionChange)}
                  options={[
                    { value: 'asc', label: t('common:ui.ASC') },
                    { value: 'desc', label: t('common:ui.DESC') },
                  ]}
                  width="100px"
                />
              </Margin>
            </Box>
          </FormControl>
        )}
        <Box margin="1rem 0">
          <DialogContentText>{t('ui.ColumnsSettings')}</DialogContentText>
        </Box>
        <Container onDrop={handleDrop}>
          {sortedColumns.map((column, idx) => {
            return (
              <Draggable key={`row-${column.id}`}>
                <Row bg={theme.palette.contrastLight.main} first={idx === 0}>
                  <div>
                    <Checkbox checked={selected.indexOf(column.id) !== -1} onChange={onSelect(column.id)} />
                    <span>{column.label}</span>
                  </div>
                  <Cursor name="move" style={{ marginRight: '.5rem' }}>
                    <DragIcon />
                  </Cursor>
                </Row>
              </Draggable>
            )
          })}
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset} color="secondary" variant='contained'>
          {t('common:actions.Reset')}
        </Button>
        <Button onClick={handleClose} color="primary" variant='contained'>
          {t('common:actions.Close')}
        </Button>
        <Button onClick={handleSaveAndClose} color="primary" variant='contained'>
          {t('common:actions.SaveAndClose')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SettingsDialog.defaultProps = {
  disableSorting: false,
}

SettingsDialog.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      visible: PropTypes.bool,
    }),
  ),
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSaveAndClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  listPerPage: PropTypes.number.isRequired,
  onListPerPageChange: PropTypes.func.isRequired,
  defaultSortField: PropTypes.string,
  defaultSortDirection: PropTypes.string,
  onDefaultSortFieldChange: PropTypes.func,
  onDefaultSortDirectionChange: PropTypes.func,
  disableSorting: PropTypes.bool,
}

export default SettingsDialog
