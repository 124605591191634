import React from 'react'
import { useTranslation } from 'react-i18next'

import TextField from '@Common/Components/TextField'
import { FieldType } from '@Common/Types/Field'

const JobFailureTypeField = ({ value, error, helperText, ...other }) => {
  const { t } = useTranslation()

  return (
    <TextField
      label={t('campaigns:fields.failureType')}
      value={value}
      error={error}
      helperText={helperText}
      readOnly
      inputProps={{ disabled: true }}
      {...other}
    />
  )
}

JobFailureTypeField.propTypes = FieldType

export default JobFailureTypeField
