import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'

const ConfirmationDialog = ({ open, title, text, onCancel, onOk, children }) => {
  const { t } = useTranslation()
  return (
    <Dialog
      maxWidth="sm"
      open={open}
    >
      <DialogTitle>{ title }</DialogTitle>
      <DialogContent dividers style={{ paddingTop: '1rem' }}>
        { text }
        { children }
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onCancel} color="primary">
          {t('common:actions.Cancel')}
        </Button>
        <Button onClick={onOk} color="primary">
          {t('common:actions.Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  open: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
}

export default ConfirmationDialog
