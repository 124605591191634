import { Button, Grid, Stack, Switch, useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'
import { compose, defaultTo, path } from 'ramda'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import CampaignCsvForm from '@Campaigns/Forms/CampaignCsvForm'
import Box from '@Common/Components/Box'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Aws'
import { FormControlLabel } from '@Common/Components/Forms'
import NameValueList from '@Common/Components/NameValueList'
import TextField from '@Common/Components/TextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import { withLoader } from '@Common/Utils/HOF'
import { useDebounce, usePropDidUpdate } from '@Common/Utils/Hooks'
import { operatorMap } from '@SoftwareCatalog/Components/BundleVersionField'
import Device from '@Vehicles/Models/Device'
import { useDeviceCardinalityQuery, useDevicesQuery } from '@Vehicles/Services/Api/Devices'

const Total = styled.div`
  margin-bottom: 2rem;
`
const TotalNumber = styled.div`
  font-weight: bold;
  font-size: 4rem;
  color: ${({ theme }) => theme.palette.primary.main};
  text-align: center;
`
const TotalLabel = styled.div`
  color: ${({ theme }) => theme.palette.content.contrastText};
  text-align: center;
  font-weight: 500;
  font-size: 1.325rem;
`

const Text = styled.div`
  color: ${({ theme }) => theme.palette.content.contrastText};
  text-align: center;
  font-weight: 500;
  font-size: 1.325rem;
`

const LIST_DISPLAY = [
  'attributes.vin',
  'attributes.type',
  'attributes.domain',
  'attributes.ring',
  'attributes.tty',
  'shadow.reported.bundleVersion',
  'shadow.reported.bundleConsistency',
]
const FIELDS_MAPPING = {}

const StepDeviceSelection = ({
  fields,
  queryString,
  deviceIds,
  setDeviceIds,
  setCardinality,
  manualVinSelection,
  setManualVinSelection,
  csvData,
  setCsvData,
  enableCsvUpload,
  setEnableCsvUpload,
  onCancel,
  onNext,
}) => {
  const { t } = useTranslation()
  const isSmallDevice = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const [vinFilter, setVinFilter] = useState('')
  const debouncedVinFilter = useDebounce(vinFilter)
  const [csvForm, setCsvForm] = useState(false)
  const [csvLines, setCsvLines] = useState(0)
  const { data: cardinalityData, isFetching: isFetchingCardinality } = useDeviceCardinalityQuery({ queryString })

  useEffect(() => {
    if (cardinalityData?.cardinality) {
      setCardinality(cardinalityData.cardinality)
    }
  }, [cardinalityData?.cardinality])

  const handleSubmit = () => {
    if (manualVinSelection && !deviceIds.length) {
      return
    } else if (enableCsvUpload && !csvData.name) {
      return
    }
    onNext()
  }

  const handleManualVinSelection = (enable) => {
    if (!enable) {
      // reset device ids if manual selection is disabled
      setDeviceIds([])
    } else {
      handleEnableCsvUpload(false)
    }
    setManualVinSelection(enable)
  }

  const handleEnableCsvUpload = (enable) => {
    if (!enable) {
      // reset fileName
      setCsvData({ name: null, length: null })
    } else {
      handleManualVinSelection(false)
    }
    setEnableCsvUpload(enable)
  }

  const handleOpenCsvForm = () => setCsvForm(true)
  const handleCloseCsvForm = () => setCsvForm(false)
  const handleCsvUploaded = ({ fileName, lines }) => {
    setCsvData({ name: fileName, lines })
    setCsvLines(lines)
    setCsvForm(false)
  }

  const [prevToken, setPrevToken] = useState()
  const qsAddition = React.useMemo(
    () => ({
      queryString: debouncedVinFilter
        ? `${queryString} AND attributes.vin:${debouncedVinFilter.replace('*', '')}*`
        : queryString,
    }),
    [debouncedVinFilter],
  )
  const [qs, setQs] = useState({
    base: {
      maxResults: getDefaultListPerPage('vehicles-selection'),
      nextToken: null,
    },
    qsAddition,
  })
  usePropDidUpdate((prevProp) => {
    setPrevToken(prevProp)
  })
  const refreshData = setQs
  const { data, isFetching, isLoading } = useDevicesQuery({ ...qs.base, ...qsAddition })

  return (
    <div>
      {withLoader(
        () => (
          <>
            <Grid container>
              <Grid item xs={12} md={3} sx={{ paddingRight: { md: 6 } }}>
                <Total>
                  <TotalNumber>
                    {manualVinSelection ? deviceIds.length : enableCsvUpload ? csvLines : cardinalityData?.cardinality}
                  </TotalNumber>
                  <TotalLabel>
                    {deviceIds.length || enableCsvUpload ? (
                      t('campaigns:ui.SelectedDevices')
                    ) : (
                      <Trans i18nKey="campaigns:ui.DevicesFromQuery"></Trans>
                    )}
                  </TotalLabel>
                </Total>
                <NameValueList
                  nowrap
                  data={[
                    { name: t('common:fields.domain'), value: fields.domain },
                    { name: t('common:fields.ring'), value: fields.ring },
                    { name: t('common:fields.assetType'), value: fields.assetType },
                    { name: t('common:fields.deviceType'), value: fields.deviceType },
                    {
                      name: t('common:ui.CurrentBundleVersion'),
                      value: fields.queryStringBundleVersion
                        ? fields.queryStringBundleToVersion
                          ? `[${fields.queryStringBundleVersion}, ${fields.queryStringBundleToVersion}]`
                          : `${operatorMap[fields.queryStringBundleOp]} ${fields.queryStringBundleVersion}`
                        : '*',
                    },
                  ]}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                sx={{ paddingLeft: { md: 6 }, borderLeft: { md: 1 }, borderLeftColor: { md: 'grey.400' } }}
              >
                <Grid container direction="row" justifyContent="space-between" rowSpacing={1}>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      sx={{ ml: 0 }}
                      control={
                        <Switch
                          checked={manualVinSelection}
                          onChange={compose(handleManualVinSelection, path(['target', 'checked']))}
                        />
                      }
                      label={t('campaigns:ui.ManualVINSelection')}
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box direction="row" justify={isSmallDevice ? 'start' : 'end'}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={enableCsvUpload}
                            onChange={compose(handleEnableCsvUpload, path(['target', 'checked']))}
                          />
                        }
                        label={t('campaigns:ui.EnableCSVUpload')}
                        labelPlacement="start"
                      />
                      <Button
                        disabled={!enableCsvUpload}
                        sx={{ ml: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={handleOpenCsvForm}
                      >
                        {t('common:actions.Upload')}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                {!enableCsvUpload && (
                  <>
                    <Box direction="row" justify="start" margin="0 0 1rem">
                      <TextField
                        value={vinFilter}
                        onChange={setStateFromEvent(setVinFilter)}
                        label={t('vehicles:fields.vin')}
                      />
                    </Box>
                    <DataTable
                      selectable={manualVinSelection}
                      name="vehicles-selection"
                      data={defaultTo([], data?.things)}
                      refreshData={refreshData}
                      prevToken={prevToken}
                      nextToken={data?.nextToken}
                      qs={qs}
                      dataCount={-1}
                      model={Device}
                      listDisplay={LIST_DISPLAY}
                      fieldsMapping={FIELDS_MAPPING}
                      loading={isFetching}
                      selected={deviceIds}
                      onSelect={setDeviceIds}
                    />
                  </>
                )}
                {enableCsvUpload && (
                  <Box margin="1.5rem 0">
                    {!csvData.name && <Text>{t('campaigns:ui.NoCsvSelected')}</Text>}
                    {csvData.name && <Text>{t('campaigns:ui.CsvUploaded')}</Text>}
                  </Box>
                )}
              </Grid>
            </Grid>
          </>
        ),
        isLoading || isFetchingCardinality,
      )}
      {csvForm && <CampaignCsvForm onSubmit={handleCsvUploaded} onCancel={handleCloseCsvForm} />}
      <Stack direction="row" justifyContent="end" spacing={2} marginTop="2rem">
        <Button variant="contained" color="primary" onClick={onCancel}>
          {t('common:actions.Cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={
            !cardinalityData?.cardinality ||
            (manualVinSelection && !deviceIds.length) ||
            (enableCsvUpload && !csvData.name)
          }
        >
          {t('common:actions.Next')}
        </Button>
      </Stack>
    </div>
  )
}

StepDeviceSelection.propTypes = {
  fields: PropTypes.object,
  queryString: PropTypes.string.isRequired,
  deviceIds: PropTypes.array,
  setDeviceIds: PropTypes.func.isRequired,
  setCardinality: PropTypes.func.isRequired,
  csvData: PropTypes.shape({
    name: PropTypes.string,
    lines: PropTypes.number,
  }),
  setCsvData: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  manualVinSelection: PropTypes.bool,
  setManualVinSelection: PropTypes.func.isRequired,
  enableCsvUpload: PropTypes.bool,
  setEnableCsvUpload: PropTypes.func.isRequired,
}

export default StepDeviceSelection
