import Logger from '@Common/Utils/Logger'
import { authTokenRequest, msalConfig } from '../Config'
import { refreshToken, resetCredentials, selectAccount } from '@Auth/Redux'
import { PublicClientApplication } from '@azure/msal-browser'
import { history } from '@Core/Redux/Store'
import config from '@Config'
import { store } from '@Core/Redux/Store'
import { toast } from 'react-toastify'
import { api } from '@Core/Services/Api'
import i18next from 'i18next'

export const msalInstance = new PublicClientApplication(msalConfig)

export const logout = (dispatch, reload = true, completeInit = () => {}) => {
  Logger.warning('Logging out from application')
  msalInstance
    .logoutPopup()
    .then(() => {
      dispatch(api.util.invalidateTags(['CurrentUser']))
      dispatch(resetCredentials())
      setTimeout(() => {
        Logger.debug('Logout, credentials reset done, redirecting to login')
        window.location.assign(window.location.origin + '#' + config.urls.login)
        Logger.debug('Reloading page')
        completeInit()
        reload && window.location.reload()
      }, 1000)
    })
    .catch((err) => {
      Logger.warning('Logout flow error:', err)
    })
}

export const refreshJwt = async () => {
  const account = selectAccount(store.getState())
  Logger.info('Refreshing JWT for account', account)
  return msalInstance
    .acquireTokenSilent({ ...authTokenRequest, account })
    .then(async (response) => {
      Logger.info('JWT retrieved - silent')
      store.dispatch(refreshToken(response.accessToken))
      return response.accessToken
    })
    .catch(async (err) => {
      Logger.info('JWT acquire token silent failed, going for popup', err)
      msalInstance
        .acquireTokenPopup(authTokenRequest)
        .then(async (response) => {
          Logger.info('JWT retrieved - popup')
          store.dispatch(refreshToken(response.accessToken))
          return response.accessToken
        })
        .catch((err) => {
          Logger.error('Get jwt error:', err)
          if (/popup_window_error/.test(err)) {
            toast.warning(i18next.t('auth:ui.CheckPopupBlocker'))
          }
          history.push(config.urls.serverError.replace(':error', 'token'))
        })
    })
}
