import { Tooltip } from '@mui/material'
import styled from 'styled-components'

import { CampaignType } from '@Campaigns/Models/Campaign'
import { useTranslation } from 'react-i18next'
import { round } from '@Common/Utils/Numbers'
import { defaultTo } from 'ramda'

const Container = styled.div`
  border-radius: 16px;
  width: 150px;
  height: 20px;
  background: ${({ theme }) => theme.palette.contrastLight.main};
  position: relative;
  display: flex;
  flex-direction: 'row';
  overflow: hidden;
`

const Section = styled.div`
  height: 20px;
  min-width: ${({ width }) => `${width}px`};
  width: ${({ width }) => `${width}px`};
  max-width: ${({ width }) => `${width}px`};
  background: ${({ theme, palette }) => theme.palette[palette].main};
`

const CampaignStatusProgress = ({ campaign }) => {
  const { t } = useTranslation()
  if (!campaign.group.thingsCardinality) return null
  const tot = campaign.group.thingsCardinality
  const ok = campaign.campaignStatus.numberOfSucceededThings
  const ko =
    campaign.campaignStatus.numberOfCanceledThings +
    campaign.campaignStatus.numberOfFailedThings +
    campaign.campaignStatus.numberOfRejectedThings +
    campaign.campaignStatus.numberOfRemovedThings +
    defaultTo(0, campaign.campaignStatus.numberOfTimedOutThings)

  const okPerc = round(100 / tot * ok)
  const koPerc = round(100 / tot * ko) 
  const pendingPerc = 100 - (okPerc + koPerc)

  return (
    <Container>
      <Tooltip title={t('campaigns:ui.Succeeded') + `: ${okPerc}%`}>
        <Section width={1.5 * okPerc} palette="successLight" />
      </Tooltip>
      <Tooltip title={t('campaigns:ui.Failed') + `: ${koPerc}%`}>
        <Section width={1.5 * koPerc} palette="errorLight" />
      </Tooltip>
      <Tooltip title={t('campaigns:ui.Pending') + `: ${pendingPerc}%`}>
        <Section width={1.5 * pendingPerc} palette="contrastLight" />
      </Tooltip>
    </Container>
  )
}

CampaignStatusProgress.propTypes = {
  campaign: CampaignType,
}

export default CampaignStatusProgress
