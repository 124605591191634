import { Alert, Checkbox, Grid, Switch } from '@mui/material'
import PropTypes from 'prop-types'
import { compose, path } from 'ramda'
import { useTranslation } from 'react-i18next'

import JobFailureTypeField from '@Campaigns/Components/JobFailureTypeField'
import Box from '@Common/Components/Box'
import { FormControlLabel } from '@Common/Components/Forms'
import NumberTextField from '@Common/Components/NumberTextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import React from 'react'
import styled from 'styled-components'

const OddGrid = styled(Grid)`
  background: ${({ theme }) => theme.palette.contrastExtraLight.main};
  margin-bottom: 1rem;
  margin-top: 1rem;
`

const CampaignJobRetryForm = ({ fields, setField, setFields, errors, useAwsDefaults, setUseAwsDefaults }) => {
  const { t } = useTranslation()

  const customDefaults = {
    retryAllEnabled: false,
    retryAllNumberOfRetries: 0,
    retryFailedEnabled: true,
    retryFailedNumberOfRetries: 0,
    retryTimedOutEnabled: true,
    retryTimedOutNumberOfRetries: 1,
  }

  const handleSetUseAwsDefaults = (value) => {
    // if value == true -> manual configuration is enabled
    if (!value) {
      setFields({
        ...fields,
        retryAllEnabled: false,
        retryFailedNumberOfRetries: null,
        retryFailedEnabled: false,
        retryAllNumberOfRetries: null,
        retryTimedOutEnabled: false,
        retryTimedOutNumberOfRetries: null,
      })
    } else {
      setFields({ ...fields, ...customDefaults })
    }
    setUseAwsDefaults(!value)
  }

  const handleToggleFailureType = (failureType) => (checked) => {
    if (checked) {
      if (failureType === 'All') {
        // uncheck and disable other fields
        setFields({
          ...fields,
          retryAllEnabled: true,
          retryFailedEnabled: false,
          retryTimedOutEnabled: false,
        })
      } else {
        setFields({ ...fields, [`retry${failureType}Enabled`]: true })
      }
    } else {
      setFields({ ...fields, [`retry${failureType}Enabled`]: false })
    }
  }

  return (
    <>
      <Box direction="row" justify="center" width="100%" margin="0 0 1rem">
        <FormControlLabel
          control={
            <Switch
              checked={!useAwsDefaults}
              onChange={compose(handleSetUseAwsDefaults, path(['target', 'checked']))}
            />
          }
          label={t('campaigns:ui.EnableManualConfiguration')}
        />
      </Box>
      {!useAwsDefaults && errors.retryRequired && (
        <Alert severity="warning" sx={{ mb: '1rem' }}>
          {errors.retryRequired}
        </Alert>
      )}
      {/* Failure type FAILED */}
      <Grid container spacing={2} mb="1rem">
        <Grid item md={6}>
          <Box direction="row" align="center">
            <Checkbox
              checked={fields.retryFailedEnabled}
              disabled={useAwsDefaults || fields.retryAllEnabled}
              sx={{ pl: 0 }}
              onChange={compose(handleToggleFailureType('Failed'), path(['target', 'checked']))}
            ></Checkbox>
            <JobFailureTypeField
              value={'FAILED'}
              fullWidth
              helperText={t('campaigns:helperTexts.retryFailureType')}
              disabled={useAwsDefaults || fields.retryAllEnabled}
            />
          </Box>
        </Grid>
        <Grid item md={6}>
          <NumberTextField
            required
            label={t('campaigns:fields.numberOfRetries')}
            type="number"
            value={fields.retryFailedNumberOfRetries}
            onChange={setStateFromEvent(setField('retryFailedNumberOfRetries'))}
            fullWidth
            error={!!errors.retryFailedNumberOfRetries}
            helperText={errors.retryFailedNumberOfRetries || t('campaigns:helperTexts.retryNumberOfRetries')}
            disabled={useAwsDefaults || fields.retryAllEnabled}
            min={0}
            max={10}
          />
        </Grid>
      </Grid>

      {/* Failure type TIMED_OUT */}
      <OddGrid container spacing={2} mb="1rem">
        <Grid item md={6}>
          <Box direction="row" align="center">
            <Checkbox
              checked={fields.retryTimedOutEnabled}
              disabled={useAwsDefaults || fields.retryAllEnabled}
              sx={{ pl: 0 }}
              onChange={compose(handleToggleFailureType('TimedOut'), path(['target', 'checked']))}
            ></Checkbox>
            <JobFailureTypeField
              value={'TIMED_OUT'}
              fullWidth
              helperText={t('campaigns:helperTexts.retryFailureType')}
              disabled={useAwsDefaults || fields.retryAllEnabled}
            />
          </Box>
        </Grid>
        <Grid item md={6}>
          <NumberTextField
            required
            label={t('campaigns:fields.numberOfRetries')}
            type="number"
            value={fields.retryTimedOutNumberOfRetries}
            onChange={setStateFromEvent(setField('retryTimedOutNumberOfRetries'))}
            fullWidth
            error={!!errors.retryTimedOutNumberOfRetries}
            helperText={errors.retryNumberOfRetries || t('campaigns:helperTexts.retryNumberOfRetries')}
            disabled={useAwsDefaults || fields.retryAllEnabled}
            min={0}
            max={10}
          />
        </Grid>
      </OddGrid>

      {/* Failure type ALL */}
      <Grid container spacing={2} mb="1rem">
        <Grid item md={6}>
          <Box direction="row" align="center">
            <Checkbox
              checked={fields.retryAllEnabled}
              disabled={useAwsDefaults}
              sx={{ pl: 0 }}
              onChange={compose(handleToggleFailureType('All'), path(['target', 'checked']))}
            ></Checkbox>
            <JobFailureTypeField
              value={'ALL'}
              fullWidth
              helperText={t('campaigns:helperTexts.retryFailureType')}
              disabled={useAwsDefaults}
            />
          </Box>
        </Grid>
        <Grid item md={6}>
          <NumberTextField
            required
            label={t('campaigns:fields.numberOfRetries')}
            type="number"
            value={fields.retryAllNumberOfRetries}
            onChange={setStateFromEvent(setField('retryAllNumberOfRetries'))}
            fullWidth
            error={!!errors.retryAllNumberOfRetries}
            helperText={errors.retryNumberOfRetries || t('campaigns:helperTexts.retryNumberOfRetries')}
            disabled={useAwsDefaults}
            min={0}
            max={10}
          />
        </Grid>
      </Grid>
    </>
  )
}

CampaignJobRetryForm.propTypes = {
  fields: PropTypes.object,
  setField: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
  useAwsDefaults: PropTypes.bool,
  setUseAwsDefaults: PropTypes.func.isRequired,
}

export default CampaignJobRetryForm
