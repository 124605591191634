import { Grid, Switch } from '@mui/material'
import PropTypes from 'prop-types'
import { compose, isEmpty, isNil, path } from 'ramda'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import Box from '@Common/Components/Box'
import { FormControlLabel } from '@Common/Components/Forms'
import NumberTextField from '@Common/Components/NumberTextField'
import { setStateFromEvent } from '@Common/Utils/Events'

const CampaignJobTimeoutForm = ({ fields, setField, setFields, errors, useAwsDefaults, setUseAwsDefaults }) => {
  const { t } = useTranslation()

  const customDefaults = {
    timeoutInProgressTimeoutInMinutes: Math.ceil(fields.timeoutInMinutes / 2),
  }

  const handleSetUseAwsDefaults = (value) => {
    if (value && (isEmpty(fields.timeoutInMinutes) || isNil(fields.timeoutInMinutes))) {
      return toast.warning(t('campaigns:ui.MustConfigureJobTimeoutFirst'))
    }
    if (!value) {
      setFields({
        ...fields,
        timeoutInProgressTimeoutInMinutes: null,
      })
    } else {
      setFields({ ...fields, ...customDefaults })
    }
    setUseAwsDefaults(!value)
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12} alignItems="center">
        <Box direction="row" justify="center" width="100%">
          <FormControlLabel
            control={
              <Switch
                checked={!useAwsDefaults}
                onChange={compose(handleSetUseAwsDefaults, path(['target', 'checked']))}
              />
            }
            label={t('campaigns:ui.EnableManualConfiguration')}
          />
        </Box>
      </Grid>
      <Grid item md={6}>
        <NumberTextField
          required
          label={t('campaigns:fields.inProgressTimeoutInMinutes')}
          type="number"
          value={fields.timeoutInProgressTimeoutInMinutes}
          onChange={setStateFromEvent(setField('timeoutInProgressTimeoutInMinutes'))}
          fullWidth
          error={!!errors.timeoutInProgressTimeoutInMinutes}
          helperText={
            errors.timeoutInProgressTimeoutInMinutes || t('campaigns:helperTexts.timeoutInProgressTimeoutInMinutes')
          }
          disabled={useAwsDefaults}
          min={1}
          max={10080}
        />
      </Grid>
    </Grid>
  )
}

CampaignJobTimeoutForm.propTypes = {
  fields: PropTypes.object,
  setField: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
  useAwsDefaults: PropTypes.bool,
  setUseAwsDefaults: PropTypes.func.isRequired,
}

export default CampaignJobTimeoutForm
