import { selectNotificationsPanelIsOpen, toggleNotificationsPanel } from "@Core/Redux/Ui"
import { Drawer } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import NotificationsList from "./NotificationsList"

const Title = styled.h2`
  margin-bottom: 0;
`

const Container = styled.div`
  padding: 1rem;
  width: 460px;
`

const Notifications = styled.div`
  padding-top: 1rem;
`

const NotificationsPanel = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isOpen = useSelector(selectNotificationsPanelIsOpen)

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={() => dispatch(toggleNotificationsPanel())}
    >
      <Container>
        <Title>{t('notifications:ui.Notifications')}</Title>
        <Notifications>
          <NotificationsList />
        </Notifications>
      </Container>
    </Drawer>
  )
}

export default NotificationsPanel
