import React from 'react'

import getStorage from '@Common/Services/Storage'
import Logger from '@Common/Utils/Logger'
import Config from '@Config'

const storageService = getStorage()

const createResizableColumn = function (col, resizer, table, idx, handleSave) {
  // Track the current position of mouse
  let x = 0
  let w = 0

  const mouseDownHandler = function (e) {
    // Get the current mouse position
    x = e.pageX

    // Calculate the current width of column
    const styles = window.getComputedStyle(col)
    w = parseInt(styles.width, 10)

    // Attach listeners for document's events
    document.addEventListener('mousemove', mouseMoveHandler)
    document.addEventListener('mouseup', mouseUpHandler)
  }

  const mouseMoveHandler = function (e) {
    // Determine how far the mouse has been moved
    const dx = e.pageX - x

    // Update the width of th cell
    if (w + dx > 30) {
      col.style.width = `${w + dx}px`
      col.style.minWidth = `${w + dx}px`
      col.style.maxWidth = `${w + dx}px`

      // Update the with of all column tds
      const rows = table.querySelectorAll('tbody tr')
      ;[].forEach.call(rows, function (row) {
        const cells = row.querySelectorAll('td')
        cells[idx].style.width = `${w + dx}px`
        cells[idx].style.minWidth = `${w + dx}px`
        cells[idx].style.maxWidth = `${w + dx}px`
      })

      handleSave(idx, w + dx)
    }
  }

  // When user releases the mouse, remove the existing event listeners
  const mouseUpHandler = function () {
    document.removeEventListener('mousemove', mouseMoveHandler)
    document.removeEventListener('mouseup', mouseUpHandler)
  }

  resizer.addEventListener('mousedown', mouseDownHandler)
}

export const useResizableColumns = (name, data, displayColumns, loading, { disabled }) => {
  React.useEffect(() => {
    if (!loading && Config.ui.enableDataTableColumnResizing && !disabled) {
      const table = document.getElementById(`id-table-${name}`)

      setTimeout(() => {
        // Query all headers
        try {
          const cols = table.querySelectorAll('th')

          const resizingSettings = storageService.get(`${name}_DATA_TABLE_RESIZING`, null)
          const handleSave = (idx, w) => {
            const data = storageService.get(`${name}_DATA_TABLE_RESIZING`, {})
            data[idx] = w
            storageService.save(`${name}_DATA_TABLE_RESIZING`, data)
          }

          ;[].forEach.call(cols, function (col, index) {
            if (!col.classList.contains('fix')) {
              // set initial column width
              let w
              if (!resizingSettings || !resizingSettings[index]) {
                // calculate on the fly the first time
                const styles = window.getComputedStyle(col)
                w = parseFloat(styles.width)
                handleSave(index, w)
              } else {
                // use cached values
                w = resizingSettings[index]
              }
              col.style.width = `${w}px`
              col.style.minWidth = `${w}px`
              col.style.maxWidth = `${w}px`

              // Update the with of all column tds
              const rows = table.querySelectorAll('tbody tr')
              ;[].forEach.call(rows, function (row) {
                const cells = row.querySelectorAll('td')
                cells[index].style.width = `${w}px`
                cells[index].style.minWidth = `${w}px`
                cells[index].style.maxWidth = `${w}px`
              })

              // Create a resizer element
              const resizer = document.createElement('div')
              resizer.classList.add('resizer')

              // Add a resizer element to the column
              col.querySelector('.resizer')?.remove()
              col.appendChild(resizer)
              createResizableColumn(col, resizer, table, index, handleSave)
            }
          })
        } catch (e) {
          Logger.warning('Datatable useResizing hook error', e)
        }
      }, 0)
    }
  }, [data, displayColumns, loading])
}
