import PropTypes from 'prop-types'
import { compose, defaultTo, either, filter, includes, isEmpty, isNil, not, path, pipe, toLower, values } from 'ramda'
import React from 'react'

import DataTable from '@Common/Components/DataTable'
import Link from '@Common/Components/Link'
import { useForm } from '@Common/Utils/Hooks'
import { readFromStorage, storeInStorage } from '@Common/Utils/Storage'
import { makePath } from '@Config'
import BundleSwComponentsFilterForm from '@SoftwareCatalog/Forms/BundleSwComponentsFilterForm'
import { BundleType } from '@SoftwareCatalog/Models/Bundle'
import { BundleSwComponent } from '@SoftwareCatalog/Models/SoftwareComponent'

import DeltaStatus from './DeltaStatus'
import SwStatus from './SwStatus'
import { useTranslation } from 'react-i18next'

const LIST_DISPLAY = [
  'swComponentInfo.name',
  'swComponentInfo.version',
  'swComponentStatus.status',
  'swComponentStatus.deltaStatus',
]

const SORT_MAPPING = {
  'swComponentInfo.version': path(['swComponentInfo', 'incrementalVersion'])
}

const FILTERS_STORAGE_KEY = 'filters-bundle-software-components'

const SwComponentListView = ({ bundle, isFetching }) => {
  const { t } = useTranslation()

  const FIELDS_MAPPING = {
    'swComponentInfo.name': (record) =>
      record.swComponentStatus.status === 'PRESENT' ? (
        <Link
          to={makePath('bundles.swDetail', {
            bundleId: bundle._id,
            swComponentName: record.swComponentInfo.name,
            swComponentVersion: record.swComponentInfo.version,
          })}
        >
          {record.swComponentInfo.name}
        </Link>
      ) : (
        record.swComponentInfo.name
      ),
    'swComponentStatus.status': (record) => <SwStatus status={record.swComponentStatus.status} />,
    'swComponentStatus.deltaStatus': (record) => <DeltaStatus status={record.swComponentStatus.deltaStatus} />,
  }

  const {
    fields: filterFields,
    setField: setFilterField,
    setFields: setFilterFields,
  } = useForm(readFromStorage(FILTERS_STORAGE_KEY, {}))
  const handleFilterForm = React.useCallback(
    (handleClose) => {
      return (
        <BundleSwComponentsFilterForm
          onClose={handleClose}
          fields={filterFields}
          setField={setFilterField}
          onReset={() => setFilterFields({})}
          onSave={() => storeInStorage(FILTERS_STORAGE_KEY, filterFields)}
        />
      )
    },
    [BundleSwComponentsFilterForm, filterFields, setFilterField],
  )

  return (
    <DataTable
      name="sw-components"
      label={t('ui.SoftwareComponents')}
      data={defaultTo([], bundle?.swComponents).filter(
        pipe(path(['swComponentInfo', 'name']), toLower, includes(defaultTo('', filterFields.name))),
      )}
      model={BundleSwComponent}
      listDisplay={LIST_DISPLAY}
      sortField="swComponentInfo.name"
      sortDirection="asc"
      defaultListPerPage={10}
      fieldsMapping={FIELDS_MAPPING}
      sortMapping={SORT_MAPPING}
      loading={isFetching}
      filterForm={handleFilterForm}
      filterFormActive={values(filter(compose(not, either(isEmpty, isNil)), filterFields)).length}
      noExport
    />
  )
}

SwComponentListView.propTypes = {
  bundle: BundleType,
  isFetching: PropTypes.bool,
}

export default SwComponentListView
