export const NOTIFICATION_TYPES = [
  'SW_COMPONENT_ADDED',
  'SW_COMPONENT_REMOVED',
  'SW_COMPONENT_DELTA_COMPLETED',
  'SW_COMPONENT_DELTA_FAILED',
  'BUNDLE_ADDED',
  'BUNDLE_REMOVED',
  'BUNDLE_DELTA_COMPLETED',
  'BUNDLE_DELTA_FAILED',
  'BUNDLE_OK',
  'BUNDLE_ERROR',
  'GROUP_CREATION_FAILED',
  'JOB_CREATION_FAILED',
  'JOB_STARTED',
  'JOB_COMPLETED',
  'JOB_CANCELLATION_IN_PROGRESS',
  'JOB_CANCELED',
  'JOB_DELETION_IN_PROGRESS',
  'JOB_DELETED',
  'CAMPAIGN_DAILY_REPORT',
  'CAMPAIGN_EXECUTION_DAILY_REPORT',
]
