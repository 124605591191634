import React from 'react'
import { useTranslation } from 'react-i18next'

import UsersList from '@Auth/Components/UsersList'
import UserForm from '@Auth/Forms/UserForm'
import UsersPermissions from '@Auth/Permissions'
import { useCreateUserMutation, useDeleteUserMutation, useUpdateUserMutation } from '@Auth/Services/Api'
import Fab from '@Common/Components/Fab'
import BaseLayout from '@Common/Layouts/BaseLayout'
import { request } from '@Common/Utils/Api'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'

const AdminUsersView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  useBreadcrumbs([{ label: t('navigation.Administration') }])
  const [createUser] = useCreateUserMutation()
  const [updateUser] = useUpdateUserMutation()
  const [deleteUser] = useDeleteUserMutation()
  const [form, setForm] = React.useState({ isOpen: false, user: null })

  const handleSaveUser = async ({
    name,
    eMail,
    role,
    swComponentNotification,
    bundleNotification,
    campaignNotification,
    campaignDailyReportNotification,
    active,
  }) => {
    const insert = form.user === null
    const endpoint = insert ? createUser : updateUser
    const userInfo = user._id === form.user?._id ? { name, eMail } : { name, eMail, role }
    const userSettings = {
      swComponentNotification,
      bundleNotification,
      campaignNotification,
      campaignDailyReportNotification,
    }
    const userStatus = { active }
    const userData = user._id === form.user?._id ? { userInfo, userSettings } : { userInfo, userSettings, userStatus }
    const data = insert ? [{ user: userData }] : [{ userId: form.user._id, user: userData }]
    const successMessage = insert ? 'UserCreated' : 'UserUpdated'
    const failureMessage = insert ? 'CreateUserError' : 'UpdateUserError'

    const res = await request(
      endpoint,
      data,
      t(`auth:success.${successMessage}`, { user: name }),
      `auth:errors.${failureMessage}`,
    )

    return res
  }

  const handleDeleteUser = async (user) => {
    const res = await request(
      deleteUser,
      [{ userId: user._id }],
      t(`auth:success.UserDeleted`, { user: user.userInfo.name }),
      `auth:errors.DeleteUserError`,
    )

    return res
  }

  return (
    <BaseLayout withFab>
      <UsersList onEdit={(user) => setForm({ isOpen: true, user })} onDelete={handleDeleteUser} />
      {form.isOpen && (
        <UserForm user={form.user} onClose={() => setForm({ isOpen: false, user: null })} onSubmit={handleSaveUser} />
      )}
      {UsersPermissions.create(user) && <Fab right="2rem" onClick={() => setForm({ isOpen: true, user: null })} />}
    </BaseLayout>
  )
}

export default AdminUsersView
