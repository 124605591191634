// import PropTypes from 'prop-types'
import { Grid } from '@mui/material'

import { CampaignType } from '@Campaigns/Models/Campaign'

import StatusPieChart from './StatusPieChart'
import { Header, Section } from './Styles'
import { useTranslation } from 'react-i18next'
import CampaignInfo from './CampaignInfo'
import CampaignReports from './CampaignReports'

const CampaignDashboard = ({ campaign }) => {
  const { t } = useTranslation()
  return (
    <Grid container>
      <Grid item sm={12} lg={5}>
        <Section>
          <Header>{campaign.campaignInfo.name}</Header>
          <StatusPieChart campaign={campaign} />
        </Section>
      </Grid>
      <Grid item sm={12} lg={7}>
        <Section>
          <Header>{t('campaigns:ui.CampaignInfo')}</Header>
          <CampaignInfo campaign={campaign} />
        </Section>
        <Section>
          <Header>{t('campaigns:ui.CampaignExecutionDailyReports')}</Header>
          <CampaignReports campaign={campaign} />
        </Section>
      </Grid>
    </Grid>
  )
}

CampaignDashboard.propTypes = {
  campaign: CampaignType,
}

export default CampaignDashboard
