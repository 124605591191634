import PropTypes from 'prop-types'
import { defaultTo, groupBy, prop, sortBy } from 'ramda'
import styled from 'styled-components'

import Box from '@Common/Components/Box'

import CampaignsRingColumnChart from './CampaignsRingColumnChart'
import Logger from '@Common/Utils/Logger'

const ColLeft = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.contrastLight.main};
  width: 50%;
`

const ColRight = styled.div`
  width: 50%;
`

/**
 * Campaigns charts section in the dashboard
 *
 * @param {Array} props.campaignStatistics - campaigns statistics
 */
const CampaignsCharts = ({ campaignStatistics }) => {
  const groupedStats = groupBy(prop('deviceType'), sortBy(prop('ring'), campaignStatistics))
  Logger.debug('Campaign pcm type grouped stats', groupedStats)

  return (
    <Box direction="row" align="center">
      <ColLeft>
        <CampaignsRingColumnChart title='PCM3' stats={defaultTo([], groupedStats.PCM3)} />
      </ColLeft>
      <ColRight>
        <CampaignsRingColumnChart title='IHP' stats={defaultTo([], groupedStats.IHP)} />
      </ColRight>
    </Box>
  )
}

CampaignsCharts.propTypes = {
  campaignStatistics: PropTypes.arrayOf(
    PropTypes.shape({
      cardinality: PropTypes.number,
      deviceType: PropTypes.string,
      domain: PropTypes.string,
      ring: PropTypes.string,
      fromSuccedeedPercentage: PropTypes.number,
      toSuccedeedPercentage: PropTypes.number,
    }),
  ),
}

export default CampaignsCharts
