import PropTypes from 'prop-types'
import { Tooltip } from "@mui/material"

const IconTooltip = ({ Icon, title }) => {
  return (
    <Tooltip title={title}><Icon /></Tooltip>
  )
}

IconTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.any,
}

export default IconTooltip
