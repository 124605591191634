import { Notifications } from '@mui/icons-material'
import { Badge, IconButton } from '@mui/material'
import { defaultTo, multiply, pipe, prop } from 'ramda'
import React from 'react'
import { useDispatch } from 'react-redux'

import { useCurrentUser } from '@Common/Utils/Hooks'
import { gte } from '@Common/Utils/Ramda'
import { toggleNotificationsPanel } from '@Core/Redux/Ui'
import { useNotificationsQuery } from '@Notifications/Services/Api'

const PER_PAGE = 11

const NotificationToggle = () => {
  const dispatch = useDispatch()
  const user = useCurrentUser()
  const qs = {
    pageSize: PER_PAGE,
    pageNumber: 0,
    orderBy: 'timestamp',
    orderType: 'desc',
  }
  const { data } = useNotificationsQuery(qs)

  const unread = data?.response?.notifications.filter(
    pipe(prop('timestamp'), multiply(1e3), gte(defaultTo(0, user?.userStatus.lastNotificationTimestamp))),
  ).length

  return (
    <IconButton onClick={() => dispatch(toggleNotificationsPanel())}>
      <Badge badgeContent={unread} color="primary" max={10}>
        <Notifications />
      </Badge>
    </IconButton>
  )
}

export default NotificationToggle
