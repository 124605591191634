import i18n from '../../i18n'
import PropTypes from 'prop-types'

export const CampaignExecutionDailyReportType = PropTypes.shape({
  _id: PropTypes.string,
  campaignId: PropTypes.string,
  campaignExecutionDailyReportTimestamp: PropTypes.string,
  campaignExecutionDailyReportS3Bucket: PropTypes.string,
  campaignExecutionDailyReportS3Key: PropTypes.string,
  campaignExecutionDailyReportUrl: PropTypes.string,
})

export default {
  primaryKey: '_id',
  columns: [
    {
      id: '_id',
      label: i18n.t('fields.id'),
    },
    {
      id: 'campaignId',
      label: i18n.t('campaigns:fields.campaignId'),
    },
    {
      id: 'campaignExecutionDailyReportTimestamp',
      label: i18n.t('campaigns:fields.campaignExecutionDailyReportTimestamp'),
      datetime: true,
    },
    {
      id: 'campaignExecutionDailyReportUrl',
      label: i18n.t('campaigns:ui.ReportDownload'),
    },
  ],
}
