import React from 'react'
import { useTheme } from '@mui/material/styles'
import { FieldType } from '../Types/Field'
import FormControlLabel from '@mui/material/FormControlLabel'
import MuiCheckbox from '@mui/material/Checkbox'

/**
 * A wrpper to make it easy to add functionality, i.e. clearable
 */
const CheckBox = ({ label, style, ...props }) => {
  const theme = useTheme()
  // get rid of "I don't want null" fucking message from MUI
  return (
    <FormControlLabel
      control={<MuiCheckbox {...props} />}
      label={label}
      style={{
        ...style,
        color: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.54)' : 'rgba(255, 255, 255, 0.7)',
        letterSpacing: '0.00938em',
      }}
    />
  )
}

CheckBox.propTypes = FieldType

export default CheckBox
