import React from 'react'
import Loader from '@Common/Components/Loader'
import { type } from 'ramda'
import PageNotFound from '@Common/Components/PageNotFound'
import { Alert } from '@mui/material'
import { Tooltip } from "@mui/material"
import i18n from 'i18n'

/**
 * With loader higher order function
 * Returns a loader if the condition is met, otherwise returns the component.
 * The component can be a component (which is immediately evaluated), or a function
 * which is called only after loading occurs.
 *
 * @param {Component|Function} Component - the component that should be rendered if not in loading state
 * @param {Boolean} condition - the loading condition: true -> show loader, false -> show component
 * @param {String} mode - the loader mode. Can be 'overlay', in such case an overlay occupies all the viewport with the spinner in the center
 * @param {Object} [loaderProps] - additional props to be passed to the Loader component
 */
export const withLoader = (Component, condition, mode, loaderProps = {}) => {
  const props = mode === 'overlay' ? { overlay: true } : { minHeight: loaderProps.minHeight || '200px' }
  return condition ? <Loader {...props} {...loaderProps} /> : type(Component) === 'Function' ? Component() : Component
}

/**
 * With page not found higher order function
 * Returns a page not found content if condition is met, otherwise returns the component.
 * The component can be a component (which is immediately evaluated), or a function
 * which is called only if page not found condition is false.
 *
 * @param {Component|Funtion} Component - the component that should be rendered if page not found condition is false
 * @param {Boolean} condition - the page not found condition: true -> page not found, false -> show component
 * @param {String} text - custom text to be shown in the page not found page
 */
export const withPageNotFound = (Component, condition, text) => {
  return condition ? <PageNotFound text={text} /> : type(Component) === 'Function' ? Component() : Component
}

/**
 * With missing data higher order function
 * Returns the component if condition is met, otherwise returns a missing data alert.
 * The component can be a component (which is immediately evaluated), or a function
 * which is called only if condition is true.
 *
 * @param {Component|Function} Component - the component that should be rendered if condition is true
 * @param {Boolean} condition - the render component condition: true -> render the component, false -> render a missing data alert
 * @param {String} [severity] - the severity prop of the alert component (info|warning|error|success)
 */
export const withMissingData = (Component, condition, severity = 'info') => {
  return condition ? (type(Component) === 'Function' ? Component() : Component) : <Alert severity={severity}>{i18n.t('ui.MissingData')}</Alert>
}

/**
 * With tooltip high order function
 * Adds a tooltip to the given component
 *
 * @param {Component} Component - the component to be decorated
 * @param {String} title - the tooltip text
 * @param {Boolean} wrap - wether or not to wrap the given component (some elements does not trigger hover)
 */
export const withTooltip = (Component, title, wrap = false) => <Tooltip title={title}>{wrap ? <div style={{ display: 'inline-block' }}>{Component}</div> : Component}</Tooltip>
