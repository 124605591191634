import { useMsalAuthentication } from '@azure/msal-react'
import { CircularProgress, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import styled from 'styled-components'

import Bg from '@Assets/Images/login-bg.jpg'
import { loginRequest } from '@Auth/Config'
import { setCredentials, setSaml } from '@Auth/Redux'
import Box from '@Common/Components/Box'
import Logo from '@Common/Components/Logo'
import { useCurrentUser } from '@Common/Utils/Hooks'
import logger from '@Common/Utils/Logger'
import config from '@Config'
import { api } from '@Core/Services/Api'
import { history } from '@Core/Redux/Store'

const Wrapper = styled(Box)`
  background: url(${Bg}) no-repeat center center;
  background-size: cover;
  min-height: 100vh;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

const LoginMsalView = () => {
  const { t } = useTranslation()
  const [login, setLogin] = React.useState(true)
  const user = useCurrentUser()
  const nav = useLocation()
  const { username: usernameParam } = useParams()
  const [username, setUsername] = React.useState(usernameParam)
  const dispatch = useDispatch()
  const { result, error } = useMsalAuthentication('popup', { ...loginRequest, loginHint: username })

  React.useEffect(() => {
    if (error) {
      logger.warning('Login msal error', error)
      if (login) {
        setLogin(false)
        setUsername('')
      }
    } else if (result) {
      logger.debug('Login msal ok', error)
      dispatch(setSaml(result.account))
      dispatch(setCredentials({ accessToken: result.accessToken, username: result.account.username }))
      dispatch(api.util.invalidateTags(['CurrentUser']))
      logger.info('Login ok', result, username, loginRequest)
    }
  }, [result, error])

  React.useEffect(() => {
    if (user?._id) {
      logger.debug('Already logged in, redirecting to ', nav.state?.next || config.urls.home)
      const redirect = nav.state?.next && nav.state.next !== '/login' ? nav.state.next : config.urls.home
      history.push(redirect)
    }
  }, [user?._id])

  return (
    <Wrapper align="center" direction="column" justify="center">
      <Box align="center" direction="column" justify="center" margin="2rem" pad="2rem" width="350px">
        <Logo style={{ width: '170px', marginBottom: '2rem' }} />
        <ButtonRow>
          <Button variant="contained" color="primary" onClick={() => history.push(config.urls.login)}>
            {t('actions.Back')}
          </Button>
        </ButtonRow>
        {login && (
          <Box direction="column" margin="1.5rem 0" align="center" justify="center">
            <CircularProgress color="white" />
            <Typography color="white.main" mt="1.5rem" paragraph>
              {t('ui.Authenticating')}
            </Typography>
          </Box>
        )}
      </Box>
    </Wrapper>
  )
}

export default LoginMsalView
