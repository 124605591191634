import React from 'react'
import PropTypes from 'prop-types'
import { FieldType } from '../Types/Field'
import MuiTextField from '@mui/material/TextField'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'

/**
 * A wrpper to make it easy to add functionality, i.e. clearable
 */
const TextField = ({ value, exactLength, maxLength, ...props }) => {
  const { t } = useTranslation()
  // get rid of "I don't want null" fucking message from MUI
  const val = R.ifElse(R.isNil, () => '', R.identity)(value)
  const helperText =
    props.helperText ||
    (exactLength
      ? t('common:ui.CharactersCount', { count: val.length, total: exactLength })
      : '')
  if (maxLength) {
    props.inputProps = {...R.defaultTo({}, props.inputProps), maxLength }
  }
  return <MuiTextField value={val} {...props} helperText={helperText} variant={props.variant} />
}

TextField.defaultProps = {
  variant: 'standard',
}

TextField.propTypes = {
  ...FieldType,
  maxLength: PropTypes.number,
  exactLength: PropTypes.number,
  variant: PropTypes.string,
}

export default TextField
