import DashboardIcon from '@mui/icons-material/Dashboard'
import AdministrationPermissions from 'Administration/Permissions'
import CampaignsPermissions from 'Campaigns/Permissions'
import SoftwareCatalogPermissions from 'SoftwareCatalog/Permissions'
import VehiclesPermissions from 'Vehicles/Permissions'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'

import AdministrationIcon from '@Common/Icons/AdministrationIcon'
import CampaignsIcon from '@Common/Icons/CampaignsIcon'
import SoftwareBundlesIcon from '@Common/Icons/SoftwareBundlesIcon'
import SoftwareCatalogIcon from '@Common/Icons/SoftwareCatalogIcon'
import VehiclesIcon from '@Common/Icons/VehiclesIcon'
import { useCurrentUser } from '@Common/Utils/Hooks'
import { makePath } from '@Config'

const MenuWrapper = styled.div`
  margin-top: 1rem;
`

const MenuItem = styled.div`
  align-items: center;
  background: ${({ active, theme }) => (active ? theme.palette.primary.main : 'transparent')};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  padding: 1rem 1rem;
`
const Label = styled.div`
  margin-left: 1rem;
`

const Menu = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <MenuWrapper>
      {CampaignsPermissions.read(user) && (
        <MenuItem
          onClick={() => navigate(makePath('campaigns.list'))}
          active={location.pathname.includes(makePath('campaigns.list'))}
        >
          <CampaignsIcon />
          <Label>{t('navigation.Campaigns')}</Label>
        </MenuItem>
      )}
      <MenuItem onClick={() => navigate(makePath('dashboard'))} active={location.pathname === makePath('dashboard')}>
        <DashboardIcon />
        <Label>{t('navigation.Dashboard')}</Label>
      </MenuItem>
      {VehiclesPermissions.read(user) && (
        <MenuItem
          onClick={() => navigate(makePath('vehicles.main'))}
          active={location.pathname.includes(makePath('vehicles.main'))}
        >
          <VehiclesIcon />
          <Label>{t('navigation.Vehicles')}</Label>
        </MenuItem>
      )}
      {SoftwareCatalogPermissions.read(user) && (
        <MenuItem
          onClick={() => navigate(makePath('bundles.main'))}
          active={location.pathname.includes(makePath('bundles.main'))}
        >
          <SoftwareBundlesIcon />
          <Label>{t('navigation.Bundles')}</Label>
        </MenuItem>
      )}
      {SoftwareCatalogPermissions.read(user) && (
        <MenuItem
          onClick={() => navigate(makePath('softwareComponents.main'))}
          active={location.pathname.includes(makePath('softwareComponents.main'))}
        >
          <SoftwareCatalogIcon />
          <Label>{t('navigation.SoftwareCatalog')}</Label>
        </MenuItem>
      )}

      {AdministrationPermissions.read(user) && (
        <MenuItem
          onClick={() => navigate(makePath('users.main'))}
          active={location.pathname.includes(makePath('users.main'))}
        >
          <AdministrationIcon />
          <Label>{t('navigation.Administration')}</Label>
        </MenuItem>
      )}
    </MenuWrapper>
  )
}

export default Menu
