import React from 'react'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import { PriorityHigh } from '@mui/icons-material'

export const Chip = styled.div`
  align-items: center;
  background: ${props => props.bg};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 26px;
  width: 26px;
`

const FailChip = () => {
  const theme = useTheme()
  return (
    <Chip bg={theme.palette.errorLight.main}>
      <PriorityHigh sx={{ color: theme.palette.errorLight.contrastText }} />
    </Chip>
  )
}

export default FailChip
