import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import MUIBreadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import { history } from '@Core/Redux/Store'
import styled from 'styled-components'
import * as R from 'ramda'

const BreadcrumbsText = styled(Typography)`
  font-size: 1.2rem !important;
  font-weight: 300 !important;
`

const BreadcrumbsLink = styled(Link)`
  cursor: pointer;
  font-size: 1.2rem !important;
  font-weight: 300 !important;
`

const Breadcrumbs = ({ items }) => {
  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      {items.map((item, index) => {
        return R.ifElse(
          R.identity,
          R.always(
            <BreadcrumbsText key={`item-${index}`} color="textPrimary">
              {item.label}
            </BreadcrumbsText>,
          ),
          R.always(
            <BreadcrumbsLink
              key={`item-${index}`}
              style={{ cursor: 'pointer' }}
              onClick={() => history.push(item.path)}
            >
              {item.label}
            </BreadcrumbsLink>,
          ),
        )(index === items.length - 1)
      })}
    </MUIBreadcrumbs>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
}

export default Breadcrumbs
