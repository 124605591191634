import PropTypes from 'prop-types'

export const CampaignInfoType = PropTypes.shape({
    assetType: PropTypes.string,
    bundleId: PropTypes.string,
    bundleIncrementalVersion: PropTypes.string,
    bundleVersion: PropTypes.string,
    deviceIds: PropTypes.arrayOf(PropTypes.string),
    deviceType: PropTypes.string,
    domain: PropTypes.string,
    failedDeviceIds: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string,
    options: PropTypes.shape({
      forceUpdate: PropTypes.bool,
      wipeSettings: PropTypes.bool,
      wipeApplicationData: PropTypes.bool,
    }),
    queryString: PropTypes.string,
    ring: PropTypes.string,
})
