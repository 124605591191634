import dayjs from 'dayjs'
import { isNil } from 'ramda'
import getStorage from '../Services/Storage'

const duration = require('dayjs/plugin/duration')
const utc = require('dayjs/plugin/utc')
const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(relativeTime)

const storageService = getStorage()

export const datetime = (...args) =>
  storageService.get('utcEnabled', false)
    ? dayjs(...args).utc()
    : dayjs(...args)

export const timeRangeToMinutes = (value, unit) => {
  if (isNil(value)) return value
  switch (unit) {
    case 'm':
     return value 
    case 'h':
     return value * 60
    case 'd':
     return value * 60 * 24
    case 'w':
     return value * 60 * 24 * 7
    default:
     return value 
      
  }
}

export const minutesToTimeRange = (value, unit) => {
  if (isNil(value)) return value
  switch (unit) {
    case 'm':
     return value 
    case 'h':
     return value / 60
    case 'd':
     return value / (60 * 24)
    case 'w':
     return value / (60 * 24 * 7)
    default:
     return value 
      
  }
}
