import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useCampaignQuery } from '@Campaigns/Services/Api'
import BaseLayout from '@Common/Layouts/BaseLayout'
import { useBreadcrumbs } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import { withLoader } from '@Common/Utils/HOF'
import CampaignExecutionsList from '@Campaigns/Components/CampaignExecutionsList'

const CampaignDetailExecutionsView = () => {
  const { t } = useTranslation()
  const { campaignId } = useParams()
  const { data: campaign, isFetching: isFetchingCampaign } = useCampaignQuery(campaignId)

  useBreadcrumbs([
    { label: t('navigation.Campaigns'), path: makePath('campaigns.list') },
    { label: campaign?.campaignInfo?.name },
  ], [campaign?.campaignInfo?.name])
  const navigate = useNavigate()

  const handleChange = (_, tab) => {
    if (tab === 0) navigate(makePath('campaigns.detail', { campaignId }))
  }

  return (
    <BaseLayout>
      <Tabs value={1} onChange={handleChange}>
        <Tab label={t('campaigns:ui.CampaignDetails')} />
        <Tab label={t('campaigns:ui.ExecutionsList')} />
      </Tabs>
      {withLoader(() => (
        <CampaignExecutionsList campaign={campaign} />
      ), isFetchingCampaign)}
    </BaseLayout>
  )
}

export default CampaignDetailExecutionsView
