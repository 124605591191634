import { Link as MuiLink } from '@mui/material'
import { useDeviceCardinalityQuery, useDevicesQuery } from 'Vehicles/Services/Api/Devices'
import { defaultTo, values, filter, compose, not, either, isEmpty, isNil } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Aws'
import Link from '@Common/Components/Link'
import BaseLayout from '@Common/Layouts/BaseLayout'
import { useBreadcrumbs, useDebounce, useForm } from '@Common/Utils/Hooks'
import Logger from '@Common/Utils/Logger'
import { operatorToAwsQuery } from '@Common/Utils/Operators'
import { makePath } from '@Config'
import { useLazyBundlesQuery } from '@SoftwareCatalog/Services/Api'
import VehiclesFilterForm from '@Vehicles/Forms/VehiclesFilterForm'
import Device from '@Vehicles/Models/Device'
import { history } from '@Core/Redux/Store'
import { readFromStorage, storeInStorage } from '@Common/Utils/Storage'

const LIST_DISPLAY = [
  'thingName',
  'attributes.vin',
  'attributes.type',
  'attributes.domain',
  'attributes.ring',
  'attributes.assetType',
  'shadow.reported.bundleVersion',
  'shadow.reported.bundleConsistency',
]

const FILTERS_STORAGE_KEY = 'filters-vehicles'

const VehiclesListView = () => {
  const { t } = useTranslation()
  const [fetchBundles] = useLazyBundlesQuery()

  useBreadcrumbs([{ label: t('navigation.Home'), path: makePath('home') }, { label: t('navigation.Vehicles') }])

  const {
    fields: filterFields,
    setField: setFilterField,
    setFields: setFilterFields,
  } = useForm(readFromStorage(FILTERS_STORAGE_KEY, {
    vin: '',
    domain: null,
    ring: null,
    assetType: null,
    type: null,
    bundleId: null,
    bundleToId: null,
    bundleIncrementalVersion: null,
    bundleToIncrementalVersion: null,
    bundleOperator: null,
  }))
  const wrappedSetFilterField = (field) => {
    return field === 'bundle'
      ? (evt, bundle, operator, toValue) =>
          setFilterFields({
            ...filterFields,
            [toValue ? 'bundleToId' : 'bundleId']: evt.target.value,
            [toValue ? 'bundleToIncrementalVersion' : 'bundleIncrementalVersion']: bundle?.bundleInfo.bundleIncrementalVersion,
            bundleOperator: operator,
          })
      : setFilterField(field)
  }
  const debouncedFilterFields = useDebounce(filterFields)
  const handleFilterForm = React.useCallback(
    (handleClose) => {
      return (
        <VehiclesFilterForm
          onClose={handleClose}
          fields={filterFields}
          setField={wrappedSetFilterField}
          onReset={() => setFilterFields({})}
          onSave={() => storeInStorage(FILTERS_STORAGE_KEY, filterFields)}
        />
      )
    },
    [VehiclesFilterForm, filterFields, setFilterField],
  )

  const qsAdditions = React.useMemo(() => {
    const conditions = [`attributes.domain:${debouncedFilterFields.domain || '*'}`]
    if (debouncedFilterFields.vin) conditions.push(`attributes.vin:${debouncedFilterFields.vin}*`)
    if (debouncedFilterFields.ring) conditions.push(`attributes.ring:${debouncedFilterFields.ring}`)
    if (debouncedFilterFields.assetType) conditions.push(`attributes.assetType:${debouncedFilterFields.assetType}`)
    if (debouncedFilterFields.type) conditions.push(`attributes.type:${debouncedFilterFields.type}`)
    if (debouncedFilterFields.bundleId && (debouncedFilterFields.bundleOperator !== 'between' || debouncedFilterFields.bundleToId))
      conditions.push(
        operatorToAwsQuery(
          'shadow.reported.bundleIncrementalVersion',
          debouncedFilterFields.bundleOperator,
          debouncedFilterFields.bundleIncrementalVersion,
          debouncedFilterFields.bundleToIncrementalVersion,
        ),
      )
    return {
      queryString: conditions.join(' AND '),
    }
  }, [
    debouncedFilterFields.vin,
    debouncedFilterFields.ring,
    debouncedFilterFields.assetType,
    debouncedFilterFields.type,
    debouncedFilterFields.bundleId,
    debouncedFilterFields.bundleToId,
    debouncedFilterFields.bundleOperator,
  ])

  const [qs, setQs] = React.useState({
    base: {
      maxResults: getDefaultListPerPage('vehicles'),
      nextToken: null,
    },
  })

  const refreshData = setQs
  const { data, isFetching } = useDevicesQuery({ ...qs.base, ...qsAdditions })
  const { data: cardinalityData, isFetching: isFetchingCardinality } = useDeviceCardinalityQuery({ ...qsAdditions })

  const goToBundle = (record) => async () => {
    let res
    try {
      const qs = {
        pageNumber: 0,
        pageSize: 1,
        orderBy: '_id',
        orderType: 'asc',
        domain: record.attributes.domain,
        ring: record.attributes.ring,
        assetType: record.attributes.assetType,
        deviceType: record.attributes.type,
        filterBy: `"bundleInfo.bundleVersion":"${record.shadow.reported.bundleVersion}"`,
      }
      res = await fetchBundles(qs)
    } catch (err) {
      Logger.error('Fetch bundles error', err)
      toast.error(t('sc:errors.FetchBundlesError'))
      return
    }

    try {
      const bundleId = res.data.response[0]._id
      history.push(makePath('bundles.detail', { bundleId }))
    } catch (err) {
      Logger.warning('The vehicle associated bundle is not present in the software catalog', err)
      toast.warn(t('sc:warnings.BundleNotPresentInSoftwareCatalog'))
    }
  }
  const FIELDS_MAPPING = React.useMemo(
    () => ({
      thingName: (record) => (
        <Link to={makePath('vehicles.detail', { deviceId: record.thingName })}>{record.thingName}</Link>
      ),
      'attributes.vin': (record) => (
        <Link to={makePath('vehicles.detail', { deviceId: record.thingName })}>{record.attributes.vin}</Link>
      ),
      'shadow.reported.bundleVersion': (record) =>
        record.shadow?.reported?.bundleVersion ? (
          <MuiLink style={{ cursor: 'pointer' }} onClick={goToBundle(record)}>
            {record.shadow?.reported?.bundleVersion}
          </MuiLink>
        ) : null,
    }),
    [goToBundle],
  )

  return (
    <BaseLayout>
      <DataTable
        name="vehicles"
        data={defaultTo([], data?.things)}
        refreshData={refreshData}
        nextToken={data?.nextToken}
        qs={qs}
        dataCount={-1}
        cardinality={cardinalityData?.cardinality}
        model={Device}
        listDisplay={LIST_DISPLAY}
        fieldsMapping={FIELDS_MAPPING}
        loading={isFetching}
        loadingCardinality={isFetchingCardinality}
        filterForm={handleFilterForm}
        filterFormActive={values(filter(compose(not, either(isEmpty, isNil)), filterFields)).length}
      />
    </BaseLayout>
  )
}

export default VehiclesListView
