import styled from 'styled-components'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import MuiSettingsIcon from '@mui/icons-material/Settings'
import TrueChip from '../TrueChip'
import { CSVLink } from 'react-csv'
// import ClearIcon from '@mui/icons-material/Clear'
import TableRow from '@mui/material/TableRow'

export const Toolbar = styled.div`
  align-items: center;
  display: flex;
  background: ${props => props.bg};
  box-sizing: border-box;
  color: ${props => props.color};
  justify-content: space-between;
  padding: 0.5rem 1rem;

  div {
    font-weight: bold;
    font-size: 0.9rem !important;
  }

  width: 100%;
`
export const BulkActions = styled.div`
  align-items: flex-end;
  display: flex;
  padding: 1rem 0;
`

export const BulkAndChildren = styled.div`
  align-items: flex-end;
  display: flex;
  padding: 1rem 0;
`

export const SelectAll = styled(Typography)`
  border-left: 1px solid #eee;
  margin-left: 1rem !important;
  padding-left: 1rem;
`

export const SelectAllLink = styled(Link)`
  cursor: pointer;
`

export const SettingsIcon = styled(MuiSettingsIcon)`
  margin-left: auto;
`

export const ExportLink = styled(CSVLink)`
  color: #000;
  text-decoration: none;
`

export const TrueIcon = TrueChip

export const FalseIcon = styled.span``

export const Tr = styled(TableRow)`
  ${(props) => props['data-no-border'] && '> * { border-bottom: 0px solid !important; }'};

  &:hover {
    td {
      background: ${({ theme }) => theme.palette.dataTableRowHover.main} !important;
    }
  }
`
