import React from 'react'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'
import i18next from 'i18next'

const options = [
  { value: null, label: i18next.t('common:ui.All') },
  { value: false, label: i18next.t('common:ui.False') },
  { value: true, label: i18next.t('common:ui.True') },
]

const BoolFilterDropdown = ({ value, onChange, required, error, helperText, ...other }) => {
  return (
    <Dropdown
      required={required}
      value={value}
      onChange={onChange}
      options={options}
      error={error}
      helperText={helperText}
      {...other}
    />
  )
}

BoolFilterDropdown.defaultProps = {
  required: false,
}

BoolFilterDropdown.propTypes = FieldType

export default BoolFilterDropdown
