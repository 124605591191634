import { Add } from '@mui/icons-material'
import MuiFab from '@mui/material/Fab'
import { styled as muistyled } from '@mui/system'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const StyledFab = muistyled(MuiFab)`
    bottom: ${(props) => props.bottom || '25px'};
    cursor: pointer;
    left: ${(props) => props.left || 'auto'};
    position: ${(props) => (props.className === 'absolute' ? 'absolute' : 'fixed')};
    right: ${(props) => props.right || 'auto'};
`

const Fab = ({ left, right, bottom, onClick, absolute, size, icon }) => {
  const { t } = useTranslation()
  return (
    <StyledFab
      className={absolute ? 'absolute' : ''}
      style={{ zIndex: 100 }}
      color="primary"
      aria-label={t('common:generic.add')}
      onClick={onClick}
      left={left}
      right={right}
      bottom={bottom}
      size={size}
    >
      {icon === 'add' && <Add />}
    </StyledFab>
  )
}

Fab.defaultProps = {
  size: 'medium',
  icon: 'add',
}

Fab.propTypes = {
  left: PropTypes.string,
  right: PropTypes.string,
  bottom: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  absolute: PropTypes.bool,
  size: PropTypes.string,
  icon: PropTypes.string,
}

export default Fab
