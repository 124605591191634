import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DeviceTypeDropdown from '@Common/Components/DeviceTypeDropdown'
import DomainDropdown from '@Common/Components/DomainDropdown'
import RingDropdown from '@Common/Components/RingDropdown'
import FiltersForm from '@Common/Forms/FiltersForm'
import { setStateFromEvent } from '@Common/Utils/Events'
import TextField from '@Common/Components/TextField'

const SoftwareComponentsFilterForm = ({ onClose, fields, setField, onReset, onSave }) => {
  const { t } = useTranslation()
  return (
    <FiltersForm title={t('sc:ui.FilterSoftwareComponents')} onClose={onClose} onReset={onReset} onSave={onSave}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <DomainDropdown value={fields.domain} onChange={setStateFromEvent(setField('domain'))} fullWidth />
        </Grid>
        <Grid item md={6}>
          <RingDropdown value={fields.ring} onChange={setStateFromEvent(setField('ring'))} fullWidth />
        </Grid>
        <Grid item md={6}>
          <DeviceTypeDropdown value={fields.type} onChange={setStateFromEvent(setField('type'))} fullWidth />
        </Grid>
        <Grid item md={6}>
          <TextField label={t('sc:fields.name')} value={fields.name} onChange={setStateFromEvent(setField('name'))} fullWidth />
        </Grid>
        <Grid item md={6}>
          <TextField label={t('sc:fields.version')} value={fields.version} onChange={setStateFromEvent(setField('version'))} fullWidth />
        </Grid>
      </Grid>
    </FiltersForm>
  )
}

SoftwareComponentsFilterForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  setField: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default SoftwareComponentsFilterForm
