import { always, anyPass } from 'ramda'

import { hasRole, ROLES } from '@Common/Utils/Permissions'

const CampaignsPermissions = {
  read: always(true),
  create: anyPass([hasRole(ROLES.ADMIN), hasRole(ROLES.MANAGER)]),
  cancel: anyPass([hasRole(ROLES.ADMIN), hasRole(ROLES.MANAGER)]),
}

export default CampaignsPermissions
