import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import config from '@Config'
import { logout } from '@Auth/Redux'
import storageFactory from '@Common/Services/Storage'
import Logger from '@Common/Utils/Logger'
import * as R from 'ramda'
import { refreshJwt } from '@Auth/Services/Msal'
import { history } from '@Core/Redux/Store'

const Storage = storageFactory()

let refreshTokenPromise
let refreshLocked = false // lock request retry while refreshing

const baseQuery = fetchBaseQuery({
  baseUrl: config.apiBasePath,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.status === 401 && args !== 'current-user') {
    Logger.debug('API 401 detected', args)
    if (!refreshLocked) {
      Logger.debug(
        'API no one is refreshing -> acquiring lock and actually refreshing now',
        args,
      )
      refreshLocked = true // acquire lock
      let resolveRefreshTokenPromise
      refreshTokenPromise = new Promise((resolve) => {
        resolveRefreshTokenPromise = resolve
      })
      let token
      try {
        token = await refreshJwt()
      } catch (e) {
        Logger.error('TOKEN ERROR', e)
      }

      if (token) {
        Logger.debug('API token refreshed', args)
        Logger.debug('API credentials set', args)
        Logger.debug('API releasing refresh lock', args)
        resolveRefreshTokenPromise()
        refreshLocked = false
      } else {
        Logger.debug('API token refreshed error', token, args)
        refreshLocked = false
        api.dispatch(logout())
        Storage.remove('refreshToken')
        history.push(config.urls.login)
        resolveRefreshTokenPromise()
        return result
      }
    }

    Logger.debug('API would like to run again request', args)
    if (refreshLocked) {
      Logger.debug('API wait, someone is refreshing token', args)
      await refreshTokenPromise
      Logger.debug('API wait for other refreshing: DONE', args)
    }

    Logger.debug('API actually running again request', args)
    // retry the initial query
    result = await baseQuery(args, api, extraOptions)
    Logger.debug('API request retry: DONE', args)
  }

  return result
}

// https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'AssetType',
    'Bundle',
    'Campaign',
    'CampaignExecution',
    'CampaignExecutionDailyReport',
    'CampaignDailyReport',
    'CurrentUser',
    'Device',
    'Notification',
    'Statistics',
    'User',
  ],
  endpoints: () => ({}),
})

export const awsApiQueryString = (qs) => {
  // const { maxResults, nextToken, orderby, ordertype, ...rest } = qs
  const { maxResults, nextToken, ...rest } = qs
  const more = Object.keys(rest)
    .filter(
      R.compose(R.not, R.either(R.isEmpty, R.isNil), R.flip(R.prop)(rest)),
    )
    .map((k) => `${k}=${encodeURIComponent(rest[k])}`)

  return (
    `maxResults=${maxResults}${nextToken ? `&nextToken=${encodeURIComponent(nextToken)}` : ''}` +
    (more.length ? '&' + more.join('&') : '')
  )
}

export const apiQueryString = (qs) => {
  const { pageNumber, pageSize, orderBy, orderType, ...rest } = qs
  const more = Object.keys(rest)
    .filter(
      R.compose(R.not, R.either(R.isEmpty, R.isNil), R.flip(R.prop)(rest)),
    )
    .map((k) => `${k}=${encodeURIComponent(rest[k])}`)

  return (
    `pageNumber=${pageNumber}&pageSize=${pageSize}&orderBy="${orderBy}":${orderType === 'asc' ? 1 : -1}` +
    (more.length ? '&' + more.join('&') : '')
  )
}

export const apiQueryStringCustom = (qs) => {
  const items = Object.keys(qs)
    .filter(R.compose(R.not, R.either(R.isEmpty, R.isNil), R.flip(R.prop)(qs)))
    .map((k) => `${k}=${encodeURIComponent(qs[k])}`)

  return items.length ? '&' + items.join('&') : ''
}

export const apiList = (res) => ({
  ...res,
  orData: R.defaultTo([], res.data),
  data: res.data?.response || [],
})

export const apiDictFromList = (res) => ({
  ...res,
  orData: res.data,
  data: res.data?.response[0] || {},
})
