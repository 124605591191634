import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CampaignType } from '@Campaigns/Models/Campaign'
import Logger from '@Common/Utils/Logger'
import { floorWithUnit, round } from '@Common/Utils/Numbers'
import { selectThemeMode } from '@Core/Redux/Ui'
import { defaultTo } from 'ramda'

const StatusPieChart = ({ campaign }) => {
  const { t } = useTranslation()
  const themeMode = useSelector(selectThemeMode)
  const status = campaign.campaignStatus
  const isSnapshot = campaign.job.jobDefinition.targetSelection === 'SNAPSHOT'

  const data = [
    { name: t('campaigns:ui.Canceled'), value: status.numberOfCanceledThings, color: '#00A2FF' },
    { name: t('campaigns:ui.Failed'), value: status.numberOfFailedThings, color: '#929292' },
    { name: t('campaigns:ui.InProgress'), value: status.numberOfInProgressThings, color: '#EF59F1' },
    { name: t('campaigns:ui.Queued'), value: status.numberOfQueuedThings, color: '#6E17D0' },
    { name: t('campaigns:ui.Rejected'), value: status.numberOfRejectedThings, color: '#FF2600' },
    { name: t('campaigns:ui.Removed'), value: status.numberOfRemovedThings, color: '#F8BA00' },
    { name: t('campaigns:ui.Succeeded'), value: status.numberOfSucceededThings, color: '#61D836' },
    { name: t('campaigns:ui.TimedOut'), value: defaultTo(0, status.numberOfTimedOutThings), color: '#17D0B8' },
  ]
  if (isSnapshot) {
    const pending =
      campaign.group.thingsCardinality -
      (status.numberOfCanceledThings +
        status.numberOfFailedThings +
        status.numberOfInProgressThings +
        status.numberOfQueuedThings +
        status.numberOfRejectedThings +
        status.numberOfRemovedThings +
        status.numberOfSucceededThings +
        defaultTo(0, status.numberOfTimedOutThings))

    data.push({ name: t('campaigns:ui.Pending'), value: pending, color: '#D41876' })
  }
  const tot = data.reduce((acc, curr) => acc + curr.value, 0)

  Logger.debug('Campaign Pie Chart', 'data', data, 'tot', tot) // eslint-disable-line

  const options = {
    chart: {
      backgroundColor: themeMode === 'light' ? '#fff' : '#202020',
      type: 'pie',
    },
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: floorWithUnit(campaign.group.thingsCardinality, 3),
      style: {
        fontSize: '2.5rem',
        color: themeMode === 'light' ? '#666' : '#aaa',
      },
      align: 'center',
      verticalAlign: 'middle',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.custom} ({point.percentage:.1f}%)</b>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        center: ['50%', '50%'],
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.name}: {point.y}%',
          color: themeMode === 'light' ? '#666' : '#aaa',
          style: { textOutline: 'none' },
        },
        showInLegend: false,
      },
    },
    legend: {
      itemStyle: {
        color: themeMode === 'light' ? '#666' : '#aaa',
      },
    },
    series: [
      {
        type: 'pie',
        name: t('campaigns:ui.Executions'),
        innerSize: '75%',
        data: data.map((item) => ({
          name: item.name,
          color: item.color,
          y: round((item.value * 100) / tot, 1),
          custom: item.value,
        })),
      },
    ],
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

StatusPieChart.propTypes = {
  campaign: CampaignType,
}

export default React.memo(StatusPieChart)
