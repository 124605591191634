import { Step, StepButton, StepLabel, Stepper } from '@mui/material'
import { prop } from 'ramda'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

import { useCreateCampaignMutation } from '@Campaigns/Services/Api'
import { isContinuousCampaign } from '@Campaigns/Utils'
import Loader from '@Common/Components/Loader'
import BaseLayout from '@Common/Layouts/BaseLayout'
import { request } from '@Common/Utils/Api'
import { datetime } from '@Common/Utils/Datetime'
import { useBreadcrumbs, useForm } from '@Common/Utils/Hooks'
import Logger from '@Common/Utils/Logger'
import { operatorToAwsQuery } from '@Common/Utils/Operators'
import { makePath } from '@Config'

import StepConfirm from './StepConfirm'
import StepDeviceSelection from './StepDeviceSelection'
import StepInfo from './StepInfo'
import StepJobConfiguration from './StepJobConfiguration'

const Container = styled.div`
  padding: 1rem 0;
`

const StepContent = styled.div`
  padding: 3rem 11% 0;
`

const CampaignCreateView = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [createCampaign] = useCreateCampaignMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  useBreadcrumbs([
    { label: t('navigation.Campaigns'), path: makePath('campaigns.list') },
    { label: t('campaigns:ui.Create') },
  ])

  const steps = [
    t('campaigns:ui.Info'),
    t('campaigns:ui.VehicleSelection'),
    t('campaigns:ui.JobConfiguration'),
    t('campaigns:ui.Finish'),
  ]

  // info
  const {
    fields: infoFields,
    setField: setInfoField,
    setFields: setInfoFields,
    errors: infoErrors,
    setErrors: setInfoErrors,
  } = useForm({
    name: '',
    domain: null,
    ring: null,
    assetType: null,
    deviceType: null,
    queryStringBundleId: null,
    queryStringBundleToId: null,
    queryStringBundleOp: null,
    queryStringBundleVersion: null,
    queryStringBundleToVersion: null,
    queryStringBundleIncrementalVersion: null,
    queryStringBundleToIncrementalVersion: null,
    bundleId: '',
    bundleVersion: '',
    bundleIncrementalVersion: '',
    forceUpdate: false,
    wipeSettings: false,
    wipeApplicationData: false,
  })

  const queryString = React.useMemo(() => {
    const conditions = [`attributes.domain:${infoFields.domain}`]
    if (infoFields.ring) conditions.push(`attributes.ring:${infoFields.ring}`)
    if (infoFields.assetType) conditions.push(`attributes.assetType:${infoFields.assetType}`)
    if (infoFields.deviceType) conditions.push(`attributes.type:${infoFields.deviceType}`)
    if (infoFields.queryStringBundleIncrementalVersion)
      conditions.push(
        operatorToAwsQuery(
          'shadow.reported.bundleIncrementalVersion',
          infoFields.queryStringBundleOp,
          infoFields.queryStringBundleIncrementalVersion,
          infoFields.queryStringBundleToIncrementalVersion,
        ),
      )
    return conditions.join(' AND ')
  }, [infoFields])

  // device selection
  const [deviceIds, setDeviceIds] = useState([])
  const [cardinality, setCardinality] = useState(null)
  const [manualVinSelection, setManualVinSelection] = useState(false)

  const totDevices = deviceIds.length || cardinality

  // csv file upload
  const [csvData, setCsvData] = useState({ name: null, lines: null })
  const [enableCsvUpload, setEnableCsvUpload] = useState(false)

  // job definition
  const {
    fields: jobFields,
    setField: setJobField,
    setFields: setJobFields,
    errors: jobErrors,
    setErrors: setJobErrors,
  } = useForm({
    timeoutInMinutes: 4320,
    targetSelection: 'SNAPSHOT',

    // abort job configuration
    abortAllEnabled: false,
    abortAllAction: null,
    abortAllMinNumberOfExecutedThingsPercentage: null,
    abortAllThresholdPercentage: null,
    abortFailedEnabled: false,
    abortFailedAction: null,
    abortFailedMinNumberOfExecutedThingsPercentage: null,
    abortFailedThresholdPercentage: null,
    abortRejectedEnabled: false,
    abortRejectedAction: null,
    abortRejectedMinNumberOfExecutedThingsPercentage: null,
    abortRejectedThresholdPercentage: null,
    abortTimedOutEnabled: false,
    abortTimedOutAction: null,
    abortTimedOutMinNumberOfExecutedThingsPercentage: null,
    abortTimedOutThresholdPercentage: null,

    // retry job configuration
    retryAllEnabled: false,
    retryAllNumberOfRetries: null,
    retryFailedEnabled: false,
    retryFailedNumberOfRetries: null,
    retryTimedOutEnabled: false,
    retryTimedOutNumberOfRetries: null,

    // rollout job configuration
    rolloutType: null,
    rolloutMaximumPerMinute: null,
    rolloutBaseRatePerMinute: null,
    rolloutIncrementFactor: null,
    rolloutNumberOfNotifiedThings: null,
    rolloutNumberOfSucceededThings: null,

    // Scheduling config
    enableScheduling: false,
    startTime: datetime().add(2, 'hour'),

    // timeout job configuration
    timeoutInProgressTimeoutInMinutes: null,
  })

  const [useAbortAwsDefaults, setUseAbortAwsDefaults] = useState(true)
  const [useRetryAwsDefaults, setUseRetryAwsDefaults] = useState(true)
  const [useRolloutAwsDefaults, setUseRolloutAwsDefaults] = useState(true)
  const [useTimeoutAwsDefaults, setUseTimeoutAwsDefaults] = useState(true)
  const [expRolloutNumberOf, setExpRolloutNumberOf] = useState('NOTIFIED') // 'NOTIFIED'|'SUCCEEDED'

  const handleCancel = () => {
    navigate(makePath('campaigns.list'))
  }

  const handleSubmit = async () => {
    const payload = buildPayload()
    Logger.debug('Confirm campaign creation submission, payload:', payload)
    setIsSubmitting(true)
    const res = await request(
      createCampaign,
      [payload],
      t('campaigns:success.CampaignCreated', { campaign: payload.campaignInfo.name }),
      'campaigns:errors.CreateCampaignFailure',
    )
    setIsSubmitting(false)

    if (res.isSuccessful) {
      navigate(makePath('campaigns.list'))
    }
  }

  const buildPayload = () => ({
    campaignInfo: {
      name: infoFields.name,
      queryString: queryString, // e.g. "attributes.domain:\"CV\" AND attributes.type:\"PCM3\" AND attributes.assetType:\"DAILY21\" AND attributes.ring:\"RELEASE\""
      ...(manualVinSelection && deviceIds.length > 0 && { deviceIds: deviceIds.map(prop('thingId')) }), // List of deviceIds (thingNames). Max deviceIds size is 100
      ...(enableCsvUpload && { fileName: csvData.name }),
      domain: infoFields.domain, // "CV"
      ring: infoFields.ring, // "RELEASE" | "STAGING" | "TEST" | "DEV"
      assetType: infoFields.assetType, // Asset type (e.g. "DAILY21")
      deviceType: infoFields.deviceType, // "PCM3" | "IHP"
      bundleId: infoFields.bundleId, // Bundle._id
      bundleVersion: infoFields.bundleVersion, // e.g. "2.20.99.45"
      bundleIncrementalVersion: infoFields.bundleIncrementalVersion, // e.g. "0002.0020.0099.0045"
      options: {
        forceUpdate: infoFields.forceUpdate,
        wipeSettings: infoFields.wipeSettings,
        wipeApplicationData: infoFields.wipeApplicationData,
      },
    },
    job: {
      ...(isContinuousCampaign(jobFields.targetSelection) ? {} : { timeoutInMinutes: jobFields.timeoutInMinutes }),
      timeoutInMinutes: jobFields.timeoutInMinutes,
      jobDefinition: {
        // See CreateJob https://docs.aws.amazon.com/iot/latest/apireference/API_CreateJob.html and IoT Commands SendCommand (without targets)
        ...(!useAbortAwsDefaults &&
          !isContinuousCampaign(jobFields.targetSelection) && {
            abortConfig: {
              criteriaList: [
                ...(!jobFields.abortAllEnabled
                  ? []
                  : [
                      {
                        action: jobFields.abortAllAction,
                        failureType: 'ALL',
                        minNumberOfExecutedThings: jobFields.abortAllMinNumberOfExecutedThingsPercentage
                          ? Math.ceil((jobFields.abortAllMinNumberOfExecutedThingsPercentage * totDevices) / 100)
                          : jobFields.abortAllMinNumberOfExecutedThingsPercentage,
                        thresholdPercentage: jobFields.abortAllThresholdPercentage,
                      },
                    ]),
                ...(!jobFields.abortFailedEnabled
                  ? []
                  : [
                      {
                        action: jobFields.abortFailedAction,
                        failureType: 'FAILED',
                        minNumberOfExecutedThings: jobFields.abortFailedMinNumberOfExecutedThingsPercentage
                          ? Math.ceil((jobFields.abortFailedMinNumberOfExecutedThingsPercentage * totDevices) / 100)
                          : jobFields.abortFailedMinNumberOfExecutedThingsPercentage,
                        thresholdPercentage: jobFields.abortFailedThresholdPercentage,
                      },
                    ]),
                ...(!jobFields.abortRejectedEnabled
                  ? []
                  : [
                      {
                        action: jobFields.abortRejectedAction,
                        failureType: 'REJECTED',
                        minNumberOfExecutedThings: jobFields.abortRejectedMinNumberOfExecutedThingsPercentage
                          ? Math.ceil((jobFields.abortRejectedMinNumberOfExecutedThingsPercentage * totDevices) / 100)
                          : jobFields.abortRejectedMinNumberOfExecutedThingsPercentage,
                        thresholdPercentage: jobFields.abortRejectedThresholdPercentage,
                      },
                    ]),
                ...(!jobFields.abortTimedOutEnabled
                  ? []
                  : [
                      {
                        action: jobFields.abortTimedOutAction,
                        failureType: 'TIMED_OUT',
                        minNumberOfExecutedThings: jobFields.abortTimedOutMinNumberOfExecutedThingsPercentage
                          ? Math.ceil((jobFields.abortTimedOutMinNumberOfExecutedThingsPercentage * totDevices) / 100)
                          : jobFields.abortTimedOutMinNumberOfExecutedThingsPercentage,
                        thresholdPercentage: jobFields.abortTimedOutThresholdPercentage,
                      },
                    ]),
              ],
            },
          }),
        ...(!useRetryAwsDefaults && {
          jobExecutionsRetryConfig: {
            criteriaList: [
              ...(!jobFields.retryFailedEnabled
                ? []
                : [
                    {
                      failureType: 'FAILED',
                      numberOfRetries: jobFields.retryFailedNumberOfRetries,
                    },
                  ]),
              ...(!jobFields.retryTimedOutEnabled
                ? []
                : [
                    {
                      failureType: 'TIMED_OUT',
                      numberOfRetries: jobFields.retryTimedOutNumberOfRetries,
                    },
                  ]),
              ...(!jobFields.retryAllEnabled
                ? []
                : [
                    {
                      failureType: 'ALL',
                      numberOfRetries: jobFields.retryAllNumberOfRetries,
                    },
                  ]),
            ],
          },
        }),
        ...(!useRolloutAwsDefaults &&
          !isContinuousCampaign(jobFields.targetSelection) && {
            jobExecutionsRolloutConfig: {
              ...(jobFields.rolloutType === 'EXP' && {
                exponentialRate: {
                  baseRatePerMinute: jobFields.rolloutBaseRatePerMinute,
                  incrementFactor: jobFields.rolloutIncrementFactor,
                  rateIncreaseCriteria: {
                    ...(expRolloutNumberOf === 'NOTIFIED' && {
                      numberOfNotifiedThings: jobFields.rolloutNumberOfNotifiedThings,
                    }),
                    ...(expRolloutNumberOf === 'SUCCEEDED' && {
                      numberOfSucceededThings: jobFields.rolloutNumberOfSucceededThings,
                    }),
                  },
                },
              }),
              maximumPerMinute: jobFields.rolloutMaximumPerMinute,
            },
          }),
        ...(jobFields.enableScheduling && {
          schedulingConfig: {
            startTime: jobFields.startTime?.format('YYYY-MM-DD HH:mm'),
          },
        }),
        targetSelection: jobFields.targetSelection, // "SNAPSHOT" | "CONTINUOUS"
        ...(!useTimeoutAwsDefaults && {
          timeoutConfig: {
            inProgressTimeoutInMinutes: jobFields.timeoutInProgressTimeoutInMinutes,
          },
        }),
      },
    },
  })

  Logger.debug('Cardinality', cardinality)
  Logger.debug('Tot devices', totDevices)

  return (
    <BaseLayout>
      <Container>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={() => setActiveStep(index)}>
                <StepLabel>{label}</StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <StepContent>
          {activeStep === 0 && (
            <StepInfo
              fields={infoFields}
              setField={setInfoField}
              setFields={setInfoFields}
              errors={infoErrors}
              setErrors={setInfoErrors}
              onCancel={handleCancel}
              onNext={() => setActiveStep(1)}
            />
          )}
          {activeStep === 1 && (
            <StepDeviceSelection
              queryString={queryString}
              fields={infoFields}
              deviceIds={deviceIds}
              setDeviceIds={setDeviceIds}
              setCardinality={setCardinality}
              manualVinSelection={manualVinSelection}
              setManualVinSelection={setManualVinSelection}
              csvData={csvData}
              setCsvData={setCsvData}
              enableCsvUpload={enableCsvUpload}
              setEnableCsvUpload={setEnableCsvUpload}
              onCancel={handleCancel}
              onNext={() => setActiveStep(2)}
            />
          )}
          {activeStep === 2 && (
            <StepJobConfiguration
              fields={jobFields}
              setField={setJobField}
              setFields={setJobFields}
              errors={jobErrors}
              setErrors={setJobErrors}
              onCancel={handleCancel}
              onNext={() => setActiveStep(3)}
              targetSelection={jobFields.targetSelection}
              totDevices={totDevices}
              useAbortAwsDefaults={useAbortAwsDefaults}
              setUseAbortAwsDefaults={setUseAbortAwsDefaults}
              useRetryAwsDefaults={useRetryAwsDefaults}
              setUseRetryAwsDefaults={setUseRetryAwsDefaults}
              useRolloutAwsDefaults={useRolloutAwsDefaults}
              setUseRolloutAwsDefaults={setUseRolloutAwsDefaults}
              useTimeoutAwsDefaults={useTimeoutAwsDefaults}
              setUseTimeoutAwsDefaults={setUseTimeoutAwsDefaults}
              expRolloutNumberOf={expRolloutNumberOf}
              setExpRolloutNumberOf={setExpRolloutNumberOf}
            />
          )}
          {activeStep === 3 && (
            <StepConfirm
              infoFields={infoFields}
              jobFields={jobFields}
              deviceIds={deviceIds}
              cardinality={cardinality}
              useAbortAwsDefaults={useAbortAwsDefaults}
              useRetryAwsDefaults={useRetryAwsDefaults}
              useRolloutAwsDefaults={useRolloutAwsDefaults}
              useTimeoutAwsDefaults={useTimeoutAwsDefaults}
              expRolloutNumberOf={expRolloutNumberOf}
              enableCsvUpload={enableCsvUpload}
              csvLines={csvData.lines}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
            />
          )}
        </StepContent>
        {isSubmitting && <Loader overlay />}
      </Container>
    </BaseLayout>
  )
}

export default CampaignCreateView
