import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.palette.content.main};
    margin: 0;
    padding: 0;
    font-family:"Avenir", "Roboto", "Helvetica", "Arial", sans-serif;
  }
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .datatable.resizable-active th,
  .datatable.resizable-active td {
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .datatable.resizable-active th span {
    position: static;
  }

  .datatable.resizable-active th.th-col-name svg {
    position: absolute;
    right: 0;
    background: #fff !important;
    margin-left: 0;
    padding-right: 4px;
    margin-right: 0;
    padding-left: 4px;
  }

  .datatable .resizer {
      border-right: 2px solid rgba(0, 0, 0, .2);
      height: 50%;
      position: absolute;
      top: 25%;
      right: 0;
      width: 5px;
      cursor: col-resize;
      user-select: none;
  }

  .resizer:hover,
  .resizing {
      border-right: 2px solid #A32427;
  }
`

export default GlobalStyle
