import * as R from 'ramda'

import { checkRequired } from '@Common/Utils/Validation'

import i18n from '../../../i18n'

export const validate = (fields, setErrors, errors) => {
  const err = {}

  // require at least one checked criteria
  if (!fields.retryAllEnabled && !fields.retryFailedEnabled && !fields.retryTimedOutEnabled) {
    err['retryRequired'] = i18n.t('campaigns:errors.SelectAtLeastOneCriterion')
  }

  const required = ['retryFailedNumberOfRetries', 'retryAllNumberOfRetries', 'retryTimedOutNumberOfRetries']
  checkRequired(err, required, fields)

  setErrors({ ...errors, ...err })

  return R.isEmpty(err)
}

export const clearErrors = (setErrors, errors) => {
  setErrors({
    ...errors,
    retryRequired: null,
    retryFailedNumberOfRetries: null,
    retryAllNumberOfRetries: null,
    retryTimedOutNumberOfRetries: null,
  })
}
