import PropTypes from 'prop-types'
import { always, cond, equals, pipe, prop, T } from 'ramda'
import TrueChip from '@Common/Components/TrueChip'
import FailChip from '@Common/Components/FailChip'
import { withTooltip } from '@Common/Utils/HOF'
import i18next from 'i18next'

const BundleStatus = pipe(
  prop('status'),
  cond([
    [equals('ERROR'), always(withTooltip(<FailChip />, i18next.t('sc:ui.AtLeastOneScIsNotPresent'), true))],
    [equals('OK'), always(withTooltip(<TrueChip />, i18next.t('sc:ui.AllScArePresent'), true))],
    [T, always(null)],
  ])
)

BundleStatus.propTypes = {
  status: PropTypes.string,
}

export default BundleStatus
