import { Button, capitalize } from '@mui/material'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import { defaultTo, prop, sortBy } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { CampaignType } from '@Campaigns/Models/Campaign'
import { useCampaignDailyReportsQuery } from '@Campaigns/Services/Api'
import Box from '@Common/Components/Box'
import Dropdown from '@Common/Components/Dropdown'
import { FormControl } from '@Common/Components/Forms'
import { datetime } from '@Common/Utils/Datetime'
import { setStateFromEvent } from '@Common/Utils/Events'
import Logger from '@Common/Utils/Logger'
import { round } from '@Common/Utils/Numbers'
import { selectThemeMode } from '@Core/Redux/Ui'
import { chartBackground, chartCommonOptions, chartYAxisCommonOptions } from '@Dashboard/Utils/Charts'
import { withMissingData } from '@Common/Utils/HOF'

const COLORS_MAP = {
  succeeded: '#FF2600',
  canceled: '#ff9900',
  failed: '#929292',
  progress: '#789532',
  queued: '#D41876',
  rejected: '#61D836',
  removed: '#F8BA00',
  timedout: '#7CB5EC',
}

const diffFromPrev = (key, idx, data) => (idx > 0 ? Math.max(data[idx][key] - data[idx - 1][key], 0) : data[idx][key])
const perc = (num, tot) => round((100 * num) / tot, 1)

const StatusHistoryTimeChart = ({ campaign }) => {
  const { t } = useTranslation()
  const themeMode = useSelector(selectThemeMode)
  const { data: reports, isFetching } = useCampaignDailyReportsQuery(campaign._id)
  const [trendUnit, setTrendUnit] = React.useState('percentage')
  const useTrendPercentage = trendUnit === 'percentage'

  const units = ['absoluteNumber', 'percentage']
  const statuses = ['succeeded', 'canceled', 'failed', 'progress', 'queued', 'rejected', 'removed', 'timedout']
  const interestingStatuses = ['succeeded', 'canceled', 'failed', 'rejected', 'timedout']

  Logger.debug('Campaign Reports', reports, defaultTo([], reports?.jobs)) // eslint-disable-line
  const tot = campaign.group.thingsCardinality

  let data = defaultTo(
    [],
    sortBy(prop('campaignDailyReportTimestamp'), defaultTo([], reports?.jobs)).map((j) => ({
      name: datetime(j.campaignDailyReportTimestamp * 1000).format('YYYY-MM-DD'),
      succeeded: j.jobProcessDetails.numberOfSucceededThings,
      trendSucceeded: perc(j.jobProcessDetails.numberOfSucceededThings, tot),
      canceled: j.jobProcessDetails.numberOfCanceledThings,
      trendCanceled: perc(j.jobProcessDetails.numberOfCanceledThings, tot),
      failed: j.jobProcessDetails.numberOfFailedThings,
      trendFailed: perc(j.jobProcessDetails.numberOfFailedThings, tot),
      progress: j.jobProcessDetails.numberOfInProgressThings,
      trendProgress: perc(j.jobProcessDetails.numberOfInProgressThings, tot),
      queued: j.jobProcessDetails.numberOfQueuedThings,
      trendQueued: perc(j.jobProcessDetails.numberOfQueuedThings, tot),
      rejected: j.jobProcessDetails.numberOfRejectedThings,
      trendRejected: perc(j.jobProcessDetails.numberOfRejectedThings, tot),
      removed: j.jobProcessDetails.numberOfRemovedThings,
      trendRemoved: perc(j.jobProcessDetails.numberOfRemovedThings, tot),
      timedout: j.jobProcessDetails.numberOfTimedOutThings,
      trendTimedout: perc(j.jobProcessDetails.numberOfTimedOutThings, tot),
    })),
  )
  const relativeData = data.map((d, key) => ({
    ...d,
    succeeded: diffFromPrev('succeeded', key, data),
    canceled: diffFromPrev('canceled', key, data),
    failed: diffFromPrev('failed', key, data),
    progress: diffFromPrev('progress', key, data),
    queued: diffFromPrev('queued', key, data),
    rejected: diffFromPrev('rejected', key, data),
    removed: diffFromPrev('removed', key, data),
    timeodout: diffFromPrev('timeodout', key, data),
  }))

  const series = []
  statuses.forEach((s) => {
    const l = `trend${capitalize(s)}`
    const p = useTrendPercentage ? `trend${capitalize(s)}` : s
    series.push({
      name: t(`campaigns:ui.${capitalize(s)}`),
      type: 'column',
      yAxis: 0,
      data: relativeData.map(prop(s)),
      color: COLORS_MAP[s],
      visible: interestingStatuses.indexOf(s) !== -1,
    })
    series.push({
      linkedTo: ':previous',
      name: t(`campaigns:ui.${l}`),
      type: 'area',
      yAxis: 1,
      data: data.map(prop(p)),
      tooltip: {
        valueSuffix: useTrendPercentage ? '%' : '',
      },
      visible: interestingStatuses.indexOf(s.replace('trend', '').toLowerCase()) !== -1,
      color: COLORS_MAP[s],
      opacity: 0.6,
    })
  })

  const options = {
    ...chartCommonOptions(themeMode),
    chart: {
      backgroundColor: chartBackground(themeMode),
      height: '450px',
      zoomType: 'x',
    },
    title: {
      style: { display: 'none' },
    },
    xAxis: [
      {
        categories: data.map(prop('name')),
        crosshair: true,
        minRange: 0.1,
      },
    ],
    yAxis: [
      chartYAxisCommonOptions(themeMode, t('dashboard:ui.DailyIncrement')),
      {
        ...chartYAxisCommonOptions(themeMode, t(`campaigns:ui.Trend${capitalize(trendUnit)}`), true),
        min: 0,
        max: useTrendPercentage ? 100 : undefined,
      },
    ],
    tooltip: {
      shared: true,
    },
    series,
  }

  return withMissingData(
    <Box direction="column">
      <FormControl>
        <Dropdown
          label={t('campaigns:ui.TrendUnit')}
          value={trendUnit}
          onChange={setStateFromEvent(setTrendUnit)}
          options={units.map((u) => ({ label: t(`campaigns:ui.${capitalize(u)}`), value: u }))}
          width="300px"
        />
      </FormControl>
      <div style={{ width: '100%' }}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <Box direction="row" justify="flex-end" width="100%" margin="1rem 0 0">
        <Button variant="contained" onClick={() => window.open(reports.campaignDailyReportUrl)}>
          {t('campaigns:ui.DownloadDailyReportCsv')}
        </Button>
      </Box>
    </Box>,
    isFetching || reports !== undefined,
  )
}

StatusHistoryTimeChart.propTypes = {
  campaign: CampaignType,
  t: PropTypes.func,
}

export default StatusHistoryTimeChart
