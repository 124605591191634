import React from 'react'
import { Route, Routes } from 'react-router-dom'

import config from '@Config'
import PageNotFoundView from '@Core/Views/PageNotFoundView'

import VehicleDetailView from './Views/VehicleDetailView'
import VehiclesListView from './Views/VehiclesListView'

/**
 * Vehicles section router
 * Conains the list and detail routes
 *
 */
export const VehiclesRouter = () => {
  return (
    <Routes>
      <Route path={config.urls.vehicles.detail} element={<VehicleDetailView />} />
      <Route path={config.urls.vehicles.main} element={<VehiclesListView />} />
      <Route path={'*'} element={<PageNotFoundView />} />
    </Routes>
  )
}

export default VehiclesRouter
