import React from 'react'
import { Route, Routes } from 'react-router-dom'

import config from '@Config'

import SofwareComponentsListView from '../Views/SoftwareComponentsListView'
import SwComponentDetailView from '../Views/SwComponentDetailView'
import PageNotFoundView from '@Core/Views/PageNotFoundView'

export const SoftwareComponentsRouter = () => {
  return (
    <Routes>
      <Route path={config.urls.softwareComponents.detail} element={<SwComponentDetailView />} />
      <Route path={config.urls.softwareComponents.main} element={<SofwareComponentsListView />} />
      <Route path={'*'} element={<PageNotFoundView />} />
    </Routes>
  )
}

export default SoftwareComponentsRouter
