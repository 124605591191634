import PropTypes from 'prop-types'
import { CampaignInfoType } from '@Campaigns/Types/CampaignInfo'

import i18n from '../../i18n'

export const CampaignExecutionType = PropTypes.shape({
  _id: PropTypes.string,
  campaignId: PropTypes.string,
  campaignInfo: CampaignInfoType,
  device: PropTypes.shape({
    deviceId: PropTypes.string,
    deviceType: PropTypes.string,
    domain: PropTypes.string,
    ring: PropTypes.string,
    tty: PropTypes.string,
    vin: PropTypes.string,
  }),
  jobExecutionStatus: PropTypes.shape({
    eventId: PropTypes.string,
    eventType: PropTypes.string,
    timestamp: PropTypes.number,
    operation: PropTypes.string,
    jobId: PropTypes.string,
    thingArn: PropTypes.string,
    status: PropTypes.string,
    statusDetails: PropTypes.shape({
      bundleVersion: PropTypes.string,
      bundleIncrementalVersion: PropTypes.string,
      bundleConsistency: PropTypes.bool,
    }),
  }),
  jobExecutions: PropTypes.arrayOf(PropTypes.shape({
      eventId: PropTypes.string,
      eventType: PropTypes.string,
      timestamp: PropTypes.number,
      operation: PropTypes.string,
      jobId: PropTypes.string,
      thingArn: PropTypes.string,
      status: PropTypes.string,
      statusDetails: PropTypes.shape({
        bundleVersion: PropTypes.string,
        bundleIncrementalVersion: PropTypes.string,
        bundleConsistency: PropTypes.bool,
      }),
    })),
  jobExecutionSummary: PropTypes.shape({
    queuedAt: PropTypes.number,
    startedAt: PropTypes.number,
  })
})

export default {
  primaryKey: '_id',
  columns: [
    {
      id: 'campaignInfo.name',
      label: i18n.t('campaigns:fields.name'),
    },
    {
      id: 'campaignInfo.queryString',
      label: i18n.t('campaigns:fields.queryString'),
    },
    {
      id: 'campaignInfo.deviceIds',
      label: i18n.t('campaigns:fields.deviceIds'),
    },
    {
      id: 'campaignInfo.domain',
      label: i18n.t('common:fields.domain'),
    },
    {
      id: 'campaignInfo.ring',
      label: i18n.t('common:fields.ring'),
    },
    {
      id: 'campaignInfo.assetType',
      label: i18n.t('common:fields.assetType'),
    },
    {
      id: 'campaignInfo.deviceType',
      label: i18n.t('common:fields.deviceType'),
    },
    {
      id: 'campaignInfo.bundleId',
      label: i18n.t('sc:fields.bundleId'),
    },
    {
      id: 'campaignInfo.bundleVersion',
      label: i18n.t('common:fields.bundleVersion'),
    },
    {
      id: 'campaignInfo.options.forceUpdate',
      label: i18n.t('campaigns:fields.forceUpdate'),
      boolean: true,
    },
    {
      id: 'campaignInfo.options.wipeSettings',
      label: i18n.t('campaigns:fields.wipeSettings'),
      boolean: true,
    },
    {
      id: 'campaignInfo.options.wipeApplicationData',
      label: i18n.t('campaigns:fields.wipeApplicationData'),
      boolean: true,
    },
    {
      id: 'device.vin',
      label: i18n.t('vehicles:fields.vin'),
    },
    {
      id: 'jobExecutionStatus.timestamp',
      label: i18n.t('campaigns:fields.jobExecutionStatus.timestamp'),
      datetime: true,
    },
    {
      id: 'jobExecutionStatus.status',
      label: i18n.t('campaigns:fields.jobExecutionStatus.status'),
    },
    {
      id: 'jobExecutionStatus.statusDetails.bundleVersion',
      label: i18n.t('campaigns:fields.jobExecutionStatus.statusDetails.bundleVersion'),
    },
    {
      id: 'jobExecutionStatus.statusDetails.bundleConsistency',
      label: i18n.t('campaigns:fields.jobExecutionStatus.statusDetails.bundleConsistency'),
      boolean: true,
    },
    {
      id: 'jobExecutionSummary.queuedAt',
      label: i18n.t('campaigns:fields.jobExecutionSummary.queuedAt'),
      datetime: true,
    },
    {
      id: 'jobExecutionSummary.startedAt',
      label: i18n.t('campaigns:fields.jobExecutionSummary.startedAt'),
      datetime: true,
    }
  ],
}
