import React from 'react'
import { useDispatch } from 'react-redux'

import { setBreadcrumbs, setThemeMode } from '@Core/Redux/Ui'
import storageFactory from '@Common/Services/Storage'
import ConfirmationDialog from '../Components/ConfirmationDialog'
import { useMediaQuery } from '@mui/material'
import * as R from 'ramda'
import { history } from '@Core/Redux/Store'
import config, { makePath } from '@Config'
import { useWhoamiQuery } from '@Auth/Services/Api'

export const useCurrentUser = () => {
  const { data } = useWhoamiQuery()
  return data
}

/**
 * Set page breadcrumbs in the redux store (which are retrieved from the BaseLayout)
 *
 * @param {Array} breadcrumbs - the breadcrumbs definition
 * @param {Boolean} cond - a condition which should be met in order to actually set the breadcrumbs
 * @param {Array} deps - any breadcrumbs deps, considered in the useEffect hook to update breadcrumbs
 */
export const useBreadcrumbs = (breadcrumbs, cond = true, deps = []) => {
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (cond) {
      dispatch(setBreadcrumbs(breadcrumbs))
    }
  }, [cond, ...deps])
}

/**
 * Set the system theme mode if no theme mode was previously set
 */
export const useGuessThemeMode = () => {
  const dispatch = useDispatch()
  const Storage = storageFactory()
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  if (!Storage.get('themeMode')) {
    dispatch(setThemeMode(prefersDarkMode ? 'dark' : 'light'))
  }
}

/**
 * Use confirm hook
 * Utility to reduce boilerplate when asking for confirmation before performing an action
 * Returns [open, confirm, close] where:
 * - open is a function used to open the confirmation modal, and takes the args payload, title, content, options
 * - confirm is the confirm modal component whose visibility is automatically managed
 * - close is the function you can use to close the confirmation dialog
 *
 * @param {Function} onConfirm - callback to call when confirmation is confirmed
 */
export const useConfirm = (onConfirm) => {
  const [state, setState] = React.useState({ open: false })
  const open = React.useCallback(
    (payload, title, content, options = null) => {
      if (options?.selected) {
        content = (
          <>
            {content}
            {options.selected.length > 0 && (
              <ul>
                {options.selected.map((item, idx) => {
                  const val = options.field.split('.').reduce((acc, curr) => (acc ? acc[curr] : null), item)
                  return <li key={idx}>{val}</li>
                })}
              </ul>
            )}
          </>
        )
      }
      setState({ open: true, title, content, payload })
    },
    [setState],
  )
  const close = () => {
    setState({ open: false })
  }
  const handleConfirm = React.useCallback(() => {
    close()
    return onConfirm(state.payload)
  }, [onConfirm, state.payload])

  const confirm = (
    <ConfirmationDialog
      open={state.open}
      title={state.title}
      onCancel={() => setState({ open: false })}
      onOk={handleConfirm}
    >
      {state.content}
    </ConfirmationDialog>
  )

  return [open, confirm, close]
}

/**
 * Use form hook
 * Utility to manage fields, errors and uptade functions
 * Return { fields, setFields, setField, errors, setErrors }, where:
 * - fields: current value fo all fields
 * - setFields: setter for all fields
 * - setField: setter for a single field, i.e. setField('myField')(value)
 * - errors: field errors
 * - setErrors: setter for the errors map
 *
 * @param {Object} initFields - initial values for all fields
 * @param {Function} [onSetField] - optional callback to call when updating a field
 */
export const useForm = (initFields, onSetField = null) => {
  const [fields, setFields] = React.useState(initFields)
  const [errors, setErrors] = React.useState({})
  const setField = (field, type, dft = null) => (value) => {
    switch (type) {
      case 'int':
        value = R.either(R.isEmpty, R.isNil)(value) ? dft : parseInt(value)
        break
      case 'float':
        value = R.either(R.isEmpty, R.isNil)(value) ? dft : parseFloat(value)
        break
      case 'array':
        value = R.isNil(value) ? dft : value
        break
      default:
        value = R.isEmpty(value) ? dft : value
    }

    setFields({ ...fields, [field]: value })
    onSetField && onSetField(field, value)
  }

  return { fields, setFields, setField, errors, setErrors }
}

/**
 * Use debounce hook
 * Utility to add a debounce to any value change
 *
 * Usage:
 * const [filterName, setFilterName] = React.useState('')
 * const debouncedName = useDebounce(filterName, 300)
 *
 * @param {Any} value - value to be debounces
 * @param {Number} delay - debounce interval in ms
 */
export const useDebounce = (value, delay = config.ui.inputsDebounceTime) => {
  const [debouncedValue, setDebouncedValue] = React.useState(value)

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}

/**
 * Use prop did update hook
 * Utility to check for a prop did update event
 *
 * @param {Function} effect - function called when the update event fires
 * @param {Any} prop - property to check for update
 */
export const usePropDidUpdate = (effect, prop) => {
  const prev = React.useRef(prop)

  React.useEffect(
    () => {
      const unmountHandler = effect(prev.current, prop)
      prev.current = prop
      return unmountHandler
    },
    [prop]
  )
}

/**
 * Use did update hook
 * Utility to check for component di update event
 *
 * @param {Function} effect - function to call when the event fires
 * @param {Array} deps - dependencies to watch for changes
 */
export const useDidUpdate = (effect, deps) => {
  const didMount = React.useRef(false)

  React.useEffect(
    () => {
      if (didMount.current) {
        effect()
      } else {
        didMount.current = true;
      }
    },
    deps,
  )
}

/**
 * Use page not found hook
 * Utility to redirect the user to a 404 page if condition is met
 *
 * @param {Boolean} condition - condition to watch for redirect
 * @param {Array} deps - list of deps to check for change (useEffect)
 */
export const usePageNotFoundRedirect = (condition, deps) => {
  React.useEffect(() => {
    if (condition) history.push(makePath('pageNotFound'))
  }, deps)
}
