import { api, apiQueryStringCustom } from '@Core/Services/Api'

const prefix = 'statistics'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    statistics: builder.query({
      query: (qs) => `${prefix}?${apiQueryStringCustom(qs)}`,
      providesTags: [{ type: 'Statistics' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useStatisticsQuery,
} = extendedApi
