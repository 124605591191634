import { api, apiQueryString } from '@Core/Services/Api'

const prefix = 'notification'
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    notifications: builder.query({
      query: (qs) => `${prefix}?${apiQueryString(qs)}`,
      providesTags: [{ type: 'Notification', id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useNotificationsQuery,
} = extendedApi
