import i18next from 'i18next'
import PropTypes from 'prop-types'
import { always, cond, equals, pipe, prop, T } from 'ramda'

import FailChip from '@Common/Components/FailChip'
import ProgressChip from '@Common/Components/ProgressChip'
import TrueChip from '@Common/Components/TrueChip'
import { withTooltip } from '@Common/Utils/HOF'

const DeltaStatus = pipe(
  prop('status'),
  cond([
    [equals('FAILED'), always(withTooltip(<FailChip />, i18next.t('sc:ui.AtLeastOneScDeltaIsFailed'), true))],
    [equals('COMPLETED'), always(withTooltip(<TrueChip />, i18next.t('sc:ui.AllScDeltasAreCompleted'), true))],
    [equals('IN_PROGRESS'), always(withTooltip(<ProgressChip />, i18next.t('sc:ui.DeltasGenerationInProgress'), true))],
    [T, always(null)],
  ]),
)

DeltaStatus.propTypes = {
  status: PropTypes.string,
}

export default DeltaStatus
