import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { SoftwareComponentType } from '@SoftwareCatalog/Models/SoftwareComponent'
import DeltaStatus from './DeltaStatus'
import { humanizeSize } from '@Common/Utils/Files'

const SwComponentInfoBox = ({ SwComponent }) => {
  if (!SwComponent) {
    return null
  }

  const data = SwComponent
  const { t } = useTranslation()
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>{t('sc:fields.version')}</TableCell>
          <TableCell>{data.swComponentInfo.version}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('common:fields.domain')}</TableCell>
          <TableCell>{data.swComponentInfo.domain}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('common:fields.ring')}</TableCell>
          <TableCell>{data.swComponentInfo.ring}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('common:fields.deviceType')}</TableCell>
          <TableCell>{data.swComponentInfo.deviceType}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('sc:fields.deltaStatus')}</TableCell>
          <TableCell><DeltaStatus status={data.swComponentStatus.deltaStatus} /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('sc:fields.size')}</TableCell>
          <TableCell>{humanizeSize(data.swComponentStatus.sizeInBytes)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

SwComponentInfoBox.propTypes = {
  SwComponent: SoftwareComponentType,
}

export default SwComponentInfoBox
