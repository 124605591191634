import BaseLayout from "@Common/Layouts/BaseLayout"
import { useBreadcrumbs } from "@Common/Utils/Hooks"
import { makePath } from "@Config"
import FiltersBar from "@Dashboard/Components/FiltersBar"
import VehiclesCharts from "@Dashboard/Components/VehiclesCharts"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useStatisticsQuery } from '@Dashboard/Services/Api/Statistics'
import { withLoader, withMissingData } from "@Common/Utils/HOF"
import { always, equals, identity, ifElse } from "ramda"
import BundlesChart from "@Dashboard/Components/BundlesChart"
import styled from 'styled-components'
import CampaignsCharts from "@Dashboard/Components/CampaignsCharts"
import { lengthGtZero } from "@Common/Utils/Ramda"

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.content.contrastText};
  font-weight: normal;
  text-transform: uppercase;
`

const Separator = styled.div`
  border-top: 1px solid ${({ theme }) => theme.palette.contrastLight.main};
  margin: 1rem 0;
`

/**
 * The dashboard view
 * Contains charts divided into three sections:
 * - devices
 * - bundles
 * - campaigns  
 */
const DashboardView = () => {
  const { t } = useTranslation()

  useBreadcrumbs([
    { label: t('navigation.Home'), path: makePath('home') },
  ])

  const [domain, setDomain] = useState('CV')
  const [assetType, setAssetType] = useState('ALL')

  const { data: statistics, isFetching } = useStatisticsQuery({ domain, assetType: ifElse(equals('ALL'), always(null), identity)(assetType) })

  return (
    <BaseLayout>
      <FiltersBar domain={domain} onDomainChange={setDomain} assetType={assetType} onAssetTypeChange={setAssetType} />
      <Separator />
      {withLoader(() => (
        <>
          <Title>{t('dashboard:ui.Vehicles')}</Title>
          {withMissingData(() => (
            <VehiclesCharts deviceStatistics={statistics.deviceStatistics} />
          ), lengthGtZero(statistics?.deviceStatistics))}

          <Separator />

          <Title>{t('dashboard:ui.Bundles')}</Title>
          {withMissingData(() => (
            <BundlesChart bundleStatistics={statistics.bundleStatistics} />
          ), lengthGtZero(statistics?.bundleStatistics))}

          <Separator />

          <Title>{t('dashboard:ui.CampaignsExecutionSuccess')}</Title>
          {withMissingData(() => (
            <CampaignsCharts campaignStatistics={statistics.campaignStatistics} />
          ), lengthGtZero(statistics?.campaignStatistics))}
        </>
      ), isFetching)}
    </BaseLayout>
  )
}

export default DashboardView
