import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Logger from '@Common/Utils/Logger'
import { round } from '@Common/Utils/Numbers'
import { selectThemeMode } from '@Core/Redux/Ui'
import { chartBackground, chartCommonOptions } from '@Dashboard/Utils/Charts'

const VehicleActivePieChart = ({ stats }) => {
  const { t } = useTranslation()
  const themeMode = useSelector(selectThemeMode)

  const accumulate = (acc, curr) => ({
    active: acc.active + curr.connectedCardinality,
    tot: acc.tot + curr.cardinality,
    // active: curr.cardinality + acc.active + Math.floor(Math.random() * 100),
    // tot: acc.tot + Math.floor(Math.random() * 1000),
  })
  const { active: activePcm3, tot: totPcm3 } = stats.PCM3.reduce(accumulate, { active: 0, tot: 0 })
  const { active: activeIhp, tot: totIhp } = stats.IHP.reduce(accumulate, { active: 0, tot: 0 })
  const active = activePcm3 + activeIhp
  const tot = totPcm3 + totIhp

  Logger.debug('Vehicles active pie chart - PCM3', 'active', activePcm3, 'tot', totPcm3)
  Logger.debug('Vehicles active pie chart - IHP', 'active', activeIhp, 'tot', totIhp)
  Logger.debug('Vehicles active pie chart', 'active', active, 'tot', tot)

  const options = {
    ...chartCommonOptions(themeMode),
    chart: {
      backgroundColor: chartBackground(themeMode),
      type: 'pie',
      height: '260px',
    },
    title: {
      style: { display: 'none' },
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '{point.name}: {point.y}%',
          color: themeMode === 'light' ? '#666' : '#aaa',
          style: { textOutline: 'none' },
        },
        showInLegend: true,
      },
    },
    legend: {
      itemStyle: {
        color: themeMode === 'light' ? '#666' : '#aaa',
      },
      align: 'left',
      verticalAlign: 'top',
      layout: 'vertical',
      x: 40,
      y: 0,
    },
    series: [
      {
        type: 'pie',
        name: t('dashboard:ui.Vehicles'),
        data: [
          {
            name: t('dashboard:ui.Connected'),
            color: '#834190',
            y: tot > 0 ? round((active * 100) / tot, 1) : 0,
          },
          {
            name: t('dashboard:ui.NotConnected'),
            color: '#CB297B',
            y: tot > 0 ? round(((tot - active) * 100) / tot, 1) : 0,
          },
        ],
      },
    ],
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}

VehicleActivePieChart.propTypes = {
  stats: PropTypes.shape({
    PCM3: PropTypes.arrayOf(
      PropTypes.shape({
        cardinality: PropTypes.number,
        connectedCardinality: PropTypes.number,
        deviceType: PropTypes.string,
        domain: PropTypes.string,
        ring: PropTypes.string,
      }),
    ),
    IHP: PropTypes.arrayOf(
      PropTypes.shape({
        cardinality: PropTypes.number,
        connectedCardinality: PropTypes.number,
        deviceType: PropTypes.string,
        domain: PropTypes.string,
        ring: PropTypes.string,
      }),
    ),
  }),
}

export default React.memo(VehicleActivePieChart)
