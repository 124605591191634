import * as R from 'ramda'

import { checkRequired } from '@Common/Utils/Validation'

export const validate = (fields, setErrors, errors, expRolloutNumberOf) => {
  const err = {}

  const required = ['rolloutType']
  checkRequired(err, required, fields)

  if (fields.rolloutType === 'LINEAR') {
    checkRequired(err, ['rolloutMaximumPerMinute'], fields)
  }

  if (fields.rolloutType === 'EXP') {
    checkRequired(
      err,
      [
        'rolloutBaseRatePerMinute',
        'rolloutIncrementFactor',
        ...(expRolloutNumberOf === 'NOTIFIED' ? ['rolloutNumberOfNotifiedThings'] : []),
        ...(expRolloutNumberOf === 'SUCCEEDED' ? ['rolloutNumberOfSucceededThings'] : []),
      ],
      fields,
    )
  }

  if (expRolloutNumberOf === 'NOTIFIED') {
    delete errors.rolloutNumberOfSucceededThings
  } else {
    delete errors.rolloutNumberOfNotifiedThings
  }

  setErrors({ ...errors, ...err })

  return R.isEmpty(err)
}
