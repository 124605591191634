import React from 'react'
import { useTheme } from '@mui/material/styles'
import DoneIcon from '@mui/icons-material/Done'
import styled from 'styled-components'

export const Chip = styled.div`
  align-items: center;
  background: ${props => props.bg};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 26px;
  width: 26px;
`

const TrueChip = () => {
  const theme = useTheme()
  return (
    <Chip bg={theme.palette.successLight.main}>
      <DoneIcon sx={{ color: theme.palette.successLight.contrastText }} />
    </Chip>
  )
}

export default TrueChip
