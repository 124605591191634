import { Alert, Button, Grid, Stack, Switch } from '@mui/material'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { compose } from 'redux'
import styled from 'styled-components'

import { FormSectionTitle } from '@Campaigns/Views/CampaignCreateView/Styles'
import AssetTypeDropdown from '@Common/Components/AssetTypeDropdown'
import Box from '@Common/Components/Box'
import DeviceTypeDropdown from '@Common/Components/DeviceTypeDropdown'
import DomainDropdown from '@Common/Components/DomainDropdown'
import { FormControlLabel } from '@Common/Components/Forms'
import RingDropdown from '@Common/Components/RingDropdown'
import TextField from '@Common/Components/TextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import Logger from '@Common/Utils/Logger'
import BundleVersionField from '@SoftwareCatalog/Components/BundleVersionField'
import { bundleDeltaStatusWarning, bundleStatusError } from '@SoftwareCatalog/Utils/StatusChecks'

import { validate } from './Validation'

const Field = styled.div`
  margin-bottom: 1.5rem;
`

const CampaignInfoForm = ({ fields, setField, setFields, errors, setErrors, onCancel, onSubmit }) => {
  const { t } = useTranslation()
  const [targetBundle, setTargetBundle] = React.useState(null)

  const handleBundleVersionChange = (evt, bundle) => {
    setFields({
      ...fields,
      bundleId: evt.target.value,
      bundleVersion: bundle?.bundleInfo?.bundleVersion,
      bundleIncrementalVersion: bundle?.bundleInfo?.bundleIncrementalVersion,
    })
    setTargetBundle(bundle)
  }

  const handleQsBundleVersionChange = (evt, bundle, op, toValue) => {
    const newFields = {
      ...fields,
      [toValue ? 'queryStringBundleToId' : 'queryStringBundleId']: evt.target.value,
      [toValue ? 'queryStringBundleToVersion' : 'queryStringBundleVersion']: bundle?.bundleInfo?.bundleVersion,
      [toValue ? 'queryStringBundleToIncrementalVersion' : 'queryStringBundleIncrementalVersion']:
        bundle?.bundleInfo?.bundleIncrementalVersion,
      queryStringBundleOp: op,
    }
    if (op !== 'between') {
      newFields.queryStringBundleToId = null
      newFields.queryStringBundleToVersion = null
    }
    setFields(newFields)
  }

  const handleSubmit = () => {
    Logger.debug('Campaign info form submission, fields:', fields)
    if (validate(fields, setErrors, targetBundle)) {
      Logger.debug('Campaign info form submission, validation passed')
      onSubmit(fields)
    } else {
      Logger.debug('Campaign info form submission, validation failed')
    }
  }

  const setFieldFromEventAndCleanBundles = (fieldName) => (evt) =>
    setFields({
      ...fields,
      [fieldName]: evt.target.value,
      queryStringBundleId: null,
      queryStringBundleToId: null,
      bundleId: null,
    })

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ paddingRight: { md: 6 } }}>
          <FormSectionTitle>{t('campaigns:ui.Query')}</FormSectionTitle>
          <Field>
            <DomainDropdown
              required
              value={fields.domain}
              onChange={setFieldFromEventAndCleanBundles('domain')}
              fullWidth
              error={!!errors.domain}
              helperText={errors.domain}
            />
          </Field>
          <Field>
            <RingDropdown
              required
              value={fields.ring}
              onChange={setFieldFromEventAndCleanBundles('ring')}
              fullWidth
              error={!!errors.ring}
              helperText={errors.ring}
            />
          </Field>
          <Field>
            <AssetTypeDropdown
              required
              value={fields.assetType}
              onChange={setFieldFromEventAndCleanBundles('assetType')}
              fullWidth
              error={!!errors.assetType}
              helperText={errors.assetType}
            />
          </Field>
          <Field>
            <DeviceTypeDropdown
              required
              value={fields.deviceType}
              onChange={setFieldFromEventAndCleanBundles('deviceType')}
              fullWidth
              error={!!errors.deviceType}
              helperText={errors.deviceType}
            />
          </Field>
          <Alert severity="info">{t('campaigns:ui.OptionalYouCanFilterByBundleVersion')}</Alert>
          <Field>
            <BundleVersionField
              baseLabel={'common:ui.CurrentBundleVersion'}
              value={fields.queryStringBundleId}
              valueTo={fields.queryStringBundleToId}
              defaultOperator={fields.queryStringBundleOp}
              onChange={handleQsBundleVersionChange}
              domain={fields.domain}
              ring={fields.ring}
              assetType={fields.assetType}
              deviceType={fields.deviceType}
              fullWidth
              error={!!errors.queryStringBundleId}
              helperText={errors.queryStringBundleId}
            />
          </Field>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ paddingLeft: { md: 6 }, borderLeft: { md: 1 }, borderLeftColor: { md: 'grey.400' } }}
        >
          <FormSectionTitle>{t('campaigns:ui.GeneralInfo')}</FormSectionTitle>
          <Field>
            <TextField
              required
              label={t('campaigns:fields.name')}
              value={fields.name}
              onChange={setStateFromEvent(setField('name'))}
              fullWidth
              error={!!errors.name}
              helperText={errors.name}
            />
          </Field>
          <Field>
            <BundleVersionField
              label={t('campaigns:ui.CampaignBundleVersion')}
              value={fields.bundleId}
              onChange={handleBundleVersionChange}
              domain={fields.domain}
              ring={fields.ring}
              assetType={fields.assetType}
              deviceType={fields.deviceType}
              fullWidth
              error={!!errors.bundleId}
              helperText={errors.bundleId}
              color="warning"
              noOperator
              required
            />
            {bundleStatusError(targetBundle) && <Alert severity="error">{t('sc:validation.BundleStatusError')}</Alert>}
            {bundleDeltaStatusWarning(targetBundle) && (
              <Alert severity="warning">{t('sc:validation.BundleDeltaStatusWarning')}</Alert>
            )}
          </Field>
          <Box direction="row" justify="end" width="100%">
            <FormControlLabel
              control={
                <Switch
                  checked={fields.forceUpdate}
                  onChange={compose(setField('forceUpdate'), path(['target', 'checked']))}
                />
              }
              label={t('campaigns:fields.forceUpdate')}
              labelPlacement="start"
            />
          </Box>
          <Box direction="row" justify="end" width="100%" margin="1rem 0 0">
            <FormControlLabel
              control={
                <Switch
                  checked={fields.wipeSettings}
                  onChange={compose(setField('wipeSettings'), path(['target', 'checked']))}
                />
              }
              label={t('campaigns:fields.wipeSettings')}
              labelPlacement="start"
            />
          </Box>
          <Box direction="row" justify="end" width="100%" margin="1rem 0 0">
            <FormControlLabel
              control={
                <Switch
                  checked={fields.wipeApplicationData}
                  onChange={compose(setField('wipeApplicationData'), path(['target', 'checked']))}
                />
              }
              label={t('campaigns:fields.wipeApplicationData')}
              labelPlacement="start"
            />
          </Box>
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="end" spacing={2} marginTop="2rem">
        <Button variant="contained" color="primary" onClick={onCancel}>
          {t('common:actions.Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {t('common:actions.Next')}
        </Button>
      </Stack>
    </>
  )
}

CampaignInfoForm.propTypes = {
  fields: PropTypes.object,
  setField: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default CampaignInfoForm
