import PropTypes from 'prop-types'
// import Link from '@Common/Components/Link'
import * as R from 'ramda'
import React from 'react'

import CampaignsFilterForm from '@Campaigns/Forms/CampaignsFilterForm'
import Campaign from '@Campaigns/Models/Campaign'
import { useCampaignsQuery } from '@Campaigns/Services/Api'
// import { useTranslation } from 'react-i18next'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import DataTableItemsListDialog from '@Common/Components/DataTableItemsListDialog'
import Link from '@Common/Components/Link'
import { useDebounce, useForm } from '@Common/Utils/Hooks'
import { readFromStorage, storeInStorage } from '@Common/Utils/Storage'
import { makePath } from '@Config'
import { apiList } from '@Core/Services/Api'

import CampaignStatus from './CampaignStatus'
import CampaignStatusProgress from './CampaignStatusProgress'

const LIST_DISPLAY = [
  'campaignInfo.name',
  'group.thingsCardinality',
  'job.jobDefinition.schedulingConfig.startTime',
  'job.jobDefinition.targetSelection',
  'campaignInfo.deviceType',
  'campaignInfo.ring',
  'campaignInfo.assetType',
  'campaignInfo.bundleVersion',
  'campaignStatus.status',
  'campaignStatus.statusTimestamp',
  'campaignStatusProgress',
]
const FIELDS_MAPPING = {
  'campaignInfo.name': (record) => (
    <Link to={makePath('campaigns.detail', { campaignId: record._id })}>{record.campaignInfo.name}</Link>
  ),
  'campaignInfo.bundleVersion': (record) => (
    <Link to={makePath('bundles.detail', { bundleId: record.campaignInfo.bundleId })}>
      {record.campaignInfo.bundleVersion}
    </Link>
  ),
  'campaignStatus.status': (record) => <CampaignStatus status={record.campaignStatus?.status} />,
  'campaignInfo.deviceIds': (record) => (
    <DataTableItemsListDialog items={record.campaignInfo.deviceIds} size="lg" title="campaigns:fields.deviceIds" />
  ),
  'campaignInfo.failedDeviceIds': (record) => (
    <DataTableItemsListDialog
      items={record.campaignInfo.failedDeviceIds}
      size="lg"
      title="campaigns:fields.deviceIds"
    />
  ),
  'campaignStatusProgress': (record) => <CampaignStatusProgress campaign={record} />,
}

const FILTERS_STORAGE_KEY = 'filters-campaigns'

/**
 * Campaigns list datatable
 *
 * @param {String} props.vin - optional vin used to filter campaigns
 */
const CampaignsList = ({ vin }) => {
  const {
    fields: filterFields,
    setField: setFilterField,
    setFields: setFilterFields,
  } = useForm(readFromStorage(FILTERS_STORAGE_KEY, {}))
  const debouncedFilterFields = useDebounce(filterFields)
  const handleFilterForm = React.useCallback(
    (handleClose) => {
      return (
        <CampaignsFilterForm
          onClose={handleClose}
          fields={filterFields}
          setField={setFilterField}
          onReset={() => setFilterFields({})}
          onSave={() => storeInStorage(FILTERS_STORAGE_KEY, filterFields)}
        />
      )
    },
    [CampaignsFilterForm, filterFields, setFilterField],
  )

  const qsAdditions = React.useMemo(() => {
    const obj = {}
    const filters = []
    if (debouncedFilterFields.vin || vin) obj.vin = debouncedFilterFields.vin || vin
    if (debouncedFilterFields.domain) filters.push(`"campaignInfo.domain":"${debouncedFilterFields.domain}"`)
    if (debouncedFilterFields.ring) filters.push(`"campaignInfo.ring":"${debouncedFilterFields.ring}"`)
    if (debouncedFilterFields.assetType) filters.push(`"campaignInfo.assetType":"${debouncedFilterFields.assetType}"`)
    if (debouncedFilterFields.deviceType) filters.push(`"campaignInfo.deviceType":"${debouncedFilterFields.deviceType}"`)
    if (debouncedFilterFields.bundleVersion) filters.push(`"campaignInfo.bundleVersion":"${debouncedFilterFields.domain}"`)
    if (debouncedFilterFields.name) filters.push(`"campaignInfo.name":{"$regex":"${debouncedFilterFields.name}", "$options": "i"}`)
    if (filters.length) obj.filterBy = filters.join(',')
    return obj
  }, [
    debouncedFilterFields.vin,
    debouncedFilterFields.domain,
    debouncedFilterFields.ring,
    debouncedFilterFields.assetType,
    debouncedFilterFields.deviceType,
    debouncedFilterFields.bundleVersion,
    debouncedFilterFields.name,
  ])

  const [qs, setQs] = React.useState({
    base: {
      pageSize: getDefaultListPerPage('campaigns'),
      pageNumber: 0,
      orderBy: 'campaignStatus.creationTimestamp',
      orderType: 'desc',
    },
    qsAdditions,
  })

  const refreshData = setQs

  const { data: campaigns, isFetching, orData } = apiList(useCampaignsQuery({ ...qs.base, ...qsAdditions }))

  return (
    <DataTable
      name="campaigns"
      data={campaigns}
      refreshData={refreshData}
      qs={qs}
      dataCount={orData?.totalSize || -1}
      dftSortField="campaignStatus.creationTimestamp"
      dftSortDirection="desc"
      model={Campaign}
      listDisplay={LIST_DISPLAY}
      fieldsMapping={FIELDS_MAPPING}
      loading={isFetching}
      filterForm={handleFilterForm}
      filterFormActive={R.values(R.filter(R.compose(R.not, R.either(R.isEmpty, R.isNil)), filterFields)).length}
    />
  )
}

CampaignsList.propTypes = {
  vin: PropTypes.string,
}

export default CampaignsList
