import ValueOrDashes from '@Common/Components/ValueOrDashes'
import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { DeviceType } from '@Vehicles/Models/Device'
import { useTranslation } from 'react-i18next'

/**
 * Vehicle detail info box
 *
 * @param {Object} props.device - the device data
 */
const VehicleInfoBox = ({ device }) => {
  const { t } = useTranslation()

  return (
    <Table size='small'>
      <TableBody>
        <TableRow>
          <TableCell>{t('vehicles:fields.vin')}</TableCell>
          <TableCell>{device.attributes.vin}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('vehicles:fields.type')}</TableCell>
          <TableCell>{device.attributes.type}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('common:fields.domain')}</TableCell>
          <TableCell>{device.attributes.domain}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('common:fields.ring')}</TableCell>
          <TableCell>{device.attributes.ring}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('common:fields.assetType')}</TableCell>
          <TableCell>{device.attributes.assetType}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('vehicles:fields.bundleVersion')}</TableCell>
          <TableCell><ValueOrDashes value={device.shadow?.reported.bundleVersion} /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('vehicles:fields.bundleConsistency')}</TableCell>
          <TableCell><ValueOrDashes value={device.shadow?.reported.bundleConsistency} isBoolean /></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

VehicleInfoBox.propTypes = {
  device: DeviceType,
}

export default VehicleInfoBox
