import CircularProgress from '@mui/material/CircularProgress'
import Skeleton from '@mui/material/Skeleton'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { selectThemeMode } from '@Core/Redux/Ui'

const Wrapper = styled.div`
  align-items: center;
  display: ${({ inline }) => (inline ? 'inline' : 'flex')};
  flex-direction: column;
  justify-content: ${({ justifyStart }) => (justifyStart ? 'flex-start' : 'center')};
  min-height: ${(props) => (props.overlay ? 'auto' : props.minHeight)};
  ${(props) =>
    props.overlay
      ? props.theme === 'light'
        ? 'background: rgba(255, 255, 255, .7)'
        : 'background: rgba(0, 0, 0, .7)'
      : ''};
  ${(props) => (props.overlay ? (props.absolute ? 'position: absolute' : 'position: fixed') : '')};
  ${(props) => (props.overlay ? 'top: 0' : '')};
  ${(props) => (props.overlay ? 'bottom: 0' : '')};
  ${(props) => (props.overlay ? 'right: 0' : '')};
  ${(props) => (props.overlay ? 'left: 0' : '')};
  ${(props) => (props.overlay ? 'z-index: 10000' : '')};
`

const Loader = ({ minHeight, size, overlay, absolute, skeleton, justifyStart, color, text, inline, style }) => {
  const theme = useSelector(selectThemeMode)
  return (
    <Wrapper
      minHeight={minHeight}
      overlay={overlay}
      absolute={absolute}
      justifyStart={justifyStart}
      inline={inline}
      theme={theme}
      style={style}
    >
      {skeleton && (
        <div style={{ width: '100%' }}>
          <Skeleton height={53} />
          <Skeleton height={53} />
          <Skeleton height={53} />
          <Skeleton height={53} />
          <Skeleton height={53} />
        </div>
      )}
      {!skeleton && <CircularProgress color={color} size={size}></CircularProgress>}
      {text && <p>{text}</p>}
    </Wrapper>
  )
}

Loader.defaultProps = {
  minHeight: '0',
  size: 40,
  skeleton: false,
  justifyStart: false,
  inline: false,
  color: 'primary',
  style: {},
}

Loader.propTypes = {
  minHeight: PropTypes.string,
  size: PropTypes.number,
  overlay: PropTypes.bool,
  skeleton: PropTypes.bool,
  justifyStart: PropTypes.bool,
  inline: PropTypes.bool,
  absolute: PropTypes.bool,
  color: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.object,
}

export default Loader
