import PropTypes from 'prop-types'
import Link from '@Common/Components/Link'
import { makePath } from '@Config'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Box from './Box'
import { useBreadcrumbs } from '@Common/Utils/Hooks'

const Title = styled.div`
  color: ${({ theme }) => theme.palette.errorLight.main};
  font-size: 180px;
`

const Text = styled.div`
  color: ${({ theme }) => theme.palette.errorLight.main};
  font-size: 30px;
  margin-bottom: 3rem;
`

const PageNotFound = ({ text }) => {
  const { t } = useTranslation()
  useBreadcrumbs([{ label: t('ui.PageNotFound') }])

  return (
    <Box direction='column' align='center' justify='center'>
      <Title>404</Title>
      <Text>{text || t('ui.PageNotFound')}</Text>
      <Link to={makePath('home')}>
        <Button variant='contained'>
          {t('ui.ReturnHome')}
        </Button>
      </Link>
    </Box>
  )
}

PageNotFound.propTypes = {
  text: PropTypes.string,
}

export default PageNotFound
