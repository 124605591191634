import { Menu, MenuItem } from '@mui/material'
import PropTypes from 'prop-types'
import { all, either, isEmpty, isNil, not, defaultTo, compose, pipe, filter, propEq, head, prop } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import Box from '@Common/Components/Box'
import Dropdown from '@Common/Components/Dropdown'
import { FieldType } from '@Common/Types/Field'
import { getRequestError } from '@Common/Utils/Api'
import { useLazyBundlesQuery } from '@SoftwareCatalog/Services/Api'
import { wrapValueInEvent } from '@Common/Utils/Events'

const Button = styled.div`
  background: ${({ theme }) => theme.palette.contrastLight.main};
  border-radius: .8rem;
  cursor: pointer;
  margin-right: 0.5rem;
  padding: 0.5rem 1rem;
`

const filtersSet = (...args) => all(compose(not, either(isEmpty, isNil)), args)

export const operatorMap = {
  'lt': '<',
  'lte': '<=',
  'eq': '==',
  'gte': '>=',
  'gt': '>',
  'neq': '!=',
  'between': '[ ]'
}

const BundleVersionField = ({
  baseLabel,
  defaultOperator,
  value,
  valueTo,
  onChange,
  required,
  error,
  helperText,
  domain,
  ring,
  assetType,
  deviceType,
  noOperator,
  ...other
}) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [fetchBundles] = useLazyBundlesQuery()

  const [operator, setOperator] = React.useState(defaultOperator || 'lt')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickOperator = (operator) => () => {
    setOperator(operator)
    if(value) {
      onChange(wrapValueInEvent(value), pipe(filter(propEq('value', value)), head, prop('original'))(options), operator)
    }
    handleClose()
  }

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const res = await fetchBundles({
          pageNumber: 0,
          pageSize: 1000,
          orderBy: 'bundleInfo.bundleIncrementalVersion:desc',
          domain,
          ring,
          assetType,
          deviceType,
        })

        setOptions(
          defaultTo([], res.data?.response).map((b) => ({
            value: b._id,
            label: b.bundleInfo.bundleVersion,
            original: b,
          })),
        )
        setIsLoading(false)
      } catch (e) {
        toast.error(t('sc:errors.FetchBundlesError', { error: getRequestError(e) }))
      }
    }
    // perform query only when filters are set
    if (filtersSet(domain, ring, assetType, deviceType)) {
      fetchData()
    }
  }, [domain, ring, assetType, deviceType])

  const handleChange = (evt) => {
    onChange(evt, pipe(filter(propEq('value', evt.target.value)), head, prop('original'))(options), operator)
  }

  const handleChangeTo = (evt) => {
    onChange(evt, pipe(filter(propEq('value', evt.target.value)), head, prop('original'))(options), operator, true)
  }

  return (
    <Box direction="row" align="flex-start">
      {!noOperator && (
        <>
          <Button
            variant="outlined"
            color="contrastLight"
            onClick={handleOpen}
            style={{ position: 'relative', top: '14px', width: '36px', textAlign: 'center' }}
          >
            {operatorMap[operator]}
          </Button>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem onClick={handleClickOperator('lt')}>{'<'}</MenuItem>
            <MenuItem onClick={handleClickOperator('lte')}>{'<='}</MenuItem>
            <MenuItem onClick={handleClickOperator('eq')}>{'=='}</MenuItem>
            <MenuItem onClick={handleClickOperator('gte')}>{'>='}</MenuItem>
            <MenuItem onClick={handleClickOperator('gt')}>{'>'}</MenuItem>
            <MenuItem onClick={handleClickOperator('neq')}>{'!='}</MenuItem>
            <MenuItem onClick={handleClickOperator('between')}>{'[ ]'}</MenuItem>
          </Menu>
        </>
      )}
      <Dropdown
        label={t(`${baseLabel}${operator === 'between' ? 'From' : ''}`)}
        required={required}
        value={value}
        onChange={handleChange}
        options={options}
        searchable
        loading={isLoading}
        helperText={
          error ? helperText : filtersSet(domain, ring, assetType, deviceType) ? helperText : t('sc:ui.PleaseSelectBundlesFilters')
        }
        error={error}
        formControlStyle={operator === 'between' ? { marginRight: '.5rem' } : {}}
        {...other}
      />
      {operator === 'between' && (
        <Dropdown
          label={t(`${baseLabel}To`)}
          required={required}
          value={valueTo}
          onChange={handleChangeTo}
          options={options}
          searchable
          loading={isLoading}
          helperText={
            error ? helperText : filtersSet(domain, ring, assetType, deviceType) ? helperText : t('sc:ui.PleaseSelectBundlesFilters')
          }
          formControlStyle={{ marginLeft: '.5rem' }}
          error={error}
          {...other}
        />
      )}
    </Box>
  )
}

BundleVersionField.defaultProps = {
  defaultOperator: 'lt',
  required: false,
  baseLabel: 'common:fields.bundleVersion',
}

BundleVersionField.propTypes = {
  ...FieldType,
  baseLabel: PropTypes.string,
  defaultOperator: PropTypes.string,
  valueTo: PropTypes.string,
  domain: PropTypes.string,
  ring: PropTypes.string,
  assetType: PropTypes.string,
  deviceType: PropTypes.string,
  noOperator: PropTypes.bool,
}

export default BundleVersionField
