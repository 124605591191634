import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useTranslation } from 'react-i18next'

import BaseLayout from '@Common/Layouts/BaseLayout'
import Fab from '@Common/Components/Fab'
import { useBreadcrumbs, useCurrentUser } from '@Common/Utils/Hooks'
import { useNavigate } from 'react-router'
import { makePath } from '@Config'
import Box from '@Common/Components/Box'
import CampaignsList from '@Campaigns/Components/CampaignsList'
import CampaignsPermissions from '@Campaigns/Permissions'

const CampaignsListView = () => {
  const { t } = useTranslation()
  const user = useCurrentUser()
  useBreadcrumbs([{ label: t('navigation.Campaigns') }])
  const navigate = useNavigate()

  const handleChange = (_, tab) => {
    if (tab === 1) navigate(makePath('campaigns.dashboard'))
  }

  return (
    <BaseLayout withFab>
      <Tabs value={0} onChange={handleChange}>
        <Tab label={t('campaigns:ui.CampaignsList')} />
        <Tab label={t('campaigns:ui.LastCampaign')} />
      </Tabs>
      <Box margin='2rem 0'>
        <CampaignsList />
      </Box>
      {CampaignsPermissions.create(user) && (
        <Fab right="2rem" onClick={() => navigate(makePath('campaigns.create'))} />
      )}
    </BaseLayout>
  )
}

export default CampaignsListView
