import { compose, defaultTo, either, filter, isEmpty, isNil, not, values } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import Link from '@Common/Components/Link'
import BaseLayout from '@Common/Layouts/BaseLayout'
import { useBreadcrumbs, useDebounce, useForm } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import BundlesFilterForm from '@SoftwareCatalog/Forms/BundleFilterForm'
import SoftwareComponent from '@SoftwareCatalog/Models/SoftwareComponent'
import { useSwComponentsQuery } from '@SoftwareCatalog/Services/SwApi'
import DeltaStatus from '@SoftwareCatalog/Components/DeltaStatus'
import SwStatus from '@SoftwareCatalog/Components/SwStatus'
import SoftwareComponentsFilterForm from '@SoftwareCatalog/Forms/SoftwareComponentsFilterForm'
import { readFromStorage, storeInStorage } from '@Common/Utils/Storage'

const LIST_DISPLAY = [
  'swComponentInfo.name',
  'swComponentInfo.version',
  'swComponentInfo.domain',
  'swComponentInfo.ring',
  'swComponentInfo.deviceType',
  'swComponentStatus.status',
  'swComponentStatus.deltaStatus',
]

const FIELDS_MAPPING = {
  'swComponentInfo.version': (record) => (
    <Link to={makePath('softwareComponents.detail', { softwareComponentId: record._id })}>{record.swComponentInfo.version}</Link>
  ),
  'swComponentStatus.status': (record) => <SwStatus status={record.swComponentStatus.status} />,
  'swComponentStatus.deltaStatus': (record) => <DeltaStatus status={record.swComponentStatus.deltaStatus} />,
}

const SORT_MAPPING = {
  'swComponentInfo.version': 'swComponentInfo.incrementalVersion',
}

const FILTERS_STORAGE_KEY = 'filters-software-components'

const SoftwareComponentsListView = () => {
  const { t } = useTranslation()
  useBreadcrumbs([{ label: t('navigation.Home'), path: makePath('home') }, { label: t('navigation.SoftwareCatalog') }])

  const { fields: filterFields, setField: setFilterField, setFields: setFilterFields } = useForm(readFromStorage(FILTERS_STORAGE_KEY, {}))
  const debouncedFilterFields = useDebounce(filterFields)
  const handleFilterForm = React.useCallback(
    (handleClose) => {
      return (
        <SoftwareComponentsFilterForm
          onClose={handleClose}
          fields={filterFields}
          setField={setFilterField}
          onReset={() => setFilterFields({})}
          onSave={() => storeInStorage(FILTERS_STORAGE_KEY, filterFields)}
        />
      )
    },
    [BundlesFilterForm, filterFields, setFilterField],
  )

  const qsAdditions = React.useMemo(() => {
    const filters = []
    if (debouncedFilterFields.domain) filters.push(`"swComponentInfo.domain":"${debouncedFilterFields.domain}"`)
    if (debouncedFilterFields.ring) filters.push(`"swComponentInfo.ring":"${debouncedFilterFields.ring}"`)
    if (debouncedFilterFields.assetType) filters.push(`"swComponentInfo.assetType":"${debouncedFilterFields.assetType}"`)
    if (debouncedFilterFields.type) filters.push(`"swComponentInfo.deviceType":"${debouncedFilterFields.type}"`)
    if (debouncedFilterFields.name) filters.push(`"swComponentInfo.name":{"$regex":"${debouncedFilterFields.name}", "$options": "i"}`)
    if (debouncedFilterFields.version) filters.push(`"swComponentInfo.version":{"$regex":"${debouncedFilterFields.version}", "$options": "i"}`)
    return filters.length ? { filterBy: filters.join(',') } : {}
  }, [
    debouncedFilterFields.domain,
    debouncedFilterFields.ring,
    debouncedFilterFields.assetType,
    debouncedFilterFields.type,
    debouncedFilterFields.name,
    debouncedFilterFields.version,
  ])
  const [qs, setQs] = React.useState({
    base: {
      pageSize: getDefaultListPerPage('sw-components'),
      pageNumber: 0,
      orderBy: 'swComponentInfo.name',
      orderType: 'desc',
    },
    qsAdditions,
  })
  const { data, isFetching } = useSwComponentsQuery({ ...qs.base, ...qsAdditions })
  const refreshData = setQs

  return (
    <BaseLayout>
      <DataTable
        name="bundles"
        data={defaultTo([], data?.response)}
        refreshData={refreshData}
        qs={qs}
        qsAdditions={qsAdditions}
        dataCount={data?.totalSize || -1}
        dftSortField="swComponentInfo.name"
        dftSortDirection="desc"
        model={SoftwareComponent}
        listDisplay={LIST_DISPLAY}
        fieldsMapping={FIELDS_MAPPING}
        sortMapping={SORT_MAPPING}
        loading={isFetching}
        filterForm={handleFilterForm}
        filterFormActive={values(filter(compose(not, either(isEmpty, isNil)), filterFields)).length}
      />
    </BaseLayout>
  )
}

export default SoftwareComponentsListView
