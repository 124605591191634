import { Chip } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Link from '@Common/Components/Link'
import PropTypes from 'prop-types'
import { defaultTo, includes, pipe, splitEvery, toLower } from 'ramda'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { setStateFromEvent } from '@Common/Utils/Events'

import Box from './Box'
import SearchTextField from './SearchTextField'
import { makePath } from '@Config'

const DataTableItemsListDialog = ({ items, size, title, columns, chipColor }) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  return (
    <>
      {!!items?.length && (
        <Chip label={`${items.length}`} onClick={handleOpen} color={chipColor} size='small' />
      )}

      <Dialog open={isOpen} onClose={handleClose} maxWidth={size} fullWidth>
        <DialogTitle>
          {t(title)}
          <Box direction="row" align="center" justify="left" margin="1rem 0">
            <SearchTextField value={filter} onChange={setStateFromEvent(setFilter)} label='' />
          </Box>
        </DialogTitle>
        <DialogContent>
          <table style={{ width: '100%' }}>
            <tbody>
              {splitEvery(columns, defaultTo([], items).filter(pipe(toLower, includes(toLower(filter))))).map(
                (s, key) => (
                  <tr key={key}>
                    {s.map((item, k) => (
                      <td key={k}><Link to={makePath('vehicles.detail', { deviceId: item })}>{item}</Link></td>
                    ))}
                  </tr>
                ),
              )}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained">
            {t('common:actions.Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

DataTableItemsListDialog.defaultProps = {
  size: 'md',
  columns: 3,
}

DataTableItemsListDialog.propTypes = {
  items: PropTypes.array,
  size: PropTypes.string,
  title: PropTypes.string.isRequired,
  columns: PropTypes.number,
  chipColor: PropTypes.string,
}

export default DataTableItemsListDialog
