import { Check, Close, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Stack, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { FormSectionTitle } from '@Campaigns/Views/CampaignCreateView/Styles'
import Margin from '@Common/Components/Margin'
import NameValueList from '@Common/Components/NameValueList'
import { useConfirm } from '@Common/Utils/Hooks'
import { humanizeDurationMs } from '@Common/Utils/Numbers'
import { operatorMap } from '@SoftwareCatalog/Components/BundleVersionField'

const Highlighted = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.3rem;
  font-weight: 500;
`

const DataRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.2rem 0;
`
const DataLabel = styled.div`
  margin-right: 1rem;
  width: 200px;
  text-align: right;
`
const DataValue = styled.div`
  font-weight: 500;
`

const Data = ({ label, value }) => (
  <DataRow>
    <DataLabel>{label}</DataLabel>
    <DataValue>{value}</DataValue>
  </DataRow>
)

Data.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
}

const StepConfirm = ({
  infoFields,
  jobFields,
  deviceIds,
  cardinality,
  useAbortAwsDefaults,
  useRetryAwsDefaults,
  useRolloutAwsDefaults,
  useTimeoutAwsDefaults,
  expRolloutNumberOf,
  enableCsvUpload,
  csvLines,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const [openCreateConfirm, CreateConfirm] = useConfirm(onSubmit)

  const handleSubmit = () => {
    openCreateConfirm(
      null,
      t('campaigns:ui.CreateCampaignConfirmationTitle'),
      <Trans i18nKey="campaigns:ui.CreateCampaignConfirmationText"></Trans>,
    )
  }

  const campaignInfoSummary = [
    {
      name: <Trans i18nKey={`campaigns:ui.DevicesNumber${enableCsvUpload ? 'Csv' : ''}`} />,
      value: <Highlighted>{(enableCsvUpload && csvLines) || deviceIds.length || cardinality}</Highlighted>,
    },
    {
      name: t('campaigns:ui.CampaignBundleVersion'),
      value: <Highlighted>{infoFields.bundleVersion}</Highlighted>,
    },
    {
      name: t('campaigns:fields.name'),
      value: infoFields.name,
    },
    {
      name: t('campaigns:fields.forceUpdate'),
      value: infoFields.forceUpdate ? <Check color="successLight" /> : <Close color="error" />,
    },
    {
      name: t('campaigns:fields.wipeSettings'),
      value: infoFields.wipeSettings ? <Check color="successLight" /> : <Close color="error" />,
    },
    {
      name: t('campaigns:fields.wipeApplicationData'),
      value: infoFields.wipeApplicationData ? <Check color="successLight" /> : <Close color="error" />,
    },
  ]

  const campaignQuerySummary = [
    {
      name: t('common:fields.domain'),
      value: infoFields.domain,
    },
    {
      name: t('common:fields.ring'),
      value: infoFields.ring,
    },
    {
      name: t('common:fields.assetType'),
      value: infoFields.assetType,
    },
    {
      name: t('common:fields.deviceType'),
      value: infoFields.deviceType,
    },
    {
      name: t('common:ui.CurrentBundleVersion'),
      value: infoFields.queryStringBundleVersion
        ? infoFields.queryStringBundleToVersion
          ? `[${infoFields.queryStringBundleVersion}, ${infoFields.queryStringBundleToVersion}]`
          : `${operatorMap[infoFields.queryStringBundleOp]} ${infoFields.queryStringBundleVersion}`
        : '*',
    },
  ]

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6} sx={{ paddingRight: { md: 6 } }}>
          <FormSectionTitle>{t('campaigns:ui.CampaignSummary')}</FormSectionTitle>
          <NameValueList data={campaignInfoSummary} />
          <hr />
          <NameValueList data={campaignQuerySummary} />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ paddingLeft: { md: 6 }, borderLeft: { md: 1 }, borderLeftColor: { md: 'grey.400' } }}
        >
          <FormSectionTitle>{t('campaigns:ui.JobConfigurationSummary')}</FormSectionTitle>
          <NameValueList
            data={[
              {
                name: t('campaigns:fields.targetSelection'),
                value: jobFields.targetSelection,
              },
              {
                name: t('campaigns:fields.timeoutInMinutes'),
                value: humanizeDurationMs(jobFields.timeoutInMinutes * 60 * 1000, false),
              },
              {
                name: t('campaigns:fields.startTime'),
                value: jobFields.startTime ? jobFields.startTime.format('YYYY-MM-DD HH:mm') : 'Now',
              },
            ]}
          />
          <Accordion style={{ marginTop: '2rem' }}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography sx={{ fontWeight: 'bold' }}>{t('campaigns:ui.Abort')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {useAbortAwsDefaults && <Typography>{t('campaigns:ui.AwsDefaults')}</Typography>}
              {!useAbortAwsDefaults && (
                <>
                  {jobFields.abortAllEnabled && (
                    <Margin bottom="1rem">
                      <Data label={t('campaigns:fields.failureType')} value="All" />
                      <Data label={t('campaigns:fields.action')} value={jobFields.abortAllAction} />
                      <Data
                        label={t('campaigns:fields.minNumberOfExecutedThingsPercentage')}
                        value={jobFields.abortAllMinNumberOfExecutedThingsPercentage}
                      />
                      <Data
                        label={t('campaigns:fields.thresholdPercentage')}
                        value={jobFields.abortAllThresholdPercentage}
                      />
                    </Margin>
                  )}
                  {jobFields.abortFailedEnabled && (
                    <Margin bottom="1rem">
                      <Data label={t('campaigns:fields.failureType')} value="Failed" />
                      <Data label={t('campaigns:fields.action')} value={jobFields.abortFailedAction} />
                      <Data
                        label={t('campaigns:fields.minNumberOfExecutedThingsPercentage')}
                        value={jobFields.abortFailedMinNumberOfExecutedThingsPercentage}
                      />
                      <Data
                        label={t('campaigns:fields.thresholdPercentage')}
                        value={jobFields.abortFailedThresholdPercentage}
                      />
                    </Margin>
                  )}
                  {jobFields.abortRejectedEnabled && (
                    <Margin bottom="1rem">
                      <Data label={t('campaigns:fields.failureType')} value="Rejected" />
                      <Data label={t('campaigns:fields.action')} value={jobFields.abortRejectedAction} />
                      <Data
                        label={t('campaigns:fields.minNumberOfExecutedThingsPercentage')}
                        value={jobFields.abortRejectedMinNumberOfExecutedThingsPercentage}
                      />
                      <Data
                        label={t('campaigns:fields.thresholdPercentage')}
                        value={jobFields.abortRejectedThresholdPercentage}
                      />
                    </Margin>
                  )}
                  {jobFields.abortTimedOutEnabled && (
                    <Margin bottom="1rem">
                      <Data label={t('campaigns:fields.failureType')} value="Timed Out" />
                      <Data label={t('campaigns:fields.action')} value={jobFields.abortTimedOutAction} />
                      <Data
                        label={t('campaigns:fields.minNumberOfExecutedThingsPercentage')}
                        value={jobFields.abortTimedOutMinNumberOfExecutedThingsPercentage}
                      />
                      <Data
                        label={t('campaigns:fields.thresholdPercentage')}
                        value={jobFields.abortTimedOutThresholdPercentage}
                      />
                    </Margin>
                  )}
                </>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography sx={{ fontWeight: 'bold' }}>{t('campaigns:ui.Retry')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {useRetryAwsDefaults && <Typography>{t('campaigns:ui.AwsDefaults')}</Typography>}
              {!useRetryAwsDefaults && (
                <>
                  {jobFields.retryFailedEnabled && (
                    <Data label={t('campaigns:ui.Failed')} value={jobFields.retryFailedNumberOfRetries} />
                  )}
                  {jobFields.retryTimedOutEnabled && (
                    <Data label={t('campaigns:ui.TimedOut')} value={jobFields.retryTimedOutNumberOfRetries} />
                  )}
                  {jobFields.retryAllEnabled && (
                    <Data label={t('campaigns:ui.All')} value={jobFields.retryAllNumberOfRetries} />
                  )}
                </>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography sx={{ fontWeight: 'bold' }}>{t('campaigns:ui.Rollout')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {useRolloutAwsDefaults && <Typography>{t('campaigns:ui.AwsDefaults')}</Typography>}
              {!useRolloutAwsDefaults && (
                <>
                  <Data
                    label={t('campaigns:ui.Type')}
                    value={t(`campaigns:ui.${jobFields.rolloutType === 'LINEAR' ? 'Linear' : 'Exponential'}`)}
                  />
                  {jobFields.rolloutType === 'LINEAR' && (
                    <Data label={t('campaigns:fields.maximumPerMinute')} value={jobFields.rolloutMaximumPerMinute} />
                  )}
                  {jobFields.rolloutType === 'EXP' && (
                    <Data label={t('campaigns:fields.baseRatePerMinute')} value={jobFields.rolloutBaseRatePerMinute} />
                  )}
                  {jobFields.rolloutType === 'EXP' && (
                    <Data label={t('campaigns:fields.incrementFactor')} value={jobFields.rolloutIncrementFactor} />
                  )}
                  {jobFields.rolloutType === 'EXP' && expRolloutNumberOf === 'NOTIFIED' && (
                    <Data
                      label={t('campaigns:fields.numberOfNotifiedThings')}
                      value={jobFields.rolloutNumberOfNotifiedThings}
                    />
                  )}
                  {jobFields.rolloutType === 'EXP' && expRolloutNumberOf === 'SUCCEEDED' && (
                    <Data
                      label={t('campaigns:fields.numberOfSucceededThings')}
                      value={jobFields.rolloutNumberOfSucceededThings}
                    />
                  )}
                </>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography sx={{ fontWeight: 'bold' }}>{t('campaigns:ui.Timeout')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {useTimeoutAwsDefaults && <Typography>{t('campaigns:ui.AwsDefaults')}</Typography>}
              {!useTimeoutAwsDefaults && (
                <>
                  <Data
                    label={t('campaigns:fields.inProgressTimeoutInMinutes')}
                    value={jobFields.timeoutInProgressTimeoutInMinutes}
                  />
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="end" spacing={2} marginTop="2rem">
        <Button variant="contained" color="primary" onClick={onCancel}>
          {t('common:actions.Cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {t('campaigns:ui.Create')}
        </Button>
      </Stack>

      {CreateConfirm}
    </>
  )
}

StepConfirm.propTypes = {
  infoFields: PropTypes.object,
  jobFields: PropTypes.object,
  deviceIds: PropTypes.array,
  cardinality: PropTypes.number,
  useAbortAwsDefaults: PropTypes.bool,
  useRetryAwsDefaults: PropTypes.bool,
  useRolloutAwsDefaults: PropTypes.bool,
  useTimeoutAwsDefaults: PropTypes.bool,
  expRolloutNumberOf: PropTypes.oneOf(['NOTIFIED', 'SUCCEEDED']),
  enableCsvUpload: PropTypes.bool,
  csvLines: PropTypes.number,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
}

export default StepConfirm
