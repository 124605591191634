import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { BundleType } from '@SoftwareCatalog/Models/Bundle'
import BundleStatus from './BundleStatus'
import DeltaStatus from './DeltaStatus'
import { calculateBundleSize } from '@SoftwareCatalog/Utils/Bundles'

const BundleInfoBox = ({ bundle }) => {
  if (!bundle) {
    return null
  }
  console.log('BUNDLE', bundle) // eslint-disable-line

  const { t } = useTranslation()
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>{t('sc:fields.version')}</TableCell>
          <TableCell>{bundle.bundleInfo.bundleVersion}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('common:fields.domain')}</TableCell>
          <TableCell>{bundle.bundleInfo.domain}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('common:fields.ring')}</TableCell>
          <TableCell>{bundle.bundleInfo.ring}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('common:fields.assetType')}</TableCell>
          <TableCell>{bundle.bundleInfo.assetType}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('common:fields.deviceType')}</TableCell>
          <TableCell>{bundle.bundleInfo.deviceType}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('sc:fields.status')}</TableCell>
          <TableCell><BundleStatus status={bundle.bundleStatus.status} /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('sc:fields.deltaStatus')}</TableCell>
          <TableCell><DeltaStatus status={bundle.bundleStatus.deltaStatus} /></TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{t('common:fields.bundleSize')}</TableCell>
          <TableCell>{calculateBundleSize(bundle)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

BundleInfoBox.propTypes = {
  bundle: BundleType,
}

export default BundleInfoBox
