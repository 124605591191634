import i18n from '../../i18n'
import PropTypes from 'prop-types'

export const DeviceType = PropTypes.shape({
  thingName: PropTypes.string.isRequired,
  thingGroupNames: PropTypes.arrayOf(PropTypes.string),
  thingId: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    assetType: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    ring: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    vin: PropTypes.string.isRequired,
  }),
  shadow: PropTypes.shape({
    reported: PropTypes.shape({
      bundleVersion: PropTypes.string.isRequired,
      bundleIncrementalVersion: PropTypes.string.isRequired,
      bundleConsistency: PropTypes.bool,
    }),
    metadata: PropTypes.shape({
      reported: PropTypes.shape({
        bundleVersion: PropTypes.shape({
          timestamp: PropTypes.number,
        }),
        bundleIncrementalVersion: PropTypes.shape({
          timestamp: PropTypes.number,
        }),
        bundleConsistency: PropTypes.shape({
          timestamp: PropTypes.number,
        }),
      }),
    }),
    version: PropTypes.number,
    timestamp: PropTypes.number,
  }),
  connectivity: PropTypes.shape({
    connected: PropTypes.bool,
    timestamp: PropTypes.number,
    disconnectReason: PropTypes.oneOf([
      'AUTH_ERROR',
      'CLIENT_INITIATED_DISCONNECT',
      'CLIENT_ERROR',
      'CONNECTION_LOST',
      'DUPLICATE_CLIENTID',
      'FORBIDDEN_ACCESS',
      'MQTT_KEEP_ALIVE_TIMEOUT',
      'SERVER_ERROR',
      'SERVER_INITIATED_DISCONNECT',
      'THROTTLED',
      'WEBSOCKET_TTL_EXPIRATION',
    ]),
  }),
})

export default {
  primaryKey: 'thingId',
  columns: [
    {
      id: 'thingId',
      label: i18n.t('vehicles:fields.thingId'),
    },
    {
      id: 'thingName',
      label: i18n.t('vehicles:fields.thingName'),
    },
    {
      id: 'attributes.assetType',
      label: i18n.t('common:fields.assetType'),
    },
    {
      id: 'attributes.domain',
      label: i18n.t('common:fields.domain'),
    },
    {
      id: 'attributes.ring',
      label: i18n.t('common:fields.ring'),
    },
    {
      id: 'attributes.type',
      label: i18n.t('vehicles:fields.type'),
    },
    {
      id: 'attributes.vin',
      label: i18n.t('vehicles:fields.vin'),
    },
    {
      id: 'connectivity.connected',
      label: i18n.t('vehicles:fields.connected'),
    },
    {
      id: 'connectivity.disconnectReason',
      label: i18n.t('vehicles:fields.disconnectReason'),
    },
    {
      id: 'connectivity.timestamp',
      label: i18n.t('vehicles:fields.connectivityTimestamp'),
    },
    {
      id: 'shadow.reported.bundleVersion',
      label: i18n.t('vehicles:fields.bundleVersion'),
    },
    {
      id: 'shadow.reported.bundleConsistency',
      label: i18n.t('vehicles:fields.bundleConsistency'),
      boolean: true,
    },
  ],
}
