import React from 'react'
import { useTranslation } from 'react-i18next'
import { FieldType } from '../../Common/Types/Field'
import Dropdown from '../../Common/Components/Dropdown'

const NotificationDropdown = ({ label, value, onChange, required, error, helperText }) => {
  const { t } = useTranslation()
  const options = [
    { value: 'NONE', label: t('common:ui.None') },
    { value: 'MAIL', label: t('common:ui.Mail') },
    { value: 'NOTIFICATION', label: t('common:ui.Notification') },
    { value: 'ALL', label: t('common:ui.All') },
  ]

  return (
    <Dropdown
      label={label}
      required={required}
      value={value}
      onChange={onChange}
      options={options}
      error={error}
      helperText={helperText}
    />
  )
}

NotificationDropdown.defaultProps = {
  required: false,
}

NotificationDropdown.propTypes = FieldType

export default NotificationDropdown
