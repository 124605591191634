import React from 'react'
import TrueChip from '../TrueChip'
import { FalseIcon } from './Styled'
import { int2HexWithPrefix } from '../../Utils/Numbers'
import * as R from 'ramda'
import { datetime } from '../../../Common/Utils/Datetime'
import config from '@Config'
import { humanizeSize } from '@Common/Utils/Files'

export const createColumnsProps = (columns, listDisplay) =>
  columns
    .map((column) => ({
      ...column,
      visible: listDisplay.indexOf(column.id) !== -1,
    }))
    .sort((a, b) => {
      if (listDisplay.indexOf(a.id) !== -1 && listDisplay.indexOf(b.id) !== -1) {
        return listDisplay.indexOf(a.id) - listDisplay.indexOf(b.id)
      } else if (listDisplay.indexOf(a.id) !== -1) {
        return -1
      } else {
        return 1
      }
    })

export const getDisplayValue = (fieldsMapping, record, field, column) => {
  return fieldsMapping[field] ? fieldsMapping[field](record, 'jsx') : getValue(record, field, column)
}

export const getCsvValue = (fieldsMapping, record, field, column) => {
  return fieldsMapping[field] ? fieldsMapping[field](record, 'csv') : getValue(record, field, column, 'csv')
}

export const getValue = (record, field, column = null, format = 'jsx') => {
  const raw = field.split('.').reduce((acc, curr) => (acc ? acc[curr] : null), record)
  if (column && column.datetime && column.inputFormat === 'datestring') {
    return raw ? datetime(raw).format('dddd, MMMM D, YYYY HH:mm') : null
  } else if (column && column.date && column.inputFormat === 'datestring') {
    return raw ? datetime(raw).format('dddd, MMMM D, YYYY') : null
  } else if (column && column.datetime && (!column.inputFormat || column.inputFormat === 'timestamp')) {
    return raw ? datetime(raw * (config.datetime.msTimestamp ? 1 : 1000)).format('dddd, MMMM D, YYYY') : null
  } else if (column && column.date && (!column.inputFormat || column.inputFormat === 'timestamp') ) {
    return raw ? datetime(raw * (config.datetime.msTimestamp ? 1 : 1000)).format('dddd, MMMM D, YYYY') : null
  } else if (column && column.hex) {
    if (format === 'jsx') return R.isNil(raw) ? null : int2HexWithPrefix(raw)
    else return raw
  } else if (column && column.fileSize) {
    if (format === 'jsx') return R.isNil(raw) ? null : humanizeSize(raw)
    else return raw
  } else if (column && column.boolean) {
    if (format === 'jsx') return raw ? <TrueChip /> : <FalseIcon />
    else return raw ? 'true' : 'false'
  }
  return raw
}
