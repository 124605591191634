import i18next from 'i18next'
import * as R from 'ramda'

export const wrap =
  (fn, ...args) =>
  () =>
    fn(...args)

export const nothing = () => {}

// required
export const checkRequired = (err, required, fields) =>
  required.forEach(
    R.ifElse(R.compose(R.not, R.either(R.isEmpty, R.isNil), R.flip(R.prop)(fields)), nothing, setRequired(err)),
  )
export const setRequired = R.curry((err, field) => {
  err[field] = i18next.t('common:validation.requiredField')
})

// not negative integer
export const setGteZeroRequired = R.curry((err, field) => {
  err[field] = i18next.t('common:validation.gteZeroRequired')
})

export const checkGteZero = (err, field, fields) =>
  R.ifElse(
    R.both(
      R.compose(R.not, R.either(R.isNil, R.isEmpty), R.prop(field)),
      R.compose(R.not, R.flip(R.gte)(0), R.prop(field)),
    ),
    wrap(setGteZeroRequired, err, field),
    nothing,
  )(fields)

// hex
export const isHex = (str) => /^[0-9A-Fa-f]*$/.test(str)
export const setHexRequired = R.curry((err, field) => {
  err[field] = i18next.t('common:validation.hexFormatRequired')
})
export const checkHex = (err, field, fields) =>
  R.ifElse(R.compose(R.not, isHex, R.prop(field)), wrap(setHexRequired, err, field), nothing)(fields)

// length
export const setLengthError = R.curry((err, length, field) => {
  err[field] = i18next.t('common:validation.invalidLength', { length })
})
export const checkLength = (err, field, length, fields) =>
  R.ifElse(
    R.both(
      R.compose(R.not, R.isEmpty, R.prop(field)),
      R.compose(R.not, R.equals(length), R.prop('length'), R.prop(field)),
    ),
    wrap(setLengthError, err, length, field),
    nothing,
  )(fields)

// email
export const isEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}
export const setEmailError = R.curry((err, field) => {
  err[field] = i18next.t('common:validation.invalidEmail')
})
export const checkEmail = (err, field, fields) =>
  R.ifElse(R.compose(R.not, isEmail, R.prop(field)), wrap(setEmailError, err, field), nothing)(fields)
