import { makePath } from '@Config'

const tagMap = {
  SW_COMPONENT_ADDED: 'Delta',
  SW_COMPONENT_REMOVED: 'Delta',
  SW_COMPONENT_DELTA_COMPLETED: 'Delta',
  SW_COMPONENT_DELTA_FAILED: 'Delta',
  BUNDLE_ADDED: 'Delta',
  BUNDLE_REMOVED: 'Delta',
  BUNDLE_DELTA_COMPLETED: 'Delta',
  BUNDLE_DELTA_FAILED: 'Delta',
  BUNDLE_OK: 'Delta',
  BUNDLE_ERROR: 'Delta',
  GROUP_CREATION_FAILED: 'Campaign',
  JOB_CREATION_FAILED: 'Campaign',
  JOB_STARTED: 'Campaign',
  JOB_COMPLETED: 'Campaign',
  JOB_CANCELLATION_IN_PROGRESS: 'Campaign',
  JOB_CANCELED: 'Campaign',
  JOB_DELETION_IN_PROGRESS: 'Campaign',
  JOB_DELETED: 'Campaign',
  CAMPAIGN_DAILY_REPORT: 'Campaign',
  CAMPAIGN_EXECUTION_DAILY_REPORT: 'Campaign',
}

export const getNotificationTag = (type) => tagMap[type]
export const getNotificationTagColor = (type) => (getNotificationTag(type) === 'Campaign' ? 'primary' : 'secondary')
export const getNotificationLink = (notification) => {
  const id = notification.data._id
  if (
    notification.type === 'SW_COMPONENT_ADDED' ||
    notification.type === 'SW_COMPONENT_REMOVED' ||
    notification.type === 'SW_COMPONENT_DELTA_COMPLETED' ||
    notification.type === 'SW_COMPONENT_DELTA_FAILED'
  ) {
    return makePath('softwareComponents.detail', { softwareComponentId: id })
  } else if (
    notification.type === 'BUNDLE_ADDED' ||
    notification.type === 'BUNDLE_REMOVED' ||
    notification.type === 'BUNDLE_DELTA_COMPLETED' ||
    notification.type === 'BUNDLE_DELTA_FAILED' ||
    notification.type === 'BUNDLE_OK' ||
    notification.type === 'BUNDLE_ERROR'
  ) {
    return makePath('bundles.detail', { bundleId: id })
  } else if (
    notification.type === 'JOB_COMPLETED' ||
    notification.type === 'JOB_STARTED' ||
    notification.type === 'JOB_CANCELED' ||
    notification.type === 'JOB_CREATION_FAILED' ||
    notification.type === 'GROUP_CREATION_FAILED' ||
    notification.type === 'JOB_CANCELLATION_IN_PROGRESS' ||
    notification.type === 'JOB_DELETION_IN_PROGRESS' ||
    notification.type === 'JOB_DELETED'
  ) {
    return makePath('campaigns.detail', { campaignId: id })
  } else if (notification.type === 'CAMPAIGN_DAILY_REPORT') {
    return null
  } else if (notification.type === 'CAMPAIGN_EXECUTION_DAILY_REPORT') {
    return null
  } else {
    return null
  }
}
