import styled from 'styled-components'

const Margin = styled.div`
  margin-bottom: ${(props) => props.bottom || 0};
  margin-left: ${(props) => props.left || 0};
  margin-right: ${(props) => props.right || 0};
  margin-top: ${(props) => props.top || 0};
`

export default Margin
