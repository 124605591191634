import PropTypes from 'prop-types'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Box from './Box'
import { useDispatch, useSelector } from 'react-redux'
import { selectThemeMode, setThemeMode, selectUtcEnabled, setUtcEnabled } from '@Core/Redux/Ui'
import { Switch } from '@mui/material'

const List = styled(Box)`
  flex-direction: column;
`
const ListItem = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.palette.contrastLight.main};
  padding-bottom: .5rem;
  margin-bottom: .5rem;

  &:last-child {
    border-bottom: 0px solid;
  } 
`

const Text = styled.div``

const SettingsDialog = ({ onClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const themeMode = useSelector(selectThemeMode)
  const utcEnabled = useSelector(selectUtcEnabled)

  const handleToggleDarkMode = () => {
    dispatch(setThemeMode(themeMode === 'light' ? 'dark' : 'light'))
  }

  const handleToggleUtcDatetime = () => {
    dispatch(setUtcEnabled(!utcEnabled))
  }

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{t('common:ui.Settings')}</DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <Text>{t('common:ui.DarkMode')}</Text>
            <Switch checked={themeMode === 'dark'} onChange={handleToggleDarkMode} />
          </ListItem>
          <ListItem>
            <Text>{t('common:ui.UtcDatetime')}</Text>
            <Switch checked={utcEnabled} onChange={handleToggleUtcDatetime} />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant='contained'>
          {t('common:actions.Close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SettingsDialog.propTypes = {
  onClose: PropTypes.func,
}

export default SettingsDialog
