import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { UserType } from '@Auth/Models/User'
import TextField from '@Common/Components/TextField'
import CheckBox from '@Common/Components/CheckBox'
import { useCurrentUser, useForm } from '@Common/Utils/Hooks'
import { setStateFromEvent } from '@Common/Utils/Events'
import { FormControl } from '@Common/Components/Forms'
import RoleDropdown from '@Auth/Components/RoleDropdown'
import { compose, defaultTo, nthArg, path, pipe } from 'ramda'
import Logger from '@Common/Utils/Logger'
import { validate } from './Validation'
import { Tab, Tabs } from '@mui/material'
import TabPanel from '@Common/Components/TabPanel'
import NotificationDropdown from '@Auth/Components/NotificationDropdown'

const UserForm = ({ onClose, user, onSubmit }) => {
  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const title = user ? t('auth:ui.EditUser') : t('auth:ui.InsertUser')
  const [activeTab, setActiveTab] = React.useState(0)

  const handleChangeTab = pipe(nthArg(1), setActiveTab)

  const { fields, setField, errors, setErrors } = useForm({
    name: defaultTo('', user?.userInfo.name),
    eMail: defaultTo('', user?.userInfo.eMail),
    role: defaultTo(null, user?.userInfo.role),
    active: defaultTo(true, user?.userStatus.active),
    swComponentNotification: defaultTo('NOTIFICATION', user?.userSettings.swComponentNotification),
    bundleNotification: defaultTo('NOTIFICATION', user?.userSettings.bundleNotification),
    campaignNotification: defaultTo('NOTIFICATION', user?.userSettings.campaignNotification),
    campaignDailyReportNotification: defaultTo('NOTIFICATION', user?.userSettings.campaignDailyReportNotification),
  })

  const handleSubmit = async () => {
    Logger.debug('User form submission, fields:', fields)
    if (validate(fields, setErrors, errors)) {
      Logger.debug('User form submission, validation passed')
      const { isSuccessful } = await onSubmit(fields)
      isSuccessful && onClose()
    } else {
      Logger.debug('User form submission, validation failed')
      setActiveTab(0) // errors can be only in the first tab
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Tabs value={activeTab} onChange={handleChangeTab}>
          <Tab label={t('auth:ui.Info')} />
          <Tab label={t('auth:ui.Settings')} />
          <Tab label={t('auth:ui.Status')} />
        </Tabs>
        <TabPanel activeTab={activeTab} index={0}>
          <FormControl>
            <TextField
              required
              label={t('auth:fields.userName')}
              value={fields.name}
              onChange={setStateFromEvent(setField('name'))}
              fullWidth
              error={!!errors.name}
              helperText={errors.name}
            />
          </FormControl>
          <FormControl>
            <TextField
              required
              label={t('auth:fields.eMail')}
              value={fields.eMail}
              type='email'
              onChange={setStateFromEvent(setField('eMail'))}
              fullWidth
              error={!!errors.eMail}
              helperText={errors.eMail}
            />
          </FormControl>
          <FormControl>
            <RoleDropdown
              required
              label={t('auth:fields.role')}
              value={fields.role}
              onChange={setStateFromEvent(setField('role'))}
              fullWidth
              error={!!errors.role}
              helperText={errors.role}
              readonly={user?._id && currentUser._id === user._id}
              disabled={user?._id && currentUser._id === user._id}
            />
          </FormControl>
        </TabPanel>
        <TabPanel activeTab={activeTab} index={1}>
          <FormControl>
            <NotificationDropdown
              required
              label={t('auth:fields.swComponentNotification')}
              value={fields.swComponentNotification}
              onChange={setStateFromEvent(setField('swComponentNotification'))}
              fullWidth
              error={!!errors.swComponentNotification}
              helperText={errors.swComponentNotification}
            />
          </FormControl>
          <FormControl>
            <NotificationDropdown
              required
              label={t('auth:fields.bundleNotification')}
              value={fields.bundleNotification}
              onChange={setStateFromEvent(setField('bundleNotification'))}
              fullWidth
              error={!!errors.bundleNotification}
              helperText={errors.bundleNotification}
            />
          </FormControl>
          <FormControl>
            <NotificationDropdown
              required
              label={t('auth:fields.campaignNotification')}
              value={fields.campaignNotification}
              onChange={setStateFromEvent(setField('campaignNotification'))}
              fullWidth
              error={!!errors.campaignNotification}
              helperText={errors.campaignNotification}
            />
          </FormControl>
          <FormControl compact>
            <NotificationDropdown
              required
              label={t('auth:fields.campaignDailyReportNotification')}
              value={fields.campaignDailyReportNotification}
              onChange={setStateFromEvent(setField('campaignDailyReportNotification'))}
              fullWidth
              error={!!errors.campaignDailyReportNotification}
              helperText={errors.campaignDailyReportNotification}
            />
          </FormControl>
        </TabPanel>
        <TabPanel activeTab={activeTab} index={2}>
          <FormControl>
            <CheckBox
              label={t('auth:fields.active')}
              checked={fields.active}
              onChange={compose(setField('active'), path(['target', 'checked']))}
              readOnly={user?._id && currentUser._id === user._id}
              disabled={user?._id && currentUser._id === user._id}
            />
          </FormControl>
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          {t('common:actions.Close')}
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          {t('common:actions.Save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UserForm.propTypes = {
  onClose: PropTypes.func,
  user: UserType,
  onSubmit: PropTypes.func,
}

export default UserForm
