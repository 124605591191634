import { Search } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'
import TextField from './TextField'

const SearchTextField = (props) => {
  return (
     <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        {...props}
      />
  )
}

export default SearchTextField
