import { createSlice } from '@reduxjs/toolkit'

import storageFactory from '@Common/Services/Storage'

const Storage = storageFactory()

const slice = createSlice({
  name: 'ui',
  initialState: {
    breadcrumbs: [],
    notificationsPanelIsOpen: false,
    themeMode: Storage.get('themeMode', 'light'),
    utcEnabled: Storage.get('utcEnabled', false),
  },
  reducers: {
    setBreadcrumbs: (state, { payload }) => {

      console.log('REDUX BREA', payload) // eslint-disable-line
      state.breadcrumbs = payload
    },
    setThemeMode: (state, { payload }) => {
      Storage.save('themeMode', payload)
      state.themeMode = payload
    },
    setUtcEnabled: (state, { payload }) => {
      state.utcEnabled = payload
      Storage.save('utcEnabled', payload)
    },
    toggleNotificationsPanel: (state) => {
      state.notificationsPanelIsOpen = !state.notificationsPanelIsOpen
    }
  },
})

export const {
  setBreadcrumbs,
  setThemeMode,
  setUtcEnabled,
  toggleNotificationsPanel,
} = slice.actions

export default slice.reducer

export const selectBreadcrumbs = (state) => state.ui.breadcrumbs
export const selectThemeMode = (state) => state.ui.themeMode
export const selectUtcEnabled = (state) => state.ui.utcEnabled
export const selectNotificationsPanelIsOpen = (state) => state.ui.notificationsPanelIsOpen
