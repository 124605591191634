import PropTypes from 'prop-types'
import * as R from 'ramda'
import React from 'react'

import { FieldType } from '../Types/Field'
import TextField from './TextField'

const NumberTextField = ({ value, onChange, min, max, warnOnly, onlyInt, ...other }) => {
  const style =
    warnOnly &&
    R.not(R.isEmpty(value)) &&
    R.not(R.isNil(value)) &&
    ((R.not(R.isNil(min)) && value < min) || (R.not(R.isNil(max)) && value > max))
      ? { background: '#ff9900' }
      : {}

  return (
    <TextField
      value={value}
      onKeyPress={(evt => {
        if (onlyInt && (evt.charCode < 48 || evt.charCode > 57)) evt.preventDefault()
      })}
      onChange={(evt) => {
        const value = R.isEmpty(evt.target.value) ? null : parseFloat(evt.target.value)
        if (!warnOnly) {
          if (R.not(R.isNil(min)) && R.not(R.isNil(value)) && R.lt(value, min)) return null
          if (R.not(R.isNil(max)) && R.not(R.isNil(value)) && R.gt(value, max)) return null
        }
        onChange({ ...evt, target: { ...evt.target, value } })
      }}
      type="number"
      inputProps={{ style }}
      {...other}
    />
  )
}

NumberTextField.propTypes = {
  ...FieldType,
  min: PropTypes.number,
  max: PropTypes.number,
  warnOnly: PropTypes.bool,
  onlyInt: PropTypes.bool,
}

export default NumberTextField
