import styled from 'styled-components'
import { useTheme } from '@mui/material/styles'
import React from 'react'

const Bar = styled.div`
  border-radius: 8px;
  height:15px;
  overflow: hidden;
  width:100px;
`
const Progress = styled.div`
  height:100%;
`
const ProgressBar = (progress) => {
    const theme = useTheme()
    return (
        <Bar style={{backgroundColor:theme.palette.contrastMedium.main}}>
            <Progress style={{width:`${progress.progress}px`,backgroundColor:theme.palette.infoLight.main}}></Progress>
        </Bar>
    )
}
export default ProgressBar
