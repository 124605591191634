import { checkEmail, checkRequired } from '@Common/Utils/Validation'
import * as R from 'ramda'

export const validate = (fields, setErrors) => {
  const err = {}

  const required = ['name', 'eMail', 'role']
  checkRequired(err, required, fields)
  checkEmail(err, 'eMail', fields)

  setErrors(err)

  return R.isEmpty(err)
}
