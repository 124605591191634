import { Table, TableBody, TableCell, TableRow } from '@mui/material'
import MuiLink from '@mui/material/Link'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CampaignType } from '@Campaigns/Models/Campaign'
import { useLazyCampaignDownloadFileQuery } from '@Campaigns/Services/Api'
import Box from '@Common/Components/Box'
import DataTableItemsListDialog from '@Common/Components/DataTableItemsListDialog'
import Link from '@Common/Components/Link'
import { datetime } from '@Common/Utils/Datetime'
import Config, { makePath } from '@Config'

import CampaignStatus from '../CampaignStatus'

const TableHeadCell = styled(TableCell)`
  font-weight: bold;
  text-align: right;
`

const CampaignInfo = ({ campaign }) => {
  const { t } = useTranslation()

  const [downloadUrl, setDownloadUrl] = useState()
  const [fetchDownloadUrl] = useLazyCampaignDownloadFileQuery()

  useEffect(() => {
    const handleDownloadUrl = async () => {
      const { data: { downloadUrl }, isSuccess } = await fetchDownloadUrl(campaign._id)
      if (isSuccess) setDownloadUrl(downloadUrl)
    }
    if (campaign.campaignInfo.fileName) {
      handleDownloadUrl()
    }
  }, [])

  const getCampaignType = ({ campaignInfo }) => {
    if (campaignInfo.fileName) {
      return t('campaigns:ui.CsvFile')
    } else if (campaignInfo.deviceIds?.length) {
      return t('campaigns:ui.DevicesList')
    } else {
      return t('campaigns:ui.Query')
    }
  }

  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableHeadCell>{t('campaigns:fields.cardinality')}</TableHeadCell>
          <TableCell>{campaign.group.thingsCardinality}</TableCell>
        </TableRow>
        <TableRow>
          <TableHeadCell>{t('campaigns:ui.Target')}</TableHeadCell>
          <TableCell>{campaign.job.jobDefinition.targetSelection}</TableCell>
        </TableRow>
        <TableRow>
          <TableHeadCell>{t('campaigns:ui.Type')}</TableHeadCell>
          <TableCell>
            <Box direction="row" justify="space-between">
              <span>{getCampaignType(campaign)}</span>
              {campaign.campaignInfo.fileName && downloadUrl && (
                <MuiLink style={{ cursor: 'pointer' }} href={downloadUrl} download target="_blank">
                  {t('campaigns:ui.DownloadFile')}
                </MuiLink>
              )}
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHeadCell>{t('fields.domain')}</TableHeadCell>
          <TableCell>{campaign.campaignInfo.domain}</TableCell>
        </TableRow>
        <TableRow>
          <TableHeadCell>{t('fields.ring')}</TableHeadCell>
          <TableCell>{campaign.campaignInfo.ring}</TableCell>
        </TableRow>
        <TableRow>
          <TableHeadCell>{t('fields.assetType')}</TableHeadCell>
          <TableCell>{campaign.campaignInfo.assetType}</TableCell>
        </TableRow>
        <TableRow>
          <TableHeadCell>{t('fields.deviceType')}</TableHeadCell>
          <TableCell>{campaign.campaignInfo.deviceType}</TableCell>
        </TableRow>
        <TableRow>
          <TableHeadCell>{t('ui.Bundle')}</TableHeadCell>
          <TableCell>
            <Link to={makePath('bundles.detail', { bundleId: campaign.campaignInfo.bundleId })}>
              {campaign.campaignInfo.bundleVersion}
            </Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHeadCell>{t('ui.CreatedBy')}</TableHeadCell>
          <TableCell>{campaign.campaignStatus.createdBy}</TableCell>
        </TableRow>
        <TableRow>
          <TableHeadCell>{t('ui.CreatedAt')}</TableHeadCell>
          <TableCell>
            {datetime(campaign.campaignStatus.creationTimestamp * (Config.datetime.msTimestamp ? 1 : 1000)).format(
              'dddd, MMMM D, YYYY HH:mm',
            )}
          </TableCell>
        </TableRow>
        {campaign.job.jobDefinition.schedulingConfig?.startTime && <TableRow>
          <TableHeadCell>{t('campaigns:ui.ScheduledAt')}</TableHeadCell>
          <TableCell>
            {datetime(campaign.job.jobDefinition.schedulingConfig?.startTime)?.format('dddd, MMMM D, YYYY HH:mm')}
          </TableCell>
        </TableRow>}
        <TableRow>
          <TableHeadCell>{t('ui.Status')}</TableHeadCell>
          <TableCell>
            <CampaignStatus status={campaign.campaignStatus.status} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHeadCell>{t('ui.StatusUpdatedAt')}</TableHeadCell>
          <TableCell>
            {datetime(campaign.campaignStatus.statusTimestamp * (Config.datetime.msTimestamp ? 1 : 1000)).format(
              'dddd, MMMM D, YYYY HH:mm',
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHeadCell>{t('campaigns:fields.deviceIds')}</TableHeadCell>
          <TableCell>
            <DataTableItemsListDialog
              items={campaign.campaignInfo.deviceIds}
              title={t('campaigns:fields.deviceIds')}
              size="lg"
              chipColor="infoLight"
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableHeadCell>{t('campaigns:fields.failedDeviceIds')}</TableHeadCell>
          <TableCell>
            <DataTableItemsListDialog
              items={campaign.campaignInfo.failedDeviceIds}
              title={t('campaigns:fields.failedDeviceIds')}
              size="lg"
              chipColor="errorLight"
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

CampaignInfo.propTypes = {
  campaign: CampaignType,
}

export default CampaignInfo
