import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Badge, Switch, Tab, Tabs, Dialog, DialogActions,DialogTitle, DialogContent, Button } from '@mui/material'
import { compose, isEmpty, nthArg, path } from 'ramda'
import getStorage from '@Common/Services/Storage'
import styled from 'styled-components'
import Dropdown from '@Common/Components/Dropdown'
import NumberTextField from '@Common/Components/NumberTextField'
import Config from '@Config'
import { useForm } from '@Common/Utils/Hooks'
import { checkRequired } from '@Common/Utils/Validation'
import { setStateFromEvent } from '@Common/Utils/Events'
import { useState } from 'react'
import i18next from 'i18next'
import Box from './Box'

const Row = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.contrastLight.main};
  width: 100%;
  padding: 0.5rem 0;
`
const Small = styled.small`
  color: ${({ theme }) => theme.palette.infoLight.main};
  font-style: italic;
`

const storageService = getStorage()

const validate = (fields, setErrors) => {
  const errors = {}
  const required = ['loggerLevel', 'inputsDebounceTime']
  checkRequired(errors, required, fields)

  if (fields.inputsDebounceTime < 300) {
    errors.inputsDebounceTime = i18next.t('validation.gteRequired', { value: 300 })
  }

  setErrors(errors)
  return isEmpty(errors)
}

// eslint-disable-next-line react/prop-types
const TabPanel = ({ value, index, children }) =>
  value === index ? (
    <Box pad="0 2rem" width="100%">
      {children}
    </Box>
  ) : null

const AdvandecSettingsModal = ({ onClose }) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(0)

  const { fields, setField, errors, setErrors } = useForm({
    loggerLevel: Config.logger.level,
    inputsDebounceTime: Config.ui.inputsDebounceTime,
    enableDataTableColumnsResizing: Config.ui.enableDataTableColumnResizing,
  })

  const handleSubmit = () => {
    if (validate(fields, setErrors)) {
      storageService.save('configLoggerLevel', fields.loggerLevel)
      storageService.save('configInputsDebounceTime', fields.inputsDebounceTime)
      storageService.save('configEnableDataTableColumnResizing', fields.enableDataTableColumnsResizing)
      window.location.reload(true)
    }
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t('ui.Settings')}</DialogTitle>
      <DialogContent>
        <Box direction="row" width="100%">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={activeTab}
            onChange={compose(setActiveTab, nthArg(1))}
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label={t('ui.Main')} />
            <Tab label={t('ui.Ui')} />
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            <Row direction="row" align="center" justify="space-between">
              <div>
                <div>{t('ui.LoggerLevel')}</div>
                <Small>{t('ui.LoggerLevelHelpText')}</Small>
              </div>
              <Dropdown
                required
                value={fields.loggerLevel}
                width="100px"
                onChange={setStateFromEvent(setField('loggerLevel'))}
                options={[
                  { value: 'DEBUG', label: 'DEBUG' },
                  { value: 'INFO', label: 'INFO' },
                  { value: 'WARNING', label: 'WARNING' },
                  { value: 'ERROR', label: 'ERROR' },
                ]}
                error={!!errors.loggerLevel}
                helperText={errors.loggerLevel}
              />
            </Row>
            <Row direction="row" align="center" justify="space-between">
              <div>
                <div>{t('ui.InputDebounceTime')}</div>
                <Small>
                  {t('ui.InputDebounceTimeHelpText')}
                </Small>
              </div>
              <NumberTextField
                required
                value={fields.inputsDebounceTime}
                style={{ minWidth: '100px', width: '100px' }}
                onChange={setStateFromEvent(setField('inputsDebounceTime'))}
                min={300}
                error={!!errors.inputsDebounceTime}
                helperText={errors.inputsDebounceTime}
                warnOnly
              />
            </Row>
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <Row direction="row" align="center" justify="space-between">
              <div>
                <div>
                <Badge badgeContent={'EXP'} color="warning">
                  <div style={{ paddingRight: '1rem' }}>{t('ui.EnableDataTableColumnsResizing')}</div>
                </Badge>
</div>
                <Small>
                  {t('ui.EnableDataTableColumnsResizingHelpText')}
                </Small>
              </div>
              <Switch
                checked={Boolean(fields.enableDataTableColumnsResizing)}
                onChange={compose(
                  setField('enableDataTableColumnsResizing'),
                  path(['target', 'checked']),
                )}
              />
            </Row>
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('actions.Cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {t('actions.SaveAndReload')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AdvandecSettingsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default AdvandecSettingsModal
