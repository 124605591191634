import { Delete, Edit, MailOutlined, NotificationsOutlined } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { compose, either, filter, isEmpty, isNil, not, values } from 'ramda'
// import Link from '@Common/Components/Link'
import React from 'react'
import { useTranslation } from 'react-i18next'

import IconTooltip from '@Common/Components/IconTooltip'
import UsersFilterForm from '@Auth/Forms/UsersFilterForm'
import User from '@Auth/Models/User'
import UsersPermissions from '@Auth/Permissions'
import { useUsersQuery } from '@Auth/Services/Api'
// import { useTranslation } from 'react-i18next'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import Link from '@Common/Components/Link'
import { useConfirm, useDebounce, useForm } from '@Common/Utils/Hooks'
import { apiList } from '@Core/Services/Api'
import i18next from 'i18next'
import { readFromStorage, storeInStorage } from '@Common/Utils/Storage'

const LIST_DISPLAY = [
  'userInfo.name',
  'userInfo.eMail',
  'userInfo.role',
  'userSettings.swComponentNotification',
  'userSettings.bundleNotification',
  'userSettings.campaignNotification',
  'userSettings.campaignDailyReportNotification',
  'userStatus.active',
]

const notificationMap = type => record => { // eslint-disable-line
  const value = record.userSettings[type]
  if (value === 'ALL') return (
    <>
      <IconTooltip Icon={MailOutlined} title={i18next.t('common:ui.Mail')} />
      <IconTooltip Icon={NotificationsOutlined} title={i18next.t('common:ui.AppNotification')} />
    </>
  )
  else if (value === 'MAIL') return <IconTooltip Icon={MailOutlined} title={i18next.t('common:ui.Mail')} />
  else if (value === 'NOTIFICATION') return <IconTooltip Icon={NotificationsOutlined} title={i18next.t('common:ui.AppNotification')} />
  else return null
}

const FIELDS_MAPPING = {
  'userInfo.eMail': (record) => <Link href={`mailto:${record.eMail}`}>{record.userInfo.eMail}</Link>,
  'userSettings.swComponentNotification': notificationMap('swComponentNotification'),
  'userSettings.bundleNotification': notificationMap('bundleNotification'),
  'userSettings.campaignNotification': notificationMap('campaignNotification'),
  'userSettings.campaignDailyReportNotification': notificationMap('campaignDailyReportNotification'),
}

const FILTERS_STORAGE_KEY = 'filters-admin-users'

const UsersList = ({ onEdit, onDelete }) => {
  const { t } = useTranslation()

  const { fields: filterFields, setField: setFilterField, setFields: setFilterFields } = useForm(readFromStorage(FILTERS_STORAGE_KEY, {}))
  const debouncedFilterFields = useDebounce(filterFields)
  const handleFilterForm = React.useCallback(
    (handleClose) => {
      return (
        <UsersFilterForm
          onClose={handleClose}
          fields={filterFields}
          setField={setFilterField}
          onReset={() => setFilterFields({})}
          onSave={() => storeInStorage(FILTERS_STORAGE_KEY, filterFields)}
        />
      )
    },
    [UsersFilterForm, filterFields, setFilterField],
  )

  const qsAdditions = React.useMemo(() => {
    const filters = []
    if (debouncedFilterFields.eMail) filters.push(`"userInfo.eMail":{"$regex":"${debouncedFilterFields.eMail}", "$options": "i"}`)
    if (not(isNil(debouncedFilterFields.active)))
      filters.push(`"userStatus.active":${debouncedFilterFields.active ? 'true' : 'false'}`)
    return filters.length ? { filterBy: filters.join(',') } : {}
  }, [debouncedFilterFields.eMail, debouncedFilterFields.active])

  const [qs, setQs] = React.useState({
    base: {
      pageSize: getDefaultListPerPage('users'),
      pageNumber: 0,
      orderBy: 'userInfo.name',
      orderType: 'asc',
    },
    qsAdditions,
  })

  const refreshData = setQs
  const { data: users, isFetching, orData } = apiList(useUsersQuery({ ...qs.base, ...qsAdditions }))

  const actions = React.useMemo(
    () => [
      {
        id: 'EDIT',
        label: t('common:actions.Edit'),
        icon: <Edit />,
        perm: UsersPermissions.update,
      },
      {
        id: 'DELETE',
        label: t('common:actions.Delete'),
        icon: <Delete />,
        perm: UsersPermissions.delete,
      },
    ],
    [],
  )

  const [openDeleteConfirm, DeleteConfirm] = useConfirm(onDelete)

  const handleAction = React.useCallback(async (actionId, user) => {
    if (actionId === 'EDIT') {
      onEdit(user)
    } else if (actionId === 'DELETE') {
      openDeleteConfirm(
        user, // payload
        t('auth:ui.DeleteUserConfirmationTitle'),
        t('auth:ui.DeleteUserConfirmationText'),
        { user, field: 'userName' },
      )
    }
  }, [])

  return (
    <>
      <DataTable
        name="users"
        data={users}
        refreshData={refreshData}
        qs={qs}
        dataCount={orData?.totalSize || -1}
        dftSortField="userName"
        dftSortDirection="asc"
        model={User}
        listDisplay={LIST_DISPLAY}
        fieldsMapping={FIELDS_MAPPING}
        loading={isFetching}
        actions={actions}
        onAction={handleAction}
        filterForm={handleFilterForm}
        filterFormActive={values(filter(compose(not, either(isEmpty, isNil)), filterFields)).length}
      />
      {DeleteConfirm}
    </>
  )
}

UsersList.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default UsersList
