import React from 'react'
import { useCurrentUser } from '../../Utils/Hooks'
import { useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import getStorage from '../../../Common/Services/Storage'
import Typography from '@mui/material/Typography'
import ActionsButton from '../ActionsButton'
import Dropdown from '../Dropdown'
import Box from '../Box'
import Cursor from '../Cursor'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from './TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Checkbox from '@mui/material/Checkbox'
import SettingsDialog from './SettingsDialog'
import Loader from '../Loader'
import ExportAction from './ExportAction'
import {
  Toolbar,
  BulkAndChildren,
  SelectAll,
  SelectAllLink,
  SettingsIcon,
  Tr,
} from './Styled'
import { createColumnsProps, getDisplayValue, getValue } from './Utils'
import { setStateFromEvent } from '../../Utils/Events'
import config from '../../../Config'
import * as R from 'ramda'
import { toast } from 'react-toastify'
import { Tooltip } from '@mui/material'
import { Cached } from '@mui/icons-material'
import { useResizableColumns } from './Hooks'

const storageService = getStorage()

const DataTable = ({
  name,
  autoWidth,
  data,
  refreshData,
  selectable,
  selectOnRowClick,
  selected,
  onSelect,
  model,
  defaultListPerPage,
  listDisplay,
  fieldsMapping,
  sortMapping,
  sortField,
  sortDirection,
  actions,
  bulkActions,
  onAction,
  noSettings,
  noToolbar,
  noExport,
  noBulkSelection,
  onExpandRow,
  loading,
  children,
  filterForm,
  filterFormActive,
  hidePagination,
  disableSorting,
  disableSticky,
  noPageField,
  disableResizing,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const storageKey = `${name}_DATA_TABLE_SETTINGS`

  // columns resizing
  const resizingData = storageService.get(
    `${name}_DATA_TABLE_RESIZING`,
    {},
  )

  // sticky
  const sticky = {
    position: 'sticky',
    background: theme.palette.content.main,
    zIndex: 2,
  }

  const stickyLeft = {
    ...sticky,
    left: 0,
  }

  const stickyRight = {
    ...sticky,
    right: 0,
  }

  // check storage for invalidation
  if (
    config.localStorageInvalidation[storageKey] &&
    storageService.get(storageKey)
  ) {
    const storageTimestamp = storageService.getNested(
      storageKey,
      'timestamp',
      0,
    )
    if (storageTimestamp < config.localStorageInvalidation[storageKey]) {
      storageService.remove(`${name}_DATA_TABLE_SETTINGS`)
      toast.warn(t('common:ui.ForceResetChangelistTableSettingsWarning'))
    }
  }

  // logged user
  const user = useCurrentUser()
  // primary key
  const primaryKeyValue = (item) =>
    model.primaryKey
      .split(',')
      .map((f) => item[f])
      .join('-')

  // sort field
  const [defaultSortField, setDefaultSortField] = React.useState(
    storageService.getNested(storageKey, 'defaultSortField', sortField),
  )

  const [defaultSortDirection, setDefaultSortDirection] = React.useState(
    storageService.getNested(storageKey, 'defaultSortDirection', sortDirection),
  )

  // bulk
  const [selectedBulk, setSelectedBulk] = React.useState(null)
  const executeBulkAction = () => {
    const action = R.find(R.propEq('id', selectedBulk))(bulkActions)
    return action && onAction(action.id)
  }
  // collapsable
  const [openRow, setOpenRow] = React.useState(null)

  // list per page
  const [listPerPage, setListPerPage] = React.useState(
    storageService.getNested(storageKey, 'listPerPage', defaultListPerPage),
  )

  // columns
  const [columnsProps, setColumnProps] = React.useState([])
  React.useEffect(() => {
    setColumnProps(
      R.ifElse(
        R.isNil,
        () => createColumnsProps(model.columns, listDisplay),
        R.identity,
      )(storageService.getNested(storageKey, 'columns')),
    )
  }, [storageKey, listDisplay])

  // columns selection
  const handleSelectColumn = (id) => (event) => {
    setColumnProps(
      columnsProps.map((column) =>
        R.ifElse(
          R.equals(id),
          R.always({ ...column, visible: R.prop('checked')(event.target) }),
          R.always(column),
        )(column.id),
      ),
    )
  }

  // filter form
  const [filterFormIsOpen, setFilterFormIsOpen] = React.useState(false)
  const handleOpenFilterForm = () => setFilterFormIsOpen(true)
  const handleCloseFilterForm = () => setFilterFormIsOpen(false)

  // settings
  const saveSettings = () => {
    storageService.save(storageKey, {
      timestamp: new Date().getTime(),
      columns: columnsProps,
      listPerPage,
      defaultSortField,
      defaultSortDirection,
    })
    setSettingsDialogIsOpen(false)
  }

  const resetSettings = () => {
    storageService.remove(storageKey)
    setColumnProps(createColumnsProps(model.columns, listDisplay))
    setListPerPage(defaultListPerPage)
    setSettingsDialogIsOpen(false)
    setDefaultSortField(sortField)
    setDefaultSortDirection(sortDirection)
    setSort({ field: sortField, direction: 'sortDirection' })
  }

  // columns to be displayed
  const displayColumns = columnsProps.filter((c) => c.visible)

  // settings
  const [settingsDialogIsOpen, setSettingsDialogIsOpen] = React.useState(false)
  const handleOpenSettings = React.useCallback(
    () => setSettingsDialogIsOpen(true),
    [setSettingsDialogIsOpen],
  )

  // selection
  const selectedIds = selected?.map((item) => primaryKeyValue(item))
  // page selection
  const handleSelectPage = (event) => {
    const displayDataIds = displayData.map(R.prop(model.primaryKey))
    const selectedIds = selected.map(R.prop(model.primaryKey))
    onSelect(
      R.ifElse(
        R.prop('checked'),
        R.always([...selected, ...displayData.filter(R.pipe(R.prop(model.primaryKey), R.flip(R.indexOf)(selectedIds), R.equals(-1)))]),
        R.always([...selected.filter(R.pipe(R.prop(model.primaryKey), R.flip(R.indexOf)(displayDataIds), R.equals(-1)))]),
      )(event.target),
    )
  }
  // record selection
  const handleSelectRecord = (record) => (event) =>
    onSelect(
      R.ifElse(
        R.prop('checked'),
        R.always([...selected, record]),
        R.always(
          selected.filter(
            R.compose(
              R.not,
              R.equals(primaryKeyValue(record)),
              primaryKeyValue,
            ),
          ),
        ),
      )(event.target),
      record,
      event.target.checked,
    )
  // all items selection
  const handleSelectAll = () => onSelect(data.map((record) => record))
  // clear selection
  const handleClearSelection = () => onSelect && onSelect([])

  // sorting
  const [sort, setSort] = React.useState({
    field: defaultSortField,
    direction: defaultSortDirection,
  })
  const handleSortChange = (field) => () =>
    setSort({
      field,
      direction:
        sort.field === field && sort.direction === 'asc' ? 'desc' : 'asc',
    })

  const handleChangeDefaultSortField = (field) => {
    setDefaultSortField(field)
    setSort({ field, direction: defaultSortDirection })
  }

  const handleChangeDefaultSortDirection = (direction) => {
    setDefaultSortDirection(direction)
    setSort({ field: defaultSortField, direction: direction })
  }

  // pagination
  const [page, setPage] = React.useState(0)
  // reset on data change
  React.useEffect(() => setPage(0), [data.length, listPerPage])

  // sort and paginate
  // sort
  const getSortValue = (record, field) =>
    sortMapping[field] ? sortMapping[field](record) : getValue(record, field)
  const sortedData = [...data].sort((a, b) => {
    const aValue = getSortValue(a, sort.field)
    const bValue = getSortValue(b, sort.field)
    return R.ifElse(R.isNil, R.always(1), () =>
      R.ifElse(
        R.isNil,
        R.always(-1),
        () => (bValue < aValue ? 1 : -1) * (sort.direction === 'asc' ? 1 : -1),
      )(bValue),
    )(aValue)
  })
  // pagination
  const displayData = sortedData.slice(
    page * Math.max(1, listPerPage),
    (page + 1) * Math.max(1, listPerPage),
  )

  // col span calculation
  const colSpan =
    displayColumns.length +
    (selectable ? 0 : 1) +
    (onExpandRow ? 1 : 0) +
    (actions && actions.length ? 1 : 0)


  useResizableColumns(name, data, displayColumns, loading, { disabled: disableResizing })

  return (
    <>
      {(!!bulkActions.length || children) && (
        <BulkAndChildren>
          {!!bulkActions.length && (
            <>
              <Dropdown
                label={t('common:ui.Commands')}
                width="300px"
                value={selectedBulk}
                onChange={setStateFromEvent(setSelectedBulk)}
                options={bulkActions.map((a) => ({
                  value: a.id,
                  label: a.label,
                  icon: a.icon,
                }))}
              />
              <Button
                size="small"
                disabled={!selectedBulk || !selected.length}
                onClick={executeBulkAction}
              >
                {t('common:ui.Go')}
              </Button>
            </>
          )}
          {children}
        </BulkAndChildren>
      )}
      {!noToolbar && (selectable || filterForm || !noSettings || !noExport || refreshData) && (
        <Toolbar
          bg={theme.palette.changelistToolbar.main}
          color={theme.palette.changelistToolbar.contrastText}
        >
          <Box direction='row' align='center'>
            {selectable && !noBulkSelection && (
              <Typography component="div">
                {t('common:ui:numSelectedTot', { num: selected.length, tot: data.length })}
              </Typography>
            )}
            {selectable &&
              !noBulkSelection &&
              selected.length === displayData.length &&
              selected.length !== data.length && (
                <SelectAll component="div">
                  <SelectAllLink onClick={handleSelectAll}>
                    {t('common:ui:selectAll')}
                  </SelectAllLink>
                </SelectAll>
              )}
            {selectable && !noBulkSelection && !!selected.length && (
              <SelectAll component="div">
                <SelectAllLink onClick={handleClearSelection}>
                  {t('common:ui:clearSelection')}
                </SelectAllLink>
              </SelectAll>
            )}
            {(!selectable || noBulkSelection) && data.length > 0 && (
              <Typography component="div">{t('ui.Total')}: {data.length}</Typography>
            )}
          </Box>
          <div>
            {!!refreshData && (
              <Cursor onClick={refreshData}>
                <Tooltip title={t('common:buttons.Refresh')}>
                  <Cached />
                </Tooltip>
              </Cursor>
            )}
            {filterForm && (
              <Cursor>
                <Tooltip title={t('common:actions.Filter')}>
                  <FilterAltIcon
                    onClick={handleOpenFilterForm}
                    color={filterFormActive ? 'primary' : undefined}
                    style={{ marginLeft: '.5rem' }}
                  />
                </Tooltip>
              </Cursor>
            )}
            {!noExport && (
              <ExportAction
                name={name}
                displayColumns={displayColumns}
                sortedData={sortedData}
                fieldsMapping={fieldsMapping}
                marginLeft=".5rem"
              />
            )}
            {!noSettings && (
              <Cursor>
                <Tooltip title={t('common:ui.Settings')}>
                  <SettingsIcon
                    onClick={handleOpenSettings}
                    style={{ marginLeft: '.5rem' }}
                  />
                </Tooltip>
              </Cursor>
            )}
          </div>
        </Toolbar>
      )}
      <TableContainer style={{ maxWidth: '100%', overflow: 'auto' }} id={`id-table-${name}`} className='datatable'>
        <Table style={autoWidth ? { width: 'auto' } : {}} size="small" id={`id-table-${name}`} className={`datatable${config.ui.enableDataTableColumnResizing ? ' resizable-active' : ''}`}>
          <TableHead key="thead">
            <TableRow key="thead-row">
              {selectable && (
                <TableCell
                  padding="checkbox"
                  style={{ width: '20px' }}
                  key="col-selection"
                  className='fix'
                >
                  {!noBulkSelection && (
                    <Checkbox
                      indeterminate={
                        selected.length > 0 &&
                        selected.length < displayData.count
                      }
                      checked={
                        displayData.length !== 0 &&
                        displayData.filter(
                          (item) =>
                            selectedIds.indexOf(primaryKeyValue(item)) === -1,
                        ).length === 0
                      }
                      onChange={handleSelectPage}
                    />
                  )}
                </TableCell>
              )}
              {displayColumns.map((column, idx) => {
                const widthStyles = {width: column.width ? column.width : 'auto'}
                const resizableIdx = selectable ? idx + 1 : idx
                if (config.ui.enableDataTableColumnResizing && resizingData[resizableIdx]) {
                  widthStyles.width = `${resizingData[resizableIdx]}px`
                  widthStyles.minWidth = `${resizingData[resizableIdx]}px`
                  widthStyles.maxWidth = `${resizingData[resizableIdx]}px`
                }
                return (
                  <TableCell
                    key={`col-${column.id}`}
                    align={column.numeric ? 'right' : 'left'}
                    sortDirection={
                      sort.field === column.id ? sort.direction : false
                    }
                    style={{
                      ...widthStyles,
                      ...(idx === 0 && !disableSticky ? stickyLeft : {}),
                    }}
                    className={(idx === displayColumns.length - 1 && !actions?.length) ? 'fix th-col-name' : 'th-col-name'}
                  >
                    {!disableSorting && !column.disableSorting && (
                      <TableSortLabel
                        active={sort.field === column.id}
                        direction={
                          sort.field === column.id ? sort.direction : 'asc'
                        }
                        onClick={handleSortChange(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                )
              })}
              {actions && actions.length && <TableCell key="col-actions" className='fix' style={disableSticky ? {} : stickyRight} />}
              {onExpandRow && (
                <TableCell className='fix' key="col-collapse" style={{ width: '30px' }} />
              )}
            </TableRow>
          </TableHead>
          {!loading && (
            <TableBody key="tbody">
              {displayData.map((record, index) => {
                const isSelected =
                  selected?.filter(
                    R.compose(
                      R.equals(primaryKeyValue(record)),
                      primaryKeyValue,
                    ),
                  ).length === 1
                const allowedActions =
                  actions && actions.length
                    ? actions.filter((a) => {
                        return (
                          (!a.perm || a.perm(user, record)) &&
                          (!a.cond || a.cond(record))
                        )
                      })
                    : []
                return (
                  <React.Fragment key={`row-${index}`}>
                    <Tr
                      hover
                      data-no-border={!!onExpandRow}
                      onClick={() => {
                        return selectOnRowClick
                          ? handleSelectRecord(record)({
                              target: { checked: !isSelected },
                            })
                          : null
                      }}
                    >
                      {selectable && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected}
                            onChange={handleSelectRecord(record)}
                          />
                        </TableCell>
                      )}
                      {displayColumns.map((column, idx) => {
                        const widthStyles = {width: column.width ? column.width : 'auto'}
                        const resizableIdx = selectable ? idx + 1 : idx
                        if (config.ui.enableDataTableColumnResizing && resizingData[resizableIdx]) {
                          widthStyles.width = `${resizingData[resizableIdx]}px`
                          widthStyles.minWidth = `${resizingData[resizableIdx]}px`
                          widthStyles.maxWidth = `${resizingData[resizableIdx]}px`
                        }
                        return (
                          <TableCell
                            key={`col-${column.id}`}
                            align={column.numeric ? 'right' : 'left'}
                            style={{
                              ...R.defaultTo({}, column.style),
                              ...widthStyles,
                              ...(idx === 0 && !disableSticky ? stickyLeft : {}),
                            }}
                          >
                            {getDisplayValue(
                              fieldsMapping,
                              record,
                              column.id,
                              column,
                            )}
                          </TableCell>
                        )
                      })}
                      {actions && actions.length && (
                        <TableCell
                          align="right"
                          key={`col-actions-${index}`}
                          style={disableSticky ? {} : stickyRight}
                        >
                          {!!allowedActions.length && (
                            <ActionsButton
                              actions={allowedActions}
                              onClick={(action) => onAction(action.id, record)}
                            />
                          )}
                        </TableCell>
                      )}
                      {onExpandRow && (
                        <TableCell align="right" key={`col-expand-${index}`}>
                          <IconButton
                            size="small"
                            onClick={() =>
                              setOpenRow(openRow === index ? null : index)
                            }
                          >
                            {openRow === index ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      )}
                    </Tr>
                    {onExpandRow && (
                      <TableRow key={`expand-${index}`}>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={colSpan}
                        >
                          <Collapse
                            in={openRow === index}
                            timeout="auto"
                            unmountOnExit
                          >
                            {onExpandRow(record)}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                )
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {loading && <Loader minHeight="100px" />}
      {!hidePagination && (
        <TablePagination
          sx={{ width: '100%', boxSizing: 'border-box' }}
          rowsPerPageOptions={[listPerPage]}
          component="div"
          count={data.length}
          rowsPerPage={listPerPage}
          page={page}
          onPageChange={(_, page) => setPage(page)}
          noPageField={noPageField}
        />
      )}
      {settingsDialogIsOpen && (
        <SettingsDialog
          columns={columnsProps}
          open={settingsDialogIsOpen}
          handleClose={() => setSettingsDialogIsOpen(false)}
          handleReset={resetSettings}
          handleSaveAndClose={saveSettings}
          onSelect={handleSelectColumn}
          onSort={setColumnProps}
          listPerPage={listPerPage}
          onListPerPageChange={(value) =>
            setListPerPage(value ? parseInt(value) : '')
          }
          defaultSortField={defaultSortField}
          defaultSortDirection={defaultSortDirection}
          onDefaultSortFieldChange={handleChangeDefaultSortField}
          onDefaultSortDirectionChange={handleChangeDefaultSortDirection}
        />
      )}
      {filterFormIsOpen && filterForm(handleCloseFilterForm)}
    </>
  )
}

DataTable.defaultProps = {
  autoWidth: false,
  sortDirection: 'asc',
  fieldsMapping: {},
  sortMapping: {},
  bulkActions: [],
  loading: false,
  defaultListPerPage: 10,
  disableSorting: false,
}

DataTable.propTypes = {
  name: PropTypes.string.isRequired,
  autoWidth: PropTypes.bool,
  data: PropTypes.array.isRequired,
  refreshData: PropTypes.func,
  selected: PropTypes.array,
  selectOnRowClick: PropTypes.bool,
  onSelect: PropTypes.func,
  selectable: PropTypes.bool,
  model: PropTypes.shape({
    primaryKey: PropTypes.string.isRequired,
    columns: PropTypes.array,
  }),
  listDisplay: PropTypes.array.isRequired,
  defaultListPerPage: PropTypes.number,
  sortMapping: PropTypes.object,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
    }),
  ),
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.node,
    }),
  ),
  onAction: PropTypes.func,
  noExport: PropTypes.bool,
  noToolbar: PropTypes.bool,
  noSettings: PropTypes.bool,
  noBulkSelection: PropTypes.bool,
  fieldsMapping: PropTypes.object,
  onExpandRow: PropTypes.func,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  filterForm: PropTypes.func,
  filterFormActive: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  hidePagination: PropTypes.bool,
  disableSorting: PropTypes.bool,
  disableSticky: PropTypes.bool,
  noPageField: PropTypes.bool,
  disableResizing: PropTypes.bool,
}

export default React.memo(DataTable)
