import { always } from 'ramda'

import { isAdmin } from '@Common/Utils/Permissions'

const UsersPermissions = {
  read: always(true),
  create: isAdmin,
  update: isAdmin,
  delete: isAdmin,
}

export default UsersPermissions
