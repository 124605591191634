import React from 'react'
import { CampaignType } from '@Campaigns/Models/Campaign'
import DataTable, { getDefaultListPerPage } from '@Common/Components/DataTable/Server'
import { apiList } from '@Core/Services/Api'
import { useCampaignExecutionsQuery } from '@Campaigns/Services/Api'
import CampaignExecution from '@Campaigns/Models/CampaignExecution'
import Link from '@Common/Components/Link'
import { makePath } from '@Config'
import { defaultTo } from 'ramda'

const LIST_DISPLAY = [
  'device.deviceId',
  'device.vin',
  'jobExecutionStatus.status',
  'jobExecutionStatus.timestamp',
  'campaignInfo.bundleVersion',

]
const FIELDS_MAPPING = {
  'device.vin': (record) => (
    <Link to={makePath('vehicles.detail', { deviceId: record.device.deviceId })}>
      {record.device.vin}
    </Link>
  ),
  'campaignInfo.bundleVersion': (record) => (
    <Link to={makePath('bundles.detail', { bundleId: record.campaignInfo.bundleId })}>
      {record.campaignInfo.bundleVersion}
    </Link>
  ),
  'jobExecutionStatus.status': (record) => defaultTo('', record.jobExecutionStatus?.status).replace('_', ' ')
}

const CampaignExecutionsList = ({ campaign }) => {
  const qsAdditions = {}
  const [qs, setQs] = React.useState({
    base: {
      pageSize: getDefaultListPerPage('campaign-executions'),
      pageNumber: 0,
      orderBy: 'jobExecutionStatus.timestamp',
      orderType: 'desc',
    },
    qsAdditions,
  })


  const refreshData = setQs
  const { data: campaignExecutions, isFetching, orData } = apiList(useCampaignExecutionsQuery({ campaignId: campaign._id,  qs: { ...qs.base, ...qsAdditions }}))

  return (
    <DataTable
      name="campaign-executions"
      data={campaignExecutions}
      refreshData={refreshData}
      qs={qs}
      dataCount={orData?.totalSize || -1}
      dftSortField="jobExecutionStatus.timestamp"
      dftSortDirection="desc"
      model={CampaignExecution}
      listDisplay={LIST_DISPLAY}
      fieldsMapping={FIELDS_MAPPING}
      loading={isFetching}
    />
  )
}

CampaignExecutionsList.propTypes = {
  campaign: CampaignType,
}

export default CampaignExecutionsList
