import { Grid } from '@mui/material'
// import { compose, nthArg } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import BaseLayout from '@Common/Layouts/BaseLayout'
import { getRequestError } from '@Common/Utils/Api'
import { withLoader } from '@Common/Utils/HOF'
import { useBreadcrumbs, usePageNotFoundRedirect } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import OngoingDeltas from '@SoftwareCatalog/Components/OngoingDeltas'
import SwComponentInfoBox from '@SoftwareCatalog/Components/SwComponentInfoBox'
import SwDeltaList from '@SoftwareCatalog/Components/SwDeltaList'
import { useLazyBundleQuery } from '@SoftwareCatalog/Services/Api'
import { useLazySwComponentsQuery } from '@SoftwareCatalog/Services/SwApi'

const Section = styled.section``
const SectionHeader = styled.h3`
  color: ${({ theme }) => theme.palette.content.contrastText};
`

const SwComponentDetailView = () => {
  const { t } = useTranslation()
  const [fetchBundle] = useLazyBundleQuery()
  const [fetchSoftwareComponents] = useLazySwComponentsQuery()
  const { bundleId, swComponentName, swComponentVersion } = useParams()
  const [bundle, setBundle] = React.useState(null)
  const [swComponent, setSwComponent] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  // const [activeTab, setActiveTab] = React.useState(0)
  const activeTab = 0

  const handleFetchSoftwareComponents = async () => {
    setIsLoading(true)
    const qs = {
      base: {
        pageNumber: 0,
        pageSize: 1,
        orderBy: '_id',
        orderType: 'asc',
      },
      qsAdditions: {
        domain: bundle.bundleInfo.domain,
        ring: bundle.bundleInfo.ring,
        deviceType: bundle.bundleInfo.deviceType,
        name: swComponentName,
        version: swComponentVersion,
      },
    }
    const resSC = await fetchSoftwareComponents({ ...qs.base, ...qs.qsAdditions })
    setSwComponent(resSC.data.response[0])
    setIsLoading(false)
  }

  // fetch bundle on component did mount
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const resB = await fetchBundle(bundleId)
        const bundle = resB.data
        setBundle(bundle)
      } catch (e) {
        setIsLoading(false)
        toast.error(t('sc:errors.FetchScError', { error: getRequestError(e) }))
      }
    }

    fetchData()
  }, [])

  // fetch software components when bundle changes
  React.useEffect(() => {
    if (bundle?._id) {
      handleFetchSoftwareComponents()
    }
  }, [bundle?._id])

  useBreadcrumbs(
    [
      { label: t('navigation.Home'), path: makePath('home') },
      { label: t('navigation.Bundles'), path: makePath('bundles.main') },
      { label: bundleId, path: makePath('bundles.detail', { bundleId: bundleId }) },
      { label: `${swComponent?.swComponentInfo.name} (${swComponent?.swComponentInfo.version})` },
    ],
    !!(bundle?._id && swComponent?._id),
    [bundle?._id, swComponent?._id],
  )

  usePageNotFoundRedirect(!isLoading && !swComponent?._id, [isLoading, swComponent])

  return (
    <BaseLayout>
      {withLoader(
        () => (
          <div>
            {/*
            <Tabs value={activeTab} onChange={compose(setActiveTab, nthArg(1))}>
              <Tab label={t('sc:ui.ComponentDetails')} />
              <Tab label={t('sc:ui.OngoingDeltaCalculations')} />
            </Tabs>
            */}
            {activeTab === 0 && (
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <Section>
                    <SectionHeader>{t('sc:ui.SwInformation')}</SectionHeader>
                    {withLoader(
                      () => (
                        <div>
                          <SwComponentInfoBox SwComponent={swComponent} />
                        </div>
                      ),
                      isLoading,
                    )}
                  </Section>
                </Grid>
                <Grid item md={9}>
                  <Section>
                    <SectionHeader>{t('sc:ui.SwDelta')}</SectionHeader>
                    <SwDeltaList data={swComponent} onRefetch={handleFetchSoftwareComponents}></SwDeltaList>
                  </Section>
                </Grid>
              </Grid>
            )}
            {activeTab === 1 && <OngoingDeltas />}
          </div>
        ),
        isLoading || !swComponent?._id,
      )}{' '}
    </BaseLayout>
  )
}

export default SwComponentDetailView
