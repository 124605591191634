import { FormControlLabel as MuiControlLabel } from '@mui/material'
import styled from 'styled-components'

export const FormControl = styled.div`
  margin-bottom: ${({ compact }) => (compact ? '.5rem' : '1rem')};
`

export const FormControlLabel = styled(MuiControlLabel)`
  color: ${({ theme }) => theme.palette.content.contrastText};
`

export const Help = styled.div`
  color: ${(props) => (props.error ? '#f44336' : 'rgba(0, 0, 0, 0.54)')};
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`
