import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  padding: .5rem 0;
`

const TabPanel = ({ activeTab, index, children }) => {
  if (index !== activeTab) return null
  return (
    <Container>{children}</Container>
  )
}

TabPanel.propTypes = {
  activeTab: PropTypes.number,
  index: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default TabPanel
