import { Alert, Checkbox, Grid, Switch } from '@mui/material'
import PropTypes from 'prop-types'
import { compose, path } from 'ramda'
import React from 'react'
import { useTranslation } from 'react-i18next'

import JobAbortActionDropdown from '@Campaigns/Components/JobAbortActionDropdown'
import JobFailureTypeField from '@Campaigns/Components/JobFailureTypeField'
import Box from '@Common/Components/Box'
import { FormControlLabel } from '@Common/Components/Forms'
import NumberTextField from '@Common/Components/NumberTextField'
import { setStateFromEvent } from '@Common/Utils/Events'
import styled from 'styled-components'

const OddGrid = styled(Grid)`
  background: ${({ theme }) => theme.palette.contrastExtraLight.main};
  margin-bottom: 1rem;
  margin-top: 1rem;
`

const CampaignJobAbortForm = ({
  fields,
  setField,
  setFields,
  errors,
  useAwsDefaults,
  setUseAwsDefaults,
}) => {
  const { t } = useTranslation()

  const customDefaults = {
    abortAllEnabled: false,
    abortAllAction: null,
    abortAllMinNumberOfExecutedThingsPercentage: null,
    abortAllThresholdPercentage: null,
    abortFailedEnabled: true,
    abortFailedAction: 'CANCEL',
    abortFailedMinNumberOfExecutedThingsPercentage: 10,
    abortFailedThresholdPercentage: 25,
    abortRejectedEnabled: true,
    abortRejectedAction: 'CANCEL',
    abortRejectedMinNumberOfExecutedThingsPercentage: 10,
    abortRejectedThresholdPercentage: 25,
    abortTimedOutEnabled: false,
    abortTimedOutAction: null,
    abortTimedOutMinNumberOfExecutedThingsPercentage: null,
    abortTimedOutThresholdPercentage: null,
  }

  const handleSetUseAwsDefaults = (value) => {
    // if value == true -> manual configuration is enabled
    if (!value) {
      setFields({
        ...fields,
        abortAllEnabled: false,
        abortAllAction: null,
        abortAllMinNumberOfExecutedThingsPercentage: null,
        abortAllThresholdPercentage: null,
        abortFailedEnabled: false,
        abortFailedAction: null,
        abortFailedMinNumberOfExecutedThingsPercentage: null,
        abortFailedThresholdPercentage: null,
        abortRejectedEnabled: false,
        abortRejectedAction: null,
        abortRejectedMinNumberOfExecutedThingsPercentage: null,
        abortRejectedThresholdPercentage: null,
        abortTimedOutEnabled: false,
        abortTimedOutAction: null,
        abortTimedOutMinNumberOfExecutedThingsPercentage: null,
        abortTimedOutThresholdPercentage: null,
      })
    } else {
      setFields({ ...fields, ...customDefaults })
    }
    setUseAwsDefaults(!value)
  }

  const handleToggleFailureType = (failureType) => (checked) => {
    if (checked) {
      if (failureType === 'All') {
        // uncheck and disable other fields
        setFields({
          ...fields,
          abortAllEnabled: true,
          abortFailedEnabled: false,
          abortRejectedEnabled: false,
          abortTimedOutEnabled: false,
        })
      } else {
        setFields({ ...fields, [`abort${failureType}Enabled`]: true })
      }
    } else {
      setFields({ ...fields, [`abort${failureType}Enabled`]: false })
    }
  }

  return (
    <>
      <Box direction="row" justify="center" width="100%" margin="0 0 1rem">
        <FormControlLabel
          control={
            <Switch
              checked={!useAwsDefaults}
              onChange={compose(handleSetUseAwsDefaults, path(['target', 'checked']))}
            />
          }
          label={t('campaigns:ui.EnableManualConfiguration')}
        />
      </Box>
      {!useAwsDefaults && errors.abortRequired && (
        <Alert severity="warning" sx={{ mb: '1rem' }}>
          {errors.abortRequired}
        </Alert>
      )}
      {/* Failure type FAILED */}
      <Grid container spacing={2} mb="1.5rem">
        <Grid item md={12}>
          <Box direction="row" align="center">
            <Checkbox
              checked={fields.abortFailedEnabled}
              disabled={useAwsDefaults || fields.abortAllEnabled}
              sx={{ pl: 0 }}
              onChange={compose(handleToggleFailureType('Failed'), path(['target', 'checked']))}
            ></Checkbox>
            <JobFailureTypeField
              value={'FAILED'}
              fullWidth
              helperText={t('campaigns:helperTexts.abortFailureType')}
              disabled={useAwsDefaults || fields.abortAllEnabled}
            />
          </Box>
        </Grid>
        <Grid item md={4}>
          <JobAbortActionDropdown
            required
            value={fields.abortFailedAction}
            onChange={setStateFromEvent(setField('abortFailedAction'))}
            fullWidth
            error={!!errors.abortFailedAction}
            helperText={errors.abortFailedAction || t('campaigns:helperTexts.abortAction')}
            disabled={useAwsDefaults || fields.abortAllEnabled}
          />
        </Grid>
        <Grid item md={4}>
          <NumberTextField
            required
            label={t('campaigns:fields.minNumberOfExecutedThingsPercentage')}
            type="number"
            value={fields.abortFailedMinNumberOfExecutedThingsPercentage}
            onChange={setStateFromEvent(setField('abortFailedMinNumberOfExecutedThingsPercentage'))}
            fullWidth
            error={!!errors.abortFailedMinNumberOfExecutedThingsPercentage}
            helperText={
              errors.abortFailedMinNumberOfExecutedThingsPercentage || t('campaigns:helperTexts.abortMinNumberOfExecutedThings')
            }
            disabled={useAwsDefaults || fields.abortAllEnabled}
            min={1}
          />
        </Grid>
        <Grid item md={4}>
          <NumberTextField
            required
            label={t('campaigns:fields.thresholdPercentage')}
            type="number"
            value={fields.abortFailedThresholdPercentage}
            onChange={setStateFromEvent(setField('abortFailedThresholdPercentage'))}
            fullWidth
            error={!!errors.abortFailedThresholdPercentage}
            helperText={errors.abortFailedThresholdPercentage || t('campaigns:helperTexts.abortThresholdPercentage')}
            disabled={useAwsDefaults || fields.abortAllEnabled}
            min={0}
            max={100}
          />
        </Grid>
      </Grid>

      {/* Failure type REJECTED */}
      <OddGrid container spacing={2} mb="1.5rem">
        <Grid item md={12}>
          <Box direction="row" align="center">
            <Checkbox
              checked={fields.abortRejectedEnabled}
              disabled={useAwsDefaults || fields.abortAllEnabled}
              sx={{ pl: 0 }}
              onChange={compose(handleToggleFailureType('Rejected'), path(['target', 'checked']))}
            ></Checkbox>
            <JobFailureTypeField
              value={'REJECTED'}
              fullWidth
              helperText={t('campaigns:helperTexts.abortFailureType')}
              disabled={useAwsDefaults || fields.abortAllEnabled}
            />
          </Box>
        </Grid>
        <Grid item md={4}>
          <JobAbortActionDropdown
            required
            value={fields.abortRejectedAction}
            onChange={setStateFromEvent(setField('abortRejectedAction'))}
            fullWidth
            error={!!errors.abortRejectedAction}
            helperText={errors.abortRejectedAction || t('campaigns:helperTexts.abortAction')}
            disabled={useAwsDefaults || fields.abortAllEnabled}
          />
        </Grid>
        <Grid item md={4}>
          <NumberTextField
            required
            label={t('campaigns:fields.minNumberOfExecutedThingsPercentage')}
            type="number"
            value={fields.abortRejectedMinNumberOfExecutedThingsPercentage}
            onChange={setStateFromEvent(setField('abortRejectedMinNumberOfExecutedThingsPercentage'))}
            fullWidth
            error={!!errors.abortRejectedMinNumberOfExecutedThingsPercentage}
            helperText={
              errors.abortRejectedMinNumberOfExecutedThingsPercentage || t('campaigns:helperTexts.abortMinNumberOfExecutedThings')
            }
            disabled={useAwsDefaults || fields.abortAllEnabled}
            min={1}
          />
        </Grid>
        <Grid item md={4}>
          <NumberTextField
            required
            label={t('campaigns:fields.thresholdPercentage')}
            type="number"
            value={fields.abortRejectedThresholdPercentage}
            onChange={setStateFromEvent(setField('abortRejectedThresholdPercentage'))}
            fullWidth
            error={!!errors.abortRejectedThresholdPercentage}
            helperText={errors.abortRejectedThresholdPercentage || t('campaigns:helperTexts.abortThresholdPercentage')}
            disabled={useAwsDefaults || fields.abortAllEnabled}
            min={0}
            max={100}
          />
        </Grid>
      </OddGrid>

      {/* Failure type TIMED_OUT */}
      <Grid container spacing={2} mb="1.5rem">
        <Grid item md={12}>
          <Box direction="row" align="center">
            <Checkbox
              checked={fields.abortTimedOutEnabled}
              disabled={useAwsDefaults || fields.abortAllEnabled}
              sx={{ pl: 0 }}
              onChange={compose(handleToggleFailureType('TimedOut'), path(['target', 'checked']))}
            ></Checkbox>
            <JobFailureTypeField
              value={'TIMED_OUT'}
              fullWidth
              helperText={t('campaigns:helperTexts.abortFailureType')}
              disabled={useAwsDefaults || fields.abortAllEnabled}
            />
          </Box>
        </Grid>
        <Grid item md={4}>
          <JobAbortActionDropdown
            required
            value={fields.abortTimedOutAction}
            onChange={setStateFromEvent(setField('abortTimedOutAction'))}
            fullWidth
            error={!!errors.abortTimedOutAction}
            helperText={errors.abortTimedOutAction || t('campaigns:helperTexts.abortAction')}
            disabled={useAwsDefaults || fields.abortAllEnabled}
          />
        </Grid>
        <Grid item md={4}>
          <NumberTextField
            required
            label={t('campaigns:fields.minNumberOfExecutedThings')}
            type="number"
            value={fields.abortTimedOutMinNumberOfExecutedThingsPercentage}
            onChange={setStateFromEvent(setField('abortTimedOutMinNumberOfExecutedThingsPercentage'))}
            fullWidth
            error={!!errors.abortTimedOutMinNumberOfExecutedThingsPercentage}
            helperText={
              errors.abortTimedOutMinNumberOfExecutedThingsPercentage || t('campaigns:helperTexts.abortMinNumberOfExecutedThings')
            }
            disabled={useAwsDefaults || fields.abortAllEnabled}
            min={1}
          />
        </Grid>
        <Grid item md={4}>
          <NumberTextField
            required
            label={t('campaigns:fields.thresholdPercentage')}
            type="number"
            value={fields.abortTimedOutThresholdPercentage}
            onChange={setStateFromEvent(setField('abortTimedOutThresholdPercentage'))}
            fullWidth
            error={!!errors.abortTimedOutThresholdPercentage}
            helperText={errors.abortTimedOutThresholdPercentage || t('campaigns:helperTexts.abortThresholdPercentage')}
            disabled={useAwsDefaults || fields.abortAllEnabled}
            min={0}
            max={100}
          />
        </Grid>
      </Grid>

      {/* Failure type ALL */}
      <OddGrid container spacing={2} mb="1rem">
        <Grid item md={12}>
          <Box direction="row" align="center">
            <Checkbox
              checked={fields.abortAllEnabled}
              disabled={useAwsDefaults}
              sx={{ pl: 0 }}
              onChange={compose(handleToggleFailureType('All'), path(['target', 'checked']))}
            ></Checkbox>
            <JobFailureTypeField
              value={'ALL'}
              fullWidth
              helperText={t('campaigns:helperTexts.abortFailureType')}
              disabled={useAwsDefaults}
            />
          </Box>
        </Grid>
        <Grid item md={4}>
          <JobAbortActionDropdown
            required
            value={fields.abortAllAction}
            onChange={setStateFromEvent(setField('abortAllAction'))}
            fullWidth
            error={!!errors.abortAllAction}
            helperText={errors.abortAllAction || t('campaigns:helperTexts.abortAction')}
            disabled={useAwsDefaults}
          />
        </Grid>
        <Grid item md={4}>
          <NumberTextField
            required
            label={t('campaigns:fields.minNumberOfExecutedThingsPercentage')}
            type="number"
            value={fields.abortAllMinNumberOfExecutedThingsPercentage}
            onChange={setStateFromEvent(setField('abortAllMinNumberOfExecutedThingsPercentage'))}
            fullWidth
            error={!!errors.abortAllMinNumberOfExecutedThingsPercentage}
            helperText={
              errors.abortAllMinNumberOfExecutedThingsPercentage || t('campaigns:helperTexts.abortMinNumberOfExecutedThings')
            }
            disabled={useAwsDefaults}
            min={1}
          />
        </Grid>
        <Grid item md={4}>
          <NumberTextField
            required
            label={t('campaigns:fields.thresholdPercentage')}
            type="number"
            value={fields.abortAllThresholdPercentage}
            onChange={setStateFromEvent(setField('abortAllThresholdPercentage'))}
            fullWidth
            error={!!errors.abortAllThresholdPercentage}
            helperText={errors.abortAllThresholdPercentage || t('campaigns:helperTexts.abortThresholdPercentage')}
            disabled={useAwsDefaults}
            min={0}
            max={100}
          />
        </Grid>
      </OddGrid>
    </>
  )
}

CampaignJobAbortForm.propTypes = {
  fields: PropTypes.object,
  setField: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
  useAwsDefaults: PropTypes.bool,
  setUseAwsDefaults: PropTypes.func.isRequired,
}

export default CampaignJobAbortForm
