import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'

import BaseLayout from '@Common/Layouts/BaseLayout'
import { withLoader, withPageNotFound } from '@Common/Utils/HOF'
import { useBreadcrumbs, usePageNotFoundRedirect } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import BundleInfoBox from '@SoftwareCatalog/Components/BundleInfoBox'
import SwList from '@SoftwareCatalog/Components/SwList'
import { useBundleQuery } from '@SoftwareCatalog/Services/Api'

const Section = styled.section``
const SectionHeader = styled.h3`
  color: ${({ theme }) => theme.palette.content.contrastText};
`

const BundleDetailView = () => {
  const { t } = useTranslation()
  const { bundleId } = useParams()
  const { data: bundle, isFetching: isFetchingBundle, isSuccess, isError } = useBundleQuery(bundleId)

  useBreadcrumbs([
    { label: t('navigation.Home'), path: makePath('home') },
    { label: t('navigation.Bundles'), path: makePath('bundles.main') },
    { label: bundleId },
  ])

  usePageNotFoundRedirect(!isFetchingBundle && !bundle?._id, [isFetchingBundle, bundle])

  return (
    <BaseLayout>
      {withLoader(
        withPageNotFound(
          () => (
            <Grid container spacing={2}>
              <Grid item md={3}>
                <Section>
                  <SectionHeader>{t('sc:ui.Information')}</SectionHeader>
                  {withLoader(
                    () => (
                      <BundleInfoBox bundle={bundle} />
                    ),
                    isFetchingBundle,
                  )}
                </Section>
              </Grid>
              <Grid item md={9}>
                <Section>
                  <SectionHeader>{t('sc:ui.SoftwareComponents')}</SectionHeader>
                  <SwList bundle={bundle} isFetching={isFetchingBundle}></SwList>
                </Section>
              </Grid>
            </Grid>
          ),
          (isSuccess || isError) && !bundle?._id,
          t('sc:ui.BundleNotFound')
        ),
        isFetchingBundle || !bundle?._id,
      )}{' '}
    </BaseLayout>
  )
}

export default BundleDetailView
