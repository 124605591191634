import PropTypes from 'prop-types'
import { always, both, cond, equals, flip, gte, ifElse, lte, pipe, prop, T } from 'ramda'
import ProgressBar from '@Common/Components/ProgressBar'

const DeltaProgress = ifElse(
  pipe(prop('progress'), equals(100)),
  always(null),
  pipe(
    prop('progress'),
    cond([
      [both(flip(lte)(100), flip(gte)(0)), (progress) => <ProgressBar progress={progress} />],
      [T, always(null)],
    ])
  ),
)

DeltaProgress.propTypes = {
  status: PropTypes.string,
}

export default DeltaProgress
