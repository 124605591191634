import i18next from "i18next"
import { T } from "ramda"
import { toast } from "react-toastify"
import Logger from "./Logger"

export const getRequestError = (error) => error // @TODO

export const responseTextError = (err) => err?.data?.responseMessage || i18next.t('errors.UnknownApiError')
export const isSuccessful = T // responses are not wrapped, error is catched in try block

export const request = async (
  endpoint,
  args,
  successText = null,
  errorTextId = null,
  unwrap = true,
) => {
  try {
    const response = unwrap
      ? await endpoint(...args).unwrap()
      : await endpoint(...args)
    if (isSuccessful(response)) {
      successText && toast.success(successText)
      return { response, isSuccessful: true }
    } else {
      errorTextId &&
        toast[response?.statusCode === 409 ? 'warning' : 'error'](
          i18next.t(errorTextId, { error: responseTextError(response) }),
        )
      return { response, isSuccessful: false }
    }
  } catch (err) {
    Logger.error('Request error: ', err)
    errorTextId &&
      toast.error(i18next.t(errorTextId, { error: responseTextError(err) }))
    return { response: await err.response, isSuccessful: false }
  }
}
