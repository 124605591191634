import PropTypes from 'prop-types'

import CampaignInfoForm from '@Campaigns/Forms/CampaignInfoForm'

const StepInfo = ({ fields, setField, setFields, errors, setErrors, onCancel, onNext }) => (
  <div>
    <CampaignInfoForm
      fields={fields}
      setField={setField}
      setFields={setFields}
      errors={errors}
      setErrors={setErrors}
      onCancel={onCancel}
      onSubmit={onNext}
    />
  </div>
)


StepInfo.propTypes = {
  fields: PropTypes.object,
  setField: PropTypes.func.isRequired,
  setFields: PropTypes.func.isRequired,
  errors: PropTypes.object,
  setErrors: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
}

export default StepInfo
