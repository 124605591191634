import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'

import CampaignsList from '@Campaigns/Components/CampaignsList'
import BaseLayout from '@Common/Layouts/BaseLayout'
import { withLoader } from '@Common/Utils/HOF'
import { useBreadcrumbs, usePageNotFoundRedirect } from '@Common/Utils/Hooks'
import { makePath } from '@Config'
import VehicleInfoBox from '@Vehicles/Components/VehicleInfoBox'
import { useDeviceQuery } from '@Vehicles/Services/Api/Devices'
import ExecutionsList from '@Vehicles/Components/ExecutionsList'

const Section = styled.section``
const SectionHeader = styled.h3`
  color: ${({ theme }) => theme.palette.content.contrastText};
  margin: 0 1rem 1rem;
`

/**
 * Vehicle detail view component
 */
const VehicleDetailView = () => {
  const { t } = useTranslation()
  const { deviceId } = useParams()
  const { data: device, isFetching: isFetchingDevice } = useDeviceQuery(deviceId)

  useBreadcrumbs([
    { label: t('navigation.Home'), path: makePath('home') },
    { label: t('navigation.Vehicles'), path: makePath('vehicles.main') },
    { label: deviceId },
  ])

  usePageNotFoundRedirect(!isFetchingDevice && !device?.thingId, [isFetchingDevice, device])

  return (
    <BaseLayout>
      {withLoader(
        () => (
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Section>
                <SectionHeader>{t('vehicles:ui.Information')}</SectionHeader>
                {withLoader(
                  () => (
                    <VehicleInfoBox device={device} />
                  ),
                  isFetchingDevice,
                )}
              </Section>
            </Grid>
            <Grid item md={9}>
              <Section>
                <SectionHeader>{t('vehicles:ui.Executions')}</SectionHeader>
                <ExecutionsList device={device} />
                <SectionHeader>{t('vehicles:ui.Campaigns')}</SectionHeader>
                <CampaignsList vin={device.attributes.vin} />
              </Section>
            </Grid>
          </Grid>
        ),
        isFetchingDevice || !device?.thingId,
      )}
    </BaseLayout>
  )
}

export default VehicleDetailView
