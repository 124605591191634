import React from 'react'
import WebSocket from "@Common/Components/WebSocket"
import Logger from "@Common/Utils/Logger"
import { useDispatch, useSelector } from 'react-redux'
import { api } from '@Core/Services/Api'
import { selectAccessToken } from '@Auth/Redux'

const NotificationsWebSocket = () => {
  const dispatch = useDispatch()
  const token = useSelector(selectAccessToken)

  const handleError = (err) => {
    Logger.error('WS notifications error', err)
  }

  const handleMessage = (message) => {
    Logger.info('WS notifications message', message)
    // refresh notifications
    dispatch(api.util.invalidateTags(['Notification', 'Campaign']))
  }

  return token ? (
    <WebSocket
      token={token}
      name='notifications'
      path=''
      onError={handleError}
      onMessage={handleMessage}
    />
  ) : null
}

export default React.memo(NotificationsWebSocket)
